import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  background: #ffffff;
  padding: 1rem;
  width: 100%;
  max-height: 311px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 12px);
  .card-info {
    display: flex;
    gap: 12px;
    width: 50%;
    .icon {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    .card-title {
      align-self: center;
      line-height: 22px;
      font-weight: 400;
      font-size: 14px;
    }
  }
  .card-counter {
    font-size: 24px;
    font-weight: 700;
    margin-right: 16px;
    transition: all linear 0.25s;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export const NewWrapper = styled.div`
  position: relative;
  background: #ffffff;
  padding: 1rem;
  width: 100%;
  max-height: 311px;
  border-radius: 6px;
  align-items: center;
  margin-bottom: 16px;
  .card-info {
    display: flex;
    gap: 12px;
    width: 50%;
    .icon {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    .card-title {
      align-self: center;
      line-height: 22px;
      font-weight: 400;
      font-size: 14px;
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`
