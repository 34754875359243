import { action } from 'mobx'
import { UnitRequest } from '../requests/UnitRequest'
import { resolve } from 'path'
import { reject } from 'lodash'

class UnitStore {
  @action getUnit = (index, size, search) => {
    return new Promise((resolve, reject) => {
      UnitRequest.getUnit(index, size, search)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getUnitById = id => {
    return new Promise((resolve, reject) => {
      UnitRequest.getUnitById(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createUnit = data => {
    return new Promise((resolve, reject) => {
      UnitRequest.createUnit(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateUnit = data => {
    return new Promise((resolve, reject) => {
      UnitRequest.updateUnit(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getUnitIdByUnitName = name => {
    return new Promise((resolve, reject) => {
      UnitRequest.getUnitIdByUnitName(name)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteUnit = id => {
    return new Promise((resolve, reject) => {
      UnitRequest.deleteUnit(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action addLog = data => {
    return new Promise((resolve, reject) => {
      UnitRequest.addLog(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new UnitStore()
