import { action } from 'mobx'
import { ImportRequest } from '../requests/ImportRequest'

class ImportStore {
  @action getImportInvoice = (index, size, search, filters) => {
    return new Promise((resolve, reject) => {
      ImportRequest.getImportInvoice(index, size, search, filters)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getImportInvoiceDetail = id => {
    return new Promise((resolve, reject) => {
      ImportRequest.getImportInvoiceDetail(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action addAuditLog = request => {
    return new Promise((resolve, reject) => {
      ImportRequest.addAuditLog(request)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteImportInvoice = id => {
    return new Promise((resolve, reject) => {
      ImportRequest.deleteImportInvoice(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createImportInvoice = data => {
    return new Promise((resolve, reject) => {
      ImportRequest.createImportInvoice(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action checkExistInvoiceNo = inv_no => {
    return new Promise((resolve, reject) => {
      ImportRequest.checkExistInvoiceNo(inv_no)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateImportInvoice = data => {
    return new Promise((resolve, reject) => {
      ImportRequest.updateImportInvoice(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateStatus = (id, status) => {
    return new Promise((resolve, reject) => {
      ImportRequest.updateStatus(id, status)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getPending = () => {
    return new Promise((resolve, reject) => {
      ImportRequest.getPending()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action sendEmail = (to, subject, content, cc) => {
    return new Promise((resolve, reject) => {
      ImportRequest.sendEmail(to, subject, content, cc)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new ImportStore()
