import { action } from 'mobx'
import { DepartmentRequest } from '../requests/DepartmentRequest'

class DepartmentStore {
  @action getDepartment = (index, size, search) => {
    return new Promise((resolve, reject) => {
      DepartmentRequest.getDepartment(index, size, search)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createDepartment = data => {
    return new Promise((resolve, reject) => {
      DepartmentRequest.createDepartment(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateDepartment = data => {
    return new Promise((resolve, reject) => {
      DepartmentRequest.updateDepartment(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deactiveDepartment = id => {
    return new Promise((resolve, reject) => {
      DepartmentRequest.deactiveDepartment(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action activeDepartment = id => {
    return new Promise((resolve, reject) => {
      DepartmentRequest.activeDepartment(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteDepartment = id => {
    return new Promise((resolve, reject) => {
      DepartmentRequest.deleteDepartment(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getDepartmentMonthlyCost = year => {
    return new Promise((resolve, reject) => {
      DepartmentRequest.getDepartmentMonthlyCost(year)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action checkExistDepartment = name => {
    return new Promise((resolve, reject) => {
      DepartmentRequest.checkExistDepartment(name)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getDepartmentByName = name => {
    return new Promise((resolve, reject) => {
      DepartmentRequest.getDepartmentByName(name)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getTotal = () => {
    return new Promise((resolve, reject) => {
      DepartmentRequest.getTotal()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getDepartmentByItem = request => {
    return new Promise((resolve, reject) => {
      DepartmentRequest.getDepartmentByItem(request)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action addLog = data => {
    return new Promise((resolve, reject) => {
      DepartmentRequest.addLog(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getbyId = id => {
    return new Promise((resolve, reject) => {
      DepartmentRequest.getDepartmentbyId(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new DepartmentStore()
