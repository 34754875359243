import styled from 'styled-components'

export const SearchBar = styled.div`
  width: 350px;
  margin-right: 15px;
  margin-bottom: 15px;
`

export const DepartmentTable = styled.div`
  .ant-table-thead > tr > th {
    text-align: center;
    vertical-align: middle;
  }

  .ant-table-thead > tr > th {
    font-weight: bold;
  }
`
