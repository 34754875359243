import React from 'react'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { Modal } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import htmlToPdfmake from 'html-to-pdfmake'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

/** App utils */
const { confirm } = Modal
const utils = {
  getBase64FromImageUrl(url) {
    return new Promise((resolve, reject) => {
      var img = new Image()
      img.setAttribute('crossOrigin', 'anonymous')

      img.onload = () => {
        var canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height

        var ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)

        var dataURL = canvas.toDataURL('image/png')

        resolve(dataURL)
      }

      img.onerror = error => {
        reject(error)
      }

      img.src = url
    })
  },

  /** remove html */
  removeHtml: string => {
    if (!string) return ''
    return string
      .replace(/<[^>]+>/g, '')
      .replace(/&nbsp;/g, '')
      .replace(/&amp;/g, '&')
      .replace(/&lt;/, '<')
      .replace(/&gt;/, '>')
      .replace(/&le;/, '≤')
      .replace(/&ge;/, '≥')
  },

  /** Compare time, return -1 for sooner, 0 for equal, 1 for later */
  compareTime: (DateA, DateB) => {
    let a = new Date(DateA)
    let b = new Date(DateB)
    let msDateA = Date.UTC(a.getFullYear(), a.getMonth() + 1, a.getDate())
    let msDateB = Date.UTC(b.getFullYear(), b.getMonth() + 1, b.getDate())
    if (parseFloat(msDateA) < parseFloat(msDateB)) return -1
    // lt
    else if (parseFloat(msDateA) === parseFloat(msDateB)) return 0
    // eq
    else if (parseFloat(msDateA) > parseFloat(msDateB)) return 1
    // gt
    else return null // error
  },

  /** Decode JWT token */
  jwtDecode: token => {
    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    return JSON.parse(jsonPayload)
  },

  /** Check nullish */
  isNullish: value => {
    return value === undefined || value === null || value === ''
  },

  /** Capitalize name */
  getNameInCapitalize: string => {
    if (!string) return ''
    return string.toLowerCase().replace(/(^|\s)\S/g, l => l.toUpperCase())
  },

  isUpper: str => {
    if (typeof str !== 'string') return false
    return !/[a-z]/.test(str) && /[A-Z]/.test(str)
  },

  checkForDuplicates: array => {
    let valuesAlreadySeen = []

    for (let i = 0; i < array.length; i++) {
      let value = array[i]
      if (valuesAlreadySeen.indexOf(value) !== -1) {
        return true
      }
      valuesAlreadySeen.push(value)
    }
    return false
  },

  exportToExcel: ({ data, fileName }) => {
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(data)

    // Auto-fit columns
    const columnWidths = data.reduce((widths, row) => {
      return Object.keys(row).map((key, i) => {
        const value = row[key] ? row[key].toString() : ''
        // Calculate the max length between current value and the header
        const headerLength = key.length
        return Math.max(widths[i] || 10, value.length, headerLength)
      })
    }, [])

    // Apply column widths
    worksheet['!cols'] = columnWidths.map(width => ({ wch: width }))

    // Style the title row
    const range = XLSX.utils.decode_range(worksheet['!ref'])
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_cell({ r: 0, c: C })
      if (!worksheet[address]) continue

      worksheet[address].v = worksheet[address].v.toUpperCase()

      // Add styling
      worksheet[address].s = {
        font: { bold: true },
        alignment: { horizontal: 'center' },
      }
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, 'sheet1')
    XLSX.writeFile(workbook, `${fileName}.xlsx`)
  },

  getParameterByName: (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&')
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  },

  confirmDelete: handleDelete => {
    return confirm({
      title: 'Bạn có muốn xóa thông tin này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDelete()
      },
    })
  },

  async exportPDF(element, title, font) {
    const pdfContent = document.getElementById(element)
    //https://www.npmjs.com/package/html-to-pdfmake
    const html = htmlToPdfmake(pdfContent.innerHTML, {
      imagesByReference: true,
    })
    const originURL = window.location.origin
    pdfMake.fonts = {
      TimesNewRoman: {
        normal: `${originURL}/assets/fonts/timesNewRoman/Times-New-Roman-Regular.ttf`,
        bold: `${originURL}/assets/fonts/timesNewRoman/Times-New-Roman-Bold.ttf`,
        italics: `${originURL}/assets/fonts/timesNewRoman/Times-New-Roman-Italic.ttf`,
        bolditalics: `${originURL}/assets/fonts/timesNewRoman/Times-New-Roman-BoldItalic.ttf`,
      },
      Roboto: {
        normal: `${originURL}/assets/fonts/roboto/Roboto-Regular.ttf`,
        bold: `${originURL}/assets/fonts/roboto/Roboto-Bold.ttf`,
        italics: `${originURL}/assets/fonts/roboto/Roboto-Italic.ttf`,
        bolditalics: `${originURL}/assets/fonts/roboto/Roboto-BoldItalic.ttf`,
      },
      CourierNew: {
        normal: `${originURL}/assets/fonts/courierNew/Courier-New-Regular.ttf`,
        bold: `${originURL}/assets/fonts/courierNew/Courier-New-Bold.ttf`,
        italics: `${originURL}/assets/fonts/courierNew/Courier-New-Italic.ttf`,
        bolditalics: `${originURL}/assets/fonts/courierNew/Courier-New-BoldItalic.ttf`,
      },
      Arial: {
        normal: `${originURL}/assets/fonts/arial/Arial-Regular.ttf`,
        bold: `${originURL}/assets/fonts/arial/Arial-Bold.ttf`,
        italics: `${originURL}/assets/fonts/arial/Arial-Italic.ttf`,
        bolditalics: `${originURL}/assets/fonts/arial/Arial-BoldItalic.ttf`,
      },
    }
    const documentDefinition = {
      content: html.content,
      defaultStyle: {
        font: font || 'Roboto',
      },
      images: html.images,
    }
    pdfMake.vfs = pdfFonts.pdfMake.vfs
    pdfMake.createPdf(documentDefinition).download(`${title}.pdf`)
  },
}

export default utils
