import React, { memo, useEffect, useState, useCallback } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import {
  Table,
  Pagination,
  Button,
  Modal,
  Form,
  Input,
  message,
  Row,
  Col,
} from 'antd'
import { TableWrapper } from '../../components/Common/Table'
import {
  EditOutlined,
  DeleteOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { SearchBar, UnitTable } from './UnitPageStyled'
import { EmptyText } from '../../components/Common/CellText'
import { Container } from '../../layouts/Container/Container'
import * as signalR from '@microsoft/signalr'
import { apiUrl } from '../../config'

const { Search } = Input
const { confirm } = Modal

const UnitPage = props => {
  const { unitStore, loadingAnimationStore } = props
  const [unitData, setUnitData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [form] = Form.useForm()
  const [selectedUnitId, setSelectedUnitId] = useState(null)

  const fetchUnitData = useCallback(async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await unitStore.getUnit(page, pageSize, search)
      const { pageData, totalCount } = response.data
      const data = pageData.map((item, index) => ({
        key: item.id,
        stt: (page - 1) * pageSize + index + 1,
        unit_name: item.unit_name,
        description: item.description,
        id: item.id,
      }))
      setUnitData(data)
      setTotal(totalCount)
    } catch (error) {
      message.error('Có lỗi xảy ra khi lấy danh sách đơn vị')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [page, pageSize, search])

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiUrl}/unitHub`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.None)
      .build()

    connection.on('UnitSignal', () => {
      fetchUnitData()
    })

    connection
      .start()
      .then(() => {
        fetchUnitData()
      })
      .catch(() => {
        fetchUnitData()
      })

    return () => {
      connection.stop()
    }
  }, [fetchUnitData])

  const openModalWithRecord = (record = null) => {
    if (record) {
      setSelectedUnitId(record.id)
      form.setFieldsValue({
        unit_name: record.unit_name,
        description: record.description,
      })
    } else {
      setSelectedUnitId(null)
      form.resetFields()
    }
    setIsModalOpen(true)
  }

  const handleSearch = value => {
    console.log('value', value)
    setSearch(value)
    setPage(1)
  }

  const handleModalCancel = () => {
    setIsModalOpen(false)
    form.resetFields()
    setSelectedUnitId(null)
  }

  const handleDeleteUnit = async record => {
    try {
      await unitStore.deleteUnit(record.id)
      fetchUnitData()
      message.success('Xóa đơn vị thành công ')

      unitStore.addLog({
        table_name: 'Đơn vị',
        action: 'Xóa',
        oldObj: unitData?.find(item => item?.id === record?.id),
        newObj: {
          unit_name: '',
          description: '',
        },
      })
    } catch (error) {}
  }

  const handleModalOk = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      const values = await form.validateFields()

      if (selectedUnitId) {
        values.id = selectedUnitId
        await unitStore.updateUnit(values)
        message.success('Cập nhật đơn vị thành công!')

        unitStore.addLog({
          table_name: 'Đơn vị',
          action: 'Chỉnh sửa',
          newObj: values,
          oldObj: unitData?.find(s => s.id === selectedUnitId),
        })
      } else {
        await unitStore.createUnit(values)
        message.success('Thêm đơn vị mới thành công!')

        unitStore.addLog({
          table_name: 'Đơn vị',
          action: 'Thêm',
          newObj: values,
          oldObj: {
            unit_name: '',
            description: '',
          },
        })
      }
      setIsModalOpen(false)
      form.resetFields()
      setSelectedUnitId(null)
      fetchUnitData()
    } catch (error) {
      console.error(error.message)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const columns = [
    {
      title: 'STT',
      align: 'center',
      width: '50px',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'TÊN ĐƠN VỊ',
      key: 'unit_name',
      dataIndex: 'unit_name',
      sorter: (a, b) => a.unit_name.localeCompare(b.unit_name),
      width: 200,
      render: text => (
        <span>{text || <EmptyText>Chưa có tên đơn vị</EmptyText>}</span>
      ),
    },
    {
      title: 'MÔ TẢ',
      key: 'description',
      dataIndex: 'description',
      render: text => (
        <span>{text || <EmptyText>Chưa có mô tả</EmptyText>}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      width: 1,
      render: (text, record) => (
        <div style={{ display: 'flex' }}>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            size="small"
            onClick={() => openModalWithRecord(record)}></Button>
          <Button
            type="danger"
            shape="circle"
            icon={<DeleteOutlined />}
            style={{ marginLeft: 8 }}
            size="small"
            onClick={() => {
              confirm({
                title: 'Bạn có chắc chắn muốn xóa đơn vị này không?',
                icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
                okText: 'Đồng ý',
                cancelText: 'Không',
                okType: 'danger',
                onOk() {
                  handleDeleteUnit(record)
                },
              })
            }}></Button>
        </div>
      ),
    },
  ]

  return (
    <DashboardLayout>
      <Helmet>
        <title>Quản lý đơn vị</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title="Quản lý đơn vị"
        hiddenGoBack></PageTitle>
      <ContentBlockWrapper>
        <Container>
          <Row style={{ marginBottom: 0, height: '38px' }}>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  maxLength={500}
                  placeholder={'Tìm kiếm theo Tên đơn vị'}
                  onSearch={handleSearch}
                />
              </SearchBar>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button type="primary" onClick={() => openModalWithRecord()}>
                Thêm
              </Button>
            </Col>
          </Row>
          <TableWrapper>
            <UnitTable>
              <Table
                style={{
                  height: 'calc(100vh - 213px)',
                  overflowY: 'auto',
                }}
                columns={columns}
                dataSource={unitData}
                bordered
                pagination={false}
              />
            </UnitTable>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div>
                Tổng số: <b>{total}</b> bản ghi
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '8px',
                }}>
                <Pagination
                  onChange={(page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                  }}
                  current={page}
                  pageSize={pageSize}
                  total={total}
                  hideOnSinglePage={true}
                  showSizeChanger={true}
                  showLessItems
                />
              </div>
            </div>
          </TableWrapper>
        </Container>
      </ContentBlockWrapper>
      <Modal
        title={selectedUnitId ? 'Chỉnh sửa đơn vị' : 'Thêm đơn vị mới'}
        visible={isModalOpen}
        okText={selectedUnitId ? 'Cập nhật' : 'Thêm'}
        onCancel={handleModalCancel}
        onOk={handleModalOk}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="unit_name"
            label="Tên đơn vị"
            rules={[{ required: true, message: 'Vui lòng nhập tên đơn vị' }]}>
            <Input placeholder="Nhập tên đơn vị" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Mô tả"
            rules={[{ required: false }]}>
            <Input.TextArea rows={4} placeholder="Nhập mô tả" />
          </Form.Item>
        </Form>
      </Modal>
    </DashboardLayout>
  )
}

export default memo(
  inject('unitStore', 'loadingAnimationStore')(observer(UnitPage))
)
