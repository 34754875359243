import { apiUrl } from '../config'
import axios from 'axios'

export const ImportRequest = {
  getImportInvoice: (index, size, search, filters) =>
    axios.post(
      `${apiUrl}/api/v1/inward_stocks/paging_filter/`,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
        filters: filters,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  addAuditLog: request =>
    axios.post(`${apiUrl}/api/v1/inward_stocks/add_audit_log_custom`, request, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getImportInvoiceDetail: id =>
    axios.get(`${apiUrl}/api/v1/inward_stocks/get_master_details?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  deleteImportInvoice: id =>
    axios.post(
      `${apiUrl}/api/v1/inward_stocks/delete`,
      { id: id },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  createImportInvoice: data =>
    axios.post(`${apiUrl}/api/v1/inward_stocks/save_full`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  checkExistInvoiceNo: inv_no =>
    axios.get(
      `${apiUrl}/api/v1/inward_stocks/check_exist_invno?invNo=${inv_no}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  updateImportInvoice: data =>
    axios.post(`${apiUrl}/api/v1/inward_stocks/update`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  updateStatus: (id, status) =>
    axios.post(
      `${apiUrl}/api/v1/inward_stocks/update-status`,
      { id: id, status: status },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  getPending: () =>
    axios.get(`${apiUrl}/api/v1/inward_stocks/pending`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  sendEmail: (to, subject, content, cc) =>
    axios.post(
      `${apiUrl}/api/v1/inward_stocks/send-email`,
      { to: to, subject: subject, content: content, cc: cc },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),
}
