import styled from 'styled-components'
import { green } from '../../color'

export const ExportTable = styled.div`
  .ant-table-thead > tr > th {
    text-align: center;
    vertical-align: middle;
  }

  .ant-table-thead > tr > th {
    font-weight: bold;
  }
`

export const SearchBar = styled.div`
  width: 350px;
  margin-right: 15px;
  margin-bottom: 15px;
`

export const FilterButtonWrapper = styled.div`
  display: inline-block;
  justify-content: flex-end;
  position: relative;
  transition: 0.3s;

  .count-filter {
    z-index: 9;
    background-color: ${green};
    color: #fff;
    font-size: 11px;
    text-align: center;
    position: absolute;
    top: -7px;
    right: -7px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
  }
`
