import { Button, Col, Input, message, Pagination, Row, Table } from 'antd'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { SearchBar } from '../ImportPage/ImportPageStyled'
import { FormOutlined, PlusOutlined } from '@ant-design/icons'
import { TableWrapper } from '../../components/Common/Table'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import RoleModal from './RoleModal'
import { Container } from '../../layouts/Container/Container'
import * as signalR from '@microsoft/signalr'
import { apiUrl } from '../../config'

const { Search } = Input

const RoleTab = props => {
  const { roleStore, loadingAnimationStore, tabKey, accountStore } = props
  const [roleModalVisible, setRoleModalVisible] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  const [roles, setRoles] = useState([])
  const [selectedRoleId, setSelectedRoleId] = useState('')

  const fetchRolesData = useCallback(async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await roleStore.getRoles(page, pageSize, search)
      const { pageData, totalCount } = response.data
      const data = pageData.map((item, index) => ({
        key: item.id,
        stt: (page - 1) * pageSize + index + 1,
        role_code: item.role_code,
        role_name: item.role_name,
        description: item.description,
        id: item.id,
        permissions: item.permissions,
        accounts: item.accounts,
      }))
      setRoles(data)
      setTotal(totalCount)
    } catch (error) {
      message.error('Có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [roleStore, page, pageSize, search])

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiUrl}/roleHub`)
      .configureLogging(signalR.LogLevel.None)
      .withAutomaticReconnect()
      .build()

    connection.on('RoleSignal', () => {
      fetchRolesData()
    })

    connection
      .start()
      .then(() => {
        fetchRolesData()
      })
      .catch(() => {
        fetchRolesData()
      })

    return () => {
      connection.stop()
    }
  }, [fetchRolesData, tabKey])

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      render: (text, record, index) => (
        <span>{(page - 1) * pageSize + index + 1}</span>
      ),
    },
    {
      title: 'MÃ VAI TRÒ',
      key: 'role_code',
      dataIndex: 'role_code',
      sorter: (a, b) => a.role_code.localeCompare(b.role_code),
    },
    {
      title: 'TÊN VAI TRÒ',
      key: 'role_name',
      dataIndex: 'role_name',
      sorter: (a, b) => a.role_name.localeCompare(b.role_name),
    },
    {
      title: 'MÔ TẢ',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: 'Chức năng',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <a onClick={() => onUpdateRole(record.id)}>
          <FormOutlined style={{ marginRight: 8 }} />
          Phân quyền và chọn người dùng
        </a>
      ),
    },
  ]

  const handleOpenRoleModal = () => {
    setRoleModalVisible(true)
  }

  const handleCloseRoleModal = () => {
    setSelectedRoleId(null)
    setRoleModalVisible(false)
  }

  const handleSaveRoleModal = (id, values) => {
    handleUpdateRole(id, values)
  }

  const handleUpdateRole = (id, value) => {
    if (id != null) {
      roleStore
        .updateRole(id, value)
        .then(res => {
          message.success('Cập nhật vai trò thành công')
          fetchRolesData()
          setRoleModalVisible(false)
          setSelectedRoleId(null)
        })
        .catch(err => {
          throw err
        })
    } else {
      roleStore
        .createRole(value)
        .then(res => {
          message.success('Tạo vai trò mới thành công')
          fetchRolesData()
          setRoleModalVisible(false)
          setSelectedRoleId(null)
        })
        .catch(err => {
          throw err
        })
    }
  }

  const onUpdateRole = async roleId => {
    await setSelectedRoleId(roleId)
    setRoleModalVisible(true)
  }
  return (
    <>
      <ContentBlockWrapper>
        <Container>
          <Row style={{ marginBottom: 0, height: '38px' }}>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  onSearch={value => {
                    setPage(1), setPageSize(10), setSearch(value)
                  }}
                  maxLength={500}
                  placeholder={'Tìm kiếm...'}
                />
              </SearchBar>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button type={'primary'} onClick={handleOpenRoleModal}>
                Thêm vai trò
              </Button>
            </Col>
          </Row>
          <TableWrapper>
            <Table
              style={{
                height: 'calc(100vh - 235px)',
                overflowY: 'auto',
              }}
              columns={columns}
              dataSource={roles}
              bordered
              pagination={false}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div>
                Tổng số: <b>{total}</b> bản ghi
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '8px',
                }}>
                <Pagination
                  onChange={(page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                  }}
                  current={page}
                  pageSize={pageSize}
                  total={total}
                  showSizeChanger={true}
                  showLessItems
                />
              </div>
            </div>
          </TableWrapper>
        </Container>
      </ContentBlockWrapper>
      <RoleModal
        open={roleModalVisible}
        roleId={selectedRoleId}
        setRoleId={setSelectedRoleId}
        handleClose={handleCloseRoleModal}
        handleSubmit={handleSaveRoleModal}
      />
    </>
  )
}

export default memo(
  inject(
    'roleStore',
    'loadingAnimationStore',
    'accountStore'
  )(observer(RoleTab))
)
