import React, { memo, useCallback, useEffect, useState } from 'react'
import {
  Modal,
  Form,
  DatePicker,
  Select,
  Button,
  Row,
  Col,
  message,
} from 'antd'
import './ImportPageStyled.css'
import { inject, observer } from 'mobx-react'

const { Option } = Select

const NewItemsModal = props => {
  const {
    isNewItemsModalVisible,
    setIsNewItemsModalVisible,
    items,
    setItems,
    categoryStore,
    loadingAnimationStore,
    inventoryItemStore,
    unitStore,
    setCurrentStep,
  } = props
  const [form] = Form.useForm()
  const [categories, setCategories] = useState([])

  useEffect(() => {
    fetchCategories()
  }, [])

  const fetchCategories = useCallback(async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await categoryStore.getCategories(1, 9999, '')
      setCategories(response.data.pageData)
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [categoryStore, loadingAnimationStore])

  const handleClose = () => {
    setIsNewItemsModalVisible(false)
    form.resetFields()
  }

  const handleSave = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      await form.validateFields()
      const values = form.getFieldsValue()

      for (const item of items) {
        const unitId = await unitStore.getUnitIdByUnitName(item.unit_name)
        const newItem = {
          ...item,
          unit_name: undefined,
          unit_id: unitId?.data,
          item_type: values[`type_${item.item_code}`],
          category_id: values[`category_${item.item_code}`],
          expired_at: values[`expirationDate_${item.item_code}`],
        }
        await inventoryItemStore.createInventoryItem(newItem)
      }
      setCurrentStep(1)
      message.success('Thêm sản phẩm thành công, vui lòng chọn lại tệp nguồn!')
      setItems([])
      handleClose()
    } catch (error) {
      console.log(error)
      message.error('Thêm sản phẩm thất bại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <Modal
      className="modalNewItemsStyle"
      visible={isNewItemsModalVisible}
      title="Thêm sản phẩm mới"
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Hủy
        </Button>,
        <Button key="submit" type="primary" onClick={handleSave}>
          Thêm
        </Button>,
      ]}>
      <Form form={form} layout="vertical">
        {items.map(item => (
          <Row gutter={10}>
            <Col span={8}>
              <Form.Item
                name={`type_${item.item_code}`}
                label={`Loại vật tư (${item.item_code})`}
                rules={[
                  { required: true, message: 'Vui lòng chọn loại vật tư' },
                ]}>
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  placeholder="Chọn loại vật tư">
                  <Option key={0} value={0}>
                    Vật tư tiêu hao
                  </Option>
                  <Option key={1} value={1}>
                    Phi kinh doanh
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={`category_${item.item_code}`}
                label={`Danh mục (${item.item_code})`}
                rules={[{ required: true, message: 'Vui lòng chọn danh mục' }]}>
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  placeholder="Chọn danh mục">
                  {categories.map(category => (
                    <Option key={category.id} value={category.id}>
                      {category.category_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={`expirationDate_${item.item_code}`}
                label={`Ngày hết hạn (${item.item_code})`}>
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày hết hạn"
                />
              </Form.Item>
            </Col>
          </Row>
        ))}
      </Form>
    </Modal>
  )
}

export default memo(
  inject(
    'categoryStore',
    'loadingAnimationStore',
    'inventoryItemStore',
    'unitStore'
  )(observer(NewItemsModal))
)
