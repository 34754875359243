import React, { memo, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Helmet } from 'react-helmet/es/Helmet'
import { inject, observer } from 'mobx-react'
import { Tabs } from 'antd'
import AccountTab from './AccountTab'
import RoleTab from './RoleTab'
import { TabWrapper } from '../../components/Common/TabComponent'
import { PermissionType } from '../../enum'

const AccountPage = props => {
  const { accountStore } = props
  const [activeKey, setActiveKey] = useState('account')

  const isAccessPermission = permissionType => {
    if (accountStore.hasPermission(permissionType)) return true
    else return false
  }
  const handleTabChange = key => {
    setActiveKey(key)
  }
  return (
    <>
      <DashboardLayout>
        <Helmet>
          <title>Quản lý tài khoản và vai trò</title>
        </Helmet>
        <PageTitle
          location={props.location}
          title={'Quản lý tài khoản và vai trò'}
          hiddenGoBack></PageTitle>
        <TabWrapper>
          <Tabs activeKey={activeKey} onChange={handleTabChange}>
            {isAccessPermission(PermissionType.ACCOUNT_VIEW) && (
              <Tabs.TabPane tab="Quản lý người dùng" key="account">
                <AccountTab tabKey={activeKey} />
              </Tabs.TabPane>
            )}
            {isAccessPermission(PermissionType.ROLE_VIEW) && (
              <Tabs.TabPane tab="Vai trò quyền hạn" key="role">
                <RoleTab tabKey={activeKey} />
              </Tabs.TabPane>
            )}
          </Tabs>
        </TabWrapper>
      </DashboardLayout>
    </>
  )
}

export default memo(
  inject('loadingAnimationStore', 'accountStore')(observer(AccountPage))
)
