import { action, observable, observe } from 'mobx'
import { CampusRequest } from '../requests/CampusRequest'

class CampusStore {
  @action getCampus = (index, size, search) => {
    return new Promise((resolve, reject) => {
      CampusRequest.getCampus(index, size, search)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getCampusById = id => {
    return new Promise((resolve, reject) => {
      CampusRequest.getCampusById(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getCampusPublic = () => {
    return new Promise((resolve, reject) => {
      CampusRequest.getCampusPublic()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createCampus = data => {
    return new Promise((resolve, reject) => {
      CampusRequest.createCampus(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateCampus = data => {
    return new Promise((resolve, reject) => {
      CampusRequest.updateCampus(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deactiveCampus = id => {
    return new Promise((resolve, reject) => {
      CampusRequest.deactiveCampus(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action activeCampus = id => {
    return new Promise((resolve, reject) => {
      CampusRequest.activeCampus(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new CampusStore()
