import { apiUrl } from '../config'
import axios from 'axios'

export const ExportRequest = {
  getExportInvoice: (index, size, search, filters) =>
    axios.post(
      `${apiUrl}/api/v1/outward_stocks/paging_filter/`,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
        filters: filters,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  getPagingFilterCustom: (index, size, search, filters, custom_type) =>
    axios.post(
      `${apiUrl}/api/v1/outward_stocks/paging_filter_custom/`,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
        filters: filters,
        custom_type: custom_type,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  getExportPrinter: (index, size, search) =>
    axios.post(
      `${apiUrl}/api/v1/outward_stock_printer_details/paging_filter/`,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  deleteExportInvoice: id =>
    axios.post(
      `${apiUrl}/api/v1/outward_stocks/delete`,
      { id: id },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  createExportInvoice: data =>
    axios.post(`${apiUrl}/api/v1/outward_stocks/save_full`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  createExportPrinter: data =>
    axios.post(`${apiUrl}/api/v1/outward_stocks/save_full_printer`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  returnInventoryItem: data =>
    axios.post(`${apiUrl}/api/v1/outward_stocks/return_inventory`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  checkExistInvoiceNo: inv_no =>
    axios.get(
      `${apiUrl}/api/v1/outward_stocks/check_exist_invno?invNo=${inv_no}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  getExportInvoiceDetail: id =>
    axios.get(`${apiUrl}/api/v1/outward_stocks/get_master_details?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getExportPrinterDetail: id =>
    axios.get(
      `${apiUrl}/api/v1/outward_stock_printer_details/get_master_details?id=${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  updateExportInvoice: data =>
    axios.post(`${apiUrl}/api/v1/outward_stocks/update`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  updateExportPrinter: data =>
    axios.post(`${apiUrl}/api/v1/outward_stock_printer_details/update`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getTotalCostPerYear: year =>
    axios.get(
      `${apiUrl}/api/v1/outward_stocks/total-cost-per-year?year=${year}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),
  getOnlyDetails: id => {
    return axios.get(
      `${apiUrl}/api/v1/outward_stocks/get_only_details?id=${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  },
  updateStatus: (id, status) =>
    axios.post(
      `${apiUrl}/api/v1/outward_stocks/update-status`,
      { id: id, status: status },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),
  addAuditLog: request =>
    axios.post(
      `${apiUrl}/api/v1/outward_stocks/add_audit_log_custom`,
      request,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

    approveOrder: params =>
      axios.get(
        `${apiUrl}/api/v1/outward_stocks/approve_status_final`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          params: params
        }
      ),
}
