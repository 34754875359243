import { action } from 'mobx'
import { CategoryRequest } from '../requests/CategoryRequest'

class CategoryStore {
  @action getCategories = (index, size, search) => {
    return new Promise((resolve, reject) => {
      CategoryRequest.getCategories(index, size, search)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getCategoryById = id => {
    return new Promise((resolve, reject) => {
      CategoryRequest.getCategoryById(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createCategory = data => {
    return new Promise((resolve, reject) => {
      CategoryRequest.createCategory(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateCategory = data => {
    return new Promise((resolve, reject) => {
      CategoryRequest.updateCategory(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteCategory = id => {
    return new Promise((resolve, reject) => {
      CategoryRequest.deleteCategory(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action addLog = data => {
    return new Promise((resolve, reject) => {
      CategoryRequest.addLog(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new CategoryStore()
