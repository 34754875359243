import { apiUrl } from '../config'
import axios from 'axios'

export const InvoiceImportRequest = {
  addNewInvoice: data =>
    axios.post(`${apiUrl}/api/v1/invoice_imports/create`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getPagingInvoice: (index, size, search, filters) =>
    axios.post(
      `${apiUrl}/api/v1/invoice_imports/paging_filter/`,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
        filters: filters,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  uploadInvoice: data =>
    axios.post(`${apiUrl}/api/v1/invoice_imports/upload`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),

  editInvoice: data =>
    axios.post(`${apiUrl}/api/v1/invoice_imports/update`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  deleteInvoice: id =>
    axios.get(`${apiUrl}/api/v1/invoice_imports/delete?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
}
