import React, { memo, useState, useEffect, useCallback } from 'react'
import {
  Col,
  Popover,
  Row,
  Space,
  Modal,
  Radio,
  Input,
  Select,
  message,
} from 'antd'
import './Setting.css'
import { AppstoreFilled } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

const { Option } = Select

const PopupAppGrid = props => {
  const { settingStore, accountStore } = props
  const history = useHistory()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [allowOverstock, setAllowOverstock] = useState(false)
  const [allowChooseItemEP, setAllowChooseItemEP] = useState(false)
  const [allowRemindUnderstock, setAllowRemindUnderstock] = useState(false)
  const [approvePerson, setApprovePerson] = useState(false)
  const [oldData, setOldData] = useState(null)
  const [remindUnderstockValue, setRemindUnderstockValue] = useState('')
  const [approvePersonValue, setApprovePersonValue] = useState(null)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const fetchData = async () => {
    try {
      if (!oldData) {
        const response = await settingStore.getSetting()
        if (response && response.data && response.data.length > 0) {
          var oldOverStock = response.data.find(x => x.name == 'AllowOverstock')
          if (oldOverStock) {
            setAllowOverstock(oldOverStock.is_active)
          }

          var oldAllowRemindUnderstock = response.data.find(
            x => x.name == 'AllowRemindUnderstock'
          )
          if (oldAllowRemindUnderstock) {
            setAllowRemindUnderstock(oldAllowRemindUnderstock.is_active)

            if (oldAllowRemindUnderstock.is_active) {
              setRemindUnderstockValue(oldAllowRemindUnderstock.value)
            }
          }

          var oldApprovePerson = response.data.find(
            x => x.name == 'ApprovePerson'
          )
          if (oldApprovePerson) {
            setApprovePerson(oldApprovePerson.is_active)

            if (oldApprovePerson.is_active) {
              setApprovePersonValue(oldApprovePerson.value)
            }
          }

          var oldChooseItem = response.data.find(
            x => x.name == 'AllowChooseItemEP'
          )
          if (oldChooseItem) {
            setAllowChooseItemEP(oldChooseItem.is_active)
          }

          setOldData(response.data)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleOk = async () => {
    let settingList = []

    if (oldData && oldData.length > 0) {
      var oldOverStock = oldData.find(x => x.name == 'AllowOverstock')

      if (oldOverStock && oldOverStock.is_active != allowOverstock) {
        let settingOverStock = {
          id: oldOverStock.id,
          is_active: allowOverstock,
          name: 'AllowOverstock',
          value: null,
        }

        settingList.push(settingOverStock)
      }
      if (!oldOverStock) {
        if (allowOverstock) {
          let settingOverStock = {
            is_active: allowOverstock,
            name: 'AllowOverstock',
          }

          settingList.push(settingOverStock)
        }
      }

      var oldAllowRemindUnderstock = oldData.find(
        x => x.name == 'AllowRemindUnderstock'
      )

      if (allowRemindUnderstock && !remindUnderstockValue) {
        message.error('Vui lòng nhập số lượng')
        return
      }

      if (
        oldAllowRemindUnderstock &&
        (oldAllowRemindUnderstock.is_active != allowRemindUnderstock ||
          oldAllowRemindUnderstock.value != remindUnderstockValue)
      ) {
        let settingRemindUnderstock = {
          id: oldAllowRemindUnderstock.id,
          is_active: allowRemindUnderstock,
          name: 'AllowRemindUnderstock',
          value: remindUnderstockValue,
        }

        settingList.push(settingRemindUnderstock)
      }

      if (!oldAllowRemindUnderstock) {
        if (allowRemindUnderstock) {
          let settingUnderstock = {
            is_active: allowRemindUnderstock,
            name: 'AllowRemindUnderstock',
            value: remindUnderstockValue,
          }

          settingList.push(settingUnderstock)
        }
      }

      var oldApprovePerson = oldData.find(x => x.name == 'ApprovePerson')

      if (approvePerson && !approvePersonValue) {
        message.error('Vui lòng chọn người duyệt')
        return
      }

      if (
        oldApprovePerson &&
        (oldApprovePerson.is_active != approvePerson ||
          oldApprovePerson.value != approvePersonValue)
      ) {
        let settingApprovePerson = {
          id: oldApprovePerson.id,
          is_active: approvePerson,
          name: 'ApprovePerson',
          value: approvePersonValue,
        }

        settingList.push(settingApprovePerson)
      }

      if (!oldApprovePerson) {
        if (approvePerson) {
          let settingApprovePerson = {
            is_active: approvePerson,
            name: 'ApprovePerson',
            value: approvePersonValue,
          }

          settingList.push(settingApprovePerson)
        }
      }

      //
      var oldAllowChooseItemEP = oldData.find(
        x => x.name == 'AllowChooseItemEP'
      )

      if (
        oldAllowChooseItemEP &&
        oldAllowChooseItemEP.is_active != allowChooseItemEP
      ) {
        let settingOverStock = {
          id: oldAllowChooseItemEP.id,
          is_active: allowChooseItemEP,
          name: 'AllowChooseItemEP',
          value: null,
        }

        settingList.push(settingOverStock)
      }
      if (!oldAllowChooseItemEP) {
        if (allowChooseItemEP) {
          let settingOverStock = {
            is_active: allowChooseItemEP,
            name: 'AllowChooseItemEP',
          }

          settingList.push(settingOverStock)
        }
      }
      //
    } else {
      if (allowOverstock) {
        let settingOverStock = {
          is_active: allowOverstock,
          name: 'AllowOverstock',
        }

        settingList.push(settingOverStock)
      }

      if (allowRemindUnderstock) {
        let settingUnderstock = {
          is_active: allowRemindUnderstock,
          name: 'AllowRemindUnderstock',
          value: remindUnderstockValue,
        }

        settingList.push(settingUnderstock)
      }

      if (approvePerson) {
        let settingApprovePerson = {
          is_active: approvePerson,
          name: 'ApprovePerson',
          value: approvePersonValue,
        }

        settingList.push(settingApprovePerson)
      }

      if (allowChooseItemEP) {
        let settingOverStock = {
          is_active: allowChooseItemEP,
          name: 'AllowChooseItemEP',
        }

        settingList.push(settingOverStock)
      }
    }
    try {
      if (settingList && settingList.length > 0) {
        if (!settingStore) {
          setIsModalVisible(false)
          return
        }
        await settingStore.createSetting(settingList)
        message.success('Thiết lập thành công!')
      }
      setIsModalVisible(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCancel = () => {
    setAllowOverstock(
      oldData?.find(x => x.name == 'AllowOverstock')?.is_active || false
    )
    setAllowRemindUnderstock(
      oldData?.find(x => x.name == 'AllowRemindUnderstock')?.is_active || false
    )
    setApprovePerson(
      oldData?.find(x => x.name == 'ApprovePerson')?.is_active || false
    )
    setAllowChooseItemEP(
      oldData?.find(x => x.name == 'AllowChooseItemEP')?.is_active || false
    )
    setIsModalVisible(false)
  }

  const onRadioChange = e => {
    setAllowOverstock(e.target.value)
  }

  const onRadioChangeChooseItem = e => {
    setAllowChooseItemEP(e.target.value)
  }

  const onRemindUnderstockChange = e => {
    setAllowRemindUnderstock(e.target.value)
  }

  const onSetApprovePerson = e => {
    setApprovePerson(e.target.value)
  }

  const onRemindUnderstockInputChange = e => {
    setRemindUnderstockValue(e.target.value)
  }

  const onSetApprovePersonValue = value => {
    setApprovePersonValue(value)
  }

  const renderMenuApp = () => {
    return [
      renderCommandMenu({
        item: { code: 'NHAT_KY_HANH_DONG' },
        col: 6,
      }),
      renderCommandMenu({
        item: { code: 'CAI_DAT' },
        col: 6,
      }),
    ]
  }

  const renderCommandMenu = ({ item, col }) => {
    let title = null
    let iconPath = null
    switch (item.code) {
      case 'NHAT_KY_HANH_DONG':
        title = 'Nhật ký'
        iconPath = 'NKHĐ.jpg'
        break
      case 'CAI_DAT':
        title = 'Cài đặt'
        iconPath = 'settings.jpg'
        break
      default:
        break
    }

    return title !== null ? cardItem(item, col, title, iconPath) : null
  }

  const cardItem = (item, col, title, iconPath) => {
    return (
      <Col
        key={item.code}
        span={col}
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          cursor: 'pointer',
        }}
        onClick={() => clickWidget(item)}>
        <Space direction={'vertical'} align={'center'}>
          <div style={{ width: 54, height: 54 }}>
            <img
              style={{ width: '100%' }}
              src={`${process.env.PUBLIC_URL}/assets/icons/portal/${iconPath}`}
              alt={title}
            />
          </div>
          <h6 style={{ fontSize: 11, fontWeight: 600 }}>{title}</h6>
        </Space>
      </Col>
    )
  }

  const clickWidget = item => {
    switch (item.code) {
      case 'NHAT_KY_HANH_DONG':
        history.push('/audit-log')
        break
      case 'CAI_DAT':
        showModal()
        break
      default:
        break
    }
  }

  const [approvers, setApprovers] = useState([])
  useEffect(() => {
    accountStore.getApprovers().then(response => {
      setApprovers(response.data)
    })
  }, [])

  return (
    <>
      {!isModalVisible && (
        <Popover
          placement="bottomRight"
          trigger="click"
          content={
            <div style={{ width: 388 }}>
              <Row
                gutter={[8, 24]}
                style={{
                  backgroundColor: 'white',
                  borderRadius: '6px',
                  padding: '18px 0',
                  marginBottom: 0,
                }}>
                {renderMenuApp()}
              </Row>
            </div>
          }>
          <AppstoreFilled
            style={{ color: '#fff', fontSize: 18, marginLeft: 14 }}
          />
        </Popover>
      )}

      <Modal
        title="TÙY CHỌN CHUNG"
        className="modal-setting"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: '20%', height: '60%', width: '70% !important' }}
        bodyStyle={{
          height: 'calc(100% - 110px)',
          overflowY: 'auto',
          padding: '20px 19px 20px 45px',
        }}>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              height: '100%',
              paddingBottom: '15px',
            }}>
            <div style={{ width: '60%' }}>
              <span
                style={{
                  marginRight: 10,
                  fontSize: '14px',
                  fontWeight: '600',
                }}>
                Cho phép xuất quá số lượng tồn
              </span>
            </div>
            <div>
              <Radio.Group onChange={onRadioChange} value={allowOverstock}>
                <Radio value={false}>Không</Radio>
                <Radio value={true}>Có</Radio>
              </Radio.Group>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              height: '100%',
            }}>
            <div style={{ width: '60%' }}>
              <span
                style={{
                  marginRight: 10,
                  fontSize: '14px',
                  fontWeight: '600',
                }}>
                Cho phép nhắc nhờ mặt hàng tồn kho dưới mức tối thiểu
              </span>
            </div>
            <div>
              <Radio.Group
                onChange={onRemindUnderstockChange}
                value={allowRemindUnderstock}>
                <Radio value={false}>Không</Radio>
                <Radio value={true}>Có</Radio>
              </Radio.Group>
            </div>
          </div>
          {allowRemindUnderstock && (
            <div
              style={{
                marginTop: '15px',
                width: '90%',
                display: 'flex',
                justifyContent: 'end',
              }}>
              <div
                style={{
                  width: '33%',
                }}>
                <Input
                  placeholder="Nhập số lượng giới hạn"
                  value={remindUnderstockValue}
                  onChange={onRemindUnderstockInputChange}
                />
              </div>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              height: '100%',
              paddingTop: '15px',
            }}>
            <div style={{ width: '60%' }}>
              <span
                style={{
                  marginRight: 10,
                  fontSize: '14px',
                  fontWeight: '600',
                }}>
                Người duyệt đơn nhập kho
              </span>
            </div>
            <div>
              <Radio.Group onChange={onSetApprovePerson} value={approvePerson}>
                <Radio value={false}>Không</Radio>
                <Radio value={true}>Có</Radio>
              </Radio.Group>
            </div>
          </div>
          {approvePerson && (
            <div
              style={{
                marginTop: '15px',
                width: '90%',
                display: 'flex',
                justifyContent: 'end',
              }}>
              <div
                style={{
                  width: '33%',
                }}>
                <Select
                  allowClear
                  placeholder="Chọn người duyệt"
                  value={approvePersonValue}
                  onChange={onSetApprovePersonValue}
                  style={{ width: '100%' }}>
                  {approvers.map(approver => (
                    <Option
                      name={approver.username}
                      value={approver.email}
                      key={approver.id}>
                      {approver.email}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              height: '100%',
              paddingTop: '15px',
            }}>
            <div style={{ width: '60%' }}>
              <span
                style={{
                  marginRight: 10,
                  fontSize: '14px',
                  fontWeight: '600',
                }}>
                Cho phép chọn sản phẩm khi xuất kho máy in
              </span>
            </div>
            <div>
              <Radio.Group
                onChange={onRadioChangeChooseItem}
                value={allowChooseItemEP}>
                <Radio value={false}>Không</Radio>
                <Radio value={true}>Có</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default memo(
  inject('settingStore', 'accountStore')(observer(PopupAppGrid))
)
