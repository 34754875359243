import DashboardPage from './pages/DashboardPage/DashboardPage'
import ImportPage from './pages/ImportPage'
import SupplierPage from './pages/SupplierPage'
import ExportPage from './pages/ExportPage'
import ExportPageNonBussiness from './pages/ExportPage/ExportPageNonBussiness'
import CampusPage from './pages/CampusPage'
import UnitPage from './pages/UnitPage'
import ItemPage from './pages/ItemPage/ItemPage'
import DepartmentPage from './pages/DepartmentPage'
import AccountPage from './pages/AccountPage/AccountPage'
import ReportItemPage from './pages/ReportItemPage/ReportItemPage'
import ReportDepartmentPage from './pages/ReportDepartmentPage/ReportDepartmentPage'
import AuditLogPage from './pages/AuditLogPage/AuditLogPage'
import { PermissionType } from './enum'
import ImportApprovalPage from './pages/ImportApprovalPage'
import ImportInvoice from './pages/ImportPage/ImportInvoice'
import ItemsByDepartmentsReportPage from './pages/ItemsByDepartmentsReportPage'
import DepartmentsByItemsReportPage from './pages/DepartmentsByItemsReportPage'
import CategoryPage from './pages/CategoryPage'
import BrokenItemPage from './pages/BrokenItemPage/BrokenItemPage'
import ConfirmReceive from './pages/ConfirmationPage/ConfirmReceive'
import RejectPage from './pages/RejectPage/RejectPage'

export const normalRoutes = [
  {
    path: '/login',
    component: DashboardPage,
    name: 'Đăng nhập',
  },
  {
    path: '/confirm',
    component: ConfirmReceive,
    name: 'Xác nhận nhận hàng',
  },
  {
    path: '/reject',
    component: RejectPage,
    name: 'Từ chối nhận hàng',
  },
]

export const routes = [
  {
    path: '/dashboard',
    component: DashboardPage,
    name: 'Trang chủ',
  },
  {
    path: '/warehouse/import',
    component: ImportPage,
    name: 'Nhập kho',
    permission: PermissionType.INWARD_INVENTORY_VIEW,
  },
  {
    path: '/warehouse/approval',
    component: ImportApprovalPage,
    name: 'Duyệt phiếu nhập kho',
    permission: PermissionType.INWARD_INVENTORY_APPROVE,
  },
  {
    path: '/warehouse/export',
    component: ExportPage,
    name: 'Xuất kho',
    permission: PermissionType.OUTWARD_INVENTORY_VIEW,
  },
  {
    path: '/warehouse/nonbusinessexport',
    component: ExportPageNonBussiness,
    name: 'Xuất kho phi kinh doanh',
    permission: PermissionType.OUTWARD_INVENTORY_VIEW,
  },
  {
    path: '/warehouse/invoice',
    component: ImportInvoice,
    name: 'Hóa đơn',
    // permission: PermissionType.OUTWARD_INVENTORY_VIEW,
  },
  {
    path: '/category/supplier',
    component: SupplierPage,
    name: 'Nhà cung cấp',
    permission: PermissionType.SUPPLIER_VIEW,
  },
  {
    path: '/category/department',
    component: DepartmentPage,
    name: 'Phòng ban',
    permission: PermissionType.DEPARTMENT_VIEW,
  },
  {
    path: '/administration/campus',
    component: CampusPage,
    name: 'Cơ sở',
    permission: PermissionType.CAMPUS_VIEW,
  },
  {
    path: '/category/unit',
    component: UnitPage,
    name: 'Đơn vị',
    permission: PermissionType.UNIT_VIEW,
  },
  {
    path: '/category/group',
    component: CategoryPage,
    name: 'Nhóm hàng hóa',
    permission: PermissionType.ITEM_VIEW,
  },
  {
    path: '/category/item',
    component: ItemPage,
    name: 'Hàng hóa, dịch vụ',
    permission: PermissionType.ITEM_VIEW,
  },
  {
    path: '/administration/account',
    component: AccountPage,
    name: 'Tài khoản và vai trò',
    permission: PermissionType.ACCOUNT_VIEW,
  },
  {
    path: '/report/inventory',
    component: ReportItemPage,
    name: 'Báo cáo hàng hóa',
    permission: PermissionType.INWARD_INVENTORY_VIEW,
  },
  {
    path: '/report/departmental-cost',
    component: ReportDepartmentPage,
    name: 'Báo cáo chi phí phòng ban',
    permission: PermissionType.OUTWARD_INVENTORY_VIEW,
  },
  {
    path: '/report/items-by-departments',
    component: ItemsByDepartmentsReportPage,
    name: 'Báo cáo chi tiết mặt hàng theo phòng ban',
    permission: PermissionType.OUTWARD_INVENTORY_VIEW,
  },
  {
    path: '/report/departments-by-items',
    component: DepartmentsByItemsReportPage,
    name: 'Báo cáo chi tiết phòng ban theo mặt hàng',
    permission: PermissionType.OUTWARD_INVENTORY_VIEW,
  },
  {
    path: '/audit-log',
    component: AuditLogPage,
    name: 'Nhật kí hoạt động',
  },
  {
    path: '/warehouse/broken-items',
    component: BrokenItemPage,
    name: 'Vật tư tồn hỏng',
    permission: PermissionType.INWARD_INVENTORY_VIEW,
  },
]
