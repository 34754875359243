import React, { useEffect, useState } from 'react'
import LoadingSpinner from './components/LoadingSpinner'
import axios from 'axios'
import { ConfigProvider, message } from 'antd'
import viVN from 'antd/es/locale/vi_VN'
import moment from 'moment'
import 'moment/locale/vi'
import ThemeProvider from './providers/ThemeProvider'
// React Router
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
// MobX
import { Provider } from 'mobx-react'
import 'mobx-react-lite/batchingForReactDom'
import loadingAnimationStore from './stores/loadingAnimationStore'
import commonStore from './stores/commonStore'
import authenticationStore from './stores/authenticationStore'
import importStore from './stores/importStore'
import exportStore from './stores/exportStore'
import supplierStore from './stores/supplierStore'
import unitStore from './stores/unitStore'
import inventoryItemStore from './stores/inventoryItemStore'
import campusStore from './stores/campusStore'
import categoryStore from './stores/categoryStore'
import departmentStore from './stores/departmentStore'
import auditLogStore from './stores/auditLogStore'
import accountStore from './stores/accountStore'
import roleStore from './stores/roleStore'
import settingStore from './stores/settingStore'
import contractStore from './stores/contractStore'
import invoiceStore from './stores/invoiceStore'
// Pages
import NotFoundPage from './pages/NotFoundPage'
import DashboardPage from './pages/DashboardPage'
import UnauthorizedPage from './pages/UnauthorizedPage/UnauthorizedPage'

// Styling
import './App.less'

import { normalRoutes, routes } from './routes'

//Google
import { GoogleOAuthProvider } from '@react-oauth/google'
import { CLIENT_ID } from './google-auth-setting'

//url
import { apiUrl } from './config.js'
import AuthGuard from './components/Auth/AuthGuard'

import { getCookieValue } from './cookie_utils'

//signalR
import * as signalR from '@microsoft/signalr'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        getCookieValue('accessToken') || getCookieValue('refreshToken') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

const history = createBrowserHistory()

const rootStore = {
  loadingAnimationStore,
  commonStore,
  authenticationStore,
  importStore,
  exportStore,
  supplierStore,
  unitStore,
  campusStore,
  inventoryItemStore,
  departmentStore,
  categoryStore,
  auditLogStore,
  accountStore,
  roleStore,
  settingStore,
  contractStore,
  invoiceStore,
}

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

axios.interceptors.request.use(
  config => {
    const token = getCookieValue('accessToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => Promise.reject(error)
)

axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config
    if (
      error?.response?.status === 401 &&
      !originalRequest?._retry &&
      originalRequest?.url !== `${apiUrl}/api/v1/account/refresh`
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject })
        })
          .then(token => {
            if (token) {
              originalRequest.headers.Authorization = `Bearer ${token}`
            }
            return axios(originalRequest)
          })
          .catch(err => Promise.reject(err))
      }

      originalRequest._retry = true
      isRefreshing = true

      return new Promise((resolve, reject) => {
        authenticationStore
          .refreshToken()
          .then(({ accessToken }) => {
            if (accessToken) {
              axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
              originalRequest.headers.Authorization = `Bearer ${accessToken}`
            }
            processQueue(null, accessToken)
            resolve(axios(originalRequest))
          })
          .catch(err => {
            processQueue(err, null)
            authenticationStore.userLogout()
            history.push('/login')
            reject(err)
          })
          .finally(() => {
            isRefreshing = false
          })
      })
    }

    if (axios.isCancel(error)) {
      return Promise.resolve()
    }

    if (error?.response?.status === 408 || error?.code === 'ECONNABORTED') {
      message.config({ maxCount: 1 })
      message.error('Request timeout!')
      history.push('/login')
    }

    if (error?.response?.data) {
      message.error(error?.response?.data.DevMsg || 'Có lỗi xảy ra')
    }

    return Promise.reject(
      error || {
        vi: 'Đã có lỗi xảy ra trong quá trình kết nối!',
      }
    )
  }
)

moment.locale('vi', {
  week: {
    dow: 1,
  },
})

const App = () => {
  useEffect(() => {
    if (getCookieValue('accessToken') || getCookieValue('refreshToken')) {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${apiUrl}/roleHub`)
        .configureLogging(signalR.LogLevel.None)
        .withAutomaticReconnect()
        .build()

      connection.on('RoleSignal', () => {
        accountStore.getAccountInfo().then(response => {
          localStorage.setItem('account-info', JSON.stringify(response.data))
        })
      })

      connection
        .start()
        .then(() => {
          accountStore.getAccountInfo().then(response => {
            localStorage.setItem('account-info', JSON.stringify(response.data))
          })
        })
        .catch(() => {
          accountStore.getAccountInfo().then(response => {
            localStorage.setItem('account-info', JSON.stringify(response.data))
          })
        })

      return () => {
        connection.stop()
      }
    }
  }, [getCookieValue('accessToken'), getCookieValue('refreshToken')])

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <AppRouter />
    </GoogleOAuthProvider>
  )
}

export const AppRouter = () => {
  return (
    <Provider {...rootStore}>
      <ThemeProvider>
        <ConfigProvider locale={viVN}>
          <Router history={history}>
            <Switch>
              {normalRoutes.map(route => (
                <Route
                  key={route.path}
                  exact
                  path={route.path}
                  component={route.component}
                />
              ))}

              <ProtectedRoute exact path={'/'} component={DashboardPage} />
              <ProtectedRoute
                exact
                path={'/dashboard'}
                component={DashboardPage}
              />

              {routes.map(route =>
                route.permission ? (
                  <AuthGuard
                    key={route.path}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                    permission={route.permission}
                  />
                ) : (
                  <ProtectedRoute
                    key={route.path}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                  />
                )
              )}
              <Route path="/unauthorized" component={UnauthorizedPage} />
              <Route component={NotFoundPage} />
            </Switch>
          </Router>
        </ConfigProvider>
        <LoadingSpinner />
      </ThemeProvider>
    </Provider>
  )
}

export default App
