import React, { memo, useCallback, useEffect, useState } from 'react'
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Typography,
  Tag,
  message,
} from 'antd'
import { inject, observer } from 'mobx-react'
import './ExportPageStyled.css'
import moment from 'moment'
import { ExportTable } from './ExportPageStyled'

const { Option } = Select
const { Title, Text } = Typography

const ExportDetailUpdate = props => {
  const {
    open,
    setOpen,
    exportId,
    setExportId,
    type,
    departmentStore,
    unitStore,
    inventoryItemStore,
    exportStore,
    loadingAnimationStore,
    fetchExportInvoice,
  } = props
  const [form] = Form.useForm()
  const [departmentList, setDepartmentList] = useState([])
  const [unitList, setUnitList] = useState([])
  const [inventoryItemList, setInventoryItemList] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [originalInvoiceNo, setOriginalInvoiceNo] = useState('')
  const [invoiceDate, setInvoiceDate] = useState(undefined)
  const [departmentName, setDepartmentName] = useState('')
  const [note, setNote] = useState('')
  const [receivedBy, setReceivedBy] = useState('')
  const [receivedEmail, setReceivedEmail] = useState('')
  const [unitName, setUnitName] = useState([])
  const [oldDetails, setOldDetails] = useState([])
  const [oldMaster, setOldMaster] = useState(undefined)

  const fetchData = async () => {
    await departmentStore.getDepartment(1, 9999, '').then(res => {
      setDepartmentList(res.data.pageData)
    })
    await unitStore.getUnit(1, 9999, '').then(res => {
      const units = res.data.pageData
      setUnitList(units)
      const unitNameMap = units.reduce((map, unit) => {
        map[unit.id] = unit.unit_name
        return map
      }, {})
      setUnitName(unitNameMap)
    })
    var filters = [
      {
        data_field: 'item_type',
        value: '1',
        operator_type: 'equal',
      },
    ]
    await inventoryItemStore
      .getInventoryItemDTO(1, 9999, '', filters)
      .then(res => {
        setInventoryItemList(res.data.pageData)
        setFilteredItems(res.data.pageData)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const [statusInv, setStatusInv] = useState(0)
  const getStatusTag = status => {
    let color
    let content

    switch (status) {
      case 0:
        color = 'darkgrey'
        content = 'Đang kiểm hàng để xuất'
        break
      case 1:
        color = 'orange'
        content = 'Đợi người nhận xác nhận'
        break
      case 2:
        color = 'red'
        content = 'Đơn hàng đã bị hủy'
        break
      case 4:
        color = 'green'
        content = 'Hoàn thành'
        break
      case 5:
        color = '#faad14'
        content = 'Chờ trả hàng'
        break
      case 6:
        color = 'lightgreen' // Update color for partial payment
        content = 'Trả 1 phần'
        break
      case 7:
        color = 'darkgreen' // Update color for full payment
        content = 'Hoàn trả đủ hàng'
        break
      case 3:
        color = 'yellow'
        content = 'Trong thời gian nhận hàng'
        break
      default:
        color = ''
        content = ''
    }

    return (
      <Tag style={{ width: 'fit-content' }} color={color}>
        {content}
      </Tag>
    )
  }
  useEffect(() => {
    if (exportId) {
      exportStore.getExportInvoiceDetail(exportId).then(async res => {
        setOriginalInvoiceNo(res.data.masterDTO.invoice_no)
        setStatusInv(res.data.masterDTO.status)
        setInvoiceDate(res.data.masterDTO.invoice_date)
        setDepartmentName(res.data.masterDTO.department_name)
        setNote(res.data.masterDTO.note)
        setReceivedBy(res.data.masterDTO.received_by)
        setReceivedEmail(res.data.masterDTO.received_email)
        form.setFieldsValue({
          invoice_no: res.data.masterDTO.invoice_no,
          invoice_date: moment.utc(
            res.data.masterDTO.invoice_date,
            'YYYY-MM-DD'
          ),
          department_id: res.data.masterDTO.department_id,
          note: res.data.masterDTO.note,
          received_by: res.data.masterDTO.received_by,
          received_email: res.data.masterDTO.received_email,
        })
        setOldMaster(res.data.masterDTO)
        setOldDetails(res.data.details)

        const detailsWithItemInfo = await Promise.all(
          res.data.details.map(async (detail, index) => {
            try {
              const itemInfo = await inventoryItemStore.getInventoryItemById(
                detail.item_id
              )
              return {
                key: index + 1,
                id: detail.id,
                item_id: detail.item_id,
                item_code: itemInfo.data.item_code,
                item_name: itemInfo.data.item_name,
                unit_id: detail.unit_id,
                quantity: detail.quantity,
                unit_price: detail.unit_price,
                itemTotal: detail.itemTotal,
                amount: detail.quantity * detail.unit_price,
                return_date: detail.return_date,
                outward_non_business_type: detail.outward_non_business_type,
              }
            } catch (error) {
              console.error(error)
              return {
                key: index + 1,
                id: '',
                item_id: '',
                item_code: '',
                item_name: '',
                unit_id: '',
                quantity: '',
                unit_price: '',
                itemTotal: undefined,
                amount: '',
                outward_non_business_type: null,
                return_date: '',
              }
            }
          })
        )

        setData(detailsWithItemInfo)
        setAmount(detailsWithItemInfo.map(row => row.amount))
        const totalAmount = detailsWithItemInfo.reduce(
          (acc, row) => acc + (row.amount || 0),
          0
        )
        setTotalAmount(totalAmount)
      })
    }
  }, [exportId])

  const [data, setData] = useState([
    {
      key: 1,
      id: '',
      item_id: '',
      item_name: '',
      unit_id: '',
      quantity: '',
      unit_price: '',
      outward_non_business_type: null,
      itemTotal: undefined,
    },
  ])

  const [amount, setAmount] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)

  function formatNumber(value) {
    if (value == null) return ''
    const roundedValue = Number(value).toFixed(2)
    const [integerPart, decimalPart] = roundedValue.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }

  const handleInputChange = (index, field, value) => {
    const newData = [...data]

    if (field === 'quantity') {
      if (/^\d*\.?\d*$/.test(value)) {
        newData[index][field] = value
      }
    } else if (field === 'unit_price') {
      if (/^\d*\.?\d*$/.test(value)) {
        newData[index][field] = value
      }
    } else {
      newData[index][field] = value
    }

    const quantity = parseFloat(newData[index]['quantity'] || '0')
    const unitPrice = parseFloat(newData[index]['unit_price'] || '0')

    newData[index].amount = quantity * unitPrice

    setData(newData)
    setAmount(newData.map(row => row.amount))

    const newTotalAmount = newData.reduce(
      (acc, row) => acc + (row.amount || 0),
      0
    )
    setTotalAmount(newTotalAmount)
  }

  const handleDeleteAll = () => {
    const newData = [
      {
        key: 1,
        id: '',
        item_id: '',
        item_code: '',
        item_name: '',
        unit_id: '',
        quantity: '',
        unit_price: '',
        outward_non_business_type: null,
        itemTotal: undefined,
      },
    ]

    setData(newData)
    setAmount(newData.map(row => row.amount))

    setTotalAmount(0)
  }

  const handleSelect = async (index, value, option) => {
    const newData = [...data]
    newData[index]['item_id'] = value
    newData[index]['item_code'] = option.item_code

    try {
      const itemSelected = await inventoryItemStore.getInventoryItemById(value)

      newData[index]['item_name'] = itemSelected.data.item_name
      newData[index]['unit_id'] = itemSelected.data.unit_id
      newData[index]['quantity'] = '1'
      newData[index]['unit_price'] = itemSelected.data.unit_price

      newData[index].itemTotal = itemSelected.data.quantity

      const quantity = parseFloat(newData[index]['quantity'] || '0')
      const unitPrice = parseFloat(newData[index]['unit_price'] || '0')
      newData[index].amount = quantity * unitPrice

      setData(newData)
      setAmount(newData.map(row => row.amount))

      const newTotalAmount = newData.reduce(
        (acc, row) => acc + (row.amount || 0),
        0
      )
      setTotalAmount(newTotalAmount)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSearch = value => {
    const filtered = inventoryItemList.filter(
      item =>
        item.item_code.toLowerCase().includes(value.toLowerCase()) ||
        item.item_name.toLowerCase().includes(value.toLowerCase())
    )
    setFilteredItems(filtered)
  }

  const handleAddRow = () => {
    // Add a new row with a temporary key
    const newData = [
      ...data,
      {
        key: data.length + 1,
        item_id: '',
        item_name: '',
        unit_id: '',
        quantity: '',
        unit_price: '',
        outward_non_business_type: null,
        itemTotal: undefined,
      },
    ]

    // Reset keys to be sequential
    const updatedData = newData.map((item, idx) => ({
      ...item,
      key: idx + 1,
    }))

    // Update the state with the new data and reset filtered items
    setData(updatedData)
    setFilteredItems(inventoryItemList)
  }

  const nonBussinessOptions = [
    { value: 1, name: 'Hoàn trả' },
    { value: 2, name: 'Cấp phát' },
    { value: 3, name: 'Bán' },
    { value: 4, name: 'Thanh lý' },
  ]

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      render: (text, record, index) => <span>{index + 1}</span>,
      width: '20px',
    },
    {
      title: 'MÃ SẢN PHẨM',
      key: 'item_id',
      render: (text, record, index) =>
        type === 'detail' ? (
          <Input style={{ width: '100%' }} value={record.item_code} disabled />
        ) : (
          <AutoComplete
            style={{ width: '100%' }}
            value={record.item_code}
            options={filteredItems.map(item => ({
              value: item.id,
              item_code: item.item_code,
              label: (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{item.item_code}</span>
                  <span>{item.item_name}</span>
                </div>
              ),
            }))}
            onSelect={(value, option) => handleSelect(index, value, option)}
            onSearch={handleSearch}
            onChange={value => handleInputChange(index, 'item_code', value)}
          />
        ),
      width: '300px',
    },
    {
      title: 'TÊN SẢN PHẨM',
      key: 'item_name',
      render: (text, record, index) => (
        <Input disabled value={record.item_name} />
      ),
      width: '300px',
    },
    {
      title: 'ĐVT',
      key: 'unit_id',
      render: (text, record, index) =>
        type === 'detail' ? (
          <Input
            style={{ width: '100%' }}
            value={unitName[record.unit_id]}
            disabled
          />
        ) : (
          <Select
            allowClear
            placeholder="Chọn đơn vị"
            style={{ width: '100%' }}
            value={record.unit_id}
            onChange={value => handleInputChange(index, 'unit_id', value)}>
            {unitList.map(unit => (
              <Option name={unit.unit_name} value={unit.id} key={unit.id}>
                {unit.unit_name}
              </Option>
            ))}
          </Select>
        ),
      width: '150px',
    },
    {
      title: 'SỐ LƯỢNG',
      key: 'quantity',
      render: (text, record, index) => (
        <Input
          value={record.quantity}
          onChange={e => handleInputChange(index, 'quantity', e.target.value)}
          style={{ width: '100%' }}
          disabled={type === 'detail' ? true : false}
        />
      ),
      width: '150px',
    },
    {
      title: 'ĐƠN GIÁ',
      key: 'unit_price',
      render: (text, record, index) => (
        <Input
          value={record.unit_price}
          onChange={e => handleInputChange(index, 'unit_price', e.target.value)}
          style={{ width: '100%' }}
          disabled={type === 'detail' ? true : false}
        />
      ),
      width: '200px',
    },
    {
      title: 'THÀNH TIỀN',
      key: 'amount',
      render: (text, record, index) => <Input disabled value={amount[index]} />,
      width: '250px',
    },
    {
      title: 'Loại phi kinh doanh',
      key: 'outward_non_business_type',
      render: (text, record, index) =>
        type === 'detail' ? (
          <Input
            style={{ width: '100%' }}
            value={
              nonBussinessOptions?.find(
                x => x.value == record.outward_non_business_type
              )?.name
            }
            disabled
          />
        ) : (
          <Select
            allowClear
            placeholder="Chọn loại PKD"
            style={{ width: '100%' }}
            value={record.outward_non_business_type}
            onChange={value =>
              handleInputChange(index, 'outward_non_business_type', value)
            }>
            {nonBussinessOptions.map(x => (
              <Option x={x.name} value={x.value} key={x.value}>
                {x.name}
              </Option>
            ))}
          </Select>
        ),
      width: '150px',
    },
    {
      title: 'NGÀY TRẢ HÀNG',
      key: 'return_date',
      render: (text, record, index) => (
        <DatePicker
          style={{ width: '100%' }}
          value={record.return_date ? moment(record.return_date) : null} // Ensure to import moment
          onChange={date => handleInputChange(index, 'return_date', date)} // Handle date changes
          disabled={type === 'detail' || record.outward_non_business_type != 1} // Disable if in detail view
        />
      ),
      width: '200px',
    },
  ]

  const handleClose = () => {
    setOpen(false)
    form.resetFields()
    setExportId('')
    const newData = [
      {
        key: 1,
        id: '',
        item_id: '',
        item_code: '',
        item_name: '',
        unit_id: '',
        quantity: '',
        unit_price: '',
        itemTotal: undefined,
      },
    ]
    setData(newData)
    setTotalAmount(0)
  }

  const handleSubmit = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      await form.validateFields()
      const values = form.getFieldsValue()

      const invoiceNo = values.invoice_no
      if (invoiceNo !== originalInvoiceNo) {
        const response = await exportStore.checkExistInvoiceNo(invoiceNo)
        if (response.data) {
          message.error('Số phiếu xuất đã tồn tại!')
          return
        }
      }

      const tableData = data.map(row => ({
        id: row.id,
        item_id: row.item_id,
        item_name: row.item_name,
        unit_id: row.unit_id,
        quantity: row.quantity,
        unit_price: row.unit_price,
        outward_id: exportId,
        return_date: row.return_date,
        outward_non_business_type: row.outward_non_business_type,
      }))

      const payload = {
        master: {
          ...values,
          total_amount: totalAmount,
          id: exportId,
          status: 0,
          export_type: 1,
        },
        details: tableData,
      }

      await exportStore.updateExportInvoice(payload)

      var departmentNameOld = departmentList.find(
        x => x.id == oldMaster?.department_id
      )?.department_name

      var departmentNameNew = departmentList.find(
        x => x.id == payload.master?.department_id
      )?.department_name

      var departmentCodeNew = departmentList.find(
        x => x.id == payload.master?.department_id
      )?.department_code

      oldMaster.department_name = departmentNameOld
      payload.master.department_name = departmentNameNew
      payload.master.department_code = departmentCodeNew

      var tableNew = data.map(row => ({
        id: row.id,
        item_id: row.item_id,
        item_name: row.item_name,
        unit_id: row.unit_id,
        unit_name: unitList?.find(x => x.id == row.unit_id)?.unit_name,
        quantity: row.quantity,
        unit_price: row.unit_price,
        outward_id: exportId,
        return_date: row.return_date,
        outward_non_business_type: row.outward_non_business_type,
      }))

      var tableOld = oldDetails.map(row => ({
        id: row.id,
        item_id: row.item_id,
        item_name: inventoryItemList?.find(x => x.id == row.item_id)?.item_name,
        unit_id: row.unit_id,
        unit_name: unitList?.find(x => x.id == row.unit_id)?.unit_name,
        quantity: row.quantity,
        unit_price: row.unit_price,
        outward_id: exportId,
        return_date: row.return_date,
        outward_non_business_type: row.outward_non_business_type,
      }))
      var auditLogRequest = {
        table_name: 'Xuất kho phi kinh doanh',
        action: 'Chỉnh sửa',
        oldObj: oldMaster,
        newObj: payload.master,
        oldDetails: tableOld,
        newDetails: tableNew,
      }
      await exportStore.addAuditLog(auditLogRequest)
      message.success('Chỉnh sửa đơn xuất kho thành công!')
      await fetchExportInvoice()
      await handleClose()
    } catch (errorInfo) {
      console.log(errorInfo)
      message.error('Vui lòng kiểm tra lại các trường dữ liệu!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <Modal
      title={'PHIẾU XUẤT KHO HÀNG HÓA PHI KINH DOANH'}
      width={'100vw'}
      className="modalStyle"
      visible={open}
      onCancel={handleClose}
      style={{
        top: '0px',
        maxWidth: '100vw',
        height: '100vh',
        paddingBottom: '0px',
        background: '#fff',
      }}
      footer={
        type === 'detail'
          ? ''
          : [
              <Button key="cancel" onClick={handleClose}>
                Hủy
              </Button>,
              <Button key="submit" type="primary" onClick={handleSubmit}>
                Chỉnh sửa
              </Button>,
            ]
      }>
      <Form
        form={form}
        scrollToFirstError={true}
        name={'create-export-invoice'}
        layout={'vertical'}>
        <div
          style={{
            padding: '20px 24px 0px 24px',
            background: '#eceef1',
          }}>
          <Row type={'flex'} gutter={30}>
            <Col xs={24} md={4}>
              <Form.Item
                label={'Số phiếu xuất'}
                name={'invoice_no'}
                rules={[
                  { required: true, message: ' Vui lòng nhập số phiếu xuất!' },
                ]}>
                <Input
                  allowClear
                  maxLength={50}
                  placeholder={'Nhập số phiếu xuất'}
                  disabled={type === 'detail' ? true : false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={4}>
              {type === 'detail' ? (
                <Form.Item label={'Ngày xuất hàng'}>
                  <Input
                    style={{ width: '100%' }}
                    value={
                      invoiceDate
                        ? moment(invoiceDate).format('YYYY-MM-DD')
                        : ''
                    }
                    disabled
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label={'Ngày xuất hàng'}
                  name={'invoice_date'}
                  rules={[
                    {
                      required: true,
                      message: ' Vui lòng chọn ngày xuất hàng!',
                    },
                  ]}>
                  <DatePicker
                    style={{ width: '100%' }}
                    placeholder="Chọn ngày xuất hàng"
                  />
                </Form.Item>
              )}
            </Col>
            <Col xs={24} md={8} style={{ paddingBottom: '20px' }}>
              {type === 'detail' ? (
                <Form.Item label={'Phòng ban'}>
                  <Input
                    style={{ width: '100%' }}
                    value={departmentName}
                    disabled
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label={'Phòng ban'}
                  name={'department_id'}
                  rules={[
                    { required: true, message: ' Vui lòng chọn phòng ban!' },
                  ]}>
                  <Select allowClear placeholder="Chọn phòng ban">
                    {departmentList.map(department => (
                      <Option
                        name={department.department_name}
                        value={department.id}
                        key={department.id}>
                        {department.department_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
            <Col
              xs={24}
              md={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'end',
              }}>
              <Title level={2} code style={{ color: '#000' }}>
                THÀNH TIỀN
              </Title>
              <Text strong style={{ fontSize: '20px', marginBottom: '0px' }}>
                {formatNumber(totalAmount)} VNĐ
              </Text>
            </Col>
          </Row>
          <Row type={'flex'} gutter={30}>
            <Col xs={24} md={4} style={{ paddingBottom: '20px' }}>
              {type === 'detail' ? (
                <Form.Item label={'Tên người nhận'} name={'received_by'}>
                  <Input
                    style={{ width: '100%' }}
                    value={receivedBy}
                    disabled
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label={'Tên người nhận'}
                  name={'received_by'}
                  rules={[
                    {
                      required: true,
                      message: ' Vui lòng nhập tên người nhận!',
                    },
                  ]}>
                  <Input allowClear maxLength={255} placeholder={'Nhập tên'} />
                </Form.Item>
              )}
            </Col>
            <Col xs={24} md={4} style={{ paddingBottom: '20px' }}>
              {type === 'detail' ? (
                <Form.Item label={'Email người nhận'} name={'received_email'}>
                  <Input
                    style={{ width: '100%' }}
                    value={receivedEmail}
                    disabled
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label={'Email người nhận'}
                  name={'received_email'}
                  rules={[
                    {
                      required: true,
                      message: ' Vui lòng nhập email người nhận!',
                    },
                  ]}>
                  <Input
                    allowClear
                    maxLength={255}
                    placeholder={'Nhập email'}
                  />
                </Form.Item>
              )}
            </Col>
            <Col xs={24} md={8} style={{ paddingBottom: '20px' }}>
              {type === 'detail' ? (
                <Form.Item label={'Ghi chú'} name={'note'}>
                  <Input style={{ width: '100%' }} value={note} disabled />
                </Form.Item>
              ) : (
                <Form.Item label={'Ghi chú'} name={'note'}>
                  <Input
                    allowClear
                    maxLength={255}
                    placeholder={'Nhập ghi chú'}
                  />
                </Form.Item>
              )}
            </Col>
            <Col
              xs={24}
              md={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'end',
                alignItems: 'end',
                justifyContent: 'center',
              }}>
              {getStatusTag(statusInv)}
            </Col>
          </Row>
        </div>
        <div
          style={{
            padding: '12px 24px 24px',
          }}>
          <ExportTable>
            <Table columns={columns} dataSource={data} pagination={false} />
          </ExportTable>
          <div
            style={{
              marginTop: '10px',
              ...(type === 'detail' && { display: 'none' }),
            }}>
            <Button
              key="themDong"
              onClick={() => {
                fetchData()
                handleAddRow()
              }}
              style={{ marginRight: '10px' }}>
              Thêm dòng
            </Button>
            <Button key="xoaTatCa" onClick={handleDeleteAll}>
              Xóa tất cả
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default memo(
  inject(
    'departmentStore',
    'unitStore',
    'inventoryItemStore',
    'exportStore',
    'loadingAnimationStore'
  )(observer(ExportDetailUpdate))
)
