import { inject, observer } from 'mobx-react'
import React, { memo, useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Container } from '../../layouts/Container/Container'
import { ItemsByDepartmentsTable } from './BrokenPageStyled'
import './BrokenPageStyle.css'
import { Button, Col, Input, Row, Table } from 'antd'

import { EmptyText } from '../../components/Common/CellText'
import { MinusOutlined, PlusOutlined, PrinterOutlined } from '@ant-design/icons'
import * as XLSX from 'xlsx'
import { SearchBar } from '../DepartmentPage/DepartmentPageStyled'
import { useHistory } from 'react-router-dom'

const { Search } = Input
const DepartmentsByItemsReportPage = props => {
  const { loadingAnimationStore, inventoryItemStore } = props
  const [itemList, setItemList] = useState([])
  const [search, setSearch] = useState('')
  const history = useHistory()

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    const fetchBrokenReport = async () => {
      try {
        const response = await inventoryItemStore.getBrokenReport({
          keyword: search,
        })
        if (response && response.data) {
          const data = response.data.map(item => ({
            key: item?.item_id,
            item_id: item?.item_id,
            item_code: item?.item_code,
            item_name: item?.item_name,
            broken_quantity: item?.broken_quantity,
            total_sold: item?.total_sold,
            total_sold_amount: item?.total_sold_amount,
            departments: item.departments.map(d => ({
              key: d?.key,
              department_id: d?.department_id,
              department_code: d?.department_code,
              department_name: d?.department_name,
              broken: d?.broken,
              total_broken_amount: d?.total_broken_amount,
            })),
          }))
          setItemList(data)
          // Thực hiện xử lý tiếp theo với biến `data` nếu cần
        }
      } catch (error) {
        console.error(error)
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    }

    fetchBrokenReport()

    // Cleanup function nếu cần
    return () => {
      loadingAnimationStore.showSpinner(false)
    }
  }, [inventoryItemStore, loadingAnimationStore, search])

  useEffect(() => {
    if (itemList.length > 0) {
      setExpandedRowKeys([itemList[0].key]) // Chỉ expand item đầu tiên
    }
  }, [itemList])

  function formatNumber(value) {
    if (value == null) return ''
    const roundedValue = Number(value).toFixed(2)
    const [integerPart, decimalPart] = roundedValue.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      width: '20px',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'Mã vật tư',
      dataIndex: 'item_code',
      key: 'item_code',
      sorter: (a, b) => a.item_code.localeCompare(b.item_code),
      sortDirections: ['ascend', 'descend'],
      render: text => (
        <span>{text || <EmptyText>Chưa có sản phẩm</EmptyText>}</span>
      ),
    },
    {
      title: 'Tên vật tư',
      dataIndex: 'item_name',
      key: 'item_name',
      sorter: (a, b) => a.item_name.localeCompare(b.item_name),
      sortDirections: ['ascend', 'descend'],
      render: text => (
        <span>{text || <EmptyText>Chưa có sản phẩm</EmptyText>}</span>
      ),
    },
    {
      title: 'Loại vật tư',
      render: () => <span>Phi kinh doanh</span>,
    },
    {
      title: 'TỔNG TỒN',
      dataIndex: 'broken_quantity',
      key: 'broken_quantity',
      sorter: (a, b) => a.broken_quantity - b.broken_quantity,
      sortDirections: ['ascend', 'descend'],
      render: text => (
        <div style={{ display: 'block', textAlign: 'right', width: '100%' }}>
          {text || 0}
        </div>
      ),
    },
    {
      title: 'ĐÃ THANH LÝ',
      dataIndex: 'total_sold',
      key: 'total_sold',
      sorter: (a, b) => a.total_sold - b.total_sold,
      sortDirections: ['ascend', 'descend'],
      render: text => (
        <div style={{ display: 'block', textAlign: 'right', width: '100%' }}>
          {text || 0}
        </div>
      ),
    },
    {
      title: 'TỔNG THANH LÝ (VNĐ)',
      dataIndex: 'total_sold_amount',
      key: 'total_sold_amount',
      sorter: (a, b) => a.total_sold_amount - b.total_sold_amount,
      sortDirections: ['ascend', 'descend'],
      render: text => (
        <div style={{ display: 'block', textAlign: 'right', width: '100%' }}>
          {formatNumber(text) || 0}
        </div>
      ),
    },
  ]

  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const expandedRowRender = record => {
    if (record.departments) {
      const itemData = record.departments.map(item => ({
        ...item,
        key: `${item.key}`,
      }))

      return (
        <Table
          className="expandedTable"
          columns={[
            {
              title: 'MÃ PHÒNG BAN',
              dataIndex: 'department_code',
              key: 'key',
              width: '100px',
            },
            {
              title: 'TÊN PHÒNG BAN',
              dataIndex: 'department_name',
              key: 'department_name',
              width: '200px',
            },
            {
              title: 'TRẢ HỎNG',
              dataIndex: 'broken',
              key: 'broken',
              width: '50px',
              align: 'right',
              render: (text, record) => (
                <span style={{ color: 'red' }}>{text} (trả hỏng)</span>
              ),
            },
            {
              title: 'TỔNG TIỀN',
              dataIndex: 'total_broken_amount',
              key: 'total_broken_amount',
              width: '150px',
              align: 'right', // Căn chỉnh cả cell sang phải
              render: text => (
                <span style={{ display: 'block', textAlign: 'right' }}>
                  {formatNumber(text)}
                </span>
              ),
            },
          ]}
          dataSource={itemData}
          pagination={false}
          showHeader={false}
          bordered
          rowKey="key"
        />
      )
    }
    return null
  }

  const handleExport = () => {
    const exportData = []

    itemList.forEach(inventory_item => {
      inventory_item.departments.forEach(department => {
        exportData.push({
          'MÃ Vật TƯ': inventory_item.item_code,
          'TÊN VẬT TƯ': inventory_item.item_name,
          'LOẠI VẬT TƯ': 'Phi kinh doanh',
          'TỔNG TỒN': inventory_item.broken_quantity,
          'ĐÃ THANH LÝ': inventory_item.total_sold,
          'TỔNG THANH LÝ': inventory_item.total_sold_amount,
          'MÃ PHÒNG BAN': department.department_code,
          'TÊN PHÒNG BAN': department.department_name,
          'TRẢ HỎNG': department.broken,
          'TỔNG TIỀN': department.total_broken_amount,
        })
      })
    })

    // Tạo worksheet từ dữ liệu
    const worksheet = XLSX.utils.json_to_sheet(exportData)

    // Chỉnh độ rộng cột cho worksheet
    worksheet['!cols'] = [
      { wch: 20 }, // MÃ Vật TƯ
      { wch: 30 }, // TÊN VẬT TƯ
      { wch: 20 }, // LOẠI VẬT TƯ
      { wch: 15 }, // TỔNG TỒN
      { wch: 15 }, // ĐÃ THANH LÝ
      { wch: 20 }, // TỔNG THANH LÝ
      { wch: 20 }, // MÃ PHÒNG BAN
      { wch: 30 }, // TÊN PHÒNG BAN
      { wch: 15 }, // TRẢ HỎNG
      { wch: 20 }, // TỔNG TIỀN
    ]

    // Chỉnh lại style cho worksheet
    const range = XLSX.utils.decode_range(worksheet['!ref'])
    for (let row = range.s.r; row <= range.e.r; ++row) {
      for (let col = range.s.c; col <= range.e.c; ++col) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: row, c: col })]
        if (cell) {
          cell.s = {
            font: {
              name: 'Arial',
              sz: 12,
              bold: row === 0, // Đặt tiêu đề là in đậm
            },
            alignment: {
              vertical: 'center',
              horizontal: 'center',
              wrapText: true,
            },
            border: {
              top: { style: 'thin' },
              bottom: { style: 'thin' },
              left: { style: 'thin' },
              right: { style: 'thin' },
            },
          }
        }
      }
    }

    // Tạo workbook và thêm worksheet
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Thống kê tồn hỏng')

    // Định dạng lại ngày trong tên file
    const formattedDate = new Date().toLocaleDateString('vi-VN', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })

    // Xuất file
    XLSX.writeFile(workbook, `Thống kê tồn hỏng - ${formattedDate}.xlsx`)
  }

  const expandAll = () => {
    if (expandedRowKeys.length === itemList?.length) {
      setExpandedRowKeys([])
    } else {
      const allKeys = itemList?.map(item => item.key)
      setExpandedRowKeys(allKeys)
    }
  }

  return (
    <>
      <DashboardLayout>
        <Helmet>
          <title>Thống kê vật tư tồn hỏng</title>
        </Helmet>
        <PageTitle
          location={props.location}
          title={'Thống kê vật tư tồn hỏng'}
          hiddenGoBack></PageTitle>
        <ContentBlockWrapper>
          <Container>
            <Row
              style={{
                marginBottom: 0,
                height: '38px',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <Col span={12}>
                <SearchBar>
                  <Search
                    allowClear
                    onSearch={value => {
                      setSearch(value)
                    }}
                    maxLength={500}
                    placeholder={
                      'Tìm kiếm theo Mã Vật Tư / Tên Vật Tư / Phòng Ban'
                    }
                  />
                </SearchBar>
              </Col>
              <Col>
                <Button onClick={handleExport} style={{ marginRight: '10px' }}>
                  <PrinterOutlined /> Xuất Excel
                </Button>
                <Button
                  type={'primary'}
                  onClick={() =>
                    history.push(
                      '/warehouse/nonbusinessexport?liquidation=true'
                    )
                  }>
                  Thanh lý
                </Button>
              </Col>
            </Row>
            <Button onClick={expandAll} style={{ marginBottom: '4px' }}>
              {expandedRowKeys?.length === itemList?.length ? (
                <MinusOutlined />
              ) : (
                <PlusOutlined />
              )}
              {expandedRowKeys?.length === itemList?.length
                ? 'Thu gọn tất cả'
                : 'Mở rộng tất cả'}
            </Button>
            <ItemsByDepartmentsTable>
              <Table
                id="reportTable"
                className="reportTable"
                style={{
                  height: 'calc(100vh - 220px)',
                  overflowY: 'auto',
                }}
                columns={columns}
                dataSource={itemList}
                bordered
                pagination={false}
                expandable={{
                  expandedRowRender: record => (
                    <div style={{ margin: 0 }}>{expandedRowRender(record)}</div>
                  ),
                  expandedRowKeys,
                  onExpand: (expanded, record) => {
                    setExpandedRowKeys(
                      expanded
                        ? [...expandedRowKeys, record.key]
                        : expandedRowKeys.filter(key => key !== record.key)
                    )
                  },
                }}
              />
            </ItemsByDepartmentsTable>
          </Container>
        </ContentBlockWrapper>
      </DashboardLayout>
    </>
  )
}

export default memo(
  inject(
    'loadingAnimationStore',
    'departmentStore',
    'inventoryItemStore'
  )(observer(DepartmentsByItemsReportPage))
)
