import { apiUrl } from '../config'
import axios from 'axios'

export const SupplierRequest = {
  getSupplier: (index, size, search) =>
    axios.post(
      `${apiUrl}/api/v1/suppliers/get-all-suppliers/`,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  getSupplierDTO: (index, size, search) =>
    axios.post(
      `${apiUrl}/api/v1/suppliers/get-all-suppliers-dto/`,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  checkExistSupplier: name =>
    axios.get(`${apiUrl}/api/v1/suppliers/check_exist_supplier?name=${name}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getSupplierById: id =>
    axios.get(`${apiUrl}/api/v1/suppliers/get-supplier-by-id?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  createSupplier: data =>
    axios.post(`${apiUrl}/api/v1/suppliers/create-supplier`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  updateSupplier: data =>
    axios.post(`${apiUrl}/api/v1/suppliers/update-supplier`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getSupplierByName: name =>
    axios.get(`${apiUrl}/api/v1/suppliers/get-supplier-by-name?name=${name}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getTotal: () =>
    axios.get(`${apiUrl}/api/v1/suppliers/total`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  uploadContract: data =>
    axios.post(`${apiUrl}/api/v1/suppliers/upload`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),

  deleteSupplier: id =>
    axios.post(
      `${apiUrl}/api/v1/suppliers/delete`,
      {
        id: id,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  addLog: request =>
    axios.post(`${apiUrl}/api/v1/suppliers/add-audit-log`, request),
}
