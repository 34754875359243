import React, { memo, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import DashboardLayout from '../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import {
  Button,
  Col,
  Input,
  Modal,
  Pagination,
  Row,
  Space,
  Table,
  message,
  Tooltip,
  Tag,
  Badge,
  DatePicker,
} from 'antd'
import { TableWrapper } from '../../components/Common/Table'
import { FilterButtonWrapper, ExportTable, SearchBar } from './ExportPageStyled'
import { Container } from '../../layouts/Container/Container'
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  PlusOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  RollbackOutlined,
  ReloadOutlined,
  BellOutlined,
  CaretDownOutlined,
} from '@ant-design/icons'
import ExportCreateModal from './ExportNonBusinessCreateModal'
import { inject, observer } from 'mobx-react'
import utils from '../../utils'
import ExportDetailUpdate from './ExportNonBussinessDetailUpdate'
import ExportExcelCreateModal from './ExportExcelCreateModal'
import { EmptyText } from '../../components/Common/CellText'
import AdvancedFilterBlockNonBusiness from './AdvancedFilterBlockNonBusiness'
import * as signalR from '@microsoft/signalr'
import { apiUrl } from '../../config'
import moment from 'moment'
import { PermissionType } from '../../enum'

const { Search } = Input
const { confirm } = Modal

const ExportPage = props => {
  const {
    history,
    exportStore,
    loadingAnimationStore,
    inventoryItemStore,
    accountStore,
  } = props
  const [exportData, setExportData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchValue, setSearchValue] = useState('')
  const [filters, setFilters] = useState([])
  const [total, setTotal] = useState(0)
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false)
  const [countFilter, setCountFilter] = useState(0)
  const [countReturn, setCountReturn] = useState(0)
  const [openExportCreate, setOpenExportCreate] = useState(false)
  const [openExportExcelCreate, setOpenExportExcelCreate] = useState(false)
  const [excelData, setExcelData] = useState({})
  const [openExportDetailUpdate, setOpenExportDetailUpdate] = useState(false)
  const [exportId, setExportId] = useState('')
  const [type, setType] = useState('')
  const [selectedRows, setSelectedRows] = useState([])

  const location = history.location
  const queryParams = new URLSearchParams(location.search)
  const trigger = queryParams.get('liquidation') === 'true'
  const [liquidationData, setLiquidationData] = useState([])

  useEffect(() => {
    if (trigger) {
      setOpenExportCreate(true)
    }
  }, [trigger])

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiUrl}/outwardStockHub`)
      .configureLogging(signalR.LogLevel.None)
      .withAutomaticReconnect()
      .build()

    connection.on('OutwardStockSignal', () => {
      fetchExportInvoice()
    })

    connection
      .start()
      .then(() => {
        fetchExportInvoice()
      })
      .catch(() => {
        fetchExportInvoice()
      })

    return () => {
      connection.stop()
    }
  }, [page, pageSize, searchValue, filters])

  const fetchExportInvoice = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await exportStore.getExportInvoice(
        page,
        pageSize,
        searchValue,
        [
          {
            data_field: 'export_type',
            value: '1',
            operator_type: 'equal',
          },
          ...filters,
        ]
      )
      const data = response.data.pageData.map((item, index) => ({
        key: item.id,
        stt: index + 1,
        invoice_no: item.invoice_no,
        invoice_date: item.invoice_date,
        department_name: item.department_name,
        total_amount: formatNumber(item.total_amount),
        note: item.note,
        status: item.status,
        return_date: item.return_date,
      }))
      setExportData(data)
      setCountReturn(0)
      setTotal(response.data.totalCount)
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const getPagingFilterCustom = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await exportStore.getPagingFilterCustom(
        page,
        pageSize,
        searchValue,
        [
          {
            data_field: 'export_type',
            value: '1',
            operator_type: 'equal',
          },
          ...filters,
        ],
        1
      )
      const data = response.data.pageData.map((item, index) => ({
        key: item.id,
        stt: index + 1,
        invoice_no: item.invoice_no,
        invoice_date: item.invoice_date,
        department_name: item.department_name,
        total_amount: formatNumber(item.total_amount),
        note: item.note,
        status: item.status,
        return_date: item.return_date,
      }))
      setCountReturn(data.length)
      setExportData(data)
      setTotal(response.data.totalCount)
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [dataMasterDetails, setDataMasterDetails] = useState([])

  const showModal = async id => {
    try {
      const dataMasterDetail = await exportStore.getOnlyDetails(id)

      if (dataMasterDetail && dataMasterDetail.data) {
        const dataWithKeys = dataMasterDetail.data.map((item, index) => ({
          key: index,
          ...item,
        }))
        setDataMasterDetails(dataWithKeys)

        const selectedKeys = dataWithKeys
          .filter(item => item.is_returned) // Filter rows where is_returned is true
          .map(item => item.key) // Get their keys

        setSelectedRows(selectedKeys)
      }
      setIsModalVisible(true)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  const handleRowSelectionChange = (keys, rows) => {
    if (rows && rows.length > 0) {
      const returnedKeys = rows
        .filter(row => row.is_returned)
        .map(row => row.key)

      // Remove any returned keys from the selected keys
      const newSelectedKeys = keys.filter(key => !returnedKeys.includes(key))

      // Combine newSelectedKeys with returnedKeys
      const combinedKeys = [...newSelectedKeys, ...returnedKeys]

      // Update state with the combined keys
      setSelectedRows(combinedKeys)
    } else {
      setSelectedRows(keys)
    }
  }

  const handleOk = async () => {
    const allRowsChecked = dataMasterDetails.every(row =>
      selectedRows.includes(row.key)
    )
    const checkedRows = dataMasterDetails.filter(
      row => selectedRows.includes(row.key) && !row.is_returned
    )

    for (let row of checkedRows) {
      if (row.quantity_broken > row.quantity) {
        // Show warning message
        message.warning(
          'Số lượng hỏng vượt quá số lượng đã đặt. Vui lòng kiểm tra lại.'
        )
        return
      }
    }
    var isReturnFull = false
    if (allRowsChecked) {
      isReturnFull = true
    }
    const dataRequest = checkedRows.map(row => ({
      id: row.id,
      outward_id: row.outward_id,
      item_id: row.item_id,
      reason_broken: row.reason_broken,
      quantity: row.quantity,
      quantity_broken: row.quantity_broken,
      is_full: isReturnFull,
    }))

    try {
      const returnInvItem = await exportStore.returnInventoryItem(dataRequest)
      setIsModalVisible(false)
      fetchExportInvoice()
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handleCancelModal = () => {
    setIsModalVisible(false)
  }

  const columnsModal = [
    {
      title: 'MÃ SẢN PHẨM',
      dataIndex: 'item_code',
      key: 'item_id',
      width: '180px',
    },
    {
      title: 'TÊN SẢN PHẨM',
      dataIndex: 'item_name',
      key: 'item_name',
      width: '300px',
    },
    {
      title: 'SỐ LƯỢNG ĐÃ ĐẶT',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '180px',
    },
    {
      title: 'SỐ LƯỢNG BỊ HỎNG',
      dataIndex: 'quantity_broken',
      key: 'quantity_broken',
      width: '180px',
      render: (text, record) => (
        <Input
          type="number"
          value={record.quantity_broken}
          placeholder="số lượng hỏng"
          onChange={e =>
            handleInputChange(
              record.key,
              'quantity_broken',
              parseFloat(e.target.value)
            )
          }
          readOnly={record.is_returned}
        />
      ),
    },
    {
      title: 'Nguyên nhân',
      dataIndex: 'reason_broken',
      key: 'reason_broken',
      width: '300px',
      render: (text, record) => (
        <Input
          allowClear
          maxLength={255}
          value={record.reason_broken}
          placeholder="Nguyên nhân hỏng"
          onChange={e =>
            handleInputChange(record.key, 'reason_broken', e.target.value)
          }
          readOnly={record.is_returned}
        />
      ),
    },
  ]

  function formatNumber(value) {
    if (value == null) return ''
    const roundedValue = Number(value).toFixed(2)
    const [integerPart, decimalPart] = roundedValue.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }

  const handleInputChange = (key, field, value) => {
    const newData = dataMasterDetails.map(item => {
      if (item.key === key) {
        return { ...item, [field]: value }
      }
      return item
    })
    setDataMasterDetails(newData)
  }

  const fullWidthStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  }

  const statusFilters = [
    { text: 'Đang kiểm hàng để xuất', value: 0, color: 'darkgrey' },
    { text: 'Đợi người nhận xác nhận', value: 1, color: 'orange' },
    { text: 'Đơn hàng đã bị hủy', value: 2, color: 'red' },
    { text: 'Trong thời gian nhận hàng', value: 3, color: 'yellow' },
    { text: 'Hoàn thành', value: 4, color: 'green' },
    { text: 'Chờ trả hàng', value: 5, color: 'green' },
    { text: 'Trả 1 phần', value: 6, color: 'green' },
    { text: 'Hoàn trả đủ hàng', value: 7, color: 'green' },
  ]

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      width: '20px',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'SỐ HÓA ĐƠN',
      key: 'invoice_no',
      dataIndex: 'invoice_no',
      sorter: (a, b) => a.invoice_no.localeCompare(b.invoice_no),
      render: text => (
        <span>{text || <EmptyText>Chưa có số hóa đơn</EmptyText>}</span>
      ),
    },
    {
      title: 'NGÀY XUẤT HÀNG',
      key: 'invoice_date',
      dataIndex: 'invoice_date',
      sorter: (a, b) => new Date(a.invoice_date) - new Date(b.invoice_date),
      render: text => {
        if (text) {
          const date = new Date(text)
          return (
            <span>
              {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
            </span>
          )
        } else {
          return <EmptyText>Chưa có ngày đặt hàng</EmptyText>
        }
      },
    },
    {
      title: 'NGÀY TRẢ ĐỦ HÀNG',
      key: 'return_date',
      dataIndex: 'return_date',
      sorter: (a, b) => new Date(a.return_date) - new Date(b.return_date),
      render: text => {
        if (text) {
          const date = new Date(text)
          return (
            <span>
              {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
            </span>
          )
        } else {
          return <EmptyText>Chưa trả hàng</EmptyText>
        }
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <DatePicker
            onChange={date => {
              setSelectedKeys(date ? [moment(date).format('YYYY-MM-DD')] : [])
              confirm()
            }}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
      onFilter: (value, record) => {
        if (!record.return_date) return false
        return moment(record.return_date).format('YYYY-MM-DD') === value
      },
    },
    {
      title: 'PHÒNG BAN',
      key: 'department_name',
      dataIndex: 'department_name',
      sorter: (a, b) => a.department_name.localeCompare(b.department_name),
      render: text => (
        <span>{text || <EmptyText>Chưa có phòng ban</EmptyText>}</span>
      ),
    },
    // {
    //   title: 'THÀNH TIỀN (VND)',
    //   key: 'total_amount',
    //   dataIndex: 'total_amount',
    //   sorter: (a, b) => a.total_amount - b.total_amount,
    //   render: text => (
    //     <span>
    //       {formatNumber(text) || <EmptyText>Chưa có tổng tiền</EmptyText>}
    //     </span>
    //   ),
    // },
    {
      title: 'GHI CHÚ',
      key: 'note',
      dataIndex: 'note',
      render: text => (
        <span>{text || <EmptyText>Chưa có ghi chú</EmptyText>}</span>
      ),
    },
    {
      title: 'TRẠNG THÁI',
      key: 'status',
      sorter: (a, b) => a.status - b.status,
      filters: statusFilters,
      onFilter: (value, record) => record.status === value,
      dataIndex: 'status',
      render: status => {
        let color
        let content
        switch (status) {
          case 0:
            color = 'darkgrey'
            content = 'Đang kiểm hàng để xuất'
            break
          case 1:
            color = 'orange'
            content = 'Đợi người nhận xác nhận'
            break
          case 2:
            color = 'red'
            content = 'Đơn hàng đã bị hủy'
            break
          case 4:
            color = 'green'
            content = 'Hoàn thành'
            break
          case 5:
            color = '#faad14' // Bright green color
            content = 'Chờ trả hàng'
            break
          case 6:
            color = 'lightgreen' // Update color for partial payment
            content = 'Trả 1 phần'
            break
          case 7:
            color = 'darkgreen' // Update color for full payment
            content = 'Hoàn trả đủ hàng'
            break
          case 3:
            color = 'yellow'
            content = 'Trong thời gian nhận hàng'
            break
          default:
            color = ''
            content = ''
        }
        if (status != null)
          return (
            <div style={fullWidthStyle}>
              <Tag
                color={color}
                style={{ width: '170px', textAlign: 'center' }}>
                {content}
              </Tag>
            </div>
          )
        else return <EmptyText>Chưa có trạng thái</EmptyText>
      },
    },
    {
      title: '',
      key: '#',
      width: 1,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {record.status === 0 && (
            <>
              <Tooltip title="Xác nhận xuất hàng">
                <Button
                  style={{ marginRight: '5px' }}
                  onClick={e => {
                    e.stopPropagation()
                    confirm({
                      title: 'Bạn đã chuẩn bị đủ hàng cho đơn xuất kho này?',
                      icon: (
                        <QuestionCircleOutlined style={{ color: 'blue' }} />
                      ),
                      okText: 'Đồng ý',
                      cancelText: 'Không',
                      okType: 'primary',
                      onOk() {
                        handleAction(record.key, 1)
                      },
                    })
                  }}
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  size="small"
                />
              </Tooltip>
              <Tooltip title="Sửa">
                <Button
                  onClick={e => {
                    e.stopPropagation()
                    setOpenExportDetailUpdate(true)
                    setExportId(record.key)
                    setType('update')
                  }}
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  size="small"
                />
              </Tooltip>
              <Tooltip title="Xóa đơn hàng">
                <Button
                  style={{ marginLeft: '5px' }}
                  onClick={e => {
                    e.stopPropagation()
                    showConfirm(record.key)
                  }}
                  type="danger"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size="small"
                />
              </Tooltip>
            </>
          )}
          {record.status === 1 && (
            <>
              {/* <Tooltip title="Hủy đơn">
                <Button
                  style={{ marginRight: '5px' }}
                  onClick={e => {
                    e.stopPropagation()
                    confirm({
                      title: 'Bạn có muốn hủy đơn xuất kho này không?',
                      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
                      okText: 'Đồng ý',
                      cancelText: 'Không',
                      okType: 'danger',
                      onOk() {
                        handleAction(record.key, 2)
                      },
                    })
                  }}
                  type="danger"
                  shape="circle"
                  icon={<CloseOutlined />}
                  size="small"
                />
              </Tooltip> */}
              {/* <Tooltip title="Duyệt">
                <Button
                  onClick={e => {
                    e.stopPropagation()
                    confirm({
                      title: 'Bạn có muốn duyệt đơn nhập kho này không?',
                      icon: (
                        <QuestionCircleOutlined style={{ color: 'blue' }} />
                      ),
                      okText: 'Đồng ý',
                      cancelText: 'Không',
                      okType: 'primary',
                      onOk() {
                        handleAction(record.key, 4)
                      },
                    })
                  }}
                  style={{ backgroundColor: 'green', color: 'white' }}
                  shape="circle"
                  icon={<CheckOutlined />}
                  size="small"></Button>
              </Tooltip> */}
            </>
          )}
          {(record.status === 5 || record.status === 6) && (
            <Tooltip title="Trả hàng">
              <Button
                style={{
                  marginRight: '5px',
                  backgroundColor: '#faad14',
                  borderColor: '#faad14',
                  color: 'white',
                }}
                onClick={e => {
                  e.stopPropagation()
                  showModal(record.key)
                }}
                shape="circle"
                icon={<RollbackOutlined />}
                size="small"
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ]

  const handleAction = async (id, status) => {
    loadingAnimationStore.showSpinner(true)
    try {
      await exportStore.updateStatus(id, status)
      message.success('Cập nhật trạng thái xuất kho thành công!')
      await fetchExportInvoice()
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const showConfirm = id => {
    confirm({
      title: 'Bạn có muốn xóa đơn xuất kho này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDelete(id)
      },
    })
  }

  const mapToOutwardStockDetailDTO = (detail, itemNameMap) => ({
    id: detail.id,
    outward_id: detail.outward_id,
    item_id: detail.item_id,
    item_name: itemNameMap?.find(x => x.id == detail.item_id)?.item_name, // Set to null or handle as needed
    campus_id: detail.campus_id,
    unit_id: detail.unit_id,
    unit_name: itemNameMap?.find(x => x.id == detail.item_id)?.unit_name, // Set to null or handle as needed
    quantity: detail.quantity,
    unit_price: detail.unit_price,
    outward_non_business_type: detail.outward_non_business_type,
    deleted: detail.deleted,
    return_date: detail.return_date,
  })

  const handleDelete = async id => {
    loadingAnimationStore.showSpinner(true)
    try {
      exportStore.getExportInvoiceDetail(id).then(async res => {
        await exportStore.deleteExportInvoice(id)

        var setOldMaster = res.data.masterDTO
        var setOldDetails = res.data.details
        await inventoryItemStore
          .getInventoryItem(1, 9999, '')
          .then(async res => {
            var itemList = res.data.pageData
            if (setOldDetails) {
              const convertedDetails = setOldDetails.map(detail =>
                mapToOutwardStockDetailDTO(detail, itemList)
              )
              if (convertedDetails) {
                var auditLogRequest = {
                  table_name: 'Xuất kho',
                  action: 'Xóa',
                  oldObj: setOldMaster,
                  newObj: null,
                  oldDetails: convertedDetails,
                  newDetails: null,
                }
                await exportStore.addAuditLog(auditLogRequest)
              }
            }
          })

        message.success('Xóa đơn xuất kho thành công!')
        await fetchExportInvoice()
      })
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
      await fetchExportInvoice()
    }
  }

  const handlePrint = () => {
    const data = exportData.map((item, index) => ({
      STT: index + 1,
      'Số phiếu xuất': item.invoice_no || '',
      'Ngày xuất': item.invoice_date
        ? new Date(item.invoice_date).toLocaleDateString()
        : '',
      'Phòng ban': item.department_name || '',
      'Tổng tiền': formatNumber(item.total_amount) || '',
      'Ghi chú': item.note || '',
    }))

    utils.exportToExcel({ data, fileName: 'xuat_kho_phi_kinh_doanh' })
  }

  const isAccessPermission = permissionType => {
    if (accountStore.hasPermission(permissionType)) return true
    else return false
  }

  return (
    <>
      <DashboardLayout>
        <Helmet>
          <title>Quản lý xuất kho</title>
        </Helmet>
        <PageTitle
          location={props.location}
          title={'Quản lý xuất kho'}
          hiddenGoBack>
          {isAccessPermission(PermissionType.OUTWARD_INVENTORY_CREATE) && (
            <Button
              style={{ marginLeft: '10px' }}
              onClick={() => {
                setOpenExportCreate(true)
              }}
              type={'primary'}>
              Thêm | <CaretDownOutlined />
            </Button>
          )}
        </PageTitle>
        <ContentBlockWrapper>
          <Container>
            <Row style={{ marginBottom: 0, height: '38px' }}>
              <Col span={12}>
                {!showAdvancedFilter ? (
                  <SearchBar>
                    <Search
                      allowClear
                      onSearch={value => {
                        setPage(1), setPageSize(10), setSearchValue(value)
                      }}
                      maxLength={500}
                      placeholder={
                        'Tìm kiếm theo Số hóa đơn / Phòng ban / Ghi chú'
                      }
                    />
                  </SearchBar>
                ) : null}
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Button
                  style={{
                    marginLeft: '10px',
                    marginRight: '15px',
                    position: 'relative',
                  }}
                  onClick={getPagingFilterCustom}>
                  <Badge
                    count={countReturn ? countReturn : 0}
                    overflowCount={99}
                    style={{
                      position: 'absolute',
                      top: '-10px',
                      right: '-104px',
                    }}>
                    <BellOutlined />
                  </Badge>
                  Tới hạn trả hàng
                </Button>
                <FilterButtonWrapper>
                  <span
                    className={'count-filter'}
                    style={{ display: !countFilter && 'none' }}>
                    {countFilter}
                  </span>
                  <Button
                    type={showAdvancedFilter ? 'primary' : 'default'}
                    ghost={showAdvancedFilter}
                    style={{ marginBottom: showAdvancedFilter ? 10 : 0 }}
                    onClick={() => setShowAdvancedFilter(!showAdvancedFilter)}>
                    {showAdvancedFilter ? (
                      <Space>
                        <CloseCircleOutlined />
                        Ẩn tìm kiếm nâng cao
                      </Space>
                    ) : (
                      <Space>
                        <FilterOutlined />
                        Tìm kiếm nâng cao
                      </Space>
                    )}
                  </Button>
                </FilterButtonWrapper>
                <Button style={{ marginLeft: '10px' }} onClick={handlePrint}>
                  <PrinterOutlined />
                  Xuất excel
                </Button>
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    fetchExportInvoice()
                  }}>
                  <ReloadOutlined />
                </Button>
              </Col>
            </Row>
            {showAdvancedFilter ? (
              <AdvancedFilterBlockNonBusiness
                setPage={setPage}
                setPageSize={setPageSize}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                setFilters={setFilters}
                filters={filters}
                setCountFilter={setCountFilter}
              />
            ) : null}
            <TableWrapper>
              <ExportTable>
                <Table
                  className="exportTable"
                  style={{
                    height: !showAdvancedFilter
                      ? 'calc(100vh - 220px)'
                      : 'calc(100vh - 289px)',
                    overflowY: 'auto',
                  }}
                  columns={columns}
                  dataSource={exportData}
                  bordered
                  pagination={false}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                        setOpenExportDetailUpdate(true)
                        setExportId(record.key)
                        setType('detail')
                      },
                    }
                  }}
                />
              </ExportTable>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <div>
                  Tổng số: <b>{total}</b> bản ghi
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '15px 0',
                  }}>
                  <Pagination
                    onChange={(page, pageSize) => {
                      setPage(page)
                      setPageSize(pageSize)
                    }}
                    current={page}
                    pageSize={pageSize}
                    total={total}
                    hideOnSinglePage={true}
                    showSizeChanger={true}
                    showLessItems
                  />
                </div>
              </div>
            </TableWrapper>
          </Container>
        </ContentBlockWrapper>
      </DashboardLayout>
      <ExportCreateModal
        open={openExportCreate}
        setOpen={setOpenExportCreate}
        fetchExportInvoice={fetchExportInvoice}
        excelData={excelData}
        setExcelData={setExcelData}
      />
      <ExportExcelCreateModal
        open={openExportExcelCreate}
        setOpen={setOpenExportExcelCreate}
        setOpenExportCreate={setOpenExportCreate}
        setExcelData={setExcelData}
        liquidationData={liquidationData}
        setLiquidationData={setLiquidationData}
      />
      <ExportDetailUpdate
        open={openExportDetailUpdate}
        setOpen={setOpenExportDetailUpdate}
        exportId={exportId}
        setExportId={setExportId}
        type={type}
        fetchExportInvoice={fetchExportInvoice}
      />
      <Modal
        title="Trả lại hàng"
        visible={isModalVisible}
        okText="Đồng ý"
        onOk={handleOk}
        onCancel={handleCancelModal}
        cancelText="Không"
        okType="primary"
        width="80%"
        icon={<CloseOutlined />}>
        <Table
          columns={columnsModal}
          dataSource={dataMasterDetails}
          pagination={false}
          rowSelection={{
            type: 'checkbox',
            onChange: handleRowSelectionChange,
            selectedRowKeys: selectedRows,
          }}
          rowKey="key"
        />
      </Modal>
    </>
  )
}

export default memo(
  inject(
    'exportStore',
    'loadingAnimationStore',
    'supplierStore',
    'inventoryItemStore',
    'accountStore'
  )(observer(ExportPage))
)
