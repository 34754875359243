import React, { memo, useEffect, useState, useCallback } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import './SupplierPage.css'
import {
  Table,
  Pagination,
  Button,
  Modal,
  Form,
  Input,
  message,
  Row,
  Col,
  DatePicker,
  Upload,
  Tag,
  Tooltip,
} from 'antd'
import { TableWrapper } from '../../components/Common/Table'
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { SearchBar, SupplierTable } from './SupplierPageStyled'
import { EmptyText } from '../../components/Common/CellText'
import { Container } from '../../layouts/Container/Container'
import moment from 'moment'
import * as signalR from '@microsoft/signalr'
import { apiUrl } from '../../config'

const { Search } = Input
const { confirm } = Modal
const { RangePicker } = DatePicker

const SupplierPage = props => {
  const { supplierStore, loadingAnimationStore, contractStore } = props
  const [supplierData, setSupplierData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const [selectedSupplierId, setSelectedSupplierId] = useState(null)
  const [pdfVisible, setPdfVisible] = useState(false)
  const [pdfUrl, setPdfUrl] = useState()
  const [contractModalVisible, setContractModalVisible] = useState(false)
  const [contractData, setContractData] = useState([])
  const [extensionModalVisible, setExtensionModalVisible] = useState(false)

  const beforeUpload = file => {
    const isPDF = file.type === 'application/pdf'
    if (!isPDF) {
      message.error('Chỉ upload file PDF')
      return Upload.LIST_IGNORE
    }
    return false
  }

  const handleExtensionModalOpen = () => {
    setExtensionModalVisible(true)
  }

  const handleExtensionModalCancel = () => {
    setExtensionModalVisible(false)
  }

  const handleDeleteSupplier = async record => {
    try {
      await supplierStore.deleteSupplier(record.id)
      fetchSupplierData()
      message.success('Xóa nhà cung cấp thành công ')
    } catch (error) {}
  }

  const handleExtensionSubmit = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      const valuesContract = await form.validateFields()
      const fileUrl = await handleFileUpload({
        file: form.getFieldValue('contract_file')[0],
        onSuccess: data => {
          setPdfUrl(data.fileUrl)
        },
        onError: error => {
          console.error('Upload error:', error)
        },
      })

      if (!fileUrl) return
      const dataContract = {
        supplier_id: selectedSupplierId,
        start_date: valuesContract.contract_date_range
          ? valuesContract.contract_date_range[0].format('YYYY-MM-DD')
          : null,
        end_date: valuesContract.contract_date_range
          ? valuesContract.contract_date_range[1].format('YYYY-MM-DD')
          : null,
        contract_resource: fileUrl,
      }
      await contractStore.addContract(dataContract)
      message.success('Gia hạn hợp đồng thành công ')
      setSelectedSupplierId(null)
      setExtensionModalVisible(false)
      setContractModalVisible(false)
      fetchSupplierData()
    } catch (e) {
      console.error(e)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleFileUpload = async ({ file, onSuccess, onError }) => {
    if (!file) {
      onError(new Error('Chưa chọn tệp'))
      return
    }
    const formData = new FormData()
    formData.append('file', file.originFileObj)
    try {
      const response = await supplierStore.uploadContract(formData)
      onSuccess(response.data, file)
      return response.data.fileUrl
    } catch (error) {
      onError(error)
      return null
    }
  }

  function convertToDateFormat(isoDateString) {
    const date = new Date(isoDateString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
  }

  function getStatus(endDate) {
    const currentDate = new Date()
    const endDateObj = new Date(endDate)
    if (endDateObj >= currentDate) {
      return (
        <Tag style={{ width: '100px' }} color="green">
          Đang hoạt động
        </Tag>
      )
    }
    return (
      <Tag style={{ width: '100px' }} color="red">
        Hết hạn
      </Tag>
    )
  }

  const fetchSupplierData = useCallback(async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await supplierStore.getSupplierDTO(
        page,
        pageSize,
        search
      )
      const { pageData, totalCount } = response.data
      const data = pageData.map((item, index) => ({
        key: item.id,
        stt: (page - 1) * pageSize + index + 1,
        supplier_name: item.supplier_name,
        tax_code: item.tax_code,
        phone_number: item.phone_number,
        address: item.address,
        description: item.description,
        id: item.id,
        start_date: convertToDateFormat(item.start_date),
        end_date: convertToDateFormat(item.end_date),
        status: getStatus(item.end_date),
        contract_resource: item.contract_resource,
        email: item.email,
        supplier_code: item.supplier_code,
      }))
      setSupplierData(data)
      setTotal(totalCount)
    } catch (error) {
      message.error('Có lỗi xảy ra khi lấy danh sách nhà cung cấp')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [page, pageSize, search])

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiUrl}/supplierHub`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.None)
      .build()

    connection.on('SupplierSignal', () => {
      fetchSupplierData()
    })

    connection
      .start()
      .then(() => {
        fetchSupplierData()
      })
      .catch(() => {
        fetchSupplierData()
      })

    return () => {
      connection.stop()
    }
  }, [fetchSupplierData])

  const handleSearch = value => {
    setSearch(value)
    setPage(1)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
    setSelectedSupplierId(null)
  }

  const handleOk = async () => {
    try {
      if (selectedSupplierId) {
        var valuesEdit = await form.validateFields()
        valuesEdit.id = selectedSupplierId
        var dataEdit = {
          id: valuesEdit.id,
          supplier_name: valuesEdit.supplier_name,
          tax_code: valuesEdit.tax_code,
          phone_number: valuesEdit.phone_number,
          address: valuesEdit.address,
          description: valuesEdit.description,
          email: valuesEdit.email,
          supplier_code: valuesEdit.supplier_code,
        }
        await supplierStore.updateSupplier(dataEdit)
        message.success('Chỉnh sửa nhà cung cấp thành công!')

        supplierStore.addLog({
          table_name: 'Nhà cung cấp',
          action: 'Chỉnh sửa',
          newObj: dataEdit,
          oldObj: supplierData?.find(s => s.id === selectedSupplierId),
        })
      } else {
        loadingAnimationStore.showSpinner(true)
        const values = await form.validateFields()
        const fileUrl = await handleFileUpload({
          file: form.getFieldValue('supplier_contract')[0],
          onSuccess: data => {
            setPdfUrl(data.fileUrl)
          },
          onError: error => {
            console.error('Upload error:', error)
          },
        })
        if (!fileUrl) return
        const data = {
          supplier_name: values.supplier_name,
          tax_code: values.tax_code,
          phone_number: values.phone_number,
          address: values.address,
          description: values.description,
          email: values.email,
          supplier_code: values.supplier_code,
          supplier_contracts: {
            start_date: values.contract_date_range
              ? values.contract_date_range[0].format('YYYY-MM-DD')
              : null,
            end_date: values.contract_date_range
              ? values.contract_date_range[1].format('YYYY-MM-DD')
              : null,
            contract_resource: fileUrl,
          },
        }
        await supplierStore.createSupplier(data)
        message.success('Thêm nhà cung cấp thành công!')

        supplierStore.addLog({
          table_name: 'Nhà cung cấp',
          action: 'Thêm',
          newObj: {
            supplier_name: data.supplier_name,
            tax_code: data.tax_code,
            phone_number: data.phone_number,
            address: data.address,
            description: data.description,
            email: data.email,
            supplier_code: data.supplier_code,
          },
          oldObj: {
            supplier_name: '',
            tax_code: '',
            phone_number: '',
            address: '',
            description: '',
            email: '',
            supplier_code: '',
          },
        })
      }
      setVisible(false)
      form.resetFields()
      setSelectedSupplierId(null)
      fetchSupplierData()
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleEdit = record => {
    setSelectedSupplierId(record.id)
    form.setFieldsValue({
      supplier_name: record.supplier_name,
      supplier_code: record.supplier_code,
      email: record.email,
      tax_code: record.tax_code,
      phone_number: record.phone_number,
      address: record.address,
      start_date: record.start_date
        ? moment(record.start_date, 'DD-MM-YYYY')
        : null,
      end_date: record.end_date ? moment(record.end_date, 'DD-MM-YYYY') : null,
      description: record.description,
    })
    setVisible(true)
  }

  const handleViewContract = contractResource => {
    const previewUrl = contractResource.replace('/view', '/preview')
    setPdfUrl(previewUrl)
    setPdfVisible(true)
  }

  const handleContractModalCancel = () => {
    setContractModalVisible(false)
    setContractData([])
  }

  const handlePdfCancel = () => {
    setPdfVisible(false)
    setPdfUrl('')
  }

  const handleRowDoubleClick = async record => {
    try {
      const response = await contractStore.getContractsBySupplierId(record.id)
      const formattedContracts = response.data.map(contract => ({
        ...contract,
        key: contract.id,
        start_date: convertToDateFormat(contract.start_date),
        end_date: convertToDateFormat(contract.end_date),
      }))
      setContractData(formattedContracts)
      setSelectedSupplierId(record.id)
      setContractModalVisible(true)
    } catch (error) {
      message.error('Có lỗi xảy ra')
    }
  }

  const handleContractModalOpen = supplierId => {
    setExtensionModalVisible(true)
  }

  const contractColumns = [
    {
      title: 'STT',
      key: 'stt',
      width: '60px',
      align: 'center',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'NGÀY BẮT ĐẦU',
      key: 'start_date',
      dataIndex: 'start_date',
      render: text => (
        <span>{text || <EmptyText>Chưa có ngày bắt đầu</EmptyText>}</span>
      ),
    },
    {
      title: 'NGÀY KẾT THÚC',
      key: 'end_date',
      dataIndex: 'end_date',
      render: text => (
        <span>{text || <EmptyText>Chưa có ngày kết thúc</EmptyText>}</span>
      ),
    },
    {
      title: '',
      key: 'view',
      align: 'center',
      render: (text, record) => (
        <Tooltip title="Xem hợp đòng">
          <Button
            onClick={() => handleViewContract(record.contract_resource)}
            icon={<EyeOutlined />}
          />
        </Tooltip>
      ),
    },
  ]

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      width: '20px',
      align: 'center',
      render: (text, record, index) => (
        <span>{(page - 1) * pageSize + index + 1}</span>
      ),
    },
    {
      title: 'MÃ',
      key: 'maNhaCungCap',
      dataIndex: 'supplier_code',
      sorter: (a, b) => a.supplier_code.localeCompare(b.supplier_code),
      render: (text, record, index) => (
        <a
          onClick={() => handleRowDoubleClick(record)}
          className="supplier-code">
          {text || <EmptyText>Chưa có mã nhà cung cấp</EmptyText>}
        </a>
      ),
    },
    {
      title: 'TÊN',
      key: 'nhaCungCap',
      dataIndex: 'supplier_name',
      sorter: (a, b) => a.supplier_name.localeCompare(b.supplier_name),
      render: text => (
        <span>{text || <EmptyText>Chưa có tên nhà cung cấp</EmptyText>}</span>
      ),
    },
    {
      title: 'SĐT',
      key: 'sdt',
      dataIndex: 'phone_number',
      sorter: (a, b) => a.phone_number.localeCompare(b.phone_number),
      render: text => (
        <span>{text || <EmptyText>Chưa có số điện thoại</EmptyText>}</span>
      ),
    },
    {
      title: 'EMAIL',
      key: 'email',
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: text => (
        <span>{text || <EmptyText>Chưa có email</EmptyText>}</span>
      ),
    },
    {
      title: 'ĐỊA CHỈ',
      key: 'diaChi',
      dataIndex: 'address',
      render: text => (
        <span>{text || <EmptyText>Chưa có địa chỉ</EmptyText>}</span>
      ),
    },
    {
      title: 'MÃ SỐ THUẾ',
      key: 'maSoThue',
      dataIndex: 'tax_code',
      render: text => (
        <span>{text || <EmptyText>Chưa có mã số thuế</EmptyText>}</span>
      ),
    },
    {
      title: 'MÔ TẢ',
      key: 'moTa',
      dataIndex: 'description',
      render: text => (
        <span>{text || <EmptyText>Chưa có mô tả</EmptyText>}</span>
      ),
    },
    {
      title: 'NGÀY BẮT ĐẦU',
      key: 'ngaybatdau',
      dataIndex: 'start_date',
      render: text => (
        <span>{text || <EmptyText>Chưa có ngày bắt đầu</EmptyText>}</span>
      ),
    },
    {
      title: 'NGÀY KẾT THÚC',
      key: 'ngayketthuc',
      dataIndex: 'end_date',
      render: text => (
        <span>{text || <EmptyText>Chưa có ngày kết thúc</EmptyText>}</span>
      ),
    },
    {
      title: 'TRẠNG THÁI',
      key: 'trangthai',
      dataIndex: 'status',
      render: text => (
        <span>{text || <EmptyText>Chưa có trạng thái</EmptyText>}</span>
      ),
      align: 'center',
    },
    {
      title: '',
      key: '#',
      dataIndex: 'id',
      width: 1,
      render: (text, record) => (
        <div style={{ display: 'flex' }}>
          <Tooltip title="Xem hợp đồng">
            <Button
              style={{
                backgroundColor: 'green',
                color: 'white',
                marginLeft: '8px',
              }}
              onClick={() => handleViewContract(record.contract_resource)}
              shape="circle"
              icon={<EyeOutlined />}
              size="small"></Button>
          </Tooltip>
          <Tooltip title="Chỉnh sửa">
            <Button
              style={{ marginLeft: '5px' }}
              onClick={() => handleEdit(record)}
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              size="small"></Button>
          </Tooltip>
          {/* <Tooltip title="Xóa">
            <Button
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
              style={{ marginLeft: 8 }}
              size="small"
              onClick={() => {
                confirm({
                  title: 'Bạn có chắc chắn muốn xóa nhà cung cấp này không?',
                  icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
                  okText: 'Đồng ý',
                  cancelText: 'Không',
                  okType: 'danger',
                  onOk() {
                    handleDeleteSupplier(record)
                  },
                })
              }}></Button>
          </Tooltip> */}
        </div>
      ),
    },
  ]

  return (
    <DashboardLayout>
      <Helmet>
        <title>Quản lý nhà cung cấp</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title="Quản lý nhà cung cấp"
        hiddenGoBack></PageTitle>
      <ContentBlockWrapper>
        <Container>
          <Row style={{ marginBottom: 0, height: '38px' }}>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  maxLength={500}
                  placeholder={'Tìm kiếm'}
                  onSearch={handleSearch}
                />
              </SearchBar>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button type="primary" onClick={() => setVisible(true)}>
                Thêm
              </Button>
            </Col>
          </Row>
          <TableWrapper>
            <SupplierTable>
              <Table
                style={{
                  height: 'calc(100vh - 213px)',
                  overflowY: 'auto',
                }}
                columns={columns}
                dataSource={supplierData}
                bordered
                pagination={false}
              />
            </SupplierTable>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div>
                Tổng số: <b>{total}</b> bản ghi
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '8px',
                }}>
                <Pagination
                  onChange={(page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                  }}
                  current={page}
                  pageSize={pageSize}
                  total={total}
                  hideOnSinglePage={true}
                  showSizeChanger={true}
                  showLessItems
                />
              </div>
            </div>
          </TableWrapper>
        </Container>
      </ContentBlockWrapper>
      <Modal
        title="Chi tiết hợp đồng"
        visible={contractModalVisible}
        onCancel={handleContractModalCancel}
        footer={null}
        width={800}>
        <Table
          columns={contractColumns}
          dataSource={contractData}
          pagination={false}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 16,
          }}>
          <Button key="back" onClick={handleContractModalCancel}>
            Hủy
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={() => handleContractModalOpen(selectedSupplierId)}
            style={{ marginLeft: 8 }}>
            Gia hạn
          </Button>
        </div>
      </Modal>
      <Modal
        title="Gia hạn hợp đồng"
        visible={extensionModalVisible}
        onCancel={handleExtensionModalCancel}
        footer={[
          <Button key="back" onClick={handleExtensionModalCancel}>
            Hủy
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Lưu
          </Button>,
        ]}
        width={'40vw'}>
        <Form form={form} layout="vertical" onFinish={handleExtensionSubmit}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Khoảng ngày hợp đồng"
                name="contract_date_range"
                rules={[
                  { required: true, message: 'Vui lòng chọn khoảng ngày' },
                ]}>
                <RangePicker
                  format="DD/MM/YYYY"
                  style={{ width: '100%' }}
                  placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="File hợp đồng"
                name="contract_file"
                rules={[
                  { required: true, message: 'Vui lòng tải lên hợp đồng' },
                ]}
                valuePropName="fileList"
                getValueFromEvent={({ fileList }) => fileList}>
                <Upload
                  listType="text"
                  beforeUpload={beforeUpload}
                  maxCount={1}
                  accept=".pdf">
                  <Button icon={<UploadOutlined />}>
                    Tải lên file hợp đồng
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={
          selectedSupplierId
            ? 'Sửa thông tin nhà cung cấp'
            : 'Thêm nhà cung cấp mới'
        }
        visible={visible}
        width={'40vw'}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Hủy
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            {selectedSupplierId ? 'Cập nhật' : 'Thêm'}
          </Button>,
        ]}>
        <Form form={form} layout="vertical">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Mã nhà cung cấp"
                name="supplier_code"
                rules={[
                  { required: true, message: 'Vui lòng nhập mã nhà cung cấp' },
                ]}>
                <Input
                  placeholder="Nhập mã nhà cung cấp"
                  disabled={!!selectedSupplierId}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tên nhà cung cấp"
                name="supplier_name"
                rules={[
                  { required: true, message: 'Vui lòng nhập tên nhà cung cấp' },
                ]}>
                <Input placeholder="Nhập tên nhà cung cấp" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Mã số thuế"
                name="tax_code"
                rules={[
                  { required: true, message: 'Vui lòng nhập mã số thuế' },
                ]}>
                <Input placeholder="Nhập mã số thuế" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Số điện thoại"
                name="phone_number"
                rules={[
                  { required: true, message: 'Vui lòng nhập số điện thoại' },
                ]}>
                <Input placeholder="Nhập số điện thoại" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Vui lòng nhập email' },
                  {
                    type: 'email',
                    message: 'Email không hợp lệ',
                  },
                ]}>
                <Input placeholder="Nhập email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Địa chỉ"
                name="address"
                rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}>
                <Input placeholder="Nhập địa chỉ" />
              </Form.Item>
            </Col>
            {!selectedSupplierId && (
              <Col span={24}>
                <Form.Item
                  label="Khoảng ngày hợp đồng"
                  name="contract_date_range"
                  rules={[
                    { required: true, message: 'Vui lòng chọn khoảng ngày' },
                  ]}>
                  <RangePicker
                    format="DD/MM/YYYY"
                    style={{ width: '100%' }}
                    placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={12}>
              <Form.Item label="Mô tả" name="description">
                <Input placeholder="Nhập mô tả" />
              </Form.Item>
            </Col>
            {!selectedSupplierId && (
              <Col span={12}>
                <Form.Item
                  label="File hợp đồng"
                  name="supplier_contract"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng tải lên file hợp đồng',
                    },
                  ]}
                  valuePropName="fileList"
                  getValueFromEvent={({ fileList }) => fileList}>
                  <Upload
                    listType="text"
                    beforeUpload={beforeUpload}
                    maxCount={1}
                    accept=".pdf">
                    <Button icon={<UploadOutlined />}>
                      Tải lên file hợp đồng
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Xem hợp đồng"
        visible={pdfVisible}
        footer={null}
        onCancel={handlePdfCancel}
        width="60%"
        bodyStyle={{ padding: 0 }}>
        <iframe
          title="Hợp đồng PDF"
          src={pdfUrl}
          style={{ width: '100%', height: '70vh' }}
          frameBorder="0"></iframe>
      </Modal>
    </DashboardLayout>
  )
}

export default memo(
  inject(
    'supplierStore',
    'loadingAnimationStore',
    'contractStore'
  )(observer(SupplierPage))
)
