import React, { useEffect, useState } from 'react'
import { Col, DatePicker, Input, Row, Select, Space } from 'antd'
import { inject, observer } from 'mobx-react'
import { DATE_FORMAT_LIST } from '../../constants'
import moment from 'moment'

const { RangePicker } = DatePicker
const { Option } = Select
const currentYear = new Date().getFullYear()

const AdvancedFilterBlock = props => {
  const {
    setPage,
    setPageSize,
    setSearchValue,
    searchValue,
    categoryStore,
    setCategoryId,
    setStartDate,
    setEndDate,
    fetchData,
    setItemType,
    categoryId,
    item_type,
    startDate,
    endDate,
  } = props
  const [categoryList, setCategoryList] = useState([])

  useEffect(() => {
    categoryStore.getCategories(1, 9999, '').then(res => {
      setCategoryList(res.data.pageData)
    })
  }, [categoryStore])

  const onChangeInvoiceDate = dates => {
    if (dates && dates.length === 2) {
      const formattedStartDate = dates[0].format('YYYY-MM-DD')
      const formattedEndDate = dates[1].format('YYYY-MM-DD')
      setStartDate(formattedStartDate)
      setEndDate(formattedEndDate)
    } else {
      setStartDate(`${currentYear}-01-01`)
      setEndDate(`${currentYear}-12-31`)
    }
    setPage(1)
    setPageSize(10)
  }

  const handleCategoryChange = value => {
    setCategoryId(value)
    setPage(1)
    setPageSize(10)
  }

  const handleItemTypeChange = value => {
    setItemType(value)
    setPage(1)
    setPageSize(10)
  }

  return (
    <div>
      <Row gutter={15} style={{ marginBottom: 0 }}>
        <Col xs={24} sm={12} md={6}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <span>Mã sản phẩm / Tên sản phẩm</span>
            <Input.Search
              onSearch={value => {
                setPage(1), setPageSize(10), setSearchValue(value)
              }}
              placeholder={'Nhập từ khóa tìm kiếm'}
              allowClear
              defaultValue={searchValue}
            />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <span>Ngày</span>
            <RangePicker
              defaultValue={[moment(startDate), moment(endDate)]}
              onChange={onChangeInvoiceDate}
              placeholder={['Từ ngày', 'Đến ngày']}
              format={DATE_FORMAT_LIST}
              style={{ width: '100%' }}
            />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <span>Nhóm hàng hóa</span>
            <Select
              defaultValue={categoryId}
              allowClear
              placeholder="Chọn nhóm hàng hóa"
              style={{ width: '100%' }}
              onChange={handleCategoryChange}>
              {categoryList.map(category => (
                <Option key={category.id} value={category.id}>
                  {category.category_name}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <span>Loại hàng hóa</span>
            <Select
              defaultValue={item_type}
              allowClear
              placeholder="Chọn loại hàng hóa"
              style={{ width: '100%' }}
              onChange={handleItemTypeChange}>
              <Option value="0">Vật tư</Option>
              <Option value="1">Phi kinh doanh</Option>
            </Select>
          </Space>
        </Col>
      </Row>
    </div>
  )
}

export default inject('categoryStore')(observer(AdvancedFilterBlock))
