import React, { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, notification } from 'antd'
import { EnvironmentFilled, LogoutOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import PopupAppGrid from '../PopupAppGrid'
import './MainHeaderBarStyled.css'

const MainHeaderBar = props => {
  const { authenticationStore, history, accountStore, campusStore } = props

  const clickLogout = useCallback(async () => {
    authenticationStore.userLogout()

    notification.destroy()

    history.push('/login')
  }, [authenticationStore, history])

  const [campusName, setCampusName] = useState('')

  useEffect(() => {
    const fetchCampusInfo = async () => {
      try {
        const accountInfo = await accountStore
          .getAccountInfo()
          .then(res => res.data)
        if (accountInfo) {
          const campusId = accountInfo.campus_id
          const response = await campusStore.getCampusById(campusId)
          const campusInfo = response.data
          if (campusInfo) {
            setCampusName(campusInfo.campus_name)
          }
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchCampusInfo()
  }, [])

  return (
    <>
      <div className={'logo'}>
        <img
          onClick={() => history.push('/dashboard')}
          src={`${process.env.PUBLIC_URL}/assets/photos/logo_fpt.png`}
          alt="FPT logo"
          className="logoImg"
          style={{ width: '52px' }}
        />
      </div>
      <span
        style={{
          fontFamily: 'serif',
          color: '#fff',
          position: 'absolute',
          left: '90px'
        }}>
        <EnvironmentFilled style={{ color: 'rgb(0, 255, 0)' }} /> {campusName.toUpperCase()}
      </span>
      <PopupAppGrid />
      <div>
        <Tooltip placement="bottom" title={'Đăng xuất'}>
          <div style={{ position: 'relative', marginLeft: 10 }}>
            <LogoutOutlined
              style={{
                color: '#fff',
                fontSize: 18,
              }}
              onClick={clickLogout}
            />
          </div>
        </Tooltip>
      </div>
    </>
  )
}

MainHeaderBar.propTypes = {
  authenticationStore: PropTypes.object,
  history: PropTypes.object,
  loadingAnimationStore: PropTypes.object,
}

export default memo(
  withRouter(
    inject(
      'authenticationStore',
      'commonStore',
      'accountStore',
      'campusStore'
    )(observer(MainHeaderBar))
  )
)
