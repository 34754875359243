import React, { memo, useEffect, useState, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import {
  Table,
  Pagination,
  Input,
  Button,
  message,
  Modal,
  Card,
  List,
  DatePicker,
  Row,
  Col,
} from 'antd'
import {
  ArrowRightOutlined,
  SearchOutlined,
  SwapRightOutlined,
} from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import DashboardLayout from '../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import moment from 'moment'
import { TableWrapper } from '../../components/Common/Table'
import { AuditlogTable } from './AuditLogPageStyled'
import PageTitle from '../../components/PageTitle'
import { Container } from '../../layouts/Container/Container'

const { RangePicker } = DatePicker

const { Search } = Input
const AuditLogPage = props => {
  const { auditLogStore, loadingAnimationStore } = props
  const [auditLogData, setAuditLogData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [filters, setFilters] = useState([])
  const [searchText, setSearchText] = useState('')
  const [selectedAuditLog, setSelectedAuditLog] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [dateRange, setDateRange] = useState([null, null])
  const history = useHistory()

  const fetchAuditLogData = useCallback(async () => {
    if (!auditLogStore || !loadingAnimationStore) return

    loadingAnimationStore.showSpinner(true)
    try {
      const filters = []
      if (dateRange && Array.isArray(dateRange)) {
        const [startDate, endDate] = dateRange

        if (startDate) {
          filters.push({
            data_field: 'log_date',
            value: startDate.toISOString(),
            operator_type: 'greaterthan',
          })
        }

        if (endDate) {
          filters.push({
            data_field: 'log_date',
            value: endDate.toISOString(),
            operator_type: 'lessthan',
          })
        }
      }

      const response = await auditLogStore.getAuditLogs(
        page,
        pageSize,
        searchText,
        filters
      )

      const { pageData, totalCount } = response.data
      const dataWithKeys = pageData.map((item, index) => ({
        ...item,
        key: item.id || index,
      }))
      setAuditLogData(dataWithKeys)
      setTotal(totalCount)
    } catch (error) {
      console.error('Failed to fetch audit logs:', error)
      message.error('Failed to fetch audit logs')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [page, pageSize, searchText, dateRange])

  useEffect(() => {
    fetchAuditLogData()
  }, [fetchAuditLogData])

  useEffect(() => {
    fetchAuditLogData()
  }, [fetchAuditLogData])

  const handleSearch = () => {
    setPage(1)
    fetchAuditLogData()
  }

  const handleSearchInput = e => {
    setSearchText(e.target.value)
  }

  const handleDateRangeChange = dates => {
    setDateRange(dates)
  }

  const handleRowClick = async record => {
    try {
      const response = await auditLogStore.getAuditLogDetail(record.id)

      let masterDes = []
      let detailsDes = {}
      try {
        if (response && response.data) {
          if (response.data.description_master) {
            response.data.description_master = JSON.parse(
              record.description_master
            )
          }
          if (response.data.description_details) {
            response.data.description_details = JSON.parse(
              record.description_details
            )
          }
          setSelectedAuditLog(response.data)
        }
      } catch (parseError) {}

      setIsModalVisible(true)
    } catch (error) {}
  }

  const renderModalContent = () => {
    if (!selectedAuditLog) return null

    return (
      <>
        <h3>1. Thông tin master</h3>
        <ul>
          {selectedAuditLog.description_master.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>

        <h3>2. Thông tin details</h3>
        <ul>
          {Object.entries(selectedAuditLog.description_details).map(
            ([key, value], index) => (
              <li key={index}>
                <strong>{key}: </strong>
                {typeof value === 'string'
                  ? value.split('\n').map((line, lineIndex) => (
                      <span key={lineIndex}>
                        {line}
                        <br />
                      </span>
                    ))
                  : value}
              </li>
            )
          )}
        </ul>
      </>
    )
  }

  const columns = [
    {
      title: 'No.',
      key: 'key',
      render: (text, record, index) => (page - 1) * pageSize + index + 1,
      width: '50px',
    },
    {
      title: 'Tên bảng',
      key: 'table_name',
      dataIndex: 'table_name',
      sorter: (a, b) => a.table_name.localeCompare(b.table_name),
    },
    {
      title: 'Hành động',
      key: 'action',
      dataIndex: 'action',
      sorter: (a, b) => a.action.localeCompare(b.action),
    },
    {
      title: 'Người thực hiện',
      key: 'username',
      dataIndex: 'username',
    },
    {
      title: 'Thời gian',
      key: 'log_date',
      dataIndex: 'log_date',
      sorter: (a, b) => new Date(a.log_date) - new Date(b.log_date),
      render: text => new Date(text).toLocaleString(),
    },
  ]

  return (
    <DashboardLayout>
      <Helmet>
        <title>Nhật kí hoạt động</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title="Nhật kí hoạt động"
        hiddenGoBack></PageTitle>
      <ContentBlockWrapper>
        <Container>
          <div style={{ display: 'flex' }}>
            <Search
              style={{ width: '50%' }}
              allowClear
              onSearch={value => {
                setPage(1), setPageSize(10), setSearchText(value)
              }}
              placeholder={'Tìm kiếm'}
            />
            <RangePicker
              style={{ width: '50%', marginLeft: '8px' }}
              value={dateRange}
              onChange={handleDateRangeChange}
              format="YYYY-MM-DD"
            />
          </div>
          <TableWrapper style={{ marginTop: 5 }}>
            <AuditlogTable>
              <Table
                columns={columns}
                dataSource={auditLogData}
                bordered
                pagination={false}
                onRow={record => ({
                  onClick: () => handleRowClick(record),
                })}
                style={{
                  height: 'calc(100vh - 213px)',
                  overflowY: 'auto',
                }}
              />
            </AuditlogTable>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div>
                Tổng số: <b>{total}</b> bản ghi
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '8px',
                }}>
                <Pagination
                  onChange={(page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                  }}
                  current={page}
                  pageSize={pageSize}
                  total={total}
                  hideOnSinglePage={true}
                  showSizeChanger={true}
                  showLessItems
                />
              </div>
            </div>
          </TableWrapper>
        </Container>
      </ContentBlockWrapper>

      <Modal
        className="audit-modal"
        width={1000}
        centered
        title="Chi tiết nhật kí hoạt động"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setIsModalVisible(false)}>
            Đóng
          </Button>,
        ]}>
        {selectedAuditLog && (
          <Card>
            {/* <p>
              <strong>Tên bảng:</strong> {selectedAuditLog.table_name}
            </p>
            <p>
              <strong>Hành động:</strong> {selectedAuditLog.action}
            </p>
            <p>
              <strong>Người thực hiện:</strong> {selectedAuditLog.username}
            </p>
            <p>
              <strong>Thời gian:</strong>{' '}
              {new Date(selectedAuditLog.log_date).toLocaleString()}
            </p> */}

            {selectedAuditLog.changes &&
            selectedAuditLog.changes.length > 0 &&
            selectedAuditLog.table_name &&
            (selectedAuditLog.table_name.toLowerCase().includes('xuất kho') ||
              selectedAuditLog.table_name
                .toLowerCase()
                .includes('nhập kho')) ? (
              <>
                <div
                  style={{
                    fontWeight: '800',
                    fontSize: '17px',
                  }}>
                  1. Tổng quan
                </div>
                <ul>
                  {selectedAuditLog.description_master &&
                    selectedAuditLog.description_master.map(
                      (description, index) => (
                        <li
                          style={{
                            fontSize: '13px',
                          }}
                          key={index}>
                          {description}
                        </li>
                      )
                    )}
                </ul>

                <div
                  style={{
                    fontWeight: '700',
                    fontSize: '17px',
                  }}>
                  2. Chi tiết
                </div>
                {selectedAuditLog.description_details &&
                  Object.entries(selectedAuditLog.description_details).map(
                    ([key, changes], index) => (
                      <div
                        key={index}
                        style={{ marginBottom: 16, marginLeft: 16 }}>
                        <h3>{key}</h3>
                        <ul>
                          {changes.map((change, i) => (
                            <li
                              style={{
                                fontSize: '13px',
                              }}
                              key={i}>
                              {change}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )
                  )}
              </>
            ) : !(
                selectedAuditLog.table_name
                  .toLowerCase()
                  .includes('xuất kho') ||
                selectedAuditLog.table_name.toLowerCase().includes('nhập kho')
              ) ? (
              <List
                dataSource={selectedAuditLog.changes}
                renderItem={change => (
                  <List.Item key={`${change.id}-${change.field_name}`}>
                    <p style={{ marginBottom: 4, fontWeight: 'bold' }}>
                      {change.field_name}:{' '}
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: '80%',
                      }}>
                      <span
                        style={{
                          textDecoration: 'line-through',
                          color: 'red',
                          width: '45%',
                        }}>
                        {change.old_value}
                      </span>
                      <SwapRightOutlined
                        style={{
                          fontWeight: 'bolder',
                          fontSize: '24px',
                          color: '#096dd9',
                          width: '10%',
                        }}
                      />
                      <span style={{ color: 'green', width: '45%' }}>
                        {change.new_value}
                      </span>
                    </div>
                  </List.Item>
                )}
              />
            ) : (
              <p style={{ marginTop: 16 }}>Không có thay đổi.</p>
            )}
          </Card>
        )}
      </Modal>
    </DashboardLayout>
  )
}

export default memo(
  inject('auditLogStore', 'loadingAnimationStore')(observer(AuditLogPage))
)
