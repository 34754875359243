import React, { useState, useEffect, memo } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import DashboardLayout from '../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import {
  Button,
  Col,
  Input,
  message,
  Pagination,
  Row,
  Space,
  Table,
} from 'antd'
import { TableWrapper } from '../../components/Common/Table'
import {
  CloseCircleOutlined,
  FilterOutlined,
  PrinterOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import { SupplierTable, SearchBar } from './ReportItemStyled'
import { inject, observer } from 'mobx-react'
import AdvancedFilterBlock from './AdvancedFilterBlock'
import { FilterButtonWrapper } from '../ImportPage/ImportPageStyled'
import { EmptyText } from '../../components/Common/CellText'
import moment from 'moment'
import { Container } from '../../layouts/Container/Container'
import './ReportItemPageStyled.css'

const { Search } = Input

const ReportItemPage = props => {
  const { loadingAnimationStore, inventoryItemStore, categoryStore } = props
  const [pageIndex, setPageIndex] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false)
  const [countFilter, setCountFilter] = useState(0)
  const [categoryId, setCategoryId] = useState()
  const currentYear = new Date().getFullYear()
  const [startDate, setStartDate] = useState(`${currentYear}-01-01`)
  const [endDate, setEndDate] = useState(`${currentYear}-12-31`)
  const [categories, setCategories] = useState([])
  const [item_type, setItemType] = useState()

  const fetchCategories = async () => {
    try {
      const categoryData = await categoryStore.getCategories(1, 9999, '')
      setCategories(categoryData.data.pageData)
    } catch (error) {
      message.error(error.message)
    }
  }

  const fetchData = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await inventoryItemStore.getReportItem(
        pageIndex,
        pageSize,
        search,
        startDate,
        endDate,
        categoryId,
        item_type
      )
      const data = response.data.pageData.map((item, index) => ({
        key: item.id,
        item_code: item.item_code,
        item_name: item.item_name,
        category_name: item.category_name,
        unit_name: item.unit_name,
        beginning_inventory_quantity: item.beginning_inventory_quantity,
        beginning_inventory_value: item.beginning_inventory_value,
        total_inward_quantity: item.total_inward_quantity,
        total_inward_value: item.total_inward_value,
        total_outward_quantity: item.total_outward_quantity,
        total_outward_value: item.total_outward_value,
        ending_inventory_quantity: item.ending_inventory_quantity,
        ending_inventory_value: item.ending_inventory_value,
        total_returned_quantity: item.total_returned_quantity,
        total_returned_value: item.total_returned_value,
        item_type: item.item_type,
      }))
      setData(data)
      setTotal(response.data.totalCount)
    } catch (error) {
      console.error('Error fetching items:', error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  useEffect(() => {
    fetchCategories()
    fetchData()
  }, [pageIndex, pageSize, search, item_type, categoryId, startDate, endDate])

  function formatNumber(value) {
    if (value == null) return ''
    const roundedValue = Number(value).toFixed(2)
    const [integerPart, decimalPart] = roundedValue.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      width: '20px',
      render: (text, record, index) => (
        <span>{(pageIndex - 1) * pageSize + index + 1}</span>
      ),
    },
    {
      title: 'MÃ SẢN PHẨM',
      key: 'ma',
      dataIndex: 'item_code',
      sorter: (a, b) => a.item_code.localeCompare(b.item_code),
      render: text => (
        <span>{text || <EmptyText>Chưa có mã sản phẩm</EmptyText>}</span>
      ),
    },
    {
      title: 'TÊN SẢN PHẨM',
      key: 'ten',
      dataIndex: 'item_name',
      sorter: (a, b) => a.item_name.localeCompare(b.item_name),
      render: text => (
        <span>{text || <EmptyText>Chưa có tên sản phẩm</EmptyText>}</span>
      ),
    },
    {
      title: 'LOẠI HÀNG HÓA',
      key: 'loaihanghoa',
      dataIndex: 'category_name',
      sorter: (a, b) => a.category_name.localeCompare(b.category_name),
      filters:
        categories?.length > 0
          ? categories.map(category => ({
              text: category?.category_name,
              value: category?.category_name,
            }))
          : [],
      onFilter: (value, record) => record?.category_name === value,
      render: text => (
        <span>{text || <EmptyText>Chưa có nhóm hàng hóa</EmptyText>}</span>
      ),
    },
    {
      title: 'ĐVT',
      key: 'donvitinh',
      dataIndex: 'unit_name',
      render: text => (
        <span>{text || <EmptyText>Chưa có đơn vị tính</EmptyText>}</span>
      ),
    },
    {
      title: 'ĐẦU KỲ',
      children: [
        {
          title: 'Số lượng',
          key: 'sldauky',
          dataIndex: 'beginning_inventory_quantity',
          render: text => (
            <span>{formatNumber(text) || <EmptyText>Chưa có</EmptyText>}</span>
          ),
        },
        {
          title: 'Giá trị',
          key: 'giadauky',
          dataIndex: 'beginning_inventory_value',
          render: text => (
            <span>{formatNumber(text) || <EmptyText>Chưa có</EmptyText>}</span>
          ),
        },
      ],
    },
    {
      title: 'NHẬP KHO',
      children: [
        {
          title: 'Số lượng',
          key: 'slnhapkho',
          dataIndex: 'total_inward_quantity',
          render: text => (
            <span>{formatNumber(text) || <EmptyText>Chưa có</EmptyText>}</span>
          ),
        },
        {
          title: 'Giá trị',
          key: 'gtnhapkho',
          dataIndex: 'total_inward_value',
          render: text => (
            <span>{formatNumber(text) || <EmptyText>Chưa có</EmptyText>}</span>
          ),
        },
      ],
    },
    {
      title: 'XUẤT KHO',
      children: [
        {
          title: 'Số lượng',
          key: 'slxuatkho',
          dataIndex: 'total_outward_quantity',
          render: text => (
            <span>{formatNumber(text) || <EmptyText>Chưa có</EmptyText>}</span>
          ),
        },
        {
          title: 'Giá trị',
          key: 'gtxuatkho',
          dataIndex: 'total_outward_value',
          render: text => (
            <span>{formatNumber(text) || <EmptyText>Chưa có</EmptyText>}</span>
          ),
        },
      ],
    },
    {
      title: 'ĐÃ TRẢ',
      children: [
        {
          title: 'Số lượng',
          key: 'sldatra',
          dataIndex: 'total_returned_quantity',
          render: (text, record) => (
            <span>
              {record.item_type === 1
                ? formatNumber(text) || <EmptyText>Chưa có</EmptyText>
                : '-'}
            </span>
          ),
        },
        {
          title: 'Giá trị',
          key: 'gtdatra',
          dataIndex: 'total_returned_value',
          render: (text, record) => (
            <span>
              {record.item_type === 1
                ? formatNumber(text) || <EmptyText>Chưa có</EmptyText>
                : '-'}
            </span>
          ),
        },
      ],
    },

    {
      title: 'CUỐI KỲ',
      children: [
        {
          title: 'Số lượng',
          key: 'slcuoiky',
          dataIndex: 'ending_inventory_quantity',
          render: text => (
            <span>{formatNumber(text) || <EmptyText>Chưa có</EmptyText>}</span>
          ),
        },
        {
          title: 'Giá trị',
          key: 'gtcuoiky',
          dataIndex: 'ending_inventory_value',
          render: text => (
            <span>{formatNumber(text) || <EmptyText>Chưa có</EmptyText>}</span>
          ),
        },
      ],
    },
  ]

  return (
    <DashboardLayout>
      <Helmet>
        <title>Quản lý hàng hóa, dịch vụ </title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={'Quản lý hàng hóa, dịch vụ'}
        hiddenGoBack></PageTitle>
      <ContentBlockWrapper>
        <Container>
          <Row style={{ marginBottom: 0, height: '38px' }}>
            <Col span={12}>
              {!showAdvancedFilter ? (
                <SearchBar>
                  <Search
                    allowClear
                    onSearch={value => {
                      setPageIndex(1), setPageSize(10), setSearch(value)
                    }}
                    maxLength={500}
                    placeholder={'Tìm kiếm theo Mã sản phẩm / Tên sản phẩm'}
                  />
                </SearchBar>
              ) : null}
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <FilterButtonWrapper>
                <span
                  className={'count-filter'}
                  style={{ display: !countFilter && 'none' }}>
                  {countFilter}
                </span>
                <Button
                  type={showAdvancedFilter ? 'primary' : 'default'}
                  ghost={showAdvancedFilter}
                  style={{ marginBottom: showAdvancedFilter ? 10 : 0 }}
                  onClick={() => setShowAdvancedFilter(!showAdvancedFilter)}>
                  {showAdvancedFilter ? (
                    <Space>
                      <CloseCircleOutlined />
                      Ẩn tìm kiếm nâng cao
                    </Space>
                  ) : (
                    <Space>
                      <FilterOutlined />
                      Tìm kiếm nâng cao
                    </Space>
                  )}
                </Button>
              </FilterButtonWrapper>

              <Button
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  setCategoryId()
                  setStartDate(`${currentYear}-01-01`)
                  setEndDate(`${currentYear}-12-31`)
                  setSearch('')
                  fetchData()
                  setShowAdvancedFilter(false)
                }}>
                <ReloadOutlined />
              </Button>
            </Col>
          </Row>
          {showAdvancedFilter ? (
            <AdvancedFilterBlock
              setPage={setPageIndex}
              setPageSize={setPageSize}
              setSearchValue={setSearch}
              searchValue={search}
              setCategoryId={setCategoryId}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              fetchData={fetchData}
              setItemType={setItemType}
              categoryId={categoryId}
              item_type={item_type}
              startDate={startDate}
              endDate={endDate}
            />
          ) : null}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <div style={{ fontSize: '15px', fontWeight: 'bold' }}>
              <strong>Thời gian: </strong>
              {moment(startDate).format('DD/MM/YYYY')} -{' '}
              {moment(endDate).format('DD/MM/YYYY')}
            </div>
            <TableWrapper>
              <SupplierTable>
                <Table
                  className="reportItemTable"
                  style={{
                    height: !showAdvancedFilter
                      ? 'calc(100vh - 237px)'
                      : 'calc(100vh - 306.5px)',
                    overflowY: 'auto',
                  }}
                  columns={columns}
                  dataSource={data}
                  bordered
                  pagination={false}
                  loading={loading}
                />
              </SupplierTable>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <div>
                  Tổng số: <b>{total}</b> bản ghi
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingTop: '8px',
                  }}>
                  <Pagination
                    current={pageIndex}
                    pageSize={pageSize}
                    total={total}
                    onChange={(page, size) => {
                      setPageIndex(page)
                      setPageSize(size)
                    }}
                    showSizeChanger
                    showLessItems
                  />
                </div>
              </div>
            </TableWrapper>
          </div>
        </Container>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default memo(
  inject(
    'loadingAnimationStore',
    'inventoryItemStore',
    'categoryStore'
  )(observer(ReportItemPage))
)
