import axios from 'axios'
import { apiUrl } from '../config'

export const AuthenticationRequest = {
  userLogin: (token, campusId) =>
    axios.post(
      `${apiUrl}/api/v1/account/login`,
      {
        token: token,
        campusId: campusId
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  refreshToken: token =>
    axios.post(
      `${apiUrl}/api/v1/account/refresh`,
      {
        token: token,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),
}
