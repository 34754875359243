import React, { memo, useEffect, useState } from 'react'
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  Typography,
  message,
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import './ExportPageStyled.css'
import moment from 'moment'
import { ExportTable } from './ExportPageStyled'
import { useHistory } from 'react-router-dom'

const { Option } = Select
const { Title, Text } = Typography

const ExportCreateModal = props => {
  const {
    open,
    setOpen,
    fetchExportInvoice,
    excelData,
    setExcelData,
    departmentStore,
    unitStore,
    inventoryItemStore,
    exportStore,
    loadingAnimationStore,
  } = props
  const [form] = Form.useForm()
  const [departmentList, setDepartmentList] = useState([])
  const [unitList, setUnitList] = useState([])
  const [inventoryItemList, setInventoryItemList] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const history = useHistory()

  useEffect(() => {
    const fetchExcelData = async () => {
      if (excelData && excelData.master && excelData.details) {
        const formValues = {
          invoice_no: excelData.master.invoice_no,
          invoice_date: excelData.master.invoice_date
            ? moment(
                excelData.master.invoice_date,
                'DD-MM-YYYY' || 'DD/MM/YYYY'
              )
            : null,
          return_date: excelData.master.return_date
            ? moment(excelData.master.return_date, 'DD-MM-YYYY' || 'DD/MM/YYYY')
            : null,
          department_id: excelData.master.department_id,
          note: excelData.master.note,
        }
        form.setFieldsValue(formValues)

        const tableData = excelData.details.map((detail, index) => ({
          key: index + 1,
          item_id: detail.item_id,
          item_code: detail.item_code,
          item_name: detail.item_name,
          unit_id: detail.unit_id,
          quantity: detail.quantity,
          unit_price: detail.unit_price,
          itemTotal: detail.quantity * detail.unit_price,
        }))
        await setData(tableData)
        await setAmount(tableData.map(row => row.itemTotal))
        await setTotalAmount(
          tableData.reduce((acc, row) => acc + (row.itemTotal || 0), 0)
        )
      }
    }

    fetchExcelData()
  }, [excelData, form])

  const fetchData = async () => {
    departmentStore.getDepartment(1, 9999, '').then(res => {
      setDepartmentList(res.data.pageData)
    })
    unitStore.getUnit(1, 9999, '').then(res => {
      setUnitList(res.data.pageData)
    })
    var filters = [
      {
        data_field: 'item_type',
        value: '1',
        operator_type: 'equal',
      },
    ]
    inventoryItemStore.getInventoryItemDTO(1, 9999, '', filters).then(res => {
      setInventoryItemList(res.data.pageData)
      setFilteredItems(res.data.pageData)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const [data, setData] = useState([
    {
      key: 1,
      item_id: '',
      item_name: '',
      unit_id: '',
      quantity: '',
      unit_price: '',
      outward_non_business_type: null,
      itemTotal: undefined,
    },
  ])

  const handleAddRow = () => {
    // Add a new row with a temporary key
    const newData = [
      ...data,
      {
        key: data.length + 1,
        item_id: '',
        item_name: '',
        unit_id: '',
        quantity: '',
        unit_price: '',
        outward_non_business_type: null,
        itemTotal: undefined,
      },
    ]

    // Reset keys to be sequential
    const updatedData = newData.map((item, idx) => ({
      ...item,
      key: idx + 1,
    }))

    // Update the state with the new data and reset filtered items
    setData(updatedData)
    setFilteredItems(inventoryItemList)
  }

  const handleRemoveRow = index => {
    if (data.length === 1) {
      message.warning('Không thể xóa dòng cuối cùng!')
      return
    }
    const newData = data.filter((item, idx) => idx !== index)
    setData(newData)

    const newTotalAmount = newData.reduce(
      (acc, row) => acc + (row.amount || 0),
      0
    )
    setTotalAmount(newTotalAmount)
  }

  const handleDeleteAll = () => {
    const newData = [
      {
        key: 1,
        item_id: '',
        item_code: '',
        item_name: '',
        unit_id: '',
        quantity: '',
        unit_price: '',
        outward_non_business_type: null,
        itemTotal: undefined,
      },
    ]

    setData(newData)
    setAmount(newData.map(row => row.amount))

    setTotalAmount(0)
  }

  const [amount, setAmount] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)

  function formatNumber(value) {
    if (value == null) return ''
    const roundedValue = Number(value).toFixed(2)
    const [integerPart, decimalPart] = roundedValue.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }

  const handleInputChange = (index, field, value) => {
    const newData = [...data]

    if (field === 'quantity') {
      if (/^\d*\.?\d*$/.test(value)) {
        newData[index][field] = value
      }
    } else if (field === 'unit_price') {
      if (/^\d*\.?\d*$/.test(value)) {
        newData[index][field] = value
      }
    } else {
      newData[index][field] = value
    }

    const quantity = parseFloat(newData[index]['quantity'] || '0')
    const unitPrice = parseFloat(newData[index]['unit_price'] || '0')

    newData[index].amount = quantity * unitPrice

    setData(newData)
    setAmount(newData.map(row => row.amount))

    const newTotalAmount = newData.reduce(
      (acc, row) => acc + (row.amount || 0),
      0
    )
    setTotalAmount(newTotalAmount)
  }

  const handleSelect = async (index, value, option) => {
    const newData = [...data]
    newData[index]['item_id'] = value
    newData[index]['item_code'] = option.item_code

    try {
      const itemSelected = await inventoryItemStore.getInventoryItemById(value)

      newData[index]['item_name'] = itemSelected.data.item_name
      newData[index]['unit_id'] = itemSelected.data.unit_id
      newData[index]['quantity'] = '1'
      newData[index]['unit_price'] = itemSelected.data.average_price

      newData[index].itemTotal = itemSelected.data.quantity

      const quantity = parseFloat(newData[index]['quantity'] || '0')
      const unitPrice = parseFloat(newData[index]['unit_price'] || '0')
      newData[index].amount = quantity * unitPrice

      setData(newData)
      setAmount(newData.map(row => row.amount))

      const newTotalAmount = newData.reduce(
        (acc, row) => acc + (row.amount || 0),
        0
      )
      setTotalAmount(newTotalAmount)
    } catch (error) {
      console.error('Error fetching item details: ', error)
    }
  }

  const handleSearch = value => {
    const filtered = inventoryItemList.filter(
      item =>
        item.item_code.toLowerCase().includes(value.toLowerCase()) ||
        item.item_name.toLowerCase().includes(value.toLowerCase())
    )
    setFilteredItems(filtered)
  }

  const nonBussinessOptions = [
    { value: 1, name: 'Hoàn trả' },
    { value: 2, name: 'Cấp phát' },
    { value: 3, name: 'Bán' },
    { value: 4, name: 'Thanh lý' },
  ]

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      render: (text, record, index) => <span>{index + 1}</span>,
      width: '20px',
    },
    {
      title: 'MÃ SẢN PHẨM',
      key: 'item_id',
      render: (text, record, index) => (
        <AutoComplete
          style={{ width: '100%' }}
          value={record.item_code}
          options={filteredItems.map(item => ({
            value: item.id,
            item_code: item.item_code,
            label: (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{item.item_code}</span>
                <span>{item.item_name}</span>
              </div>
            ),
          }))}
          onSelect={(value, option) => handleSelect(index, value, option)}
          onSearch={handleSearch}
          onChange={value => handleInputChange(index, 'item_code', value)}
        />
      ),
      width: '300px',
    },
    {
      title: 'TÊN SẢN PHẨM',
      key: 'item_name',
      render: (text, record, index) => (
        <Input disabled value={record.item_name} />
      ),
      width: '300px',
    },
    {
      title: 'ĐVT',
      key: 'unit_id',
      render: (text, record, index) => (
        <Select
          allowClear
          placeholder="Chọn đơn vị"
          style={{ width: '100%' }}
          value={record.unit_id}
          onChange={value => handleInputChange(index, 'unit_id', value)}>
          {unitList.map(unit => (
            <Option name={unit.unit_name} value={unit.id} key={unit.id}>
              {unit.unit_name}
            </Option>
          ))}
        </Select>
      ),
      width: '150px',
    },
    // max={record.itemTotal}
    {
      title: 'SỐ LƯỢNG',
      key: 'quantity',
      render: (text, record, index) => (
        <Input
          value={record.quantity}
          onChange={e => handleInputChange(index, 'quantity', e.target.value)}
          style={{ width: '100%' }}
        />
      ),
      width: '150px',
    },

    {
      title: 'ĐƠN GIÁ',
      key: 'unit_price',
      render: (text, record, index) => (
        <Input
          value={record.unit_price}
          onChange={e => handleInputChange(index, 'unit_price', e.target.value)}
          style={{ width: '100%' }}
        />
      ),
      width: '200px',
    },
    {
      title: 'THÀNH TIỀN',
      key: 'amount',
      render: (text, record, index) => <Input disabled value={amount[index]} />,
      width: '250px',
    },
    {
      title: 'Loại phi kinh doanh',
      key: 'outward_non_business_type',
      render: (text, record, index) => (
        <Select
          allowClear
          placeholder="Chọn loại PKD"
          style={{ width: '100%' }}
          value={record.outward_non_business_type}
          onChange={value =>
            handleInputChange(index, 'outward_non_business_type', value)
          }>
          {nonBussinessOptions.map(x => (
            <Option x={x.name} value={x.value} key={x.value}>
              {x.name}
            </Option>
          ))}
        </Select>
      ),
      width: '150px',
    },
    {
      title: 'NGÀY TRẢ HÀNG',
      key: 'return_date',
      render: (text, record, index) => (
        <DatePicker
          style={{ width: '100%' }}
          value={record.return_date ? moment(record.return_date) : null} // Ensure to import moment
          onChange={date => handleInputChange(index, 'return_date', date)} // Handle date changes
          disabled={record.outward_non_business_type != 1}
        />
      ),
      width: '200px',
    },
    {
      title: '',
      key: '-',
      render: (text, record, index) => (
        <Button
          icon={<DeleteOutlined />}
          onClick={() => handleRemoveRow(index)}
        />
      ),
      width: '50px',
    },
  ]

  const handleClose = () => {
    setOpen(false)
    form.resetFields()
    setExcelData({})
    setFilteredItems(inventoryItemList)
    const newData = [
      {
        key: 1,
        item_id: '',
        item_code: '',
        item_name: '',
        unit_id: '',
        quantity: '',
        unit_price: '',
        outward_non_business_type: null,
        itemTotal: undefined,
      },
    ]
    setData(newData)
    setTotalAmount(0)
    history.replace('/warehouse/nonbusinessexport')
  }

  const handleSubmit = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      await form.validateFields()
      const values = form.getFieldsValue()

      const invoiceNo = values.invoice_no
      const response = await exportStore.checkExistInvoiceNo(invoiceNo)
      if (response.data) {
        message.error('Số phiếu xuất đã tồn tại!')
        return
      }

      let hasError = false

      data.forEach((record, index) => {
        // Check each field and provide specific error messages
        if (!record.item_code) {
          hasError = true
          message.error(`Dòng ${index + 1}: Mã sản phẩm không được để trống.`)
          return
        }
        if (!record.item_name) {
          hasError = true
          message.error(`Dòng ${index + 1}: Tên sản phẩm không được để trống.`)
          return
        }

        if (!record.unit_id) {
          hasError = true
          message.error(`Dòng ${index + 1}: Đơn vị không được để trống.`)
          return
        }
        if (!record.quantity || record.quantity <= '0') {
          hasError = true
          message.error(`Dòng ${index + 1}: Số lượng phải lớn hơn 0`)
          return
        }
        if (!record.unit_price || record.unit_price <= '0') {
          hasError = true
          message.error(`Dòng ${index + 1}: Đơn giá phải lớn hơn 0`)
          return
        }

        if (!record.outward_non_business_type) {
          hasError = true
          message.error(
            `Dòng ${index + 1}: Loại phi kinh doanh không được để trống.`
          )
          return
        }

        if (!record.return_date && record.outward_non_business_type == 1) {
          hasError = true
          message.error(`Dòng ${index + 1}: Ngày trả hàng không được để trống.`)
          return
        }

        const returnDate = moment(record.return_date)
        const invoiceDate = moment(values.invoice_date)

        if (
          (returnDate.isBefore(invoiceDate) || // Check if returnDate is strictly before invoiceDate
            returnDate.isSame(invoiceDate)) && // Allow returnDate to be the same as invoiceDate
          record.outward_non_business_type == 1
        ) {
          hasError = true
          message.error(
            `Dòng ${
              index + 1
            }: Ngày trả hàng phải lớn hơn hoặc bằng ngày hóa đơn.`
          )
          return
        }
      })

      if (hasError) {
        return
      }

      const tableData = data.map(row => ({
        item_id: row.item_id,
        item_name: row.item_name,
        unit_id: row.unit_id,
        quantity: row.quantity,
        unit_price: row.unit_price,
        return_date: row.return_date,
        outward_non_business_type: row.outward_non_business_type,
      }))

      const payload = {
        master: {
          ...values,
          total_amount: totalAmount,
          status: 0,
          export_type: 1,
        },
        details: tableData,
      }

      var responseSaveFull = await exportStore.createExportInvoice(payload)
      if (
        responseSaveFull &&
        responseSaveFull.data &&
        !responseSaveFull.data.isCorrect &&
        responseSaveFull.data.message
      ) {
        message.error(responseSaveFull.data.message)
      } else {
        message.success('Thêm đơn xuất kho thành công!')

        var departmentNameNew = departmentList.find(
          x => x.id == payload.master?.department_id
        )?.department_name

        var departmentCodeNew = departmentList.find(
          x => x.id == payload.master?.department_id
        )?.department_code

        payload.master.department_name = departmentNameNew
        payload.master.department_code = departmentCodeNew

        const tableNew = data.map(row => ({
          item_id: row.item_id,
          item_name: row.item_name,
          unit_id: row.unit_id,
          unit_name: unitList?.find(x => x.id == row.unit_id)?.unit_name,
          quantity: Number(row.quantity),
          unit_price: Number(row.unit_price),
          return_date: row.return_date,
          outward_non_business_type: row.outward_non_business_type,
        }))

        var auditLogRequest = {
          table_name: 'Xuất kho phi kinh doanh',
          action: 'Thêm',
          oldObj: null,
          newObj: payload.master,
          oldDetails: null,
          newDetails: tableNew,
        }
        await exportStore.addAuditLog(auditLogRequest)

        await fetchExportInvoice()
        await handleClose()
      }
    } catch (errorInfo) {
      console.log(errorInfo)
      message.error('Vui lòng kiểm tra lại các trường dữ liệu!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <Modal
      title={'PHIẾU XUẤT KHO HÀNG HÓA PHI KINH DOANH'}
      width={'100vw'}
      className="modalStyle"
      visible={open}
      onCancel={handleClose}
      style={{
        top: '0px',
        maxWidth: '100vw',
        height: '100vh',
        paddingBottom: '0px',
        background: '#fff',
      }}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Hủy
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Thêm
        </Button>,
      ]}>
      <Form
        form={form}
        scrollToFirstError={true}
        name={'create-export-invoice'}
        layout={'vertical'}>
        <div
          style={{
            padding: '20px 24px 0px 24px',
            background: '#eceef1',
          }}>
          <Row type={'flex'} gutter={30}>
            <Col xs={24} md={4}>
              <Form.Item
                label={'Số phiếu xuất'}
                name={'invoice_no'}
                rules={[
                  { required: true, message: ' Vui lòng nhập số phiếu xuất!' },
                ]}>
                <Input
                  allowClear
                  maxLength={50}
                  placeholder={'Nhập số phiếu xuất'}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={4} style={{ paddingBottom: '20px' }}>
              <Form.Item
                label={'Ngày xuất hàng'}
                name={'invoice_date'}
                rules={[
                  { required: true, message: ' Vui lòng chọn ngày xuất hàng!' },
                ]}>
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày xuất hàng"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label={'Phòng ban'}
                name={'department_id'}
                rules={[
                  { required: true, message: ' Vui lòng chọn phòng ban!' },
                ]}>
                <Select allowClear placeholder="Chọn phòng ban">
                  {departmentList.map(department => (
                    <Option
                      name={department.department_name}
                      value={department.id}
                      key={department.id}>
                      {department.department_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={24}
              md={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'end',
              }}>
              <Title level={2} code style={{ color: '#000' }}>
                THÀNH TIỀN
              </Title>
              <Text strong style={{ fontSize: '20px', marginBottom: '0px' }}>
                {formatNumber(totalAmount)} VNĐ
              </Text>
            </Col>
          </Row>
          <Row type={'flex'} gutter={30}>
            <Col xs={24} md={4} style={{ paddingBottom: '20px' }}>
              <Form.Item
                label={'Tên người nhận'}
                name={'received_by'}
                rules={[
                  { required: true, message: ' Vui lòng nhập tên người nhận!' },
                ]}>
                <Input allowClear maxLength={255} placeholder={'Nhập tên'} />
              </Form.Item>
            </Col>
            <Col xs={24} md={4} style={{ paddingBottom: '20px' }}>
              <Form.Item
                label={'Email người nhận'}
                name={'received_email'}
                rules={[
                  {
                    required: true,
                    message: ' Vui lòng nhập email người nhận!',
                  },
                  {
                    type: 'email',
                    message: 'Email không hợp lệ!',
                  },
                ]}>
                <Input allowClear maxLength={255} placeholder={'Nhập email'} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8} style={{ paddingBottom: '20px' }}>
              <Form.Item label={'Ghi chú'} name={'note'}>
                <Input
                  allowClear
                  maxLength={255}
                  placeholder={'Nhập ghi chú'}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div
          style={{
            padding: '12px 24px 24px',
          }}>
          <ExportTable>
            <Table columns={columns} dataSource={data} pagination={false} />
          </ExportTable>
          <div style={{ marginTop: '10px' }}>
            <Button
              key="themDong"
              onClick={() => {
                fetchData()
                handleAddRow()
              }}
              style={{ marginRight: '10px' }}>
              Thêm dòng
            </Button>
            <Button key="xoaTatCa" onClick={handleDeleteAll}>
              Xóa tất cả
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default memo(
  inject(
    'departmentStore',
    'unitStore',
    'inventoryItemStore',
    'exportStore',
    'loadingAnimationStore'
  )(observer(ExportCreateModal))
)
