import React, { memo, useCallback, useEffect, useState } from 'react'
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Typography,
  message,
} from 'antd'
import { inject, observer } from 'mobx-react'
import './ImportPageStyled.css'
import moment from 'moment'
import { ImportTable } from './ImportPageStyled'
import { DeleteOutlined, PrinterOutlined } from '@ant-design/icons'
import XLSX from 'xlsx'
import { EmptyText } from '../../components/Common/CellText'

const { Option } = Select
const { Title, Text } = Typography

const ImportDetailUpdate = props => {
  const {
    open,
    setOpen,
    importId,
    setImportId,
    type,
    supplierStore,
    unitStore,
    inventoryItemStore,
    importStore,
    loadingAnimationStore,
    fetchImportInvoice,
    settingStore,
  } = props
  const [form] = Form.useForm()
  const [supplierList, setSupplierList] = useState([])
  const [unitList, setUnitList] = useState([])
  const [inventoryItemList, setInventoryItemList] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [originalInvoiceNo, setOriginalInvoiceNo] = useState('')
  const [invoiceDate, setInvoiceDate] = useState(undefined)
  const [supplierName, setSupplierName] = useState('')
  const [note, setNote] = useState('')
  const [unitName, setUnitName] = useState([])
  const [status, setStatus] = useState('')
  const [receivedAt, setReceivedAt] = useState('')
  const [oldDetails, setOldDetails] = useState([])
  const [oldMaster, setOldMaster] = useState(undefined)
  const [createdBy, setCreatedBy] = useState('')

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = useCallback(async () => {
    await supplierStore.getSupplier(1, 9999, '').then(res => {
      setSupplierList(res.data.pageData)
    })
    await unitStore.getUnit(1, 9999, '').then(res => {
      const units = res.data.pageData
      setUnitList(units)
      const unitNameMap = units.reduce((map, unit) => {
        map[unit.id] = unit.unit_name
        return map
      }, {})
      setUnitName(unitNameMap)
    })
    await inventoryItemStore.getInventoryItem(1, 9999, '').then(res => {
      setInventoryItemList(res.data.pageData)
      setFilteredItems(res.data.pageData)
    })
  }, [supplierStore, unitStore, inventoryItemStore])

  useEffect(() => {
    if (importId) {
      importStore.getImportInvoiceDetail(importId).then(async res => {
        setOriginalInvoiceNo(res.data.masterDTO.invoice_no)
        setInvoiceDate(res.data.masterDTO.invoice_date)
        setSupplierName(res.data.masterDTO.supplier_name)
        setNote(res.data.masterDTO.note)
        setStatus(res.data.masterDTO.status)
        setReceivedAt(res.data.masterDTO.received_at)
        setCreatedBy(res.data.masterDTO.created_by)
        form.setFieldsValue({
          invoice_no: res.data.masterDTO.invoice_no,
          invoice_date: moment.utc(
            res.data.masterDTO.invoice_date,
            'YYYY-MM-DD'
          ),
          supplier_id: res.data.masterDTO.supplier_id,
          note: res.data.masterDTO.note,
        })

        setOldMaster(res.data.masterDTO)
        setOldDetails(res.data.details)

        const detailsWithItemInfo = await Promise.all(
          res.data.details.map(async (detail, index) => {
            try {
              const itemInfo = await inventoryItemStore.getInventoryItemById(
                detail.item_id
              )
              return {
                key: index + 1,
                id: detail.id,
                item_id: detail.item_id,
                item_code: itemInfo.data.item_code,
                item_name: itemInfo.data.item_name,
                unit_id: detail.unit_id,
                quantity: detail.quantity,
                unit_price: detail.unit_price,
                tax: detail.tax,
                taxTotal:
                  (detail.quantity * detail.unit_price * detail.tax) / 100,
                itemTotal: detail.itemTotal,
                amount:
                  detail.quantity * detail.unit_price * (1 + detail.tax / 100),
              }
            } catch (error) {
              console.error(error)
              return {
                key: index + 1,
                id: '',
                item_id: '',
                item_code: '',
                item_name: '',
                unit_id: '',
                quantity: '',
                unit_price: '',
                tax: '',
                taxTotal: undefined,
                itemTotal: undefined,
                amount: '',
              }
            }
          })
        )

        setData(detailsWithItemInfo)
        setAmount(detailsWithItemInfo.map(row => row.amount))
        const totalAmount = detailsWithItemInfo.reduce(
          (acc, row) => acc + (row.amount || 0),
          0
        )
        setTotalAmount(totalAmount)
      })
    }
  }, [importId])

  const [data, setData] = useState([
    {
      key: 1,
      id: '',
      item_id: '',
      item_name: '',
      unit_id: '',
      quantity: '',
      unit_price: '',
      tax: '',
      taxTotal: undefined,
      itemTotal: undefined,
    },
  ])

  const [amount, setAmount] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)

  function formatNumber(value) {
    if (value == null) return ''
    const roundedValue = Number(value).toFixed(2)
    const [integerPart, decimalPart] = roundedValue.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }

  const handleInputChange = (index, field, value) => {
    const newData = [...data]

    if (field === 'quantity') {
      if (/^\d*\.?\d*$/.test(value)) {
        newData[index][field] = value
      }
    } else if (field === 'unit_price') {
      if (/^\d*\.?\d*$/.test(value)) {
        newData[index][field] = value
      }
    } else {
      newData[index][field] = value
    }

    const quantity = parseFloat(newData[index]['quantity'] || '0')
    const unitPrice = parseFloat(newData[index]['unit_price'] || '0')
    const tax = parseFloat(newData[index]['tax'] || '0')
    newData[index].taxTotal = (quantity * unitPrice * tax) / 100
    newData[index].amount = quantity * unitPrice * (1 + tax / 100)

    setData(newData)
    setAmount(newData.map(row => row.amount))

    const newTotalAmount = newData.reduce(
      (acc, row) => acc + (row.amount || 0),
      0
    )
    setTotalAmount(newTotalAmount)
  }

  const handleSelect = async (index, value, option) => {
    const newData = [...data]
    newData[index]['item_id'] = value
    newData[index]['item_code'] = option.item_code

    try {
      const itemSelected = await inventoryItemStore.getInventoryItemById(value)

      newData[index]['item_name'] = itemSelected.data.item_name
      newData[index]['unit_id'] = itemSelected.data.unit_id
      newData[index]['quantity'] = '1'
      newData[index]['unit_price'] = itemSelected.data.unit_price
      newData[index]['tax'] = '0'

      newData[index].itemTotal = itemSelected.data.quantity

      const quantity = parseFloat(newData[index]['quantity'] || '0')
      const unitPrice = parseFloat(newData[index]['unit_price'] || '0')
      const tax = parseFloat(newData[index]['tax'] || '0')
      newData[index].taxTotal = (quantity * unitPrice * tax) / 100
      newData[index].amount = quantity * unitPrice * (1 + tax / 100)

      setData(newData)
      setAmount(newData.map(row => row.amount))

      const newTotalAmount = newData.reduce(
        (acc, row) => acc + (row.amount || 0),
        0
      )
      setTotalAmount(newTotalAmount)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSearch = value => {
    const filtered = inventoryItemList.filter(
      item =>
        item.item_code.toLowerCase().includes(value.toLowerCase()) ||
        item.item_name.toLowerCase().includes(value.toLowerCase())
    )
    setFilteredItems(filtered)
  }

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      render: (text, record, index) => <span>{index + 1}</span>,
      width: '20px',
    },
    {
      title: 'MÃ SẢN PHẨM',
      key: 'item_id',
      render: (text, record, index) =>
        type === 'detail' ? (
          <Input style={{ width: '100%' }} value={record.item_code} disabled />
        ) : (
          <AutoComplete
            style={{ width: '100%' }}
            value={record.item_code}
            disabled={status === 'APPROVED' ? true : false}
            options={filteredItems.map(item => ({
              value: item.id,
              item_code: item.item_code,
              label: (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <span>{item.item_code}</span>
                  <span>{item.item_name}</span>
                </div>
              ),
            }))}
            onSelect={(value, option) => handleSelect(index, value, option)}
            onSearch={handleSearch}
            onChange={value => handleInputChange(index, 'item_code', value)}
          />
        ),
      width: '300px',
    },
    {
      title: 'TÊN SẢN PHẨM',
      key: 'item_name',
      render: (text, record, index) => (
        <Input disabled value={record.item_name} />
      ),
      width: '300px',
    },
    {
      title: 'ĐVT',
      key: 'unit_id',
      render: (text, record, index) =>
        type === 'detail' ? (
          <Input
            style={{ width: '100%' }}
            value={unitName[record.unit_id]}
            disabled
          />
        ) : (
          <Select
            allowClear
            placeholder="Chọn đơn vị"
            style={{ width: '100%' }}
            value={record.unit_id}
            disabled={status === 'APPROVED' ? true : false}
            onChange={value => handleInputChange(index, 'unit_id', value)}>
            {unitList.map(unit => (
              <Option name={unit.unit_name} value={unit.id} key={unit.id}>
                {unit.unit_name}
              </Option>
            ))}
          </Select>
        ),
      width: '150px',
    },
    {
      title: 'SỐ LƯỢNG',
      key: 'quantity',
      render: (text, record, index) =>
        type === 'detail' ? (
          <Input value={record.quantity} style={{ width: '100%' }} disabled />
        ) : (
          <Input
            value={record.quantity}
            onChange={e => handleInputChange(index, 'quantity', e.target.value)}
            style={{ width: '100%' }}
          />
        ),
      width: '150px',
    },
    {
      title: 'ĐƠN GIÁ',
      key: 'unit_price',
      render: (text, record, index) =>
        type === 'detail' ? (
          <Input value={record.unit_price} style={{ width: '100%' }} disabled />
        ) : (
          <Input
            value={record.unit_price}
            onChange={e =>
              handleInputChange(index, 'unit_price', e.target.value)
            }
            style={{ width: '100%' }}
          />
        ),
      width: '200px',
    },
    {
      title: 'THUẾ (%)',
      key: 'tax',
      render: (text, record, index) => (
        <Select
          value={record.tax}
          onChange={value => handleInputChange(index, 'tax', value)}
          style={{ width: '100%' }}
          disabled={type === 'detail' || status === 'APPROVED' ? true : false}>
          {[0, 3, 5, 8, 10].map(tax => (
            <Option key={tax} value={tax}>
              {tax}%
            </Option>
          ))}
        </Select>
      ),
      width: '150px',
    },
    {
      title: 'TIỀN THUẾ',
      key: 'taxTotal',
      render: (text, record, index) => (
        <Input disabled value={record.taxTotal} />
      ),
      width: '200px',
    },
    {
      title: 'THÀNH TIỀN',
      key: 'amount',
      render: (text, record, index) => <Input disabled value={amount[index]} />,
      width: '250px',
    },
    {
      title: '',
      key: '-',
      render: (text, record, index) => (
        <Button
          icon={<DeleteOutlined />}
          disabled={type === 'detail' || status === 'APPROVED' ? true : false}
          onClick={() => handleRemoveRow(index)}
        />
      ),
      width: '50px',
    },
  ]

  const handleClose = () => {
    setOpen(false)
    form.resetFields()
    setImportId('')
    const newData = [
      {
        key: 1,
        id: '',
        item_id: '',
        item_code: '',
        item_name: '',
        unit_id: '',
        quantity: '',
        unit_price: '',
        tax: '',
        taxTotal: undefined,
        itemTotal: undefined,
      },
    ]
    setData(newData)
    setAmount([])
    setTotalAmount(0)
  }

  const handleSubmit = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      await form.validateFields()
      const values = form.getFieldsValue()

      const invoiceNo = values.invoice_no
      if (invoiceNo !== originalInvoiceNo) {
        const response = await importStore.checkExistInvoiceNo(invoiceNo)
        if (response.data) {
          message.error('Số phiếu nhập đã tồn tại!')
          return
        }
      }

      const invalidRows = data.filter((row, index) => {
        const errors = []
        if (!row.item_id) errors.push('Thiếu mã sản phẩm')
        if (!row.unit_id) errors.push('Thiếu đơn vị tính')
        if (!row.quantity || parseFloat(row.quantity) <= 0)
          errors.push('Số lượng không hợp lệ')
        if (
          row?.unit_price === undefined ||
          row?.unit_price === '' ||
          row?.unit_price === null
        )
          errors.push('Thiếu đơn giá')

        if (errors.length > 0) {
          message.error(`Dòng ${index + 1}: ${errors.join(', ')}`)
          return true
        }
        return false
      })

      if (invalidRows.length > 0) {
        return
      }

      const tableData = data.map(row => ({
        id: row?.id ? row.id : null,
        item_id: row.item_id,
        item_name: row.item_name,
        unit_id: row.unit_id,
        quantity: row.quantity,
        tax: row.tax,
        unit_price: row.unit_price,
        inward_id: importId,
      }))

      const payload = {
        master: {
          ...values,
          total_amount: totalAmount,
          id: importId,
          status: status,
        },
        details: tableData,
      }

      await importStore.updateImportInvoice(payload)

      var sNameOld = supplierList?.find(x => x.id == oldMaster?.supplier_id)
        ?.supplier_name

      var sNameNew = supplierList?.find(
        x => x.id == payload.master?.supplier_id
      )?.supplier_name

      var sCodeNew = supplierList?.find(
        x => x.id == payload.master?.supplier_id
      )?.supplier_code

      oldMaster.supplier_name = sNameOld
      payload.master.supplier_name = sNameNew
      payload.master.supplier_code = sCodeNew

      var tableNew = data.map(row => ({
        id: row.id,
        item_id: row.item_id,
        item_name: row.item_name,
        unit_id: row.unit_id,
        unit_name: unitList?.find(x => x.id == row.unit_id)?.unit_name,
        quantity: Number(row.quantity),
        unit_price: Number(row.unit_price),
        inward_id: importId,
        tax: row.tax,
      }))

      var tableOld = oldDetails.map(row => ({
        id: row.id,
        item_id: row.item_id,
        item_name: inventoryItemList?.find(x => x.id == row.item_id)?.item_name,
        unit_id: row.unit_id,
        unit_name: unitList?.find(x => x.id == row.unit_id)?.unit_name,
        quantity: row.quantity,
        unit_price: row.unit_price,
        inward_id: importId,
        tax: row.tax,
      }))

      if (status !== 'APPROVED') {
        message.success('Chỉnh sửa đơn nhập kho thành công!')
      } else {
        const compareUnitPricesAndQuantities = (oldArray, newArray) => {
          const changes = []
          let oldTotal = 0
          let newTotal = 0

          oldArray.forEach((oldItem, index) => {
            const newItem = newArray[index]
            oldTotal += oldItem.unit_price * oldItem.quantity
            newTotal += newItem.unit_price * newItem.quantity

            if (
              oldItem.unit_price !== newItem.unit_price ||
              oldItem.quantity !== newItem.quantity
            ) {
              changes.push({
                id: oldItem.id,
                item_name: oldItem.item_name,
                old_price: oldItem.unit_price,
                new_price: newItem.unit_price,
                old_quantity: oldItem.quantity,
                new_quantity: newItem.quantity,
              })
            }
          })

          return { changes, oldTotal, newTotal }
        }

        const {
          changes: priceAndQuantityChanges,
          oldTotal,
          newTotal,
        } = compareUnitPricesAndQuantities(tableOld, tableNew)

        if (priceAndQuantityChanges && priceAndQuantityChanges?.length > 0) {
          let emailContent = `<p>Hiện tại đang có phiếu nhập kho ${invoiceNo} đã xác nhận hàng thành công nhưng có sự thay đổi so với lúc duyệt. Dưới đây là thông tin cơ bản của phiếu nhập kho đó:</p>`
          emailContent += `<p><strong>Tổng tiền: ${formatAmount(
            oldTotal
          )} -> ${formatAmount(newTotal)}</strong></p></br>`

          priceAndQuantityChanges.forEach(change => {
            emailContent += `<p><strong>Sản phẩm ${change.item_name}:</strong></p>`
            emailContent += `  <p>Số lượng: ${change.old_quantity} -> ${change.new_quantity}</p>`
            emailContent += `  <p>Đơn giá: ${change.old_price} -> ${change.new_price}</p></br>`
          })

          var toEmail
          await settingStore.getSetting().then(response => {
            if (response && response.data && response.data.length > 0) {
              var data = response?.data?.find(x => x.name == 'ApprovePerson')
              toEmail = data?.value
            }
          })
          const htmlContent = `
            <!DOCTYPE html>
            <html lang="en">
              <head>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Order Ready Notification</title>
                <style>
                  body {
                    font-family: Arial, sans-serif;
                    margin: 0;
                    padding: 0;
                    background-color: #f4f4f4;
                  }
                  .container {
                    width: 100%;
                    max-width: 600px;
                    background: #ffffff;
                    padding: 20px;
                  }
                  h1 {
                    color: #333333;
                  }
                  p {
                    color: #666666;
                    line-height: 1.5;
                  }
                  .button {
                    display: inline-block;
                    padding: 10px 20px;
                    font-size: 16px;
                    color: #ffffff !important;
                    background-color: #007bff;
                    text-decoration: none;
                    border-radius: 5px;
                  }
                </style>
              </head>
              <body>
                <div class="container">
                  <h1>Chào bạn,</h1>
                  ${emailContent}
                  <p>Cảm ơn bạn đã sử dụng dịch vụ của chúng tôi!</p>
                  <p>Trân trọng,<br />Đội ngũ hỗ trợ</p>
                </div>
              </body>
            </html>
          `
          if (toEmail === undefined || toEmail === null || toEmail === '') {
            message.warning('Không có người duyệt nào được cấu hình!')
            return
          }
          await importStore.sendEmail(
            toEmail,
            'PHIẾU NHẬP KHO THAY ĐỔI SO VỚI LÚC DUYỆT',
            htmlContent,
            [createdBy]
          )
        }
        await importStore.updateStatus(importId, 'COMPLETED')
        message.success('Xác nhận đơn nhập kho thành công!')
      }

      var auditLogRequest = {
        table_name: 'Nhập kho',
        action: 'Chỉnh sửa',
        oldObj: oldMaster,
        newObj: payload.master,
        oldDetails: tableOld,
        newDetails: tableNew,
      }

      await importStore.addAuditLog(auditLogRequest)

      await fetchImportInvoice()
      await handleClose()
    } catch (errorInfo) {
      console.error(errorInfo)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const formatAmount = totalAmount => {
    const formattedAmount =
      typeof totalAmount === 'number'
        ? totalAmount.toLocaleString('vi-VN', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }) + ' vnđ'
        : `${totalAmount} vnđ`
    return formattedAmount
  }

  const handleAddRow = () => {
    setData([
      ...data,
      {
        key: data.length + 1,
        id: importId,
        item_id: '',
        item_name: '',
        unit_id: '',
        quantity: '',
        unit_price: '',
        tax: '',
        taxTotal: undefined,
        itemTotal: undefined,
      },
    ])
    setFilteredItems(inventoryItemList)
  }

  const handleRemoveRow = index => {
    if (data.length === 1) {
      message.warning('Không thể xóa dòng cuối cùng!')
      return
    }
    const newData = data.filter((item, idx) => idx !== index)
    setData(newData)

    setAmount(newData.map(row => row.itemTotal))
    const newTotalAmount = newData.reduce(
      (acc, row) => acc + (row.itemTotal || 0),
      0
    )
    setTotalAmount(newTotalAmount)
  }

  const handleDeleteAll = () => {
    const newData = [
      {
        key: 1,
        id: null,
        item_id: '',
        item_code: '',
        item_name: '',
        unit_id: '',
        quantity: '',
        unit_price: '',
        tax: '',
        taxTotal: undefined,
        itemTotal: undefined,
      },
    ]

    setData(newData)
    setAmount(newData.map(row => row.amount))
    setTotalAmount(0)
  }

  const handlePrint = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      data.map((item, index) => ({
        STT: index + 1,
        'MÃ SẢN PHẨM': item.item_code || '',
        'TÊN SẢN PHẨM': item.item_name || '',
        'ĐƠN VỊ': unitName[item.unit_id] || '',
        'SỐ LƯỢNG': item.quantity || '',
        'ĐƠN GIÁ': item.unit_price || '',
        'THUẾ (%)': item.tax || '',
        'TIỀN THUẾ': item.taxTotal || '',
        'THÀNH TIỀN': item.amount || '',
      }))
    )

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    XLSX.writeFile(workbook, 'import-details.xlsx')
  }

  const TitleHeader = ({ status }) => {
    let color
    let content

    switch (status) {
      case 'DRAFT':
        color = 'darkgrey'
        content = 'Bản nháp'
        break
      case 'PENDING':
        color = 'orange'
        content = 'Chờ duyệt'
        break
      case 'REJECTED':
        color = 'red'
        content = 'Từ chối'
        break
      case 'APPROVED':
        color = 'blue'
        content = 'Đã duyệt'
        break
      case 'COMPLETED':
        color = 'green'
        content = 'Hoàn thành'
        break
      default:
        color = ''
        content = ''
    }

    return (
      <>
        PHIẾU NHẬP KHO{' '}
        {status ? (
          <Tag color={color}>{content}</Tag>
        ) : (
          <EmptyText>Chưa có trạng thái</EmptyText>
        )}
      </>
    )
  }

  return (
    <Modal
      title={TitleHeader({ status })}
      width={'100vw'}
      className="modalImportStyle"
      visible={open}
      onCancel={handleClose}
      style={{
        top: '0px',
        maxWidth: '100vw',
        height: '100vh',
        paddingBottom: '0px',
        background: '#fff',
      }}
      footer={
        type === 'detail'
          ? ''
          : [
              <Button key="cancel" onClick={handleClose}>
                Hủy
              </Button>,
              <Button key="submit" type="primary" onClick={handleSubmit}>
                {status !== 'APPROVED' ? 'Chỉnh sửa' : 'Xác nhận'}
              </Button>,
            ]
      }>
      <Form
        form={form}
        scrollToFirstError={true}
        name={'create-import-invoice'}
        layout={'vertical'}>
        <div
          style={{
            padding: '20px 24px 0px 24px',
            background: '#eceef1',
          }}>
          <Row type={'flex'} gutter={30}>
            <Col xs={24} md={8}>
              <Form.Item
                label={'Số phiếu nhập'}
                name={'invoice_no'}
                rules={[
                  { required: true, message: ' Vui lòng nhập số phiếu nhập!' },
                ]}>
                <Input
                  allowClear
                  maxLength={50}
                  placeholder={'Nhập số phiếu nhập'}
                  disabled={
                    type === 'detail' || status === 'APPROVED' ? true : false
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              {type === 'detail' ? (
                <Form.Item label={'Ngày đặt hàng'}>
                  <Input
                    style={{ width: '100%' }}
                    value={
                      invoiceDate
                        ? moment(invoiceDate).format('YYYY-MM-DD')
                        : ''
                    }
                    disabled
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label={'Ngày đặt hàng'}
                  name={'invoice_date'}
                  rules={[
                    {
                      required: true,
                      message: ' Vui lòng chọn ngày đặt hàng!',
                    },
                  ]}>
                  <DatePicker
                    style={{ width: '100%' }}
                    placeholder="Chọn ngày đặt hàng"
                    disabled={status === 'APPROVED' ? true : false}
                  />
                </Form.Item>
              )}
            </Col>
            <Col
              xs={24}
              md={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'end',
              }}>
              <Title level={2} code style={{ color: '#000' }}>
                THÀNH TIỀN
              </Title>
              <Text strong style={{ fontSize: '20px', marginBottom: '0px' }}>
                {formatNumber(totalAmount)} VNĐ
              </Text>
            </Col>
          </Row>
          <Row type={'flex'} gutter={30} style={{ paddingBottom: '20px' }}>
            <Col xs={24} md={8}>
              {type === 'detail' ? (
                <Form.Item label={'Nhà cung cấp'}>
                  <Input
                    style={{ width: '100%' }}
                    value={supplierName}
                    disabled
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label={'Nhà cung cấp'}
                  name={'supplier_id'}
                  rules={[
                    { required: true, message: ' Vui lòng chọn nhà cung cấp!' },
                  ]}>
                  <Select
                    allowClear
                    placeholder="Chọn nhà cung cấp"
                    disabled={status === 'APPROVED' ? true : false}>
                    {supplierList.map(supplier => (
                      <Option
                        name={supplier.supplier_name}
                        value={supplier.id}
                        key={supplier.id}>
                        {supplier.supplier_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
            <Col xs={24} md={8}>
              {type === 'detail' ? (
                <Form.Item label={'Ghi chú'} name={'note'}>
                  <Input style={{ width: '100%' }} value={note} disabled />
                </Form.Item>
              ) : (
                <Form.Item label={'Ghi chú'} name={'note'}>
                  <Input
                    allowClear
                    maxLength={255}
                    placeholder={'Nhập ghi chú'}
                    disabled={status === 'APPROVED' ? true : false}
                  />
                </Form.Item>
              )}
            </Col>
            <Col
              xs={24}
              md={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'end',
              }}>
              <Title level={2} code style={{ color: '#000' }}>
                NGÀY NHẬN
              </Title>
              <Text
                strong
                style={{
                  fontSize: '14px',
                  fontStyle: 'italic',
                  marginBottom: '0px',
                }}>
                {type === 'detail'
                  ? receivedAt && receivedAt !== ''
                    ? moment(receivedAt).format('DD-MM-YYYY')
                    : 'Chưa có'
                  : 'Chưa có'}
              </Text>
            </Col>
          </Row>
        </div>
        <div
          style={{ float: 'right', marginRight: '24px', marginBottom: '10px' }}>
          <Button onClick={handlePrint}>
            <PrinterOutlined /> Xuất excel
          </Button>
        </div>
        <div
          style={{
            padding: '12px 24px 24px',
          }}>
          <ImportTable>
            <Table columns={columns} dataSource={data} pagination={false} />
          </ImportTable>
          {status === 'DRAFT' && (
            <div style={{ marginTop: '10px' }}>
              <Button
                key="themDong"
                onClick={() => {
                  fetchData()
                  handleAddRow()
                }}
                style={{ marginRight: '10px' }}>
                Thêm dòng
              </Button>
              <Button key="xoaTatCa" onClick={handleDeleteAll}>
                Xóa tất cả
              </Button>
            </div>
          )}
        </div>
      </Form>
    </Modal>
  )
}

export default memo(
  inject(
    'supplierStore',
    'unitStore',
    'inventoryItemStore',
    'importStore',
    'loadingAnimationStore',
    'settingStore'
  )(observer(ImportDetailUpdate))
)
