import React, { memo, useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import {
  Button,
  Col,
  Input,
  Menu,
  Pagination,
  Row,
  Table,
  message,
  Modal,
} from 'antd'
import { SearchBar } from '../ExportPageStyled'
import {
  DeleteOutlined,
  EditOutlined,
  PrinterOutlined,
  ReloadOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { TableWrapper } from '../../../components/Common/Table'
import { EmptyText } from '../../../components/Common/CellText'
import { ExportTable } from '../ExportPageStyled'
import ExportExcelCreateModal from '../ExportExcelCreateModal'
import ExportPagePrinter from '../Printer/ExportPrinterCreateModal'
import { Container } from '../../../layouts/Container/Container'
import * as signalR from '@microsoft/signalr'
import { apiUrl } from '../../../config'
import utils from '../../../utils'
const { Search } = Input
const { confirm } = Modal

const ExportPrinterTab = props => {
  const { exportStore, loadingAnimationStore } = props
  const [exportData, setExportData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchValue, setSearchValue] = useState('')
  const [total, setTotal] = useState(0)
  const [openExportCreate, setOpenExportCreate] = useState(false)
  const [openExportExcelCreate, setOpenExportExcelCreate] = useState(false)
  const [excelData, setExcelData] = useState({})
  const [openExportDetailUpdate, setOpenExportDetailUpdate] = useState(false)
  const [exportId, setExportId] = useState('')
  const [type, setType] = useState('')

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiUrl}/exportStockPrinterHub`)
      .configureLogging(signalR.LogLevel.None)
      .withAutomaticReconnect()
      .build()

    connection.on('OutwardStockPrinterSignal', () => {
      fetchExportInvoice()
    })

    connection
      .start()
      .then(() => {
        fetchExportInvoice()
      })
      .catch(() => {
        fetchExportInvoice()
      })

    return () => {
      connection.stop()
    }
  }, [page, pageSize, searchValue])

  const fetchExportInvoice = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await exportStore.getExportPrinter(
        page,
        pageSize,
        searchValue
      )
      const data = response.data.pageData.map((item, index) => ({
        key: item.id,
        stt: index + 1,
        invoice_no: item.invoice_no,
        invoice_date: item.invoice_date,
        department_name: item.department_name,
        total_amount: item.total_amount,
        note: item.note,
      }))
      setExportData(data)
      setTotal(response.data.totalCount)
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  function formatNumber(value) {
    if (value == null) return ''
    const roundedValue = Number(value).toFixed(2)
    const [integerPart, decimalPart] = roundedValue.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      width: '20px',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'SỐ PHIẾU XUẤT',
      key: 'invoice_no',
      dataIndex: 'invoice_no',
      sorter: (a, b) => a.invoice_no.localeCompare(b.invoice_no),
      render: text => (
        <span>{text || <EmptyText>Chưa có số phiếu xuất</EmptyText>}</span>
      ),
    },
    {
      title: 'NGÀY XUẤT HÀNG',
      key: 'invoice_date',
      dataIndex: 'invoice_date',
      sorter: (a, b) => new Date(a.invoice_date) - new Date(b.invoice_date),
      render: text => {
        if (text) {
          const date = new Date(text)
          return (
            <span>
              {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
            </span>
          )
        } else {
          return <EmptyText>Chưa có ngày xuất hàng</EmptyText>
        }
      },
    },
    {
      title: 'THÀNH TIỀN (VNĐ)',
      key: 'total_amount',
      dataIndex: 'total_amount',
      sorter: (a, b) => a.total_amount - b.total_amount,
      render: text => (
        <span>
          {formatNumber(text) || <EmptyText>Chưa có tổng tiền</EmptyText>}
        </span>
      ),
    },
    {
      title: 'GHI CHÚ',
      key: 'note',
      dataIndex: 'note',
      render: text => (
        <span>{text || <EmptyText>Chưa có ghi chú</EmptyText>}</span>
      ),
    },
    // {
    //   title: '',
    //   key: '#',
    //   width: 1,
    //   render: (text, record) => (
    //     <div style={{ display: 'flex' }}>
    //       <Button
    //         onClick={e => {
    //           e.stopPropagation()
    //           setOpenExportCreate(true)
    //           setExportId(record.key)
    //           setType('update')
    //         }}
    //         type="primary"
    //         shape="circle"
    //         icon={<EditOutlined />}
    //         size="small"></Button>
    //       <Button
    //         style={{ marginLeft: '5px' }}
    //         onClick={e => {
    //           e.stopPropagation()
    //           showConfirm(record.key)
    //         }}
    //         type="danger"
    //         shape="circle"
    //         icon={<DeleteOutlined />}
    //         size="small"></Button>
    //     </div>
    //   ),
    // },
  ]

  const showConfirm = id => {
    // confirm({
    //   title: 'Bạn có muốn xóa đơn xuất kho này không?',
    //   icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
    //   okText: 'Đồng ý',
    //   cancelText: 'Không',
    //   okType: 'danger',
    //   onOk() {
    //     handleDelete(id)
    //   },
    // })
  }

  const handleDelete = async id => {
    // loadingAnimationStore.showSpinner(true)
    try {
      await exportStore.deleteExportInvoice(id)
      message.success('Xóa đơn xuất kho thành công!')
      await fetchExportInvoice()
    } catch (error) {
      console.error(error)
    } finally {
      // loadingAnimationStore.showSpinner(false)
    }
  }

  const handlePrint = () => {
    const data = exportData.map((item, index) => ({
      STT: index + 1,
      'Số phiếu xuất': item.invoice_no || '',
      'Ngày xuất': item.invoice_date
        ? new Date(item.invoice_date).toLocaleDateString()
        : '',
      // 'Phòng ban': item.department_name || '',
      'Tổng tiền': formatNumber(item.total_amount) || '',
      'Ghi chú': item.note || '',
    }))

    utils.exportToExcel({ data, fileName: 'xuat_kho_may_in' })
  }

  return (
    <>
      <ContentBlockWrapper>
        <Container>
          <Row style={{ marginBottom: 0, height: '38px' }}>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  onSearch={value => {
                    setPage(1), setPageSize(10), setSearchValue(value)
                  }}
                  maxLength={500}
                  placeholder={'Tìm kiếm '}
                />
              </SearchBar>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button style={{ marginLeft: '10px' }} onClick={handlePrint}>
                <PrinterOutlined />
                Xuất excel
              </Button>
              <Button
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  fetchExportInvoice()
                  console.log('Refresh button clicked')
                }}>
                <ReloadOutlined />
              </Button>
              <Button
                style={{ marginLeft: '10px', cursor: 'pointer' }}
                onClick={() => {
                  setOpenExportCreate(true)
                  setType('add')
                  setExportId(null)
                }}
                type={'primary'}>
                Thêm
              </Button>
            </Col>
          </Row>
          <TableWrapper>
            <ExportTable>
              <Table
                className="exportTable"
                style={{
                  height: 'calc(100vh - 235px)',
                  overflowY: 'auto',
                }}
                columns={columns}
                dataSource={exportData}
                bordered
                pagination={false}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: event => {
                      setOpenExportCreate(true)
                      setExportId(record.key)
                      setType('detail')
                    },
                  }
                }}
              />
            </ExportTable>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div>
                Tổng số: <b>{total}</b> bản ghi
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '8px',
                }}>
                <Pagination
                  onChange={(page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                  }}
                  current={page}
                  pageSize={pageSize}
                  total={total}
                  hideOnSinglePage={true}
                  showSizeChanger={true}
                  showLessItems
                />
              </div>
            </div>
          </TableWrapper>
        </Container>
      </ContentBlockWrapper>
      <ExportPagePrinter
        open={openExportCreate}
        setOpen={setOpenExportCreate}
        fetchExportInvoice={fetchExportInvoice}
        excelData={excelData}
        setExcelData={setExcelData}
        exportId={exportId}
        type={type}
      />
      <ExportExcelCreateModal
        key="exportExcelCreate"
        open={openExportExcelCreate}
        setOpen={setOpenExportExcelCreate}
        setOpenExportCreate={setOpenExportCreate}
        setExcelData={setExcelData}
      />
    </>
  )
}

export default memo(
  inject(
    'exportStore',
    'loadingAnimationStore',
    'supplierStore'
  )(observer(ExportPrinterTab))
)
