import styled from 'styled-components'

export const ItemsByDepartmentsTable = styled.div`
  .ant-table-thead > tr > th {
    text-align: center;
    vertical-align: middle;
  }

  .ant-table-thead > tr > th {
    font-weight: bold;
  }
`
