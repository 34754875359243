import { Button, Col, Form, Input, message, Modal, Row, Table } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { memo, useCallback, useEffect, useState } from 'react'
const { Search } = Input
const SelectRoleModal = props => {
  const {
    accountId,
    open,
    handleClose,
    handleSubmit,
    roleStore,
    loadingAnimationStore,
    accountStore,
    setAccountId
  } = props
  const [form] = Form.useForm()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [roles, setRoles] = useState([])

  const fetchRolesData = useCallback(async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await roleStore.getRoles(1, 999, '')
      const { pageData, totalCount } = response.data
      const data = pageData.map(item => ({
        key: item.id,
        role_code: item.role_code,
        role_name: item.role_name,
        description: item.description,
        id: item.id,
        permissions: item.permissions,
        accounts: item.accounts,
      }))
      setRoles(data)
    } catch (error) {
      message.error('Có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [roleStore, loadingAnimationStore])

  const fetchAccountData = useCallback(
    async accountId => {
      await fetchRolesData();
      loadingAnimationStore.showSpinner(true)
      try {
        const res = await accountStore.getAccount(accountId).then((res) => {
            const accountData = res.data
            form.setFieldsValue({
              email: accountData.email,
              username: accountData.username,
              campus: accountData.campus.campus_name,
            })
            setSelectedRowKeys(accountData.roles.map(item => item.id));             
        })        
      } catch (error) {
        message.error('Có lỗi xảy ra')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    },
    [roleStore, loadingAnimationStore, accountId, roleStore]
  )


  useEffect(() => {
    const fetchData = async roleId => {
      if (accountId !== '' && accountId !== undefined && accountId !== null) {
        await fetchAccountData(accountId)
      }
    }
    fetchData(accountId)
  }, [accountId, fetchAccountData])

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      width: 20,
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'MÃ VAI TRÒ',
      key: 'role_code',
      width: '300px',
      dataIndex: 'role_code',
    },
    {
      title: 'TÊN VAI TRÒ',
      key: 'role_name',
      width: '300px',
      dataIndex: 'role_name',
    },
    {
      title: 'MÔ TẢ',
      key: 'description',
      dataIndex: 'description',
    },
  ]

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys)
    const accountIds = selectedRows.map(row => row.id)
    console.log(accountIds)
  }

  const reset = () => {
    resetSelection();
    setAccountId();
  }

  const resetSelection = () => {
    setSelectedRowKeys([])
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const submitAccount = () => {
    handleSubmit(accountId, {roleIds: selectedRowKeys});
  }

  return (
    <>
      <Modal
        title={'SỬA NGƯỜI DÙNG'}
        width={'100vw'}
        className="roleModalStyle"
        visible={open}
        onCancel={() => {
          reset()
          handleClose()
        }}
        style={{
          top: '0px',
          maxWidth: '100vw',
          height: '100vh',
          paddingBottom: '0px',
          background: '#fff',
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              reset()
              handleClose()
            }}>
            Hủy
          </Button>,
          <Button key="submit" type="primary" onClick={() => {
            reset()
            submitAccount();
          }}>
            Lưu
          </Button>,
        ]}>
        <Form
          form={form}
          scrollToFirstError={true}
          name={'accountForm'}
          layout={'vertical'}>
          <div
            style={{
              padding: '0px 24px 0px 24px',
              background: '#ffff',
            }}>
            <Row type={'flex'} gutter={30} style={{ marginBottom: '0px' }}>
              <Col
                xs={24}
                md={8}
                style={{ paddingRight: '12px', paddingLeft: '0px' }}>
                <Form.Item label={'Email'} name={'email'}>
                  <Input disabled={true} allowClear maxLength={255} />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                md={8}
                style={{ paddingRight: '12px', paddingLeft: '0px' }}>
                <Form.Item label="Username" name={'username'}>
                  <Input disabled={true} allowClear maxLength={255} />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                md={8}
                style={{ paddingRight: '12px', paddingLeft: '0px' }}>
                <Form.Item label="Cơ sở" name={'campus'}>
                  <Input disabled={true} allowClear maxLength={255} />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
        <h2>Chọn vai trò</h2>
        <Table
          columns={columns}
          dataSource={roles}
          bordered
          className="custom-table"
          pagination={false}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
        />
      </Modal>
    </>
  )
}

export default memo(
  inject('roleStore', 'loadingAnimationStore', 'accountStore')(observer(SelectRoleModal))
)
