import React from 'react'
import PropTypes from 'prop-types'
import {
  ContentWrapper,
  LayoutWrapper,
  MainWrapper,
  SidebarWrapper,
  SmallSidebarWrapper,
} from './DashboardLayoutStyled'
import MainSidebar from '../../components/MainSidebar'
import MainHeaderBar from '../../components/MainHeaderBar'
import { inject, observer } from 'mobx-react'

const DashboardLayout = props => {
  const { children, title, commonStore, backgroundColor } = props

  const { collapsedMenu, setCollapsedMenu } = commonStore

  return (
    <>
      <SmallSidebarWrapper>
        <MainHeaderBar title={title} />
      </SmallSidebarWrapper>
      <MainWrapper className={'main-wrapper'}>
        <LayoutWrapper>
          <SidebarWrapper collapsedMenu={collapsedMenu}>
            <MainSidebar />
          </SidebarWrapper>
          <ContentWrapper
            collapsedMenu={collapsedMenu}
            marginTop={17}
            backgroundColor={backgroundColor}
            id="infinityScrollableDiv">
            {children}
          </ContentWrapper>
        </LayoutWrapper>
      </MainWrapper>
    </>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export default inject('commonStore')(observer(DashboardLayout))
