import React, { memo, useCallback, useEffect, useState } from 'react'
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Typography,
  message,
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import './ImportPageStyled.css'
import moment from 'moment'
import { ImportTable } from './ImportPageStyled'

const { Option } = Select
const { Title, Text } = Typography

const ImportCreateModal = props => {
  const {
    open,
    setOpen,
    supplierStore,
    unitStore,
    inventoryItemStore,
    importStore,
    loadingAnimationStore,
    fetchImportInvoice,
    excelData,
    setExcelData,
  } = props
  const [form] = Form.useForm()
  const [supplierList, setSupplierList] = useState([])
  const [unitList, setUnitList] = useState([])
  const [inventoryItemList, setInventoryItemList] = useState([])
  const [filteredItems, setFilteredItems] = useState([])

  const fetchData = useCallback(async () => {
    await supplierStore.getSupplier(1, 9999, '').then(res => {
      setSupplierList(res.data.pageData)
    })
    await unitStore.getUnit(1, 9999, '').then(res => {
      setUnitList(res.data.pageData)
    })
    await inventoryItemStore.getInventoryItem(1, 9999, '').then(res => {
      setInventoryItemList(res.data.pageData)
      setFilteredItems(res.data.pageData)
    })
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    const fetchExcelData = async () => {
      if (excelData && excelData.master && excelData.details) {
        const formValues = {
          invoice_no: excelData.master.invoice_no,
          invoice_date: excelData.master.invoice_date
            ? moment.utc(excelData.master.invoice_date)
            : null,
          supplier_id: excelData.master.supplier_id,
          note: excelData.master.note,
        }
        form.setFieldsValue(formValues)

        const tableData = excelData.details.map((detail, index) => ({
          key: index + 1,
          item_id: detail.item_id,
          item_code: detail.item_code,
          item_name: detail.item_name,
          unit_id: detail.unit_id,
          quantity: detail.quantity,
          unit_price: detail.unit_price,
          tax: '0',
          taxTotal: '0',
          itemTotal: detail.quantity * detail.unit_price,
        }))
        await setData(tableData)
        await setAmount(tableData.map(row => row.itemTotal))
        await setTotalAmount(
          tableData.reduce((acc, row) => acc + (row.itemTotal || 0), 0)
        )
      }
    }

    fetchExcelData()
  }, [excelData, form])

  const [data, setData] = useState([
    {
      key: 1,
      item_id: '',
      item_name: '',
      unit_id: '',
      quantity: '',
      unit_price: '',
      tax: '',
      taxTotal: undefined,
      itemTotal: undefined,
    },
  ])

  const handleAddRow = () => {
    setData([
      ...data,
      {
        key: data.length + 1,
        item_id: '',
        item_name: '',
        unit_id: '',
        quantity: '',
        unit_price: '',
        tax: '',
        taxTotal: undefined,
        itemTotal: undefined,
      },
    ])
    setFilteredItems(inventoryItemList)
  }

  const handleRemoveRow = index => {
    if (data.length === 1) {
      message.warning('Không thể xóa dòng cuối cùng!')
      return
    }
    const newData = data.filter((item, idx) => idx !== index)
    setData(newData)

    setAmount(newData.map(row => row.itemTotal))
    const newTotalAmount = newData.reduce(
      (acc, row) => acc + (row.itemTotal || 0),
      0
    )
    setTotalAmount(newTotalAmount)
  }

  const handleDeleteAll = () => {
    const newData = [
      {
        key: 1,
        item_id: '',
        item_code: '',
        item_name: '',
        unit_id: '',
        quantity: '',
        unit_price: '',
        tax: '',
        taxTotal: undefined,
        itemTotal: undefined,
      },
    ]

    setData(newData)
    setAmount(newData.map(row => row.amount))

    setTotalAmount(0)
  }

  const [amount, setAmount] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)

  function formatNumber(value) {
    if (value == null) return ''
    const roundedValue = Number(value).toFixed(2)
    const [integerPart, decimalPart] = roundedValue.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }

  const handleInputChange = (index, field, value) => {
    const newData = [...data]

    if (field === 'quantity') {
      if (/^\d*\.?\d*$/.test(value)) {
        newData[index][field] = value
      }
    } else if (field === 'unit_price') {
      if (/^\d*\.?\d*$/.test(value)) {
        newData[index][field] = value
      }
    } else {
      newData[index][field] = value
    }

    const quantity = parseFloat(newData[index]['quantity'] || '0')
    const unitPrice = parseFloat(newData[index]['unit_price'] || '0')
    const tax = parseFloat(newData[index]['tax'] || '0')
    newData[index].taxTotal = (quantity * unitPrice * tax) / 100
    newData[index].itemTotal = quantity * unitPrice + newData[index].taxTotal

    setData(newData)
    setAmount(newData.map(row => row.itemTotal))

    const newTotalAmount = newData.reduce(
      (acc, row) => acc + (row.itemTotal || 0),
      0
    )
    setTotalAmount(newTotalAmount)
  }

  const handleSelect = async (index, value, option) => {
    const newData = [...data]
    newData[index]['item_id'] = value
    newData[index]['item_code'] = option.item_code

    try {
      const itemSelected = await inventoryItemStore.getInventoryItemById(value)

      newData[index]['item_name'] = itemSelected.data.item_name
      newData[index]['unit_id'] = itemSelected.data.unit_id
      newData[index]['quantity'] = '1'
      newData[index]['unit_price'] = itemSelected.data.unit_price
      newData[index]['tax'] = '0'
      newData[index].itemTotal = itemSelected.data.quantity

      const quantity = parseFloat(newData[index]['quantity'] || '0')
      const unitPrice = parseFloat(newData[index]['unit_price'] || '0')

      newData[index].itemTotal = quantity * unitPrice
      newData[index].taxTotal = 0

      setData(newData)
      setAmount(newData.map(row => row.itemTotal))

      const newTotalAmount = newData.reduce(
        (acc, row) => acc + (row.itemTotal || 0),
        0
      )
      setTotalAmount(newTotalAmount)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSearch = value => {
    const filtered = inventoryItemList.filter(
      item =>
        item.item_code.toLowerCase().includes(value.toLowerCase()) ||
        item.item_name.toLowerCase().includes(value.toLowerCase())
    )
    setFilteredItems(filtered)
  }

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      render: (text, record, index) => <span>{index + 1}</span>,
      width: '20px',
    },
    {
      title: 'MÃ SẢN PHẨM',
      key: 'item_id',
      render: (text, record, index) => (
        <AutoComplete
          style={{ width: '100%' }}
          value={record.item_code}
          options={filteredItems.map(item => ({
            value: item.id,
            item_code: item.item_code,
            label: (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{item.item_code}</span>
                <span>{item.item_name}</span>
              </div>
            ),
          }))}
          onSelect={(value, option) => handleSelect(index, value, option)}
          onSearch={handleSearch}
          onChange={value => handleInputChange(index, 'item_code', value)}
        />
      ),
      width: '300px',
    },
    {
      title: 'TÊN SẢN PHẨM',
      key: 'item_name',
      render: (text, record, index) => (
        <Input disabled value={record.item_name} />
      ),
      width: '300px',
    },
    {
      title: 'ĐVT',
      key: 'unit_id',
      render: (text, record, index) => (
        <Select
          allowClear
          placeholder="Chọn đơn vị"
          style={{ width: '100%' }}
          value={record.unit_id}
          onChange={value => handleInputChange(index, 'unit_id', value)}>
          {unitList.map(unit => (
            <Option name={unit.unit_name} value={unit.id} key={unit.id}>
              {unit.unit_name}
            </Option>
          ))}
        </Select>
      ),
      width: '150px',
    },
    {
      title: 'SỐ LƯỢNG',
      key: 'quantity',
      render: (text, record, index) => (
        <Input
          value={record.quantity}
          onChange={e => handleInputChange(index, 'quantity', e.target.value)}
          style={{ width: '100%' }}
        />
      ),
      width: '150px',
    },
    {
      title: 'ĐƠN GIÁ',
      key: 'unit_price',
      render: (text, record, index) => (
        <Input
          value={record.unit_price}
          onChange={e => handleInputChange(index, 'unit_price', e.target.value)}
          style={{ width: '100%' }}
        />
      ),
      width: '200px',
    },
    {
      title: 'THUẾ (%)',
      key: 'tax',
      render: (text, record, index) => (
        <Select
          defaultValue={0}
          onChange={value => handleInputChange(index, 'tax', value)}
          style={{ width: '100%' }}>
          {[0, 3, 5, 8, 10].map(tax => (
            <Option key={tax} value={tax}>
              {tax}%
            </Option>
          ))}
        </Select>
      ),
      width: '150px',
    },
    {
      title: 'TIỀN THUẾ',
      key: 'taxTotal',
      render: (text, record, index) => (
        <Input disabled value={record.taxTotal} />
      ),
      width: '200px',
    },
    {
      title: 'THÀNH TIỀN',
      key: 'amount',
      render: (text, record, index) => <Input disabled value={amount[index]} />,
      width: '250px',
    },
    {
      title: '',
      key: '-',
      render: (text, record, index) => (
        <Button
          icon={<DeleteOutlined />}
          onClick={() => handleRemoveRow(index)}
        />
      ),
      width: '50px',
    },
  ]

  const handleClose = () => {
    setOpen(false)
    form.resetFields()
    setExcelData({})
    setFilteredItems(inventoryItemList)
    const newData = [
      {
        key: 1,
        item_id: '',
        item_code: '',
        item_name: '',
        unit_id: '',
        quantity: '',
        unit_price: '',
        tax: '',
        taxTotal: undefined,
        itemTotal: undefined,
      },
    ]
    setData(newData)
    setAmount([])
    setTotalAmount(0)
  }

  const handleSubmit = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      await form.validateFields()
      const values = form.getFieldsValue()

      const invoiceNo = values.invoice_no
      const response = await importStore.checkExistInvoiceNo(invoiceNo)
      if (response.data) {
        message.error('Số phiếu nhập đã tồn tại!')
        return
      }

      const invalidRows = data.filter((row, index) => {
        const errors = []
        if (!row.item_id) errors.push('Thiếu mã sản phẩm')
        if (!row.unit_id) errors.push('Thiếu đơn vị tính')
        if (!row.quantity || parseFloat(row.quantity) <= 0)
          errors.push('Số lượng không hợp lệ')
        if (
          row?.unit_price === undefined ||
          row?.unit_price === '' ||
          row?.unit_price === null
        )
          errors.push('Thiếu đơn giá')
        if (!row.tax) errors.push('Thiếu thuế')

        if (errors.length > 0) {
          message.error(`Dòng ${index + 1}: ${errors.join(', ')}`)
          return true
        }
        return false
      })

      if (invalidRows.length > 0) {
        return
      }

      const tableData = data.map(row => ({
        item_id: row.item_id,
        item_code: row.item_code,
        item_name: row.item_name,
        unit_id: row.unit_id,
        quantity: row.quantity,
        unit_price: row.unit_price,
        tax: row.tax,
      }))

      const payload = {
        master: { ...values, total_amount: totalAmount },
        details: tableData,
      }

      await importStore.createImportInvoice(payload)
      message.success('Thêm đơn nhập kho thành công!')

      var supplierNameNew = supplierList?.find(
        x => x.id == payload.master?.supplier_id
      )?.supplier_name

      var supplierCodeNew = supplierList?.find(
        x => x.id == payload.master?.supplier_id
      )?.supplier_code

      payload.master.supplier_name = supplierNameNew
      payload.master.supplier_code = supplierCodeNew

      const tableNew = data.map(row => ({
        item_id: row.item_id,
        item_name: row.item_name,
        item_code: row.item_code,
        unit_id: row.unit_id,
        unit_name: unitList?.find(x => x.id == row.unit_id)?.unit_name,
        quantity: row.quantity,
        unit_price: row.unit_price,
        tax: row.tax,
      }))

      var auditLogRequest = {
        table_name: 'Nhập kho',
        action: 'Thêm',
        oldObj: null,
        newObj: payload.master,
        oldDetails: null,
        newDetails: tableNew,
      }
      await importStore.addAuditLog(auditLogRequest)

      await fetchImportInvoice()
      await handleClose()
    } catch (errorInfo) {
      console.error(errorInfo)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <Modal
      title={'PHIẾU NHẬP KHO'}
      width={'100vw'}
      className="modalImportStyle"
      visible={open}
      onCancel={handleClose}
      style={{
        top: '0px',
        maxWidth: '100vw',
        height: '100vh',
        paddingBottom: '0px',
        background: '#fff',
      }}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Hủy
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Thêm
        </Button>,
      ]}>
      <Form
        form={form}
        scrollToFirstError={true}
        name={'create-import-invoice'}
        layout={'vertical'}>
        <div
          style={{
            padding: '20px 24px 0px 24px',
            background: '#eceef1',
          }}>
          <Row type={'flex'} gutter={30}>
            <Col xs={24} md={8}>
              <Form.Item
                label={'Số phiếu nhập'}
                name={'invoice_no'}
                rules={[
                  { required: true, message: ' Vui lòng nhập số phiếu nhập!' },
                ]}>
                <Input
                  allowClear
                  maxLength={50}
                  placeholder={'Nhập số phiếu nhập'}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label={'Ngày đặt hàng'}
                name={'invoice_date'}
                rules={[
                  { required: true, message: ' Vui lòng chọn ngày đặt hàng!' },
                ]}>
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày đặt hàng"
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              md={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'end',
              }}>
              <Title level={2} code style={{ color: '#000' }}>
                THÀNH TIỀN
              </Title>
              <Text strong style={{ fontSize: '20px', marginBottom: '0px' }}>
                {formatNumber(totalAmount)} VNĐ
              </Text>
            </Col>
          </Row>
          <Row type={'flex'} gutter={30} style={{ paddingBottom: '20px' }}>
            <Col xs={24} md={8}>
              <Form.Item
                label={'Nhà cung cấp'}
                name={'supplier_id'}
                rules={[
                  { required: true, message: ' Vui lòng chọn nhà cung cấp!' },
                ]}>
                <Select allowClear placeholder="Chọn nhà cung cấp">
                  {supplierList.map(supplier => (
                    <Option
                      name={supplier.supplier_name}
                      value={supplier.id}
                      key={supplier.id}>
                      {supplier.supplier_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item label={'Ghi chú'} name={'note'}>
                <Input
                  allowClear
                  maxLength={255}
                  placeholder={'Nhập ghi chú'}
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              md={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'end',
              }}>
              <Title level={2} code style={{ color: '#000' }}>
                NGÀY NHẬN
              </Title>
              <Text
                strong
                style={{
                  fontSize: '14px',
                  fontStyle: 'italic',
                  marginBottom: '0px',
                }}>
                Chưa có
              </Text>
            </Col>
          </Row>
        </div>
        <div
          style={{
            padding: '12px 24px 24px',
          }}>
          <ImportTable>
            <Table columns={columns} dataSource={data} pagination={false} />
          </ImportTable>
          <div style={{ marginTop: '10px' }}>
            <Button
              key="themDong"
              onClick={() => {
                fetchData()
                handleAddRow()
              }}
              style={{ marginRight: '10px' }}>
              Thêm dòng
            </Button>
            <Button key="xoaTatCa" onClick={handleDeleteAll}>
              Xóa tất cả
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default memo(
  inject(
    'supplierStore',
    'unitStore',
    'inventoryItemStore',
    'importStore',
    'loadingAnimationStore'
  )(observer(ImportCreateModal))
)
