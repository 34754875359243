import { action, observable, observe } from 'mobx'
import { SettingRequest } from '../requests/SettingRequest'

class SettingStore {
  createSetting(request) {
    return new Promise((resolve, reject) => {
      SettingRequest.createSetting(request)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  getSetting() {
    return new Promise((resolve, reject) => {
      SettingRequest.getSetting()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new SettingStore()
