import React, { memo, useEffect, useState, useCallback } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import {
  Table,
  Pagination,
  Button,
  Modal,
  Form,
  Input,
  Col,
  Row,
  message,
} from 'antd'
import { TableWrapper } from '../../components/Common/Table'
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'

import { DepartmentTable, SearchBar } from './DepartmentPageStyled'
import { EmptyText } from '../../components/Common/CellText'
import { Container } from '../../layouts/Container/Container'
import * as signalR from '@microsoft/signalr'
import { apiUrl } from '../../config'
import { PermissionType } from '../../enum'

const { Search } = Input
const { confirm } = Modal

const DepartmentPage = props => {
  const { departmentStore, loadingAnimationStore, accountStore } = props
  const [departmentData, setDepartmentData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [form] = Form.useForm()
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null)

  const isAccessPermission = permissionType => {
    if (accountStore.hasPermission(permissionType)) return true
    else return false
  }

  const fetchDepartmentData = useCallback(async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await departmentStore.getDepartment(
        page,
        pageSize,
        searchValue
      )
      const { pageData, totalCount } = response.data
      const data = pageData.map((item, index) => ({
        key: item.id,
        stt: (page - 1) * pageSize + index + 1,
        department_code: item.department_code,
        department_name: item.department_name,
        created_by: item.created_by,
        created_at: item.created_at,
        modified_by: item.modified_by,
        modified_at: item.modified_at,
        deleted: item.deleted,
        id: item.id,
        is_active: item.is_active,
      }))
      setDepartmentData(data)
      setTotal(totalCount)
    } catch (error) {
      message.error('Đã có lỗi xảy ra trong quá trình lấy dữ liệu')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [departmentStore, loadingAnimationStore, page, pageSize, searchValue])

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiUrl}/departmentHub`)
      .configureLogging(signalR.LogLevel.None)
      .withAutomaticReconnect()
      .build()

    connection.on('DepartmentSignal', () => {
      fetchDepartmentData()
    })

    connection
      .start()
      .then(() => {
        fetchDepartmentData()
      })
      .catch(() => {
        fetchDepartmentData()
      })

    return () => {
      connection.stop()
    }
  }, [fetchDepartmentData])

  const openModalWithRecord = (record = null) => {
    if (record) {
      setSelectedDepartmentId(record.id)
      form.setFieldsValue({
        department_code: record.department_code,
        department_name: record.department_name,
      })
    } else {
      setSelectedDepartmentId(null)
      form.resetFields()
    }
    setIsModalOpen(true)
  }

  const handleDeleteDepartment = async record => {
    try {
      // Perform the deletion of the department
      await departmentStore.deleteDepartment(record.id)

      // Prepare the log data
      const logData = {
        table_name: 'Phòng ban',
        action: 'Xóa',
        oldObj: {
          department_code: record.department_code,
          department_name: record.department_name,
        },
        newObj: {
          department_code: '',
          department_name: '',
        }, // No new object since it's a deletion
      }

      // Send the log data to the API
      await departmentStore.addLog(logData)

      // Fetch updated department data
      fetchDepartmentData()

      // Show success message
      message.success('Xóa phòng ban thành công')
    } catch (error) {
      message.error('Thao tác thất bại. ' + error)
    }
  }

  const handleModalCancel = () => {
    setIsModalOpen(false)
    form.resetFields()
    setSelectedDepartmentId(null)
  }

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields()
      let actionType = ''
      let oldData = null

      if (selectedDepartmentId) {
        values.id = selectedDepartmentId
        // Fetch the old data before updating
        var oldDataRes = await departmentStore.getbyId(selectedDepartmentId)
        oldData = oldDataRes.data
        console.log(oldData)
        await departmentStore.updateDepartment(values)
        actionType = 'Chỉnh sửa'
        message.success('Cập nhật phòng ban thành công')
      } else {
        // Initialize oldData if it's null
        if (!oldData) {
          oldData = {
            department_code: '',
            department_name: '',
          }
        }
        await departmentStore.createDepartment(values)
        message.success('Tạo phòng ban thành công')
        actionType = 'Tạo mới'
      }

      // Prepare the log data
      const logData = {
        table_name: 'Phòng ban',
        action: actionType,
        oldObj: oldData,
        newObj: values,
      }

      // Send the log data to the API
      await departmentStore.addLog(logData)

      setIsModalOpen(false)
      form.resetFields()
      setSelectedDepartmentId(null)
      fetchDepartmentData()
    } catch (error) {}
  }

  const columns = [
    {
      title: 'STT',
      align: 'center',
      width: '50px',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'MÃ PHÒNG BAN',
      key: 'department_code',
      dataIndex: 'department_code',
      width: '20%',
      sorter: (a, b) => a.department_code.localeCompare(b.department_code),
      render: text => (
        <span>{text || <EmptyText>Chưa có mã phòng ban</EmptyText>}</span>
      ),
    },
    {
      title: 'TÊN PHÒNG BAN',
      key: 'department_name',
      dataIndex: 'department_name',
      sorter: (a, b) => a.department_name.localeCompare(b.department_name),
      render: text => (
        <span>{text || <EmptyText>Chưa có tên phòng ban</EmptyText>}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      width: 1,
      render: (text, record) => (
        <div style={{ display: 'flex' }}>
          {isAccessPermission(PermissionType.DEPARTMENT_UPDATE) && (
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              size="small"
              onClick={() => openModalWithRecord(record)}></Button>
          )}
          {isAccessPermission(PermissionType.DEPARTMENT_DELETE) && (
            <Button
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
              style={{ marginLeft: 8 }}
              size="small"
              onClick={() => {
                confirm({
                  title: 'Bạn có chắc chắn muốn xóa phòng ban này không?',
                  icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
                  okText: 'Đồng ý',
                  cancelText: 'Không',
                  okType: 'danger',
                  onOk() {
                    handleDeleteDepartment(record)
                  },
                })
              }}></Button>
          )}
        </div>
      ),
    },
  ]

  return (
    <DashboardLayout>
      <Helmet>
        <title>Quản lý phòng ban</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title="Quản lý phòng ban"
        hiddenGoBack></PageTitle>
      <ContentBlockWrapper>
        <Container>
          <div
            style={{
              marginBottom: 0,
              height: '38px',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <div>
              <SearchBar>
                <Search
                  allowClear
                  onSearch={value => {
                    setPage(1), setPageSize(10), setSearchValue(value)
                  }}
                  maxLength={500}
                  placeholder={'Tìm kiếm theo Mã Phòng Ban / Tên Phòng Ban'}
                />
              </SearchBar>
            </div>
            <div>
              {isAccessPermission(PermissionType.DEPARTMENT_CREATE) && (
                <Button type="primary" onClick={() => openModalWithRecord()}>
                  Thêm
                </Button>
              )}
            </div>
          </div>
          <TableWrapper>
            <DepartmentTable>
              <Table
                style={{
                  height: 'calc(100vh - 213px)',
                  overflowY: 'auto',
                }}
                columns={columns}
                dataSource={departmentData}
                bordered
                pagination={false}
              />
            </DepartmentTable>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div>
                Tổng số: <b>{total}</b> bản ghi
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '8px',
                }}>
                <Pagination
                  onChange={(page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                  }}
                  current={page}
                  pageSize={pageSize}
                  total={total}
                  hideOnSinglePage={true}
                  showSizeChanger={true}
                  showLessItems
                />
              </div>
            </div>
          </TableWrapper>
        </Container>
      </ContentBlockWrapper>
      <Modal
        title={
          selectedDepartmentId ? 'Chỉnh sửa phòng ban' : 'Thêm phòng ban mới'
        }
        visible={isModalOpen}
        okText={selectedDepartmentId ? 'Cập nhật' : 'Thêm'}
        onCancel={handleModalCancel}
        onOk={handleModalOk}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="department_code"
            label="Mã Phòng Ban"
            rules={[{ required: true, message: 'Vui lòng nhập mã phòng ban' }]}>
            <Input
              placeholder="Nhập mã phòng ban"
              disabled={!!selectedDepartmentId}
            />
          </Form.Item>
          <Form.Item
            name="department_name"
            label="Tên Phòng Ban"
            rules={[
              { required: true, message: 'Vui lòng nhập tên phòng ban' },
            ]}>
            <Input placeholder="Nhập tên phòng ban" />
          </Form.Item>
        </Form>
      </Modal>
    </DashboardLayout>
  )
}

export default memo(
  inject(
    'departmentStore',
    'loadingAnimationStore',
    'accountStore'
  )(observer(DepartmentPage))
)
