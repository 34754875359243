import axios from 'axios'
import { apiUrl } from '../config'

export const CampusRequest = {
  getCampus: (index, size, search) =>
    axios.post(
      `${apiUrl}/api/v1/campuses/get-all-campuses/`,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  getCampusById: id =>
    axios.get(`${apiUrl}/api/v1/campuses/get-campus-by-id?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getCampusPublic: () =>
    axios.get(`${apiUrl}/api/v1/campuses/public`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  createCampus: data =>
    axios.post(`${apiUrl}/api/v1/campuses/create-campus`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  updateCampus: data =>
    axios.post(`${apiUrl}/api/v1/campuses/update-campus`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  deactiveCampus: id =>
    axios.post(
      `${apiUrl}/api/v1/campuses/deactive-campus`,
      {
        id: id,
      },
      {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      }
    ),

  activeCampus: id =>
    axios.post(
      `${apiUrl}/api/v1/campuses/active-campus`,
      {
        id: id,
      },
      {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      }
    ),
}
