import { action } from 'mobx'
import { AuditLogRequest } from '../requests/AuditLogRequest'

class auditLogStore {
  @action getAuditLogs(index, size, search, filters) {
    return new Promise((resolve, reject) => {
      AuditLogRequest.getAuditLogs(index, size, search, filters)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getAuditLogDetail(id) {
    return new Promise((resolve, reject) => {
      AuditLogRequest.getAuditLogDetailById(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
export default new auditLogStore()
