// Datetime
import React from 'react'

export const ISO_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT_DEFAULT = 'DD-MM-YYYY'
export const DATE_FORMAT_SLASH = 'DD/MM/YYYY'
export const DATE_FORMAT_LIST = [
  'DD/MM/YYYY',
  'D/M/YY',
  'DD/MM/YY',
  'D/M/YYYY',
  'D M YY',
  'DDMMYYYY',
]
export const TIME_FORMAT_LIST = ['HH:mm', 'H:m', 'H m']
export const HHhMM = 'HH[h]mm'
export const DD_MM = 'DD-MM'
export const MM_YYYY = 'MM/YYYY'
export const HH_mm = 'HH:mm'
export const YYYY_MM_DD_HH_mm = 'YYYY-MM-DD HH:mm'
