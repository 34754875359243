import React, { memo, useCallback, useEffect, useState } from 'react'
import { Menu, Tooltip } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import {
  HomeOutlined,
  GroupOutlined,
  FileTextOutlined,
  MenuOutlined,
  SolutionOutlined,
} from '@ant-design/icons'
import { SiderbarWrapper } from './MainSidebarStyled'
import { PermissionType } from '../../enum'

const MainSidebar = props => {
  const { location, commonStore, accountStore } = props
  const { openedSubMenu, setOpenedSubMenu, collapsedMenu } = commonStore
  const [isAccessWarehouse, setIsAccessWarehouse] = useState(false)
  const [isAccessCategory, setIsAccessCategory] = useState(false)
  const [isAccessAdministration, setIsAccessAdministration] = useState(false)

  useEffect(() => {
    accountStore
      .getAccountInfo()
      .then(res => {
        const permissions = res.data.granted_permissions
        if (
          permissions.some(permission =>
            [
              'INWARD_INVENTORY.VIEW',
              'INWARD_INVENTORY.APPROVE',
              'OUTWARD_INVENTORY.VIEW',
            ].includes(permission)
          )
        ) {
          setIsAccessWarehouse(true)
        }
        if (
          permissions.some(permission =>
            [
              'SUPPLIER.VIEW',
              'DEPARTMENT.VIEW',
              'UNIT.VIEW',
              'ITEM.VIEW',
            ].includes(permission)
          )
        ) {
          setIsAccessCategory(true)
        }
        if (
          permissions.some(permission =>
            ['CAMPUS.VIEW', 'ACCOUNT.VIEW'].includes(permission)
          )
        ) {
          setIsAccessAdministration(true)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const isAccessPermission = permissionType => {
    if (accountStore.hasPermission(permissionType)) return true
    else return false
  }

  // Menu trang chủ
  const menuTrangChu = (
    <Menu.Item key={'/dashboard'} icon={<HomeOutlined />}>
      <Link to={'/dashboard'}>Trang chủ</Link>
    </Menu.Item>
  )

  // Menu kho
  const menuKho = (
    <Menu.SubMenu key={'warehouse'} icon={<GroupOutlined />} title="Kho">
      {isAccessPermission(PermissionType.INWARD_INVENTORY_VIEW) && (
        <Menu.Item key={'/warehouse/import'}>
          <Link to={'/warehouse/import'}>Nhập kho</Link>
        </Menu.Item>
      )}

      {isAccessPermission(PermissionType.INWARD_INVENTORY_APPROVE) && (
        <Menu.Item key={'/warehouse/approval'}>
          <Link to={'/warehouse/approval'}>Duyệt phiếu nhập kho</Link>
        </Menu.Item>
      )}

      {isAccessPermission(PermissionType.OUTWARD_INVENTORY_VIEW) && (
        <Menu.Item key={'/warehouse/export'}>
          <Link to={'/warehouse/export'}>Xuất kho tiêu hao</Link>
        </Menu.Item>
      )}

      {isAccessPermission(PermissionType.OUTWARD_INVENTORY_VIEW) && (
        <Menu.Item key={'/warehouse/nonbusinessexport'}>
          <Link to={'/warehouse/nonbusinessexport'}>
            Xuất kho phi kinh doanh
          </Link>
        </Menu.Item>
      )}
      {isAccessPermission(PermissionType.INWARD_INVENTORY_VIEW) && (
        <Menu.Item key={'/warehouse/invoice'}>
          <Link to={'/warehouse/invoice'}>Hóa đơn</Link>
        </Menu.Item>
      )}
      {isAccessPermission(PermissionType.INWARD_INVENTORY_VIEW) && (
        <Menu.Item key={'/warehouse/broken-items'}>
          <Link to={'/warehouse/broken-items'}>Vật tư tồn hỏng</Link>
        </Menu.Item>
      )}
    </Menu.SubMenu>
  )

  // Menu báo cáo
  const menuBaoCao = (
    <Menu.SubMenu key={'report'} icon={<FileTextOutlined />} title="Báo cáo">
      <Menu.Item key={'/report/inventory'}>
        <Link to={'/report/inventory'}>Tồn kho</Link>
      </Menu.Item>
      <Menu.Item key={'/report/departmental-cost'}>
        <Link to={'/report/departmental-cost'}>Chi phí các phòng ban</Link>
      </Menu.Item>
      <Menu.Item key={'/report/items-by-departments'}>
        <Tooltip title={'Chi tiết mặt hàng theo phòng ban'}>
          <Link to={'/report/items-by-departments'}>
            Chi tiết mặt hàng theo phòng ban
          </Link>
        </Tooltip>
      </Menu.Item>
      <Menu.Item key={'/report/departments-by-items'}>
        <Tooltip title={'Chi tiết phòng ban theo mặt hàng'}>
          <Link to={'/report/departments-by-items'}>
            Chi tiết phòng ban theo mặt hàng
          </Link>
        </Tooltip>
      </Menu.Item>
    </Menu.SubMenu>
  )

  // Menu danh mục
  const menuDanhMuc = (
    <Menu.SubMenu key={'category'} icon={<MenuOutlined />} title="Danh mục">
      {isAccessPermission(PermissionType.SUPPLIER_VIEW) && (
        <Menu.Item key={'/category/supplier'}>
          <Link to={'/category/supplier'}>Nhà cung cấp</Link>
        </Menu.Item>
      )}
      {isAccessPermission(PermissionType.DEPARTMENT_VIEW) && (
        <Menu.Item key={'/category/department'}>
          <Link to={'/category/department'}>Phòng ban</Link>
        </Menu.Item>
      )}
      {isAccessPermission(PermissionType.UNIT_VIEW) && (
        <Menu.Item key={'/category/unit'}>
          <Link to={'/category/unit'}>Đơn vị</Link>
        </Menu.Item>
      )}

      {isAccessPermission(PermissionType.ITEM_VIEW) && (
        <Menu.Item key={'/category/item'}>
          <Link to={'/category/item'}>Vật tư hàng hóa</Link>
        </Menu.Item>
      )}
      <Menu.Item key={'/category/group'}>
        <Link to={'/category/group'}>Nhóm hàng hóa</Link>
      </Menu.Item>
    </Menu.SubMenu>
  )

  // Menu quản trị
  const menuQuanTri = (
    <Menu.SubMenu
      key={'administration'}
      icon={<SolutionOutlined />}
      title="Quản trị">
      {isAccessPermission(PermissionType.CAMPUS_VIEW) && (
        <Menu.Item key={'/administration/campus'}>
          <Link to={'/administration/campus'}>Cơ sở</Link>
        </Menu.Item>
      )}
      {isAccessPermission(PermissionType.ACCOUNT_VIEW) && (
        <Menu.Item key={'/administration/account'}>
          <Link to={'/administration/account'}>Tài khoản và vai trò</Link>
        </Menu.Item>
      )}
    </Menu.SubMenu>
  )

  const onSubMenuToggle = useCallback(keys => {
    setOpenedSubMenu(keys)
  }, [])

  const onClickMenuItem = ({ keyPath }) => {
    setOpenedSubMenu([keyPath[1]])
  }

  useEffect(() => {
    // Trang chủ
    if (location.pathname.includes('/dashboard')) {
      commonStore.setPage(['/dashboard'])
      setOpenedSubMenu([])
      return
    }

    // Kho
    if (
      location.pathname.includes('/warehouse/import') ||
      location.pathname.includes('/warehouse/import/')
    ) {
      commonStore.setPage(['/warehouse/import'])
      setOpenedSubMenu(['warehouse'])
      return
    }
    if (
      location.pathname.includes('/warehouse/approval') ||
      location.pathname.includes('/warehouse/approval/')
    ) {
      commonStore.setPage(['/warehouse/approval'])
      setOpenedSubMenu(['warehouse'])
      return
    }
    if (
      location.pathname.includes('/warehouse/nonbusinessexport') ||
      location.pathname.includes('/warehouse/nonbusinessexport/')
    ) {
      commonStore.setPage(['/warehouse/nonbusinessexport'])
      setOpenedSubMenu(['warehouse'])
      return
    }
    if (
      location.pathname.includes('/warehouse/export') ||
      location.pathname.includes('/warehouse/export/')
    ) {
      commonStore.setPage(['/warehouse/export'])
      setOpenedSubMenu(['warehouse'])
      return
    }
    if (
      location.pathname.includes('/warehouse/invoice') ||
      location.pathname.includes('/warehouse/invoice/')
    ) {
      commonStore.setPage(['/warehouse/invoice'])
      setOpenedSubMenu(['warehouse'])
      return
    }
    if (
      location.pathname.includes('/warehouse/broken-items') ||
      location.pathname.includes('/warehouse/broken-items/')
    ) {
      commonStore.setPage(['/warehouse/broken-items'])
      setOpenedSubMenu(['warehouse'])
      return
    }

    // Báo cáo
    if (
      location.pathname.includes('/report/inventory') ||
      location.pathname.includes('/report/inventory/')
    ) {
      commonStore.setPage(['/report/inventory'])
      setOpenedSubMenu(['report'])
      return
    }
    if (
      location.pathname.includes('/report/departmental-cost') ||
      location.pathname.includes('/report/departmental-cost/')
    ) {
      commonStore.setPage(['/report/departmental-cost'])
      setOpenedSubMenu(['report'])
      return
    }
    if (
      location.pathname.includes('/report/items-by-departments') ||
      location.pathname.includes('/report/items-by-departments/')
    ) {
      commonStore.setPage(['/report/items-by-departments'])
      setOpenedSubMenu(['report'])
      return
    }
    if (
      location.pathname.includes('/report/departments-by-items') ||
      location.pathname.includes('/report/departments-by-items')
    ) {
      commonStore.setPage(['/report/departments-by-items'])
      setOpenedSubMenu(['report'])
      return
    }

    // Danh mục
    if (
      location.pathname.includes('/category/supplier') ||
      location.pathname.includes('/category/supplier/')
    ) {
      commonStore.setPage(['/category/supplier'])
      setOpenedSubMenu(['category'])
      return
    }
    if (
      location.pathname.includes('/category/department') ||
      location.pathname.includes('/category/department/')
    ) {
      commonStore.setPage(['/category/department'])
      setOpenedSubMenu(['category'])
      return
    }
    if (
      location.pathname.includes('/category/unit') ||
      location.pathname.includes('/category/unit/')
    ) {
      commonStore.setPage(['/category/unit'])
      setOpenedSubMenu(['category'])
      return
    }
    if (
      location.pathname.includes('/category/item') ||
      location.pathname.includes('/category/item/')
    ) {
      commonStore.setPage(['/category/item'])
      setOpenedSubMenu(['category'])
      return
    }

    if (
      location.pathname.includes('/category/group') ||
      location.pathname.includes('/category/group/')
    ) {
      commonStore.setPage(['/category/group'])
      setOpenedSubMenu(['category'])
      return
    }

    // Quản trị
    if (
      location.pathname.includes('/administration/campus') ||
      location.pathname.includes('/administration/campus/')
    ) {
      commonStore.setPage(['/administration/campus'])
      setOpenedSubMenu(['administration'])
      return
    }
    if (
      location.pathname.includes('/administration/account') ||
      location.pathname.includes('/administration/account/')
    ) {
      commonStore.setPage(['/administration/account'])
      setOpenedSubMenu(['administration'])
      return
    }

    commonStore.setPage([location.pathname])
  }, [location.pathname])

  return (
    <SiderbarWrapper>
      <Menu
        mode="inline"
        selectedKeys={commonStore.pageName}
        openKeys={openedSubMenu}
        // openKeys={['warehouse', 'report', 'category', 'administration']}
        onOpenChange={onSubMenuToggle}
        inlineCollapsed={!collapsedMenu}
        onClick={onClickMenuItem}>
        {menuTrangChu}
        {isAccessWarehouse && menuKho}
        {isAccessWarehouse && menuBaoCao}
        {isAccessCategory && menuDanhMuc}
        {isAccessAdministration && menuQuanTri}
      </Menu>
    </SiderbarWrapper>
  )
}

export default memo(
  withRouter(
    inject(
      'accountStore',
      'commonStore',
      'loadingAnimationStore'
    )(observer(MainSidebar))
  )
)
