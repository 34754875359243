import styled from 'styled-components'

export const TabWrapper = styled.div`
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 8px 0;
  }

  .ant-tabs-tab {
    padding: 4px 0 4px 0;
  }

  .ant-tabs-content-holder {
    height: calc(100vh - 142px);
  }
`
