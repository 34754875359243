import { action } from 'mobx'
import { ExportRequest } from '../requests/ExportRequest'

class ExportStore {
  @action getExportInvoice = (index, size, search, filters) => {
    return new Promise((resolve, reject) => {
      ExportRequest.getExportInvoice(index, size, search, filters)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getPagingFilterCustom = (
    index,
    size,
    search,
    filters,
    custom_type
  ) => {
    return new Promise((resolve, reject) => {
      ExportRequest.getPagingFilterCustom(
        index,
        size,
        search,
        filters,
        custom_type
      )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getExportPrinter = (index, size, search) => {
    return new Promise((resolve, reject) => {
      ExportRequest.getExportPrinter(index, size, search)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action updateStatus = (id, status) => {
    return new Promise((resolve, reject) => {
      ExportRequest.updateStatus(id, status)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getOnlyDetails = id => {
    return new Promise((resolve, reject) => {
      ExportRequest.getOnlyDetails(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteExportInvoice = id => {
    return new Promise((resolve, reject) => {
      ExportRequest.deleteExportInvoice(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createExportInvoice = data => {
    return new Promise((resolve, reject) => {
      ExportRequest.createExportInvoice(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action returnInventoryItem = data => {
    return new Promise((resolve, reject) => {
      ExportRequest.returnInventoryItem(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createExportPrinterStore = data => {
    return new Promise((resolve, reject) => {
      ExportRequest.createExportPrinter(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action checkExistInvoiceNo = inv_no => {
    return new Promise((resolve, reject) => {
      ExportRequest.checkExistInvoiceNo(inv_no)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getExportInvoiceDetail = id => {
    return new Promise((resolve, reject) => {
      ExportRequest.getExportInvoiceDetail(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getExportPrinterDetail = id => {
    return new Promise((resolve, reject) => {
      ExportRequest.getExportPrinterDetail(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateExportInvoice = data => {
    return new Promise((resolve, reject) => {
      ExportRequest.updateExportInvoice(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateExportPrinter = data => {
    return new Promise((resolve, reject) => {
      ExportRequest.updateExportPrinter(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getTotalCostPerYear = year => {
    return new Promise((resolve, reject) => {
      ExportRequest.getTotalCostPerYear(year)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action addAuditLog = request => {
    return new Promise((resolve, reject) => {
      ExportRequest.addAuditLog(request)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action approveOrder = params => {
    return new Promise((resolve, reject) => {
      ExportRequest.approveOrder(params)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new ExportStore()
