import React, { memo, useCallback, useEffect, useState } from 'react'
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Table,
  Typography,
  message,
  Tabs,
  Checkbox,
  Switch,
  Modal,
} from 'antd'
import { inject, observer } from 'mobx-react'
import './AccountPageStyled.css'
import { SearchBar } from '../ImportPage/ImportPageStyled'

const { Search } = Input

const RoleModal = props => {
  const {
    open,
    handleClose,
    handleSubmit,
    roleId,
    accountStore,
    loadingAnimationStore,
    roleStore,
    setRoleId,
  } = props

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedAccountIds, setSelectedAccountIds] = useState([])
  const [search, setSearch] = useState('')
  const [accounts, setAccounts] = useState([])
  const [form] = Form.useForm()

  const fetchAccountsData = useCallback(async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await accountStore.getAllActiveAccounts({keyword: search})
      const data = response.data.map((item, index) => ({
        key: item.id,
        username: item.username,
        email: item.email,
        id: item.id,
        campus: item.campus,
      }))
      setAccounts(data)
    } catch (error) {
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [accountStore, loadingAnimationStore, search])

  const fetchRoleData = useCallback(
    async roleId => {
      loadingAnimationStore.showSpinner(true)
      try {
        const res = await roleStore.getRole(roleId).then(res => {
          const roleData = res.data
          form.setFieldsValue({
            role_code: roleData.role_code,
            role_name: roleData.role_name,
            description: roleData.description,
          })
          updatePermissions(roleData.permissions)
          const accountIds = roleData.accounts.map(a => a.id)
          setSelectedRowKeys(accountIds)
        })
      } catch (error) {
        message.error('Có lỗi xảy ra')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    },
    [roleStore, loadingAnimationStore, roleId]
  )

  useEffect(() => {
    const fetchData = async roleId => {
      if (roleId !== '' && roleId !== undefined && roleId !== null) {
        await fetchRoleData(roleId)
      }else{
        resetPermissions();
      }
    }
    fetchData(roleId)
    fetchAccountsData()
  }, [roleId, fetchRoleData, fetchAccountsData, search])

  const accountColumns = [
    {
      title: 'STT',
      key: 'stt',
      align: 'center',
      width: 20,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Username',
      key: 'username',
      dataIndex: 'username',
      width: 100,
    },
    {
      title: 'E-mail',
      key: 'email',
      dataIndex: 'email',
      width: 120,
    },
    {
      title: 'Cơ sở',
      key: 'campus.campus_name',
      dataIndex: 'campus',
      render: (text, record) => (
        <span>
          {record.campus ? record.campus.campus_name : 'Chưa có dữ liệu'}
        </span>
      ),
    },
  ]

  const [permissions, setPermissions] = useState({
    inventory: [
      {
        key: 'INWARD_INVENTORY',
        resource_name: 'Nhập kho',
        VIEW: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        APPROVE: false,
      },
      {
        key: 'OUTWARD_INVENTORY',
        resource_name: 'Xuất kho',
        VIEW: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        APPROVE: false,
      },
    ],
    category: [
      {
        key: 'SUPPLIER',
        resource_name: 'Nhà cung cấp',
        VIEW: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        APPROVE: false,
      },
      {
        key: 'DEPARTMENT',
        resource_name: 'Phòng ban',
        VIEW: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        APPROVE: false,
      },
      {
        key: 'UNIT',
        resource_name: 'Đơn vị',
        VIEW: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        APPROVE: false,
      },
      {
        key: 'ITEM',
        resource_name: 'Hàng hóa',
        VIEW: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        APPROVE: false,
      },
    ],
    administration: [
      {
        key: 'ACCOUNT',
        resource_name: 'Tài khoản',
        VIEW: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        APPROVE: false,
      },
      {
        key: 'ROLE',
        resource_name: 'Vai trò',
        VIEW: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        APPROVE: false,
      },
    ],
  })

  const [switches, setSwitches] = useState({
    inventory: false,
    category: false,
    administration: false,
  })

  const updateSwitchState = (tabKey, updatedPermissions) => {
    const allChecked = updatedPermissions.every(
      record =>
        record.VIEW &&
        record.CREATE &&
        record.UPDATE &&
        record.DELETE &&
        record.APPROVE
    )
    setSwitches(prevSwitches => ({
      ...prevSwitches,
      [tabKey]: allChecked,
    }))
  }

  const handleCheckboxChange = (tabKey, recordKey, permissionKey) => {
    setPermissions(prevPermissions => {
      const updatedPermissions = prevPermissions[tabKey].map(record =>
        record.key === recordKey
          ? { ...record, [permissionKey]: !record[permissionKey] }
          : record
      )
      updateSwitchState(tabKey, updatedPermissions)
      return {
        ...prevPermissions,
        [tabKey]: updatedPermissions,
      }
    })
  }

  const handleSwitchChange = (tabKey, checked) => {
    setSwitches(prevSwitches => ({
      ...prevSwitches,
      [tabKey]: checked,
    }))
    setPermissions(prevPermissions => ({
      ...prevPermissions,
      [tabKey]: prevPermissions[tabKey].map(record => ({
        ...record,
        VIEW: checked,
        CREATE: checked,
        UPDATE: checked,
        DELETE: checked,
        APPROVE: checked,
      })),
    }))
  }

  const columns = tabKey => [
    {
      title: '',
      dataIndex: 'resource_name',
      align: 'left',
      width: '25%',
      className: 'bold-cell',
    },
    {
      title: 'Toàn quyền',
      key: 'all',
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Checkbox
          checked={
            record.VIEW &&
            record.CREATE &&
            record.UPDATE &&
            record.DELETE &&
            record.APPROVE
          }
          onChange={() => {
            const allChecked = !(
              record.VIEW &&
              record.CREATE &&
              record.UPDATE &&
              record.DELETE &&
              record.APPROVE
            )
            const updatedPermissions = permissions[tabKey].map(item =>
              item.key === record.key
                ? {
                    ...item,
                    VIEW: allChecked,
                    CREATE: allChecked,
                    UPDATE: allChecked,
                    DELETE: allChecked,
                    APPROVE: allChecked,
                  }
                : item
            )
            updateSwitchState(tabKey, updatedPermissions)
            setPermissions(prevPermissions => ({
              ...prevPermissions,
              [tabKey]: updatedPermissions,
            }))
          }}
        />
      ),
    },
    {
      title: 'Xem',
      key: 'VIEW',
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Checkbox
          checked={record.VIEW}
          onChange={() => handleCheckboxChange(tabKey, record.key, 'VIEW')}
        />
      ),
    },
    {
      title: 'Thêm',
      key: 'CREATE',
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Checkbox
          checked={record.CREATE}
          onChange={() => handleCheckboxChange(tabKey, record.key, 'CREATE')}
        />
      ),
    },
    {
      title: 'Sửa',
      key: 'UPDATE',
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Checkbox
          checked={record.UPDATE}
          onChange={() => handleCheckboxChange(tabKey, record.key, 'UPDATE')}
        />
      ),
    },
    {
      title: 'Xóa',
      key: 'DELETE',
      align: 'center',
      width: 100,
      render: (text, record) => (
        <Checkbox
          checked={record.DELETE}
          onChange={() => handleCheckboxChange(tabKey, record.key, 'DELETE')}
        />
      ),
    },
    {
      title: 'Duyệt',
      key: 'APPROVE',
      width: '30%',
      align: 'left',
      render: (text, record) => (
        <Checkbox
          checked={record.APPROVE}
          onChange={() => handleCheckboxChange(tabKey, record.key, 'APPROVE')}
        />
      ),
    },
  ]

  const handleRoleModalOk = () => {
    form
      .validateFields()
      .then(values => {
        const extractedPermissions = []

        Object.values(permissions).forEach(tabPermissions => {
          tabPermissions.forEach(record => {
            const scopes = Object.keys(record).filter(
              key => key !== 'key' && key !== 'resource_name' && record[key]
            )
            if (scopes.length > 0) {
              extractedPermissions.push({
                resourceName: record.key,
                scopes,
              })
            }
          })
        })

        const id = roleId ? roleId : null
        const response = {
          roleCode: values.role_code,
          roleName: values.role_name,
          description: values.description,
          permissions: extractedPermissions,
          accountIds: selectedRowKeys,
        }

        handleSubmit(id, response)    
      })
      .catch(info => {

      })
  }

  const resetPermissions = () => {
    setRoleId('')
    form.resetFields()
    resetSelection()
    setPermissions({
      inventory: permissions.inventory.map(record => ({
        ...record,
        VIEW: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        APPROVE: false,
      })),
      category: permissions.category.map(record => ({
        ...record,
        VIEW: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        APPROVE: false,
      })),
      administration: permissions.administration.map(record => ({
        ...record,
        VIEW: false,
        CREATE: false,
        UPDATE: false,
        DELETE: false,
        APPROVE: false,
      })),
    })

    setSwitches({
      inventory: false,
      category: false,
      administration: false,
    })
  }

  const updatePermissions = existedPermissions => {
    const updatedPermissions = { ...permissions }

    existedPermissions.forEach(item => {
      Object.keys(updatedPermissions).forEach(key => {
        updatedPermissions[key].forEach(resource => {
          if (resource.key === item.resource_name) {
            resource[item.scope] = true
          }
        })
      })
    })

    setPermissions(updatedPermissions)
  }

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys)
    const accountIds = selectedRows.map(row => row.id)
    setSelectedAccountIds(accountIds)
    // console.log(accountIds)
  }

  const resetSelection = () => {
    setSelectedRowKeys([])
    setSelectedAccountIds([])
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  return (
    <Modal
      title={'Vai trò'}
      width={'100vw'}
      className="roleModalStyle"
      visible={open}
      onCancel={() => {
        resetPermissions()
        handleClose()
      }}
      style={{
        top: '0px',
        maxWidth: '100vw',
        height: '100vh',
        paddingBottom: '0px',
        background: '#fff',
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            resetPermissions()
            handleClose()
          }}>
          Hủy
        </Button>,
        <Button key="submit" type="primary" onClick={handleRoleModalOk}>
          Lưu
        </Button>,
      ]}>
      <Form
        form={form}
        scrollToFirstError={true}
        name={'createRoleForm'}
        layout={'vertical'}>
        <div
          style={{
            padding: '0px 24px 0px 24px',
            background: '#ffff',
          }}>
          <Row type={'flex'} gutter={30} style={{ marginBottom: '0px' }}>
            <Col
              xs={24}
              md={4}
              style={{ paddingRight: '12px', paddingLeft: '0px' }}>
              <Form.Item
                label={'Mã vai trò'}
                name={'role_code'}
                rules={[
                  { required: true, message: ' Vui lòng nhập mã vai trò!' },
                ]}>
                <Input
                  disabled={roleId && roleId !== '' && roleId !== undefined}
                  allowClear
                  maxLength={50}
                  placeholder={'Nhập mã vai trò'}
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              md={6}
              style={{ paddingRight: '12px', paddingLeft: '0px' }}>
              <Form.Item
                label={'Tên vai trò'}
                name={'role_name'}
                rules={[
                  { required: true, message: ' Vui lòng nhập tên vai trò!' },
                ]}>
                <Input
                  allowClear
                  maxLength={50}
                  placeholder={'Nhập tên vai trò'}
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              md={14}
              style={{ paddingRight: '12px', paddingLeft: '0px' }}>
              <Form.Item label={'Mô tả'} name={'description'}>
                <Input allowClear maxLength={255} placeholder={'Nhập mô tả'} />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Tabs defaultActiveKey="permission" className="custom-tabs">
          <Tabs.TabPane tab="Quyền hạn của vai trò" key="permission">
            <Tabs
              defaultActiveKey="permission"
              tabPosition="left"
              style={{ borderTop: '1px solid #f0f0f0' }}
              className="custom-tabs">
              <Tabs.TabPane tab="Kho" key="inventory">
                <div className="custom-switch">
                  <label>Toàn quyền</label>
                  <Switch
                    checked={switches.inventory}
                    onChange={checked =>
                      handleSwitchChange('inventory', checked)
                    }
                  />
                </div>
                <Table
                  columns={columns('inventory')}
                  dataSource={permissions.inventory}
                  rowKey="key"
                  bordered
                  pagination={false}
                  className="custom-table"
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Danh mục" key="category">
                <div className="custom-switch">
                  <label>Toàn quyền</label>
                  <Switch
                    checked={switches.category}
                    onChange={checked =>
                      handleSwitchChange('category', checked)
                    }
                  />
                </div>
                <Table
                  columns={columns('category')}
                  dataSource={permissions.category}
                  rowKey="key"
                  bordered
                  pagination={false}
                  className="custom-table"
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Quản trị" key="administration">
                <div className="custom-switch">
                  <label>Toàn quyền</label>
                  <Switch
                    checked={switches.administration}
                    onChange={checked =>
                      handleSwitchChange('administration', checked)
                    }
                  />
                </div>
                <Table
                  columns={columns('administration')}
                  dataSource={permissions.administration}
                  rowKey="key"
                  bordered
                  pagination={false}
                  className="custom-table"
                />
              </Tabs.TabPane>
            </Tabs>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Người dùng có vai trò này" key="role-accounts">
            <Row
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 0,
              }}>
              <Col span={12}>
                <SearchBar>
                  <Search
                    allowClear
                    onSearch={value => {
                      setSearch(value)
                    }}
                    maxLength={500}
                    placeholder={'Tìm kiếm...'}
                  />
                </SearchBar>
              </Col>
            </Row>
            <Table
              columns={accountColumns}
              dataSource={accounts}
              bordered
              pagination={false}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
            />
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  )
}

export default memo(
  inject(
    'accountStore',
    'roleStore',
    'loadingAnimationStore'
  )(observer(RoleModal))
)
