import { action, observable, observe } from 'mobx'
import { RoleRequest } from '../requests/RoleRequest';


class AccountStore {
    getRole(id){
        return new Promise((resolve, reject) => {
            RoleRequest.getRole(id)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    getRoles(index, size, search) {
        return new Promise((resolve, reject) => {
            RoleRequest.getRoles(index, size, search)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    createRole(request) {
        return new Promise((resolve, reject) => {
            RoleRequest.createRole(request)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    updateRole(id, request) {
        return new Promise((resolve, reject) => {
            RoleRequest.updateRole(id, request)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    deleteRole(id){
        return new Promise((resolve, reject) => {
            RoleRequest.deleteRole(id)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    assignAccounts(id, request){
        return new Promise((resolve, reject) => {
            RoleRequest.assignAccounts(id, request)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    @action addLog = data => {
        return new Promise((resolve, reject) => {
          RoleRequest.addLog(data)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      }
}

export default new AccountStore();