import { Form, Input, Modal, Select } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { memo, useCallback, useEffect, useState } from 'react'

const AccountModal = props => {
  const {
    campusStore,
    accountStore,
    isModalOpen,
    handleModalCancel,
    handleModalOk,
  } = props

  const [form] = Form.useForm()
  const [campuses, setCampuses] = useState([])

  const fetchData = useCallback(async () => {
    await campusStore.getCampusPublic().then(res => {
      setCampuses(res.data)
    })
    var res = await accountStore.getCurrentAccount()
    if (res) {
      form.setFieldsValue({
        campusId: res?.campus_id,
      })
    }
  }, [isModalOpen])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleOk = async () => {
    try {
      const values = await form.validateFields()
      await handleModalOk(values)
      form.setFieldsValue({
        email: '',
      })
    } catch (error) {
      console.error('Error during form submission:', error)
      // Bạn có thể hiển thị thông báo lỗi hoặc thực hiện các hành động khác tại đây
    }
  }

  return (
    <>
      <Modal
        title="Thêm tài khoản"
        visible={isModalOpen}
        onCancel={() => {
          handleModalCancel()
          form.resetFields()
        }}
        onOk={handleOk}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              { required: true, message: 'Vui lòng nhập E-mail người dùng' },
              {
                type: 'email',
                message: 'Email không hợp lệ',
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="campusId"
            label="Cơ sở"
            rules={[{ required: true, message: 'Vui lòng chọn cơ sở' }]}>
            <Select
              showSearch
              disabled={true}
              style={{ width: '100%' }}
              placeholder="Chọn cơ sở"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={campuses.map(campus => ({
                value: campus.id,
                label: campus.campus_name,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default memo(
  inject('campusStore', 'accountStore')(observer(AccountModal))
)
