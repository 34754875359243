import React, { memo, useEffect, useState, useRef } from 'react'
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  Typography,
  message,
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import '../ExportPageStyled.css'
import moment from 'moment'
import { ExportTable } from '../ExportPageStyled'
import ItemCreateDrawer from '../../ItemPage/ItemCreateDrawer'
const { Option } = Select
const { Title, Text } = Typography

const ExportPagePrinter = props => {
  const history = useHistory()
  const {
    open,
    setOpen,
    fetchExportInvoice,
    excelData,
    departmentStore,
    unitStore,
    inventoryItemStore,
    exportStore,
    loadingAnimationStore,
    exportId,
    type,
    settingStore,
  } = props
  const [form] = Form.useForm()
  const [departmentList, setDepartmentList] = useState([])
  const [unitList, setUnitList] = useState([])
  const [inventoryItemList, setInventoryItemList] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [filteredInventoryItem, setFilteredInventoryItem] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isAllowChooseItem, setIsAllowChooseItem] = useState(false)
  const [invoiceDate, setInvoiceDate] = useState(undefined)
  const [itemData, setItemData] = useState({
    item_name: '',
    item_code: '',
    key_id: '',
  })
  const handleModalClose = () => {
    setIsModalVisible(false)
  }

  const handleCreateItem = () => {
    // createNewInventoryItem()
    setIsModalVisible(false)
  }

  const handleCreateSuccess = async (formData, status, keyId) => {
    // Thực hiện các hành động cần thiết khi thêm mới thành công ở đây

    if (status) {
      const newData = [...data]
      const lastIndex = newData.length - 1
      const itemSelected = await inventoryItemStore.getInventoryItemByCode(
        formData.item_code
      )
      var row = departmentList.filter(x => x.id == keyId)
      var dataRow = row && row.length > 0 ? row[0] : null
      newData[lastIndex]['item_name'] = itemSelected.data.item_name
      newData[lastIndex]['item_code'] = itemSelected.data.item_code
      newData[lastIndex]['id'] = keyId
      newData[lastIndex]['item_id'] = itemSelected.data.id
      newData[lastIndex]['unit_id'] = itemSelected.data.unit_id

      const selectedUnit = unitList.find(
        x => x.id === itemSelected.data.unit_id
      )
      newData[lastIndex]['unit_name'] = selectedUnit
        ? selectedUnit.unit_name
        : ''
      newData[lastIndex]['quantity'] = 1
      newData[lastIndex]['unit_price'] = itemSelected.data.unit_price
        ? itemSelected.data.unit_price
        : 1
      // newData[lastIndex]['printer_code'] = dataRow ? dataRow.printer_code : null
      // newData[lastIndex]['printer_id'] = dataRow ? dataRow.printer_id : null
      newData[lastIndex]['department_code'] = dataRow
        ? dataRow.department_code
        : null

      newData[lastIndex].itemTotal = itemSelected.data.quantity
        ? itemSelected.data.quantity
        : newData[lastIndex]['quantity']

      const quantity = parseFloat(
        newData[lastIndex]['quantity'].toString() || '0'
      )
      const unitPrice = parseFloat(
        newData[lastIndex]['unit_price'].toString() || '0'
      )
      newData[lastIndex].amount = quantity * unitPrice

      setData(newData)
      setAmount(newData.map(row => row.amount))

      const newTotalAmount = newData.reduce(
        (acc, row) => acc + (row.amount || 0),
        0
      )
      setTotalAmount(newTotalAmount)

      // Đóng drawer
      setIsModalVisible(false)
    }
  }

  useEffect(() => {
    const fetchExcelData = async () => {
      if (excelData && excelData.master && excelData.details) {
        const formValues = {
          invoice_no: excelData.master.invoice_no,
          invoice_date: excelData.master.invoice_date
            ? moment(
                excelData.master.invoice_date,
                'DD-MM-YYYY' || 'DD/MM/YYYY'
              )
            : null,
          id: excelData.master.id,
          note: excelData.master.note,
        }
        form.setFieldsValue(formValues)

        const tableData = excelData.details.map((detail, index) => ({
          key: index + 1,
          id: '',
          department_id: detail.id,
          department_code: detail.department_code,
          department_name: detail.department_name,
          unit_id: detail.unit_id,
          quantity: detail.quantity,
          unit_price: parseFloat(detail.unit_price),
          itemTotal: detail.quantity * detail.unit_price,
        }))
        await setData(tableData)
        await setAmount(tableData.map(row => row.itemTotal))
        await setTotalAmount(
          tableData.reduce((acc, row) => acc + (row.itemTotal || 0), 0)
        )
      }
    }

    fetchExcelData()
  }, [excelData, form])

  const fetchData = async () => {
    departmentStore.getDepartment(1, 9999, '').then(res => {
      setDepartmentList(res.data.pageData)
      setFilteredItems(res.data.pageData)
    })
    unitStore.getUnit(1, 9999, '').then(res => {
      setUnitList(res.data.pageData)
    })

    inventoryItemStore.getInventoryItem(1, 9999, '').then(res => {
      setInventoryItemList(res.data.pageData)
      setFilteredInventoryItem(res.data.pageData)
    })

    settingStore.getSetting().then(response => {
      if (response && response.data && response.data.length > 0) {
        var data = response?.data?.find(x => x.name == 'AllowChooseItemEP')

        if (data) {
          setIsAllowChooseItem(data.is_active)
        }
      }
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (exportId) {
      console.log('Export ID:', exportId)
      exportStore.getExportPrinterDetail(exportId).then(async res => {
        // setOriginalInvoiceNo(res.data.masterDTO.invoice_no)
        setInvoiceDate(res.data.masterDTO.invoice_date)
        // setDepartmentName(res.data.masterDTO.department_name)
        // setNote(res.data.masterDTO.note)
        form.setFieldsValue({
          invoice_no: res.data.masterDTO.invoice_no,
          invoice_date: moment.utc(
            res.data.masterDTO.invoice_date,
            'YYYY-MM-DD'
          ),
          // department_id: res.data.masterDTO.department_id,
          note: res.data.masterDTO.note,
        })

        const detailsWithItemInfo = await Promise.all(
          res.data.details.map(async (detail, index) => {
            try {
              return {
                key: index + 1,
                id: detail.id,
                item_id: detail.item_id,
                item_code: detail.item_code,
                item_name: detail.item_name,
                // printer_code: detail.printer_code,
                // printer_id: detail.printer_id,
                unit_id: detail.unit_id,
                unit_name: detail.unit_name,
                department_name: detail.department_name,
                department_code: detail.department_code,
                department_id: detail.department_id,
                quantity: detail.quantity,
                unit_price: detail.unit_price,
                itemTotal: detail.itemTotal,
                amount: detail.quantity * detail.unit_price,
              }
            } catch (error) {
              console.error(error)
              return {
                key: index + 1,
                id: '',
                item_id: '',
                item_code: '',
                item_name: '',
                // printer_code: '',
                // printer_id: '',
                unit_name: '',
                department_name: '',
                department_code: '',
                department_id: '',
                unit_id: '',
                quantity: '',
                unit_price: '',
                itemTotal: undefined,
                amount: '',
              }
            }
          })
        )

        setData(detailsWithItemInfo)
        setAmount(detailsWithItemInfo.map(row => row.amount))
        const totalAmount = detailsWithItemInfo.reduce(
          (acc, row) => acc + (row.amount || 0),
          0
        )
        setTotalAmount(totalAmount)
      })
    } else {
      // Reset form fields and state values when exportId is null
      form.resetFields()
      setInvoiceDate(null)
      setData([
        {
          key: 1,
          id: '',
          item_id: '',
          item_code: '',
          item_name: '',
          // printer_code: '',
          // printer_id: '',
          unit_id: '',
          unit_name: '',
          department_name: '',
          department_code: '',
          department_id: '',
          quantity: 0,
          unit_price: 0,
          itemTotal: undefined,
        },
      ])
      setAmount([])
      setTotalAmount(0)
    }
  }, [open, exportId])

  const [data, setData] = useState([
    {
      key: 1,
      id: '',
      item_id: '',
      item_code: '',
      item_name: '',
      // printer_code: '',
      // printer_id: '',
      unit_id: '',
      unit_name: '',
      department_name: '',
      department_code: '',
      department_id: '',
      quantity: 0,
      unit_price: 0,
      itemTotal: undefined,
    },
  ])

  const handleAddRow = () => {
    setData([
      ...data,
      {
        key: data ? data.length + 1 : 1,
        id: '',
        item_id: '',
        item_code: '',
        item_name: '',
        // printer_code: '',
        // printer_id: '',
        unit_id: '',
        unit_name: '',
        department_name: '',
        department_code: '',
        department_id: '',
        quantity: 0,
        unit_price: 0,
        itemTotal: undefined,
      },
    ])
    setFilteredItems(departmentList)
  }

  const handleRemoveRow = index => {
    if (data.length === 1) {
      message.warning('Không thể xóa dòng cuối cùng!')
      return
    }

    const newData = data.filter((item, idx) => idx !== index)
    const updatedData = newData.map((item, idx) => ({
      ...item,
      key: idx + 1, // Đánh lại key từ 1
    }))

    setData(updatedData)

    const newTotalAmount = updatedData.reduce(
      (acc, row) => acc + (row.quantity || 0),
      0
    )
    setTotalAmount(newTotalAmount)
  }

  const handleDeleteAll = () => {
    const newData = [
      {
        key: 1,
        id: '',
        department_name: '',
        // printer_id: '',
        // printer_code: '',
        item_id: '',
        item_name: '',
        unit_id: '',
        unit_name: '',
        quantity: 0,
        unit_price: 0,
        itemTotal: undefined,
      },
    ]

    setData(newData)
    setAmount(newData.map(row => row.amount))

    setTotalAmount(1)
  }

  const [amount, setAmount] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)

  const handleInputChange = (index, field, value) => {
    const newData = [...data]

    // Update the field value based on the input
    if (field === 'unit_price' || field === 'quantity') {
      if (/^\d*\.?\d*$/.test(value)) {
        newData[index][field] = value
      }
    } else {
      newData[index][field] = value
    }

    // Calculate amount based on updated quantity and unit_price
    const quantity = parseFloat(newData[index].quantity?.toString() || '0')
    const unitPrice = parseFloat(newData[index].unit_price?.toString() || '0')

    if (!isNaN(quantity) && !isNaN(unitPrice)) {
      newData[index].amount = quantity * unitPrice
    } else {
      newData[index].amount = 0 // or handle invalid input appropriately
    }

    setData(newData)
    setAmount(newData.map(row => row.amount))

    const newTotalAmount = newData.reduce(
      (acc, row) => acc + (row.amount || 0),
      0
    )
    setTotalAmount(newTotalAmount)
  }

  const handleSelectIventoryItem = async (index, value, option) => {
    const newData = [...data]
    newData[index]['item_id'] = value
    newData[index]['item_code'] = option.item_code

    try {
      const itemSelected = await inventoryItemStore.getInventoryItemById(value)

      newData[index]['item_name'] = itemSelected.data.item_name
      newData[index]['unit_id'] = itemSelected.data.unit_id
      newData[index]['unit_name'] = unitList.filter(
        x => x.id == itemSelected.data.unit_id
      )
        ? unitList.filter(x => x.id == itemSelected.data.unit_id)[0].unit_name
        : ''
      newData[index]['quantity'] = 1
      newData[index]['unit_price'] = itemSelected.data.average_price

      newData[index].itemTotal = itemSelected.data.quantity
        ? itemSelected.data.quantity
        : newData[index]['quantity']

      const quantity = parseFloat(newData[index]['quantity'].toString() || '0')
      const unitPrice = parseFloat(
        newData[index]['unit_price'].toString() || '0'
      )
      newData[index].amount = quantity * unitPrice

      setData(newData)
      setAmount(newData.map(row => row.amount))

      const newTotalAmount = newData.reduce(
        (acc, row) => acc + (row.amount || 0),
        0
      )
      setTotalAmount(newTotalAmount)
    } catch (error) {
      console.error('Error fetching item details: ', error)
    }
  }

  const handleSelect = async (index, value, option) => {
    // newData[index]['department_name'] = option.department_code

    try {
      if (isAllowChooseItem) {
        const newData = [...data]
        newData[index]['department_name'] = value
        var row = departmentList.filter(x => x.id == option.key)
        var dataRow = row && row.length > 0 ? row[0] : null

        // newData[index]['printer_code'] = dataRow ? dataRow.printer_code : null
        // newData[index]['printer_id'] = dataRow ? dataRow.printer_id : null
        newData[index]['department_id'] = dataRow ? dataRow.id : null
        newData[index]['department_code'] = dataRow
          ? dataRow.department_code
          : null

        setData(newData)
      } else {
        const itemSelected = await inventoryItemStore.getInventoryItemByCode(
          'GIAYIN'
        )

        if (!itemSelected || (itemSelected && !itemSelected.data)) {
          // Modal.confirm({
          //   title: 'Mặt hàng chưa tồn tại',
          //   content:
          //     'Mặt hàng "Giấy in" chưa tồn tại. Bạn có muốn thêm mới mặt hàng này để tạo Phiếu xuất máy in?',
          //   okText: 'Đồng ý',
          //   cancelText: 'Hủy',
          //   onOk: () => {
          //     setItemData({
          //       item_name: 'Giấy in',
          //       item_code: 'GIAYIN',
          //       key_id: option.key,
          //     })
          //     setIsModalVisible(true)
          //   },
          // })
          message.error(
            'Mặt hàng "Giấy in" chưa tồn tại. Xin vui lòng thêm mới mặt hàng với mã code là <GIAYIN> để tạo Phiếu xuất máy in'
          )
          return // Exit the function if the item does not exist
        }
        const newData = [...data]
        newData[index]['department_name'] = value
        var row = departmentList.filter(x => x.id == option.key)
        var dataRow = row && row.length > 0 ? row[0] : null
        newData[index]['item_name'] = itemSelected.data.item_name
        newData[index]['item_code'] = itemSelected.data.item_code
        newData[index]['id'] = ''
        newData[index]['item_id'] = itemSelected.data.id
        newData[index]['unit_id'] = itemSelected.data.unit_id
        newData[index]['unit_name'] = unitList.filter(
          x => x.id == itemSelected.data.unit_id
        )
          ? unitList.filter(x => x.id == itemSelected.data.unit_id)[0].unit_name
          : ''
        newData[index]['quantity'] = 1
        newData[index]['unit_price'] = itemSelected.data.average_price
          ? itemSelected.data.average_price
          : 0
        // newData[index]['printer_code'] = dataRow ? dataRow.printer_code : null
        // newData[index]['printer_id'] = dataRow ? dataRow.printer_id : null
        newData[index]['department_id'] = dataRow ? dataRow.id : null
        newData[index]['department_code'] = dataRow
          ? dataRow.department_code
          : null

        newData[index].itemTotal = itemSelected.data.quantity
          ? itemSelected.data.quantity
          : newData[index]['quantity']

        const quantity = parseFloat(
          newData[index]['quantity'].toString() || '0'
        )
        const unitPrice = parseFloat(
          newData[index]['unit_price'].toString() || '0'
        )
        newData[index].amount = quantity * unitPrice

        setData(newData)
        setAmount(newData.map(row => row.amount))

        const newTotalAmount = newData.reduce(
          (acc, row) => acc + (row.amount || 0),
          0
        )
        setTotalAmount(newTotalAmount)
      }
    } catch (error) {
      console.error('Error fetching item details: ', error)
    }
  }

  const handleSearch = value => {
    const filtered = departmentList.filter(
      item =>
        item.department_code.toLowerCase().includes(value.toLowerCase()) ||
        item.department_name.toLowerCase().includes(value.toLowerCase())
    )
    setFilteredItems(filtered)
  }
  const selectRef = useRef(null)
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'F1') {
        event.preventDefault()
        if (selectRef.current) {
          selectRef.current.focus()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      render: (text, record, index) => <span>{index + 1}</span>,
      width: '20px',
    },
    {
      title: 'TÊN PHÒNG BAN',
      key: 'department_id',
      render: (text, record, index) => (
        <Select
          ref={selectRef}
          style={{ width: '100%' }}
          value={record.department_name}
          onSelect={(value, option) => handleSelect(index, value, option)}
          onSearch={handleSearch}
          onChange={value => handleInputChange(index, 'department_name', value)}
          showSearch
          disabled={type === 'detail'}
          filterOption={false}
          allowClear
          placeholder="Chọn phòng ban">
          {filteredItems.map(item => (
            <Option key={item.id} value={item.department_name}>
              {item.department_name}
            </Option>
          ))}
        </Select>
      ),
      width: '300px',
    },
    // {
    //   title: 'MÃ MÁY IN',
    //   key: 'printer_id',
    //   render: (text, record, index) => (
    //     <Input disabled key={record.printer_id} value={record.printer_code} />
    //   ),
    //   width: '300px',
    // },
    {
      title: 'MÃ SẢN PHẨM',
      key: 'item_id',
      render: (text, record, index) => (
        <AutoComplete
          style={{ width: '100%' }}
          value={record.item_code}
          options={filteredInventoryItem.map(item => ({
            value: item.id,
            item_code: item.item_code,
            label: (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{item.item_code}</span>
                <span>{item.item_name}</span>
              </div>
            ),
          }))}
          onSelect={(value, option) =>
            handleSelectIventoryItem(index, value, option)
          }
          onSearch={handleSearch}
          onChange={value => handleInputChange(index, 'item_code', value)}
          disabled={!isAllowChooseItem || type === 'detail'}
        />
      ),
      width: '300px',
    },
    {
      title: 'TÊN SẢN PHẨM',
      key: 'item_id',
      render: (text, record, index) => (
        <Input disabled value={record.item_name} />
      ),
      width: '300px',
    },
    {
      title: 'ĐVT',
      key: 'unit_id',
      render: (text, record, index) => (
        <Select
          allowClear
          placeholder="Chọn đơn vị"
          style={{ width: '100%' }}
          value={record.unit_id}
          disabled={!isAllowChooseItem || type === 'detail'}
          onChange={value => handleInputChange(index, 'unit_id', value)}>
          {unitList.map(unit => (
            <Option name={unit.unit_name} value={unit.id} key={unit.id}>
              {unit.unit_name}
            </Option>
          ))}
        </Select>
      ),
      width: '150px',
    },
    {
      title: 'SỐ LƯỢNG',
      key: 'quantity',
      render: (text, record, index) => (
        <InputNumber
          disabled={type === 'detail'}
          value={record.quantity}
          onChange={value => {
            // Check if the value is a valid number
            handleInputChange(index, 'quantity', value)
          }}
          style={{ width: '100%' }}
        />
      ),
      width: '150px',
    },
    {
      title: 'ĐƠN GIÁ',
      key: 'unit_price',
      render: (text, record, index) => (
        <Input
          disabled={type === 'detail'}
          value={record.unit_price}
          onChange={e => handleInputChange(index, 'unit_price', e.target.value)}
          style={{ width: '100%' }}
        />
      ),
      width: '200px',
    },
    {
      title: 'THÀNH TIỀN',
      key: 'amount',
      render: (text, record, index) => <Input disabled value={amount[index]} />,
      width: '250px',
    },
    {
      title: '',
      key: '-',
      render: (text, record, index) =>
        type !== 'detail' && (
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleRemoveRow(index)}
          />
        ),
      width: '50px',
    },
  ]

  const handleClose = () => {
    setOpen(false)
    form.resetFields()
    setFilteredItems(departmentList)
    const newData = [
      {
        key: 1,
        id: '',
        item_id: '',
        item_code: '',
        item_name: '',
        // printer_code: '',
        // printer_id: '',
        unit_id: '',
        unit_name: '',
        department_name: '',
        department_code: '',
        department_id: '',
        quantity: 0,
        unit_price: 0,
        itemTotal: undefined,
      },
    ]
    setData(newData)
    setTotalAmount(1)
  }

  const handleSubmit = async () => {
    // if (type === 'detail') {
    //   settype('edit')
    //   return
    // }

    loadingAnimationStore.showSpinner(true)
    try {
      await form.validateFields()
      const values = form.getFieldsValue()

      const invoiceNo = values.invoice_no
      const response = await exportStore.checkExistInvoiceNo(invoiceNo)
      if (response.data) {
        message.error('Số hóa đơn đã tồn tại!')
        return
      }

      const validTableData = data.filter(
        row => row.department_id && row.department_id !== ''
      )

      if (validTableData.length === 0) {
        message.warning('Bạn phải nhập ít nhất 1 dòng chi tiết!')
        return
      }

      let isValid = true

      data.forEach((record, index) => {
        // Check department_id
        if (!record.department_name) {
          isValid = false
          message.error(
            `Tên phòng ban không được để trống - tại dòng ${index + 1}`
          )
          return
        }

        // Check printer_id
        // if (!record.printer_id) {
        //   isValid = false
        //   message.error(`Mã máy in không được để trống - tại dòng ${index + 1}`)
        //   return
        // }

        // Check item_id
        if (!record.item_code) {
          isValid = false
          message.error(`Mặt hàng không được để trống - tại dòng ${index + 1}`)
          return
        }

        // Check quantity
        if (!record.quantity || record.quantity <= 0) {
          isValid = false
          message.error(
            `Số lượng xuất hàng phải lớn hơn 0 - tại dòng ${index + 1}`
          )
          return
        }

        // Check unit_price
        if (!record.unit_price || record.unit_price <= 0) {
          isValid = false
          message.error(
            `Đơn giá sản phẩm phải lớn hơn 0 - tại dòng ${index + 1}`
          )
          return
        }
      })

      // If all fields are valid, submit the form
      if (!isValid) {
        return
      }

      const tableData = validTableData.map(row => ({
        id: row.id ? row.id : null,
        department_id: row.department_id,
        department_name: row.department_name,
        department_code: row.department_code,
        unit_id: row.unit_id,
        unit_name: row.unit_name,
        item_id: row.item_id,
        item_name: row.item_name,
        item_code: row.item_code,
        // printer_id: row.printer_id,
        // printer_code: row.printer_code,
        quantity: row.quantity,
        unit_price: parseFloat(row.unit_price.toString()),
        total_price: row.itemTotal,
        outward_stock_printer_id: exportId,
      }))

      const payload = {
        master: { ...values, total_amount: totalAmount, id: exportId },
        details: tableData,
      }

      // if (type == 'edit') {
      //   await exportStore.updateExportPrinter(payload)
      //   message.success('Chỉnh sửa đơn xuất kho máy in thành công!')
      // } else {
      await exportStore.createExportPrinterStore(payload)
      message.success('Thêm đơn xuất kho máy in thành công!')
      // }

      await handleClose()
      fetchExportInvoice()
    } catch (errorInfo) {
      console.log(errorInfo)
      message.error('Vui lòng kiểm tra lại các trường dữ liệu!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <Modal
      title={'PHIẾU XUẤT KHO MÁY IN'}
      width={'100vw'}
      className="modalStyle"
      visible={open}
      onCancel={handleClose}
      style={{
        top: '0px',
        maxWidth: '100vw',
        height: '100vh',
        paddingBottom: '0px',
        background: '#fff',
      }}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Hủy
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          {type == 'detail' ? '' : 'Thêm'}
        </Button>,
      ]}>
      <Form
        form={form}
        scrollToFirstError={true}
        name={'create-export-invoice'}
        layout={'vertical'}>
        <div
          style={{
            padding: '20px 24px 0px 24px',
            background: '#eceef1',
          }}>
          <Row type={'flex'} gutter={30}>
            <Col xs={24} md={8}>
              <Form.Item
                label={'Số hóa đơn'}
                name={'invoice_no'}
                rules={[
                  { required: true, message: ' Vui lòng nhập số hóa đơn!' },
                ]}>
                <Input
                  allowClear
                  maxLength={50}
                  placeholder={'Nhập số hóa đơn'}
                  disabled={type === 'detail'}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              {type === 'detail' ? (
                <Form.Item label={'Ngày xuất hàng'}>
                  <Input
                    style={{ width: '100%' }}
                    value={
                      invoiceDate
                        ? moment(invoiceDate).format('YYYY-MM-DD')
                        : ''
                    }
                    disabled
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label={'Ngày xuất hàng'}
                  name={'invoice_date'}
                  rules={[
                    {
                      required: true,
                      message: ' Vui lòng chọn ngày xuất hàng!',
                    },
                  ]}>
                  <DatePicker
                    style={{ width: '100%' }}
                    placeholder="Chọn ngày xuất hàng"
                  />
                </Form.Item>
              )}
            </Col>
            <Col
              xs={24}
              md={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'end',
              }}>
              <Title level={2} code style={{ color: '#000' }}>
                THÀNH TIỀN
              </Title>
              <Text strong style={{ fontSize: '20px', marginBottom: '0px' }}>
                {totalAmount.toLocaleString('vi-VN')} VNĐ
              </Text>
            </Col>
          </Row>
          <Row type={'flex'} gutter={30}>
            <Col xs={24} md={8} style={{ paddingBottom: '20px' }}>
              <Form.Item label={'Ghi chú'} name={'note'}>
                <Input
                  allowClear
                  maxLength={255}
                  placeholder={'Nhập ghi chú'}
                  disabled={type === 'detail'}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div
          style={{
            padding: '12px 24px 24px',
          }}>
          <ExportTable>
            <Table columns={columns} dataSource={data} pagination={false} />
          </ExportTable>
          <div
            style={{
              marginTop: '10px',
              ...(type === 'detail' && { display: 'none' }),
            }}>
            <Button
              key="themDong"
              onClick={() => {
                fetchData()
                handleAddRow()
              }}
              style={{ marginRight: '10px' }}>
              Thêm dòng
            </Button>
            <Button key="xoaTatCa" onClick={handleDeleteAll}>
              Xóa tất cả
            </Button>
          </div>
        </div>
      </Form>
      <ItemCreateDrawer
        visible={isModalVisible}
        onCancel={handleModalClose}
        itemData={itemData}
        onCreate={handleCreateItem}
        onCreateSuccess={handleCreateSuccess} // Truyền hàm callback vào props
      />
    </Modal>
  )
}

export default memo(
  inject(
    'departmentStore',
    'unitStore',
    'inventoryItemStore',
    'exportStore',
    'loadingAnimationStore',
    'settingStore'
  )(observer(ExportPagePrinter))
)
