import axios from 'axios'
import { apiUrl } from '../config'

export const AuditLogRequest = {
  getAuditLogs: (index, size, search, filters) =>
    axios.post(
      `${apiUrl}/api/v1/audit-logs/get-all-audit-logs`,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
        filters: filters,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  getAuditLogDetailById: id =>
    axios.post(`${apiUrl}/api/v1/audit-logs/get-audit-log-by-id`, {
      id: id,
    }),
}
