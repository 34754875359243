import { action, observable } from 'mobx'
import { AuthenticationRequest } from '../requests/AuthenticationRequest'
import { setCookie, deleteCookie, getCookieValue } from '../cookie_utils' // Make sure to import utility functions for cookies
import accountStore from './accountStore'

class AuthenticationStore {
  @observable isAuthenticated = false

  @action userLogin = (token, campusId) => {
    return new Promise((resolve, reject) => {
      AuthenticationRequest.userLogin(token, campusId)
        .then(response => {
          const {
            accessToken,
            accessTokenExpiryTime,
            refreshToken,
            refreshTokenExpiryTime,
          } = response.data.tokenPair

          // Set cookies manually
          const domain = window.location.hostname
          const secure = true // Set to true if using HTTPS
          const sameSite = 'None' // Set to None for cross-site cookies

          // Calculate the number of days from now for the expiry times
          const accessTokenExpiryDays =
            (new Date(accessTokenExpiryTime).getTime() - new Date().getTime()) /
            (1000 * 60 * 60 * 24)
          const refreshTokenExpiryDays =
            (new Date(refreshTokenExpiryTime).getTime() -
              new Date().getTime()) /
            (1000 * 60 * 60 * 24)

          setCookie(
            'accessToken',
            accessToken,
            accessTokenExpiryDays,
            '/',
            domain,
            secure,
            sameSite
          )
          setCookie(
            'refreshToken',
            refreshToken,
            refreshTokenExpiryDays,
            '/',
            domain,
            secure,
            sameSite
          )
          accountStore.getAccountInfo().then(response => {
            localStorage.setItem('account-info', JSON.stringify(response.data))
          })
          this.isAuthenticated = true
          console.log('Login successful, cookies set.')
          resolve(response)
        })
        .catch(error => {
          this.isAuthenticated = false
          console.error('Login failed:', error)
          reject(error)
        })
    })
  }

  @action userLogout = () => {
    return new Promise((resolve, reject) => {
      try {
        // Clear cookies manually
        deleteCookie('accessToken', '/', window.location.hostname)
        deleteCookie('refreshToken', '/', window.location.hostname)
        localStorage.removeItem('account-info')
        // remove l

        this.isAuthenticated = false
        console.log('Logout successful, cookies cleared.')
        resolve()
      } catch (error) {
        console.error('Logout failed:', error)
        reject(error)
      }
    })
  }

  @action refreshToken = () => {
    return new Promise((resolve, reject) => {
      // Get refreshToken from cookie
      const refreshToken = getCookieValue('refreshToken')

      AuthenticationRequest.refreshToken(refreshToken)
        .then(response => {
          const {
            accessToken,
            accessTokenExpiryTime,
            refreshToken,
            refreshTokenExpiryTime,
          } = response.data

          // Set cookies manually
          const domain = window.location.hostname
          const secure = true // Set to true if using HTTPS
          const sameSite = 'None' // Set to None for cross-site cookies

          // Calculate the number of days from now for the expiry times
          const accessTokenExpiryDays =
            (new Date(accessTokenExpiryTime).getTime() - new Date().getTime()) /
            (1000 * 60 * 60 * 24)
          const refreshTokenExpiryDays =
            (new Date(refreshTokenExpiryTime).getTime() -
              new Date().getTime()) /
            (1000 * 60 * 60 * 24)

          setCookie(
            'accessToken',
            accessToken,
            accessTokenExpiryDays,
            '/',
            domain,
            secure,
            sameSite
          )
          setCookie(
            'refreshToken',
            refreshToken,
            refreshTokenExpiryDays,
            '/',
            domain,
            secure,
            sameSite
          )

          console.log('Token refreshed, cookies set.')
          resolve(response)
        })
        .catch(error => {
          console.error('Token refresh failed:', error)
          reject(error)
        })
    })
  }
}

export default new AuthenticationStore()
