// src/pages/ItemPage/ItemContent.js

import React, { useState, useEffect } from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Pagination,
  Row,
  Table,
  Tabs,
} from 'antd'
import * as XLSX from 'xlsx'
import { TableWrapper } from '../../components/Common/Table'
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { SupplierTable, SearchBar } from './ItemPageStyled'
import { EmptyText } from '../../components/Common/CellText'
import { Container } from '../../layouts/Container/Container'
import { TabWrapper } from '../../components/Common/TabComponent'
import ItemCreateDrawer from './ItemCreateDrawer'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { inject, observer } from 'mobx-react'
import confirm from 'antd/lib/modal/confirm'
import * as signalR from '@microsoft/signalr'
import { apiUrl } from '../../config'

const { Search } = Input

const ItemVatTu = ({
  inventoryItemStore,
  loadingAnimationStore,
  categoryStore,
}) => {
  const [pageIndex, setPageIndex] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [itemType, setItemType] = useState(null)
  const [categories, setCategories] = useState([])
  const fetchData = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await inventoryItemStore.getInventoryItemDTO(
        pageIndex,
        pageSize,
        search,
        [
          {
            data_field: 'item_type',
            value: '0',
            operator_type: 'equal',
          },
        ]
      )
      const data = response.data.pageData.map(async (item, index) => {
        return {
          key: item.id,
          id: item.id,
          stt: index + 1,
          item_name: item.item_name,
          item_code: item.item_code,
          category_name: item.category_name,
          unit_name: item.unit_name,
          unit_price: item.unit_price,
          quantity: item.quantity,
          vat: item.vat,
        }
      })

      const categoryResponse = await categoryStore.getCategories(1, 1000, '')
      const categoryList = categoryResponse?.data?.pageData?.map(
        (item, index) => ({
          text: item.category_name,
          value: item.category_name,
        })
      )
      setCategories(categoryList)

      const transformedData = await Promise.all(data)
      setData(transformedData)
      setTotal(response.data.totalCount)
    } catch (error) {
      console.error('Error fetching items:', error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleModalOpen = (record = null, type = null) => {
    setSelectedRecord(record)
    setItemType(type)
    setIsModalVisible(true)
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
    setSelectedRecord(null)
    setItemType(null)
  }

  const handleCreateItem = () => {
    setIsModalVisible(false)
    setSelectedRecord(null)
  }

  const handleDeleteItem = async record => {
    try {
      await inventoryItemStore.deleteItem(record.id)
      fetchData()
      message.success('Xóa hàng hóa thành công ')

      inventoryItemStore.addLog({
        table_name: 'Vật tư, hàng hóa',
        action: 'Xóa',
        newObj: {},
        oldObj: record,
      })
    } catch (error) {}
  }

  function formatNumber(value) {
    if (value == null) return ''
    const roundedValue = Number(value).toFixed(2)
    const [integerPart, decimalPart] = roundedValue.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }

  const columns = [
    {
      title: 'STT',
      align: 'center',
      width: '50px',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'MÃ SẢN PHẨM',
      key: 'ma',
      dataIndex: 'item_code',
      sorter: (a, b) => a.item_code.localeCompare(b.item_code),
      render: text => (
        <span>{text || <EmptyText>Chưa có mã sản phẩm</EmptyText>}</span>
      ),
    },
    {
      title: 'TÊN SẢN PHẨM',
      key: 'ten',
      dataIndex: 'item_name',
      sorter: (a, b) => a.item_name.localeCompare(b.item_name),
      render: text => (
        <span>{text || <EmptyText>Chưa có tên sản phẩm</EmptyText>}</span>
      ),
    },
    {
      title: 'LOẠI HÀNG HÓA',
      key: 'loaihanghoa',
      dataIndex: 'category_name',
      filters: categories || [],
      onFilter: (value, record) => record.category_name === value,
      render: text => (
        <span>{text || <EmptyText>Chưa có loại hàng hóa</EmptyText>}</span>
      ),
    },
    {
      title: 'ĐVT',
      key: 'donvitinh',
      dataIndex: 'unit_name',
      sorter: (a, b) => a.unit_name.localeCompare(b.unit_name),
      render: text => (
        <span>{text || <EmptyText>Chưa có đơn vị tính</EmptyText>}</span>
      ),
    },
    {
      title: 'ĐƠN GIÁ (VNĐ)',
      key: 'dongia',
      dataIndex: 'unit_price',
      sorter: (a, b) => a.unit_price - b.unit_price,
      render: text => (
        <span>
          {formatNumber(text) || <EmptyText>Chưa có đơn giá</EmptyText>}
        </span>
      ),
    },
    {
      title: 'SỐ LƯỢNG TỒN',
      key: 'soluong',
      dataIndex: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
      render: text => {
        const quantity = Number(text)
        return (
          <div style={{ color: quantity < 0 ? 'red' : 'inherit' }}>
            {formatNumber(quantity) || 0}
          </div>
        )
      },
    },
    {
      title: '',
      key: '#',
      dataIndex: 'id',
      width: 1,
      render: (text, record) => (
        <div style={{ display: 'flex' }}>
          <Button
            onClick={() => handleEdit(record, '0')}
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            size="small"></Button>
          <Button
            type="danger"
            shape="circle"
            icon={<DeleteOutlined />}
            style={{ marginLeft: 8 }}
            size="small"
            onClick={() => {
              confirm({
                title: 'Bạn có chắc chắn muốn xóa hàng hóa này không?',
                icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
                okText: 'Đồng ý',
                cancelText: 'Không',
                okType: 'danger',
                onOk() {
                  handleDeleteItem(record)
                },
              })
            }}></Button>
        </div>
      ),
    },
  ]

  const handleItemSaved = () => {
    fetchData()
  }

  const handleEdit = (record = null, type = null) => {
    handleModalOpen(record, type)
  }

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiUrl}/itemHub`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.None)
      .build()

    connection.on('ItemSignal', () => {
      fetchData()
    })

    connection
      .start()
      .then(() => {
        fetchData()
      })
      .catch(() => {
        fetchData()
      })

    return () => {
      connection.stop()
    }
  }, [pageIndex, pageSize, search])

  return (
    <>
      <ContentBlockWrapper>
        <Container>
          <Row style={{ marginBottom: 0, height: '38px' }}>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  maxLength={500}
                  placeholder={'Tìm kiếm theo Tên sản phẩm'}
                  onSearch={value => {
                    setSearch(value)
                    setPageIndex(1)
                  }}
                />
              </SearchBar>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                style={{ marginLeft: '10px', cursor: 'pointer' }}
                type={'primary'}
                onClick={() => handleModalOpen(null, '0')}>
                Thêm
              </Button>
            </Col>
          </Row>
          <TableWrapper>
            <SupplierTable>
              <Table
                style={{
                  height: 'calc(100vh - 235px)',
                  overflowY: 'auto',
                }}
                columns={columns}
                dataSource={data}
                bordered
                pagination={false}
                loading={loading}
              />
            </SupplierTable>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div>
                Tổng số: <b>{total}</b> bản ghi
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '8px',
                }}>
                <Pagination
                  current={pageIndex}
                  pageSize={pageSize}
                  total={total}
                  onChange={(page, size) => {
                    setPageIndex(page)
                  }}
                  showSizeChanger
                  onShowSizeChange={(current, size) => {
                    setPageSize(size)
                  }}
                  showLessItems
                />
              </div>
            </div>
          </TableWrapper>
          <ItemCreateDrawer
            visible={isModalVisible}
            onCancel={handleModalClose}
            onCreate={handleCreateItem}
            selectedRecord={selectedRecord}
            itemType={itemType}
            onItemSaved={handleItemSaved}
          />
        </Container>
      </ContentBlockWrapper>
    </>
  )
}

export default inject(
  'loadingAnimationStore',
  'inventoryItemStore',
  'categoryStore'
)(observer(ItemVatTu))
