import React, { memo } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

const AuthGuard = ({ component: Component, permission, accountStore, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (!accountStore.getCurrentAccount()) {
                    return <Redirect to="/login" />;
                }

                if (accountStore.hasPermission(permission)) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to="/unauthorized" />;
                }
            }}
        />
    );
};

export default memo(
    inject('accountStore')(observer(AuthGuard))
  )
