import axios from 'axios'
import { apiUrl } from '../config'

export const AccountRequest = {
  getAllActiveAccounts: (keyword) =>
    axios.get(`${apiUrl}/api/v1/accounts`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: keyword
    }),

  getAccount: id =>
    axios.get(`${apiUrl}/api/v1/accounts/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getAccounts: (index, size, search) =>
    axios.post(
      `${apiUrl}/api/v1/accounts/paging_filter`,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  createAccount: request =>
    axios.post(`${apiUrl}/api/v1/accounts`, request, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  updateAccount: (id, request) =>
    axios.post(`${apiUrl}/api/v1/accounts/${id}/update`, request, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getAccountById: id =>
    axios.get(`${apiUrl}/api/v1/accounts/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  deactivateAccount: id =>
    axios.post(
      `${apiUrl}/api/v1/accounts/${id}/deactivate`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  activateAccount: id =>
    axios.post(
      `${apiUrl}/api/v1/accounts/${id}/activate`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  getAccountInfo: () =>
    axios.get(`${apiUrl}/api/v1/accounts/me/authorities`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getApprovers: () =>
    axios.get(`${apiUrl}/api/v1/accounts/inward_stocks/approvers`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  addLog: request =>
    axios.post(`${apiUrl}/api/v1/accounts/add-audit-log`, request),
}
