import { action } from 'mobx'
import { AccountRequest } from '../requests/AccountRequest'

class AccountStore {
  @action hasPermission(permissionType) {
    const accountInfo = localStorage.getItem('account-info')
    if (!accountInfo) {
      return false
    }

    try {
      const parsedAccountInfo = JSON.parse(accountInfo)
      if (
        parsedAccountInfo &&
        Array.isArray(parsedAccountInfo.granted_permissions)
      ) {
        const grantedPermissions = parsedAccountInfo.granted_permissions
        const permission = `${permissionType.resource}.${permissionType.scope}`
        return grantedPermissions.includes(permission)
      }
    } catch (error) {
      return false
    }

    return false
  }

  @action getCurrentAccount() {
    const accountInfo = localStorage.getItem('account-info')
    try {
      const parsedAccountInfo = JSON.parse(accountInfo)
      return parsedAccountInfo
    } catch (error) {
      return null
    }
  }

  @action getAllActiveAccounts(keyword) {
    return new Promise((resolve, reject) => {
      AccountRequest.getAllActiveAccounts(keyword)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getAccount(id) {
    return new Promise((resolve, reject) => {
      AccountRequest.getAccount(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getAccounts(index, size, search) {
    return new Promise((resolve, reject) => {
      AccountRequest.getAccounts(index, size, search)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createAccount(request) {
    return new Promise((resolve, reject) => {
      AccountRequest.createAccount(request)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
        })
    })
  }

  @action updateAccount(id, request) {
    return new Promise((resolve, reject) => {
      AccountRequest.updateAccount(id, request)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getAccountById(id) {
    return new Promise((resolve, reject) => {
      AccountRequest.getAccountById(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deactivateAccount(id) {
    return new Promise((resolve, reject) => {
      AccountRequest.deactivateAccount(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action activateAccount(id) {
    return new Promise((resolve, reject) => {
      AccountRequest.activateAccount(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getAccountInfo() {
    return new Promise((resolve, reject) => {
      AccountRequest.getAccountInfo()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getApprovers() {
    return new Promise((resolve, reject) => {
      AccountRequest.getApprovers()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action addLog = data => {
    return new Promise((resolve, reject) => {
      AccountRequest.addLog(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new AccountStore()
