import React, { memo, useCallback, useEffect, useState } from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Table,
  Typography,
} from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { ImportApprovalTable } from './ImportApprovalPageStyled'
import { systemUrl } from '../../config'

const { Title, Text } = Typography

const ImportApprovalDetailModal = props => {
  const {
    open,
    setOpen,
    importId,
    setImportId,
    type,
    unitStore,
    inventoryItemStore,
    importStore,
    loadingAnimationStore,
    fetchImportInvoice,
  } = props
  const [form] = Form.useForm()
  const [invoiceDate, setInvoiceDate] = useState(undefined)
  const [supplierName, setSupplierName] = useState('')
  const [note, setNote] = useState('')
  const [unitName, setUnitName] = useState([])
  const [receivedAt, setReceivedAt] = useState('')

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = useCallback(async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      await unitStore.getUnit(1, 9999, '').then(res => {
        const unitNameMap = res.data.pageData.reduce((map, unit) => {
          map[unit.id] = unit.unit_name
          return map
        }, {})
        setUnitName(unitNameMap)
      })
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [unitStore])

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    try {
      if (importId) {
        importStore.getImportInvoiceDetail(importId).then(async res => {
          setInvoiceDate(res.data.masterDTO.invoice_date)
          setSupplierName(res.data.masterDTO.supplier_name)
          setNote(res.data.masterDTO.note)
          setReceivedAt(res.data.masterDTO.received_at)
          form.setFieldsValue({
            invoice_no: res.data.masterDTO.invoice_no,
            invoice_date: moment(
              res.data.masterDTO.invoice_date,
              'DD-MM-YYYY' || 'DD/MM/YYYY'
            ),
            supplier_id: res.data.masterDTO.supplier_id,
            note: res.data.masterDTO.note,
          })

          const detailsWithItemInfo = await Promise.all(
            res.data.details.map(async (detail, index) => {
              try {
                const itemInfo = await inventoryItemStore.getInventoryItemById(
                  detail.item_id
                )
                return {
                  key: index + 1,
                  id: detail.id,
                  item_id: detail.item_id,
                  item_code: itemInfo.data.item_code,
                  item_name: itemInfo.data.item_name,
                  unit_id: detail.unit_id,
                  quantity: detail.quantity,
                  unit_price: detail.unit_price,
                  itemTotal: detail.itemTotal,
                  amount: detail.quantity * detail.unit_price,
                }
              } catch (error) {
                console.error(error)
                return {
                  key: index + 1,
                  id: '',
                  item_id: '',
                  item_code: '',
                  item_name: '',
                  unit_id: '',
                  quantity: '',
                  unit_price: '',
                  itemTotal: undefined,
                  amount: '',
                }
              }
            })
          )

          setData(detailsWithItemInfo)
          setAmount(detailsWithItemInfo.map(row => row.amount))
          const totalAmount = detailsWithItemInfo.reduce(
            (acc, row) => acc + (row.amount || 0),
            0
          )
          setTotalAmount(totalAmount)
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [importId])

  const [data, setData] = useState([
    {
      key: 1,
      id: '',
      item_id: '',
      item_name: '',
      unit_id: '',
      quantity: '',
      unit_price: '',
      itemTotal: undefined,
    },
  ])

  const [amount, setAmount] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      render: (text, record, index) => <span>{index + 1}</span>,
      width: '20px',
    },
    {
      title: 'MÃ SẢN PHẨM',
      key: 'item_id',
      render: (text, record, index) =>
        type === 'detail' ? (
          <Input style={{ width: '100%' }} value={record.item_code} disabled />
        ) : (
          <></>
        ),
      width: '300px',
    },
    {
      title: 'TÊN SẢN PHẨM',
      key: 'item_name',
      render: (text, record, index) => (
        <Input disabled value={record.item_name} />
      ),
      width: '300px',
    },
    {
      title: 'ĐVT',
      key: 'unit_id',
      render: (text, record, index) =>
        type === 'detail' ? (
          <Input
            style={{ width: '100%' }}
            value={unitName[record.unit_id]}
            disabled
          />
        ) : (
          <></>
        ),
      width: '150px',
    },
    {
      title: 'SỐ LƯỢNG',
      key: 'quantity',
      render: (text, record, index) => (
        <Input
          value={record.quantity}
          style={{ width: '100%' }}
          disabled={type === 'detail' ? true : false}
        />
      ),
      width: '150px',
    },

    {
      title: 'ĐƠN GIÁ',
      key: 'unit_price',
      render: (text, record, index) => (
        <Input
          value={record.unit_price}
          style={{ width: '100%' }}
          disabled={type === 'detail' ? true : false}
        />
      ),
      width: '200px',
    },
    {
      title: 'THÀNH TIỀN',
      key: 'amount',
      render: (text, record, index) => <Input disabled value={amount[index]} />,
      width: '250px',
    },
  ]

  const handleClose = () => {
    setOpen(false)
    form.resetFields()
    setImportId('')
    const newData = [
      {
        key: 1,
        id: '',
        item_id: '',
        item_code: '',
        item_name: '',
        unit_id: '',
        quantity: '',
        unit_price: '',
        itemTotal: undefined,
      },
    ]
    setData(newData)
    setTotalAmount(0)
  }

  const handleApprove = async id => {
    loadingAnimationStore.showSpinner(true)
    try {
      var createdBy, invoiceNo, totalAmount
      var invoiceDate = new Date()
      await importStore.getImportInvoiceDetail(id).then(response => {
        createdBy = response?.data?.masterDTO?.created_by
        invoiceNo = response?.data?.masterDTO?.invoice_no
        invoiceDate = new Date(response?.data?.masterDTO?.invoice_date)
        totalAmount = response?.data?.masterDTO?.total_amount
      })
      const formattedDate = `${invoiceDate.getDate()}/${
        invoiceDate.getMonth() + 1
      }/${invoiceDate.getFullYear()}`
      const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Order Ready Notification</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              background-color: #f4f4f4;
            }
            .container {
              width: 100%;
              max-width: 600px;
              background: #ffffff;
              padding: 20px;
            }
            h1 {
              color: #333333;
            }
            p {
              color: #666666;
              line-height: 1.5;
            }
            .button {
              display: inline-block;
              padding: 10px 20px;
              font-size: 16px;
              color: #ffffff !important;
              background-color: #007bff;
              text-decoration: none;
              border-radius: 5px;
            }
          </style>
        </head>
        <body>
          <div class="container">
            <h1>Chào bạn,</h1>
            <p>Phiếu nhập kho ${invoiceNo} đã được duyệt. Dưới đây là thông tin cơ bản của phiếu nhập kho:</p>
            <p><strong>Số phiếu nhập:</strong> ${invoiceNo}</p>
            <p><strong>Ngày đặt hàng:</strong> ${formattedDate}</p>
            <p><strong>Tổng tiền:</strong> ${formatNumber(totalAmount)} vnđ</p>
            <a
              href=\`${systemUrl}/warehouse/import\`
              class="button"
            >
              Tới nhập kho
            </a>
            <p>Cảm ơn bạn đã sử dụng dịch vụ của chúng tôi!</p>
            <p>Trân trọng,<br />Đội ngũ hỗ trợ</p>
          </div>
        </body>
      </html>
      `
      await importStore.sendEmail(
        createdBy,
        'PHIẾU NHẬP KHO ĐÃ ĐƯỢC DUYỆT',
        htmlContent,
        []
      )
      await importStore.updateStatus(id, 'APPROVED')
      message.success('Duyệt đơn nhập kho thành công!')
      await fetchImportInvoice()
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  function formatNumber(value) {
    if (value == null) return ''
    const roundedValue = Number(value).toFixed(2)
    const [integerPart, decimalPart] = roundedValue.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }

  const handleReject = async id => {
    loadingAnimationStore.showSpinner(true)
    try {
      await importStore.updateStatus(id, 'REJECTED')
      message.success('Duyệt đơn nhập kho thành công!')
      await fetchImportInvoice()
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <Modal
      title={'PHIẾU NHẬP KHO'}
      width={'100vw'}
      className="modalImportStyle"
      visible={open}
      onCancel={handleClose}
      style={{
        top: '0px',
        maxWidth: '100vw',
        height: '100vh',
        paddingBottom: '0px',
        background: '#fff',
      }}
      footer={[
        <Button
          key="cancel"
          danger
          onClick={() => {
            handleReject(importId)
            handleClose()
          }}>
          Từ chối
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            handleApprove(importId)
            handleClose()
          }}>
          Duyệt
        </Button>,
      ]}>
      <Form
        form={form}
        scrollToFirstError={true}
        name={'create-import-invoice'}
        layout={'vertical'}>
        <div
          style={{
            padding: '20px 24px 0px 24px',
            background: '#eceef1',
          }}>
          <Row type={'flex'} gutter={30}>
            <Col xs={24} md={8}>
              <Form.Item
                label={'Số phiếu nhập'}
                name={'invoice_no'}
                rules={[
                  { required: true, message: ' Vui lòng nhập số phiếu nhập!' },
                ]}>
                <Input
                  allowClear
                  maxLength={50}
                  placeholder={'Nhập số phiếu nhập'}
                  disabled={type === 'detail' ? true : false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              {type === 'detail' ? (
                <Form.Item label={'Ngày đặt hàng'}>
                  <Input
                    style={{ width: '100%' }}
                    value={
                      invoiceDate
                        ? moment(invoiceDate).format('YYYY-MM-DD')
                        : ''
                    }
                    disabled
                  />
                </Form.Item>
              ) : (
                <></>
              )}
            </Col>
            <Col
              xs={24}
              md={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'end',
              }}>
              <Title level={2} code style={{ color: '#000' }}>
                THÀNH TIỀN
              </Title>
              <Text strong style={{ fontSize: '20px', marginBottom: '0px' }}>
                {totalAmount.toLocaleString('vi-VN')} VNĐ
              </Text>
            </Col>
          </Row>
          <Row type={'flex'} gutter={30} style={{ paddingBottom: '20px' }}>
            <Col xs={24} md={8}>
              {type === 'detail' ? (
                <Form.Item label={'Nhà cung cấp'}>
                  <Input
                    style={{ width: '100%' }}
                    value={supplierName}
                    disabled
                  />
                </Form.Item>
              ) : (
                <></>
              )}
            </Col>
            <Col xs={24} md={8}>
              {type === 'detail' ? (
                <Form.Item label={'Ghi chú'} name={'note'}>
                  <Input style={{ width: '100%' }} value={note} disabled />
                </Form.Item>
              ) : (
                <></>
              )}
            </Col>
            <Col
              xs={24}
              md={8}
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'end',
              }}>
              <Title level={2} code style={{ color: '#000' }}>
                NGÀY NHẬN
              </Title>
              <Text
                strong
                style={{
                  fontSize: '14px',
                  fontStyle: 'italic',
                  marginBottom: '0px',
                }}>
                {type === 'detail'
                  ? receivedAt && receivedAt !== ''
                    ? moment(receivedAt).format('DD-MM-YYYY')
                    : 'Chưa có'
                  : 'Chưa có'}
              </Text>
            </Col>
          </Row>
        </div>
        <div
          style={{
            padding: '12px 24px 24px',
          }}>
          <ImportApprovalTable>
            <Table columns={columns} dataSource={data} pagination={false} />
          </ImportApprovalTable>
        </div>
      </Form>
    </Modal>
  )
}

export default memo(
  inject(
    'unitStore',
    'inventoryItemStore',
    'importStore',
    'loadingAnimationStore'
  )(observer(ImportApprovalDetailModal))
)
