import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { Button, Result } from 'antd'
import { useHistory } from 'react-router-dom'
import { Wrapper } from '../NotFoundPage/NotFoundPageStyled'
const UnauthorizedPage = props => {
  const history = useHistory()
  return (
    <Wrapper>
      <Helmet>
        <title>403 | IMS Portal</title>
      </Helmet>
      <Result
        status="403"
        title="403"
        subTitle="Xin lỗi, bạn không có quyền hạn để truy cập vào trang này."
        extra={
          <Fragment>
            <Button onClick={() => history.goBack()}>
              Quay lại trang trước
            </Button>
            <Button type={'primary'} onClick={() => history.push('/')}>
              Về trang chủ
            </Button>
          </Fragment>
        }
      />
    </Wrapper>
  )
}
export default UnauthorizedPage
