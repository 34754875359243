import React, { memo, useEffect, useState } from 'react'
import {
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Steps,
  Upload,
  message,
  List,
  Typography,
  Button,
} from 'antd'
import { inject, observer } from 'mobx-react'
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons'
import XLSX from 'xlsx'
import NewItemsModal from './NewItemsModal'

const { Step } = Steps
const { Dragger } = Upload
const { Title, Paragraph, Text } = Typography

const ImportExcelCreateModal = props => {
  const {
    open,
    setOpen,
    importStore,
    supplierStore,
    inventoryItemStore,
    unitStore,
    setOpenImportCreate,
    setExcelData,
  } = props
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])
  const [newItems, setNewItems] = useState([])
  const [isNewItemsModalVisible, setIsNewItemsModalVisible] = useState(false)
  const [unitList, setUnitList] = useState([])
  const [currentStep, setCurrentStep] = useState(0)

  const tips = [
    'Tệp Excel phải có định dạng như mẫu để nhập thành công.',
    'Các trường bắt buộc phải có dữ liệu: Số HĐ, ngày ĐH, nhà cung cấp.',
    'Số HĐ không được trùng với các số HĐ đã có trong hệ thống.',
    'Định dạng ngày ĐH phải là dd-mm-yyyy hoặc dd/mm/yyyy.',
    'Nhà cung cấp phải tồn tại trong hệ thống.',
    'Với những mã hàng đã có trong hệ thống chỉ cần nhập mã hàng với số lượng và số lượng sẽ được tổng hợp.',
    'Với những mã hàng chưa có trong hệ thống cần nhập đầy đủ thông tin của sản phẩm để thêm mới sản phẩm vào hệ thống.',
  ]

  useEffect(() => {
    fetchUnits()
  }, [])

  const fetchUnits = async () => {
    const response = await unitStore.getUnit(1, 9999, '')
    setUnitList(response.data.pageData)
  }

  const downloadTemplate = () => {
    const ws = XLSX.utils.aoa_to_sheet([
      [
        'Số HĐ',
        'Ngày ĐH',
        'Nhà cung cấp',
        'Mã hàng',
        'Nội dung',
        'ĐVT',
        'Số lượng',
        'Đơn giá',
        'Ghi chú',
      ],
    ])

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Template')

    const date = new Date()
    const formattedDate = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`
    const fileName = `Nhập kho ${formattedDate}.xlsx`

    XLSX.writeFile(wb, fileName)
  }

  const handleFileUpload = async ({ file, onSuccess, onError }) => {
    setNewItems([])
    const reader = new FileReader()
    reader.onload = async e => {
      try {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: 'array' })

        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

        if (jsonData.length < 2) {
          message.error('File Excel không có dữ liệu hợp lệ!')
          setFileList([])
          onError('error')
          return
        }

        const isExistInvoice = await importStore.checkExistInvoiceNo(
          jsonData[1][0]
        )
        if (isExistInvoice.data) {
          message.error('Số HĐ đã tồn tại!')
          setFileList([])
          onError('error')
          return
        }

        const rawDate = jsonData[1][1]
        let formattedDate = ''

        if (typeof rawDate === 'number') {
          const date = new Date((rawDate - 25569) * 86400 * 1000)
          const isoString = date.toISOString().split('T')[0]
          const [year, month, day] = isoString.split('-')
          formattedDate = `${day}/${month}/${year}`
        } else {
          const dateFormatRegex = /^(\d{1,2})[-/](\d{1,2})[-/](\d{4})$/
          const match = rawDate.match(dateFormatRegex)
          if (match) {
            const [_, day, month, year] = match
            const date = new Date(Date.UTC(year, month - 1, day))
            const isoString = date.toISOString().split('T')[0]
            const [isoYear, isoMonth, isoDay] = isoString.split('-')
            formattedDate = `${isoMonth}/${isoDay}/${isoYear}`
          } else {
            message.error('Ngày ĐH sai định dạng!')
            setFileList([])
            onError('error')
            return
          }
        }

        const dateFormatRegex = /^(0[1-9]|1[0-2])[-/](0[1-9]|[12][0-9]|3[01])[-/]\d{4}$/
        if (!dateFormatRegex.test(formattedDate)) {
          message.error('Ngày ĐH sai định dạng!')
          setFileList([])
          onError('error')
          return
        }

        const isExistSupplier = await supplierStore.checkExistSupplier(
          jsonData[1][2]
        )
        if (!isExistSupplier.data) {
          message.error('Nhà cung cấp không tồn tại!')
          setFileList([])
          onError('error')
          return
        }

        const masterData = {
          invoice_no: jsonData[1][0],
          invoice_date: formattedDate,
          supplier_id: jsonData[1][2],
          note: jsonData[1][8],
        }

        if (
          !masterData.invoice_no ||
          !masterData.invoice_date ||
          !masterData.supplier_id
        ) {
          message.error(
            'Các cột Số HĐ, ngày ĐH và nhà cung cấp là bắt buộc và không được để trống!'
          )
          setFileList([])
          onError('error')
          return
        }

        const aggregatedItems = {}
        const newItemsList = []

        for (let i = 1; i < jsonData.length; i++) {
          const row = jsonData[i]
          const itemId = row[3]
          const quantity = row[6]

          if (itemId) {
            const isExistItem = await inventoryItemStore.checkExistItem(itemId)
            if (isExistItem.data) {
              if (!row[6] || !row[7]) {
                message.error('Số lượng và đơn giá không được để trống!')
                setFileList([])
                onError('error')
                return
              }

              if (aggregatedItems[itemId]) {
                aggregatedItems[itemId].quantity += quantity
              } else {
                const item = await inventoryItemStore.getInventoryItemByCode(
                  itemId
                )
                aggregatedItems[itemId] = {
                  item_id: item.data.id,
                  item_code: itemId,
                  item_name: item.data.item_name,
                  unit_id: item.data.unit_id,
                  quantity: quantity,
                  unit_price: row[7],
                }
              }
            } else {
              if (!row[3] || !row[4] || !row[5] || !row[6] || !row[7]) {
                message.error('Mã hàng mới phải có đầy đủ dữ liệu!')
                setFileList([])
                onError('error')
                return
              }

              const unitExists = unitList.some(
                unit =>
                  unit.unit_name.trim().toLowerCase() ===
                  row[5].trim().toLowerCase()
              )
              if (!unitExists) {
                message.error(`Đơn vị tính '${row[5]}' không tồn tại!`)
                setFileList([])
                onError('error')
                return
              }

              if (row[6] <= 0) {
                message.error('Số lượng phải lớn hơn 0!')
                setFileList([])
                onError('error')
                return
              }

              newItemsList.push({
                item_code: itemId,
                item_name: row[4],
                unit_name: row[5],
                quantity: quantity,
                unit_price: row[7],
              })
            }
          }
        }
        setNewItems(newItemsList)
        if (newItemsList.length > 0) {
          setCurrentStep(1)
        } else {
          setCurrentStep(2)
        }

        const detailsData = [...Object.values(aggregatedItems)]
        const supplierId = await supplierStore.getSupplierByName(
          masterData.supplier_id
        )
        setExcelData({
          master: {
            ...masterData,
            supplier_id: supplierId.data.id,
          },
          details: detailsData,
        })
        message.success('Nhập file thành công!')
      } catch (error) {
        message.error(error.message)
        setFileList([])
        onError('error')
      }
    }
    reader.onerror = () => {
      message.error('Nhập file thất bại!')
      setFileList([])
      onError('error')
    }
    reader.readAsArrayBuffer(file)
  }

  const handleChange = info => {
    let newFileList = [...info.fileList]

    newFileList = newFileList.slice(-1)

    setFileList(newFileList)
  }

  const handleNextStep = () => {
    setOpen(false)
    setFileList([])
    setNewItems([])
    setIsNewItemsModalVisible(false)
    setCurrentStep(0)
    setOpenImportCreate(true)
  }

  return (
    <Modal
      title={'Thêm đơn nhập kho từ file Excel'}
      width={'100vw'}
      visible={open}
      onCancel={() => {
        setOpen(false)
        setFileList([])
        setNewItems([])
        setIsNewItemsModalVisible(false)
        setCurrentStep(0)
        setExcelData({ master: {}, details: [] })
      }}
      footer={false}>
      <Steps current={currentStep}>
        <Step title="Chọn tệp nguồn" />
        <Step title="Kiểm tra dữ liệu" />
        <Step title="Kết quả" />
      </Steps>
      <Divider />
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Loại chứng từ" name="invoice_type">
              <Input disabled placeholder="Nhập kho" />
            </Form.Item>
            <Form.Item
              label="Chọn tệp Excel"
              name="excel_file"
              rules={[{ required: true, message: 'Vui lòng chọn tệp Excel' }]}>
              <Dragger
                customRequest={handleFileUpload}
                accept=".xlsx"
                fileList={fileList}
                onChange={handleChange}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Nhấp hoặc kéo tệp vào khu vực này để tải lên
                </p>
                <p className="ant-upload-hint">
                  Hỗ trợ tải lên một lần hoặc hàng loạt
                </p>
              </Dragger>
            </Form.Item>
            <p>
              <a
                href="#"
                style={{ marginRight: '5px' }}
                onClick={downloadTemplate}>
                Tải tệp mẫu
              </a>
              <DownloadOutlined />
            </p>
          </Col>
          <Col span={8}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Title code level={3}>
                Danh sách sản phẩm mới
              </Title>
              {newItems.length > 0 && (
                <p>
                  <a href="#" onClick={() => setIsNewItemsModalVisible(true)}>
                    Thêm sản phẩm mới
                  </a>
                </p>
              )}
            </div>
            {newItems.length > 0 && (
              <List
                size="small"
                bordered
                dataSource={newItems}
                renderItem={item => (
                  <List.Item
                    key={item.item_code}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <span>{item.item_code}</span>
                    <span>{item.item_name}</span>
                    <span>{item.quantity}</span>
                    <span>{item.unit_name}</span>
                    <span>{item?.unit_price ? item.unit_price + 'đ' : ''}</span>
                  </List.Item>
                )}
              />
            )}
          </Col>
          <Col span={8}>
            <img
              className="icon"
              src={`${process.env.PUBLIC_URL}/assets/icons/import/tips.png`}
            />
            <Paragraph>
              {tips.map((tip, index) => (
                <Text key={index}>
                  - {tip}
                  <br />
                </Text>
              ))}
            </Paragraph>
          </Col>
        </Row>
      </Form>
      {currentStep === 2 && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button type="primary" onClick={handleNextStep}>
            Bước tiếp theo
          </Button>
        </div>
      )}
      <NewItemsModal
        isNewItemsModalVisible={isNewItemsModalVisible}
        setIsNewItemsModalVisible={setIsNewItemsModalVisible}
        items={newItems}
        setItems={setNewItems}
        setCurrentStep={setCurrentStep}
      />
    </Modal>
  )
}

export default memo(
  inject(
    'importStore',
    'supplierStore',
    'inventoryItemStore',
    'unitStore'
  )(observer(ImportExcelCreateModal))
)
