import { apiUrl } from '../config'
import axios from 'axios'

export const UnitRequest = {
  getUnit: (index, size, search) =>
    axios.post(
      `${apiUrl}/api/v1/units/get-all-units/`,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  getUnitById: id =>
    axios.get(`${apiUrl}/api/v1/units/get-unit-by-id?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  createUnit: data =>
    axios.post(`${apiUrl}/api/v1/units/create-unit`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  updateUnit: data =>
    axios.post(`${apiUrl}/api/v1/units/update-unit`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getUnitIdByUnitName: name =>
    axios.get(`${apiUrl}/api/v1/units/get-unit-id-by-unit-name?name=${name}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  deleteUnit: id =>
    axios.post(
      `${apiUrl}/api/v1/units/delete-unit`,
      {
        id: id,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

    addLog: request =>
      axios.post(`${apiUrl}/api/v1/units/add-audit-log`, request),
}
