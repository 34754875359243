import React, { memo, useEffect, useState, useCallback } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import {
  Table,
  Pagination,
  Button,
  Modal,
  Form,
  Input,
  message,
  Switch,
} from 'antd'
import { TableWrapper } from '../../components/Common/Table'
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons'
import { EmptyText } from '../../components/Common/CellText'
import { CampusTable } from './CampusPageStyled'
import { Container } from '../../layouts/Container/Container'

const { confirm } = Modal

const CampusPage = props => {
  const { campusStore, loadingAnimationStore } = props
  const [campusData, setCampusData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [form] = Form.useForm()
  const [selectedCampusId, setSelectedCampusId] = useState(null)

  const fetchCampusData = useCallback(async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await campusStore.getCampus(page, pageSize, '')
      const { pageData, totalCount } = response.data
      const data = pageData.map((item, index) => ({
        key: item.id,
        stt: (page - 1) * pageSize + index + 1,
        campus_name: item.campus_name,
        address: item.address,
        deleted: item.deleted, // Add status field
        id: item.id, // Adding ID for actions
        is_active: item.is_active,
      }))
      setCampusData(data)
      setTotal(totalCount)
    } catch (error) {
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [campusStore, loadingAnimationStore, page, pageSize])

  useEffect(() => {
    fetchCampusData()
  }, [fetchCampusData])

  const openModalWithRecord = (record = null) => {
    if (record) {
      setSelectedCampusId(record.id)
      form.setFieldsValue({
        campus_name: record.campus_name,
        address: record.address,
      })
    } else {
      setSelectedCampusId(null)
      form.resetFields()
    }
    setIsModalOpen(true)
  }

  const handleModalCancel = () => {
    setIsModalOpen(false)
    form.resetFields()
    setSelectedCampusId(null)
  }

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields()
      if (selectedCampusId) {
        values.id = selectedCampusId
        await campusStore.updateCampus(values)
        message.success('Cập nhật cơ sở thành công!')
      } else {
        await campusStore.createCampus(values)
        message.success('Tạo mới cơ sở thành công!')
      }
      setIsModalOpen(false)
      form.resetFields()
      setSelectedCampusId(null)
      fetchCampusData()
    } catch (error) {}
  }

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      dataIndex: 'stt',
      width: '20px',
    },
    {
      title: 'TÊN CƠ SỞ',
      key: 'campus_name',
      dataIndex: 'campus_name',
      width: '20%',
      render: text => (
        <span>{text || <EmptyText>Chưa có tên cơ sở</EmptyText>}</span>
      ),
    },
    {
      title: 'ĐỊA CHỈ',
      key: 'address',
      dataIndex: 'address',
      width: '60%',
      render: text => (
        <span>{text || <EmptyText>Chưa có địa chỉ</EmptyText>}</span>
      ),
    },

    {
      title: 'Chỉnh sửa',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Button
          type="primary"
          shape="circle"
          icon={<EditOutlined />}
          size="small"
          onClick={() => openModalWithRecord(record)}></Button>
      ),
    },
  ]

  return (
    <DashboardLayout>
      <Helmet>
        <title>Quản lý cơ sở</title>
      </Helmet>
      <PageTitle location={props.location} title="Quản lý cơ sở" hiddenGoBack>
        <Button type="primary" onClick={() => openModalWithRecord()}>
          Thêm
        </Button>
      </PageTitle>
      <ContentBlockWrapper>
        <Container>
          <TableWrapper>
            <CampusTable>
              <Table
                style={{
                  height: 'calc(100vh - 175px)',
                  overflowY: 'auto',
                }}
                columns={columns}
                dataSource={campusData}
                bordered
                pagination={false}
              />
            </CampusTable>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div>
                Tổng số: <b>{total}</b> bản ghi
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '8px',
                }}>
                <Pagination
                  onChange={(page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                  }}
                  current={page}
                  pageSize={pageSize}
                  total={total}
                  hideOnSinglePage={true}
                  showSizeChanger={true}
                  showLessItems
                />
              </div>
            </div>
          </TableWrapper>
        </Container>
      </ContentBlockWrapper>
      <Modal
        title={selectedCampusId ? 'Chỉnh sửa cơ sở' : 'Thêm cơ sở mới'}
        visible={isModalOpen}
        okText={selectedCampusId ? 'Cập nhật' : 'Thêm'}
        onCancel={handleModalCancel}
        onOk={handleModalOk}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="campus_name"
            label="Tên Cơ Sở"
            rules={[{ required: true, message: 'Vui lòng nhập tên cơ sở' }]}>
            <Input placeholder="Nhập tên cơ sở" />
          </Form.Item>
          <Form.Item
            name="address"
            label="Địa Chỉ"
            rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}>
            <Input placeholder="Nhập địa chỉ" />
          </Form.Item>
        </Form>
      </Modal>
    </DashboardLayout>
  )
}

export default memo(
  inject('campusStore', 'loadingAnimationStore')(observer(CampusPage))
)
