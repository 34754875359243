import { update } from 'lodash'
import { apiUrl } from '../config'
import axios from 'axios'

export const InventoryItemRequest = {
  getInventoryItem: (index, size, search) =>
    axios.post(
      `${apiUrl}/api/v1/inventory_items/get-all-items/`,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  getInventoryItemById: id =>
    axios.get(`${apiUrl}/api/v1/inventory_items/get-item-by-id?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  checkExistItem: code =>
    axios.get(
      `${apiUrl}/api/v1/inventory_items/check_exist_item?code=${code}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  createInventoryItem: data =>
    axios.post(`${apiUrl}/api/v1/inventory_items/create-item`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getInventoryItemDTO: (index, size, search, filters) =>
    axios.post(
      `${apiUrl}/api/v1/inventory_items/get-all-items-dto`,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
        filters: filters,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  getTotal: () =>
    axios.get(`${apiUrl}/api/v1/inventory_items/total`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getReportItem: (
    index,
    size,
    search,
    startDate,
    endDate,
    category_id,
    item_type
  ) => {
    let url = `${apiUrl}/api/v1/inventory_items/get-report-item?`

    if (startDate) {
      url += `startDate=${startDate}&`
    }

    if (endDate) {
      url += `endDate=${endDate}&`
    }

    if (category_id) {
      url += `categoryId=${category_id}&`
    }
    if (item_type) {
      url += `item_type=${item_type}&`
    }

    url = url.slice(0, -1)

    return axios.post(
      url,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  },

  getInventoryItemByCode: code =>
    axios.get(
      `${apiUrl}/api/v1/inventory_items/get-item-by-code?code=${code}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  updateItem: data =>
    axios.post(`${apiUrl}/api/v1/inventory_items/update-item`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getRemind: (page, pageSize, quantity) =>
    axios.get(
      `${apiUrl}/api/v1/inventory_items/remind?pageNumber=${page}&pageSize=${pageSize}&quantity=${quantity}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  getItemByCategory: () =>
    axios.get(`${apiUrl}/api/v1/inventory_items/get-item-by-category`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getItemByDepartment: request =>
    axios.post(
      `${apiUrl}/api/v1/inventory_items/get-item-by-department`,
      request,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  getBrokenReport: keyword =>
    axios.get(`${apiUrl}/api/v1/inventory_items/broken`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: keyword,
    }),

  deleteItem: id =>
    axios.post(
      `${apiUrl}/api/v1/inventory_items/delete`,
      {
        id: id,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  addLog: request =>
    axios.post(`${apiUrl}/api/v1/inventory_items/add-audit-log`, request),
}
