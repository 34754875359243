import { apiUrl } from '../config'
import axios from 'axios'

export const SettingRequest = {
  createSetting: data =>
    axios.post(`${apiUrl}/api/v1/settings`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getSetting: () =>
    axios.get(`${apiUrl}/api/v1/settings`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
}
