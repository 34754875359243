import React, { memo, useEffect, useState } from 'react'
import { Drawer, Form, Input, Button, Select, Row, Col, message } from 'antd'
import { inject, observer } from 'mobx-react'

const { Option } = Select

const ItemCreateDrawer = props => {
  const {
    visible,
    onCancel,
    unitStore,
    inventoryItemStore,
    categoryStore,
    loadingAnimationStore,
    selectedRecord,
    itemType,
    onItemSaved,
    itemData,
    onCreateSuccess,
  } = props
  const [unitList, setUnitList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [form] = Form.useForm()

  useEffect(() => {
    unitStore.getUnit(1, 9999, '').then(res => {
      setUnitList(res.data.pageData)
    })
    categoryStore.getCategories(1, 9999, '').then(res => {
      setCategoryList(res.data.pageData)
    })
  }, [unitStore, categoryStore])

  useEffect(() => {
    if (selectedRecord) {
      const selectedCategory = categoryList.find(
        category => category.category_name === selectedRecord.category_name
      )
      const selectedUnit = unitList.find(
        unit => unit.unit_name === selectedRecord.unit_name
      )
      form.setFieldsValue({
        item_name: selectedRecord.item_name,
        item_code: selectedRecord.item_code,
        category_id: selectedCategory ? selectedCategory.id : undefined,
        unit_id: selectedUnit ? selectedUnit.id : undefined,
        item_type: itemType || selectedRecord.item_type,
        unit_price: selectedRecord.unit_price,
      })
    } else {
      form.resetFields()
      form.setFieldsValue({ item_type: itemType })
    }
  }, [selectedRecord, categoryList, unitList, form, itemType])

  useEffect(() => {
    if (visible && itemData && itemData.item_name && itemData.item_code) {
      form.setFieldsValue({
        item_name: itemData.item_name,
        item_code: itemData.item_code,
        item_type: '0',
      })
    }
  }, [visible, form, itemData])

  const handleOk = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const values = await form.validateFields()
      if (selectedRecord) {
        values.id = selectedRecord.id
        await inventoryItemStore.updateItem(values)
        message.success('Cập nhật sản phẩm thành công')

        inventoryItemStore.addLog({
          table_name: 'Vật tư, hàng hóa',
          action: 'Chỉnh sửa',
          newObj: values,
          oldObj: selectedRecord,
        })
      } else {
        const tableData = {
          item_code: values.item_code,
          item_name: values.item_name,
          category_id: values.category_id,
          unit_id: values.unit_id,
          item_type: values.item_type,
          unit_price: values.unit_price,
        }
        await inventoryItemStore.createInventoryItem(tableData)
        message.success('Thêm hàng tồn kho mới thành công!')

        inventoryItemStore.addLog({
          table_name: 'Vật tư, hàng hóa',
          action: 'Thêm',
          newObj: tableData,
          oldObj: {
            item_code: '',
            item_name: '',
            item_type: values.item_type,
            unit_price: 0,
          },
        })
        if (
          onCreateSuccess &&
          itemData &&
          itemData.item_name &&
          itemData.item_code
        ) {
          onCreateSuccess(tableData, true, itemData.key_id)
        }
        form.resetFields()
      }
      onItemSaved()
      onCancel()
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <Drawer
      title={selectedRecord ? 'Sửa vật tư' : 'Thêm vật tư'}
      visible={visible}
      width={'40vw'}
      onClose={onCancel}
      extra={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onCancel} style={{ marginRight: 8 }}>
            Hủy
          </Button>
          <Button onClick={handleOk} type="primary">
            {selectedRecord ? 'Cập nhật' : 'Thêm'}
          </Button>
        </div>
      }>
      <Form form={form} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Mã"
              name="item_code"
              rules={[{ required: true, message: 'Vui lòng nhập mã vật tư' }]}>
              <Input placeholder="Nhập mã vật tư" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Tên"
              name="item_name"
              rules={[{ required: true, message: 'Vui lòng nhập tên vật tư' }]}>
              <Input placeholder="Nhập tên vật tư" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Nhóm vật tư"
              name="category_id"
              rules={[
                { required: true, message: 'Vui lòng chọn nhóm vật tư' },
              ]}>
              <Select placeholder="Chọn nhóm vật tư">
                {categoryList.map(category => (
                  <Option value={category.id} key={category.id}>
                    {category.category_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Đơn vị tính"
              name="unit_id"
              rules={[
                { required: true, message: 'Vui lòng chọn đơn vị tính' },
              ]}>
              <Select placeholder="Chọn đơn vị tính">
                {unitList.map(unit => (
                  <Option value={unit.id} key={unit.id}>
                    {unit.unit_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            {' '}
            <Form.Item
              label="Đơn giá"
              name="unit_price"
              rules={[{ required: true, message: 'Vui lòng nhập đơn giá' }]}>
              <Input placeholder="Nhập đơn giá" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Loại vật tư"
              name="item_type"
              rules={[
                { required: true, message: 'Vui lòng chọn loại vật tư' },
              ]}>
              <Select disabled={!!itemType} placeholder="Chọn loại vật tư">
                <Option value="0" key="0">
                  Tiêu hao
                </Option>
                <Option value="1" key="1">
                  Phi kinh doanh
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  )
}

export default memo(
  inject(
    'unitStore',
    'inventoryItemStore',
    'categoryStore',
    'loadingAnimationStore'
  )(observer(ItemCreateDrawer))
)
