import React from 'react'
import { Wrapper } from './CounterCardStyled'

const CounterCard = props => {
  const { title, counter, icon, onClick, children } = props
  return (
    <Wrapper onClick={onClick}>
      <div className="card-info">
        <img className="icon" src={icon} alt="" />
        <span className="card-title">{title}</span>
      </div>
      <div className="card-counter">{counter}</div>
      {children}
    </Wrapper>
  )
}

export default CounterCard
