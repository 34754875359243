import React, { useEffect, useState } from 'react'
import { Col, DatePicker, Input, Row, Select, Space } from 'antd'
import { inject, observer } from 'mobx-react'
import { DATE_FORMAT_LIST } from '../../constants'
import moment from 'moment'

const { RangePicker } = DatePicker
const { Option } = Select

const AdvancedFilterBlock = props => {
  const {
    setPage,
    setPageSize,
    setSearchValue,
    searchValue,
    setFilters,
    filters,
    setCountFilter,
    departmentStore,
  } = props
  const [departmentList, setDepartmentList] = useState([])

  useEffect(() => {
    departmentStore.getDepartment(1, 9999, '').then(res => {
      setDepartmentList(res.data.pageData)
    })
  }, [])

  const getFiltersCount = () => {
    return filters.length
  }

  const updateFilters = newFilters => {
    const currentFilterCount = getFiltersCount()
    const newFilterCount = newFilters.length

    setFilters(newFilters)

    if (newFilterCount > currentFilterCount) {
      setCountFilter(prevCount => prevCount + 1)
    } else if (newFilterCount < currentFilterCount) {
      setCountFilter(prevCount => prevCount - 1)
    }
  }

  const onChangeInvoiceDate = dates => {
    setPage(1)
    setPageSize(10)
    const [startDateSelected, endDateSelected] = dates || []
    const startDate = startDateSelected ? startDateSelected.toISOString() : ''
    const endDate = endDateSelected ? endDateSelected.toISOString() : ''

    const newFilters = filters.filter(
      filter => filter.data_field !== 'invoice_date'
    )

    if (startDate) {
      newFilters.push({
        data_field: 'invoice_date',
        value: startDate,
        operator_type: 'greaterthan',
      })
    }
    if (endDate) {
      newFilters.push({
        data_field: 'invoice_date',
        value: endDate,
        operator_type: 'lessthan',
      })
    }

    updateFilters(newFilters)
  }

  const onChangeDepartment = value => {
    setPage(1)
    setPageSize(10)

    const newFilters = [
      ...filters.filter(filter => filter.data_field !== 'department_id'),
      value
        ? {
            data_field: 'department_id',
            value: value,
            operator_type: 'equal',
          }
        : {},
    ].filter(filter => filter.data_field)

    updateFilters(newFilters)
  }

  const onSearch = value => {
    setPage(1)
    setPageSize(10)
    setSearchValue(value)

    if (value) {
      setCountFilter(prevCount => prevCount + 1)
    } else {
      setCountFilter(prevCount => prevCount - 1)
    }
  }

  return (
    <div>
      <Row type={'flex'} gutter={15} style={{ marginBottom: 0 }}>
        <Col xs={24} sm={12} md={8}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <span>Số hóa đơn / Phòng ban / Ghi chú</span>
            <Input.Search
              onSearch={onSearch}
              placeholder={'Nhập từ khóa tìm kiếm'}
              allowClear
              defaultValue={searchValue}
            />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Ngày xuất hàng</span>
            <RangePicker
              value={[
                filters.find(
                  filter =>
                    filter.data_field === 'invoice_date' &&
                    filter.operator_type === 'greaterthan'
                )?.value
                  ? moment(
                      filters.find(
                        filter =>
                          filter.data_field === 'invoice_date' &&
                          filter.operator_type === 'greaterthan'
                      )?.value
                    )
                  : null,
                filters.find(
                  filter =>
                    filter.data_field === 'invoice_date' &&
                    filter.operator_type === 'lessthan'
                )?.value
                  ? moment(
                      filters.find(
                        filter =>
                          filter.data_field === 'invoice_date' &&
                          filter.operator_type === 'lessthan'
                      )?.value
                    )
                  : null,
              ]}
              onChange={onChangeInvoiceDate}
              placeholder={['Từ ngày', 'Đến ngày']}
              format={DATE_FORMAT_LIST}
              style={{ width: '100%' }}
            />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Space
            direction="vertical"
            style={{ width: '100%', marginBottom: 10 }}>
            <span>Phòng ban</span>
            <Select
              value={
                filters.find(filter => filter.data_field === 'department_id')
                  ?.value
              }
              onChange={onChangeDepartment}
              allowClear
              placeholder="Chọn phòng ban"
              style={{ width: '100%' }}>
              {departmentList.map(department => (
                <Option
                  name={department.department_name}
                  value={department.id}
                  key={department.id}>
                  {department.department_name}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
      </Row>
    </div>
  )
}

export default inject('departmentStore')(observer(AdvancedFilterBlock))
