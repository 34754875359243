import { apiUrl } from '../config'
import axios from 'axios'

export const CategoryRequest = {
  getCategories: (index, size, search) =>
    axios.post(
      `${apiUrl}/api/v1/categories/get-all-categories/`,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  getCategoryById: id =>
    axios.get(`${apiUrl}/api/v1/categories/get-category-by-id?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  createCategory: data =>
    axios.post(`${apiUrl}/api/v1/categories/create-category`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  updateCategory: data =>
    axios.post(`${apiUrl}/api/v1/categories/update-category`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  deleteCategory: id =>
    axios.post(
      `${apiUrl}/api/v1/categories/delete`,
      {
        id: id,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

    addLog: request =>
      axios.post(`${apiUrl}/api/v1/categories/add-audit-log`, request),
}
