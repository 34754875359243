import axios from 'axios'
import { apiUrl } from '../config'

export const DepartmentRequest = {
  getDepartment: (index, size, search) =>
    axios.post(
      `${apiUrl}/api/v1/departments/get-all-departments/`,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  getDepartmentbyId: id =>
    axios.get(`${apiUrl}/api/v1/departments/get-department-by-id?id=${id}`),

  createDepartment: data =>
    axios.post(`${apiUrl}/api/v1/departments/create-department`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  updateDepartment: data =>
    axios.post(`${apiUrl}/api/v1/departments/update-department`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  deactiveDepartment: id =>
    axios.post(
      `${apiUrl}/api/v1/departments/deactive-department`,
      {
        id: id,
      },
      {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      }
    ),

  activeDepartment: id =>
    axios.post(
      `${apiUrl}/api/v1/departments/active-department`,
      {
        id: id,
      },
      {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      }
    ),

  deleteDepartment: id =>
    axios.post(
      `${apiUrl}/api/v1/departments/delete-department`,
      {
        id: id,
      },
      {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      }
    ),

  getDepartmentMonthlyCost: year =>
    axios.post(`${apiUrl}/api/v1/departments/get-monthly-cost/${year}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  checkExistDepartment: name =>
    axios.get(
      `${apiUrl}/api/v1/departments/check_exist_department?name=${name}`,
      {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      }
    ),

  getDepartmentByName: name =>
    axios.get(
      `${apiUrl}/api/v1/departments/get-department-by-name?name=${name}`,
      {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      }
    ),

  getTotal: () =>
    axios.get(`${apiUrl}/api/v1/departments/total`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getDepartmentByItem: request =>
    axios.post(`${apiUrl}/api/v1/departments/get-department-by-item`, request, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  addLog: request =>
    axios.post(`${apiUrl}/api/v1/departments/add-audit-log`, request),
}
