import React, { memo, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import DashboardLayout from '../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Modal,
  Pagination,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  message,
} from 'antd'
import { TableWrapper } from '../../components/Common/Table'
import { FilterButtonWrapper, ImportTable, SearchBar } from './ImportPageStyled'
import {
  CaretDownOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  SendOutlined,
} from '@ant-design/icons'
import AdvancedFilterBlock from './AdvancedFilterBlock'
import ImportCreateModal from './ImportCreateModal'
import { inject, observer } from 'mobx-react'
import utils from '../../utils'
import ImportExcelCreateModal from './ImportExcelCreateModal'
import ImportDetailUpdate from './ImportDetailUpdate'
import { EmptyText } from '../../components/Common/CellText'
import * as signalR from '@microsoft/signalr'
import { apiUrl } from '../../config'
import { Container } from '../../layouts/Container/Container'
import { PermissionType } from '../../enum'
import { systemUrl } from '../../config'

const { Search } = Input
const { confirm } = Modal

const ImportPage = props => {
  const {
    importStore,
    loadingAnimationStore,
    accountStore,
    settingStore,
    inventoryItemStore,
  } = props
  const [importData, setImportData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchValue, setSearchValue] = useState('')
  const [filters, setFilters] = useState([])
  const [total, setTotal] = useState(0)
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false)
  const [countFilter, setCountFilter] = useState(0)
  const [openImportCreate, setOpenImportCreate] = useState(false)
  const [openImportExcelCreate, setOpenImportExcelCreate] = useState(false)
  const [excelData, setExcelData] = useState({})
  const [openImportDetailUpdate, setOpenImportDetailUpdate] = useState(false)
  const [importId, setImportId] = useState('')
  const [type, setType] = useState('')

  const isAccessPermission = permissionType => {
    if (accountStore.hasPermission(permissionType)) return true
    else return false
  }

  const fetchImportInvoice = useCallback(async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await importStore.getImportInvoice(
        page,
        pageSize,
        searchValue,
        filters
      )
      const data = response.data.pageData.map((item, index) => ({
        key: item.id,
        stt: index + 1,
        invoice_no: item.invoice_no,
        invoice_date: item.invoice_date,
        supplier_name: item.supplier_name,
        total_amount: item.total_amount,
        note: item.note,
        status: item.status,
        received_at: item.received_at,
      }))
      setImportData(data)
      setTotal(response.data.totalCount)
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [page, pageSize, searchValue, filters])

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiUrl}/inwardStockHub`)
      .configureLogging(signalR.LogLevel.None)
      .withAutomaticReconnect()
      .build()

    connection.on('InwardStockSignal', () => {
      fetchImportInvoice()
    })

    connection
      .start()
      .then(() => {
        fetchImportInvoice()
      })
      .catch(() => {
        fetchImportInvoice()
      })

    return () => {
      connection.stop()
    }
  }, [fetchImportInvoice])

  function formatNumber(value) {
    if (value == null) return ''
    const roundedValue = Number(value).toFixed(2)
    const [integerPart, decimalPart] = roundedValue.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }

  const statusFilters = [
    { text: 'Bản nháp', value: 'DRAFT' },
    { text: 'Chờ duyệt', value: 'PENDING' },
    { text: 'Đã duyệt', value: 'APPROVED' },
    { text: 'Từ chối', value: 'REJECTED' },
    { text: 'Hoàn thành', value: 'COMPLETED' },
  ]

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      width: '20px',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'SỐ PHIẾU NHẬP',
      key: 'invoice_no',
      dataIndex: 'invoice_no',
      sorter: (a, b) => a.invoice_no.localeCompare(b.invoice_no),
      render: text => (
        <span>{text || <EmptyText>Chưa có số phiếu nhập</EmptyText>}</span>
      ),
    },
    {
      title: 'NGÀY ĐẶT HÀNG',
      key: 'invoice_date',
      dataIndex: 'invoice_date',
      sorter: (a, b) => new Date(a.invoice_date) - new Date(b.invoice_date),
      render: text => {
        if (text) {
          const date = new Date(text)
          return (
            <span>
              {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
            </span>
          )
        } else {
          return <EmptyText>Chưa có ngày đặt hàng</EmptyText>
        }
      },
    },
    {
      title: 'NHÀ CUNG CẤP',
      key: 'supplier_name',
      dataIndex: 'supplier_name',
      sorter: (a, b) => a.supplier_name.localeCompare(b.supplier_name),
      render: text => (
        <span>{text || <EmptyText>Chưa có nhà cung cấp</EmptyText>}</span>
      ),
    },
    {
      title: 'NGÀY NHẬN',
      key: 'received_at',
      dataIndex: 'received_at',
      sorter: (a, b) => new Date(a.received_at) - new Date(b.received_at),
      render: text => {
        if (text) {
          const date = new Date(text)
          return (
            <span>
              {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
            </span>
          )
        } else {
          return <EmptyText>Chưa có ngày nhận</EmptyText>
        }
      },
    },
    {
      title: 'THÀNH TIỀN (VNĐ)',
      key: 'total_amount',
      dataIndex: 'total_amount',
      sorter: (a, b) => a.total_amount - b.total_amount,
      render: text => (
        <span>
          {formatNumber(text) || <EmptyText>Chưa có thành tiền</EmptyText>}
        </span>
      ),
    },
    {
      title: 'GHI CHÚ',
      key: 'note',
      dataIndex: 'note',
      render: text => (
        <span>{text || <EmptyText>Chưa có ghi chú</EmptyText>}</span>
      ),
    },
    {
      title: 'TRẠNG THÁI',
      key: 'status',
      dataIndex: 'status',
      filters: statusFilters,
      onFilter: (value, record) => record.status === value,
      render: status => {
        let color
        let content
        switch (status) {
          case 'DRAFT':
            color = 'darkgrey'
            content = 'Bản nháp'
            break
          case 'PENDING':
            color = 'orange'
            content = 'Chờ duyệt'
            break
          case 'REJECTED':
            color = 'red'
            content = 'Từ chối'
            break
          case 'APPROVED':
            color = 'blue'
            content = 'Đã duyệt'
            break
          case 'COMPLETED':
            color = 'green'
            content = 'Hoàn thành'
            break
          default:
            color = ''
            content = ''
        }
        if (status)
          return (
            <Tag style={{ width: '100px' }} color={color}>
              {content}
            </Tag>
          )
        else return <EmptyText>Chưa có trạng thái</EmptyText>
      },
    },
    {
      title: '',
      key: '#',
      width: 1,
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '5px' }}>
          {isAccessPermission(PermissionType.INWARD_INVENTORY_UPDATE) && (
            <>
              {record.status === 'APPROVED' && (
                <Tooltip title="Xác nhận hàng">
                  <Button
                    onClick={e => {
                      e.stopPropagation()
                      setOpenImportDetailUpdate(true)
                      setImportId(record.key)
                      setType('update')
                    }}
                    style={{ backgroundColor: 'green', color: 'white' }}
                    shape="circle"
                    icon={<CheckOutlined />}
                    size="small"></Button>
                </Tooltip>
              )}
              {record.status === 'DRAFT' && (
                <Tooltip title="Chờ duyệt">
                  <Button
                    onClick={e => {
                      e.stopPropagation()
                      confirm({
                        title:
                          'Bạn có muốn chuyển trạng thái đơn nhập kho này thành chờ duyệt không?',
                        icon: (
                          <QuestionCircleOutlined style={{ color: 'blue' }} />
                        ),
                        okText: 'Đồng ý',
                        cancelText: 'Không',
                        okType: 'primary',
                        onOk() {
                          handlePending(record.key)
                        },
                      })
                    }}
                    type="primary"
                    shape="circle"
                    icon={<SendOutlined />}
                    size="small"></Button>
                </Tooltip>
              )}
            </>
          )}
          {isAccessPermission(PermissionType.INWARD_INVENTORY_UPDATE) && (
            <>
              {record.status !== 'COMPLETED' &&
                record.status !== 'PENDING' &&
                record.status !== 'APPROVED' &&
                record.status !== 'REJECTED' && (
                  <Tooltip title="Sửa">
                    <Button
                      onClick={e => {
                        e.stopPropagation()
                        setOpenImportDetailUpdate(true)
                        setImportId(record.key)
                        setType('update')
                      }}
                      type="primary"
                      shape="circle"
                      icon={<EditOutlined />}
                      size="small"></Button>
                  </Tooltip>
                )}
            </>
          )}
          {isAccessPermission(PermissionType.INWARD_INVENTORY_DELETE) && (
            <>
              {record.status !== 'APPROVED' &&
                record.status !== 'COMPLETED' &&
                record.status !== 'PENDING' &&
                record.status !== 'REJECTED' && (
                  <Tooltip title="Xóa">
                    <Button
                      onClick={e => {
                        e.stopPropagation()
                        confirm({
                          title: 'Bạn có muốn xóa đơn nhập kho này không?',
                          icon: (
                            <QuestionCircleOutlined style={{ color: 'red' }} />
                          ),
                          okText: 'Đồng ý',
                          cancelText: 'Không',
                          okType: 'danger',
                          onOk() {
                            handleDelete(record.key)
                          },
                        })
                      }}
                      type="danger"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      size="small"></Button>
                  </Tooltip>
                )}
            </>
          )}
        </div>
      ),
    },
  ]

  const handlePending = async id => {
    loadingAnimationStore.showSpinner(true)
    try {
      var toEmail, createdBy, invoiceNo, totalAmount
      var invoiceDate = new Date()
      await settingStore.getSetting().then(response => {
        if (response && response.data && response.data.length > 0) {
          var data = response?.data?.find(x => x.name == 'ApprovePerson')
          toEmail = data?.value
        }
      })
      await importStore.getImportInvoiceDetail(id).then(response => {
        createdBy = response?.data?.masterDTO?.created_by
        invoiceNo = response?.data?.masterDTO?.invoice_no
        invoiceDate = new Date(response?.data?.masterDTO?.invoice_date)
        totalAmount = response?.data?.masterDTO?.total_amount
      })
      const formattedDate = `${invoiceDate.getDate()}/${
        invoiceDate.getMonth() + 1
      }/${invoiceDate.getFullYear()}`
      const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Order Ready Notification</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              background-color: #f4f4f4;
            }
            .container {
              width: 100%;
              max-width: 600px;
              background: #ffffff;
              padding: 20px;
            }
            h1 {
              color: #333333;
            }
            p {
              color: #666666;
              line-height: 1.5;
            }
            .button {
              display: inline-block;
              padding: 10px 20px;
              font-size: 16px;
              color: #ffffff !important;
              background-color: #007bff;
              text-decoration: none;
              border-radius: 5px;
            }
          </style>
        </head>
        <body>
          <div class="container">
            <h1>Chào bạn,</h1>
            <p>Hiện tại đang có phiếu nhập kho cần bạn duyệt. Dưới đây là thông tin cơ bản của phiếu nhập kho:</p>
            <p><strong>Số phiếu nhập:</strong> ${invoiceNo}</p>
            <p><strong>Ngày đặt hàng:</strong> ${formattedDate}</p>
            <p><strong>Tổng tiền:</strong> ${formatNumber(totalAmount)} vnđ</p>
            <a
              href=\`${systemUrl}/warehouse/approval\`
              class="button"
            >
              Tới duyệt phiếu nhập kho
            </a>
            <p>Cảm ơn bạn đã sử dụng dịch vụ của chúng tôi!</p>
            <p>Trân trọng,<br />Đội ngũ hỗ trợ</p>
          </div>
        </body>
      </html>
      `
      if (toEmail === undefined || toEmail === null || toEmail === '') {
        message.warning('Không có người duyệt nào được cấu hình!')
        return
      }
      await importStore.sendEmail(
        toEmail,
        'PHIẾU NHẬP KHO ĐANG CHỜ DUYỆT',
        htmlContent,
        [createdBy]
      )
      await importStore.updateStatus(id, 'PENDING')
      message.success(
        'Chuyển trạng thái đơn nhập kho thành chờ duyệt thành công!'
      )
      await fetchImportInvoice()
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }
  const mapToOutwardStockDetailDTO = (detail, itemNameMap) => ({
    id: detail.id,
    inward_id: detail.inward_id,
    item_id: detail.item_id,
    item_name: itemNameMap?.find(x => x.id == detail.item_id)?.item_name, // Set to null or handle as needed
    campus_id: detail.campus_id,
    unit_id: detail.unit_id,
    unit_name: itemNameMap?.find(x => x.id == detail.item_id)?.unit_name, // Set to null or handle as needed
    quantity: detail.quantity,
    unit_price: detail.unit_price,
    deleted: detail.deleted,
    tax: detail.tax,
  })
  const handleDelete = async id => {
    loadingAnimationStore.showSpinner(true)
    try {
      importStore.getImportInvoiceDetail(id).then(async res => {
        await importStore.deleteImportInvoice(id)

        var setOldMaster = res.data.masterDTO
        var setOldDetails = res.data.details
        await inventoryItemStore
          .getInventoryItem(1, 9999, '')
          .then(async res => {
            var itemList = res.data.pageData
            if (setOldDetails) {
              const convertedDetails = setOldDetails.map(detail =>
                mapToOutwardStockDetailDTO(detail, itemList)
              )
              if (convertedDetails) {
                var auditLogRequest = {
                  table_name: 'Nhập kho',
                  action: 'Xóa',
                  oldObj: setOldMaster,
                  newObj: null,
                  oldDetails: convertedDetails,
                  newDetails: null,
                }
                await importStore.addAuditLog(auditLogRequest)
              }
            }
          })
      })

      message.success('Xóa đơn nhập kho thành công!')
      await fetchImportInvoice()
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
      await fetchImportInvoice()
    }
  }

  const menu = (
    <Menu>
      <Menu.Item key="nhap-file-xlsx">
        <a onClick={() => setOpenImportExcelCreate(true)}>Nhập file XLSX</a>
      </Menu.Item>
    </Menu>
  )

  const handlePrint = () => {
    const data = importData.map((item, index) => ({
      STT: index + 1,
      'SỐ PHIẾU NHẬP': item.invoice_no || '',
      'NGÀY ĐẶT HÀNG': item.invoice_date
        ? new Date(item.invoice_date).toLocaleDateString()
        : '',
      'NHÀ CUNG CẤP': item.supplier_name || '',
      'NGÀY NHẬN': item.received_at
        ? new Date(item.received_at).toLocaleDateString()
        : '',
      'THÀNH TIỀN': formatNumber(item.total_amount) || '',
      'GHI CHÚ': item.note || '',
    }))

    utils.exportToExcel({ data, fileName: 'import-data' })
  }

  return (
    <>
      <DashboardLayout>
        <Helmet>
          <title>Quản lý nhập kho</title>
        </Helmet>
        <PageTitle
          location={props.location}
          title={'Quản lý nhập kho'}
          hiddenGoBack>
          {isAccessPermission(PermissionType.INWARD_INVENTORY_CREATE) && (
            <Dropdown overlay={menu} placement="bottomLeft">
              <Button
                onClick={() => {
                  setOpenImportCreate(true)
                }}
                type={'primary'}>
                Thêm | <CaretDownOutlined />
              </Button>
            </Dropdown>
          )}
        </PageTitle>
        <ContentBlockWrapper>
          <Container>
            <Row style={{ marginBottom: 0, height: '38px' }}>
              <Col span={12}>
                {!showAdvancedFilter ? (
                  <SearchBar>
                    <Search
                      allowClear
                      onSearch={value => {
                        setPage(1), setPageSize(10), setSearchValue(value)
                      }}
                      maxLength={500}
                      placeholder={
                        'Tìm kiếm theo Số phiếu nhập / Nhà cung cấp / Ghi chú'
                      }
                    />
                  </SearchBar>
                ) : null}
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <FilterButtonWrapper>
                  <span
                    className={'count-filter'}
                    style={{ display: !countFilter && 'none' }}>
                    {countFilter}
                  </span>
                  <Button
                    type={showAdvancedFilter ? 'primary' : 'default'}
                    ghost={showAdvancedFilter}
                    style={{ marginBottom: showAdvancedFilter ? 10 : 0 }}
                    onClick={() => setShowAdvancedFilter(!showAdvancedFilter)}>
                    {showAdvancedFilter ? (
                      <Space>
                        <CloseCircleOutlined />
                        Ẩn tìm kiếm nâng cao
                      </Space>
                    ) : (
                      <Space>
                        <FilterOutlined />
                        Tìm kiếm nâng cao
                      </Space>
                    )}
                  </Button>
                </FilterButtonWrapper>
                <Button style={{ marginLeft: '10px' }} onClick={handlePrint}>
                  <PrinterOutlined />
                  Xuất excel
                </Button>
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    fetchImportInvoice()
                  }}>
                  <ReloadOutlined />
                </Button>
              </Col>
            </Row>
            {showAdvancedFilter ? (
              <AdvancedFilterBlock
                setPage={setPage}
                setPageSize={setPageSize}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                setFilters={setFilters}
                filters={filters}
                setCountFilter={setCountFilter}
              />
            ) : null}
            <TableWrapper>
              <ImportTable>
                <Table
                  className="importTable"
                  style={{
                    height: !showAdvancedFilter
                      ? 'calc(100vh - 213px)'
                      : 'calc(100vh - 282px)',
                    overflowY: 'auto',
                  }}
                  columns={columns}
                  dataSource={importData}
                  bordered
                  pagination={false}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                        setOpenImportDetailUpdate(true)
                        setImportId(record.key)
                        setType('detail')
                      },
                    }
                  }}
                />
              </ImportTable>
            </TableWrapper>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div>
                Tổng số: <b>{total}</b> bản ghi
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '8px',
                }}>
                <Pagination
                  onChange={(page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                  }}
                  current={page}
                  pageSize={pageSize}
                  total={total}
                  hideOnSinglePage={true}
                  showSizeChanger={true}
                  showLessItems
                />
              </div>
            </div>
          </Container>
        </ContentBlockWrapper>
      </DashboardLayout>
      <ImportCreateModal
        open={openImportCreate}
        setOpen={setOpenImportCreate}
        fetchImportInvoice={fetchImportInvoice}
        excelData={excelData}
        setExcelData={setExcelData}
      />
      <ImportExcelCreateModal
        open={openImportExcelCreate}
        setOpen={setOpenImportExcelCreate}
        setOpenImportCreate={setOpenImportCreate}
        setExcelData={setExcelData}
      />
      <ImportDetailUpdate
        open={openImportDetailUpdate}
        setOpen={setOpenImportDetailUpdate}
        importId={importId}
        setImportId={setImportId}
        type={type}
        fetchImportInvoice={fetchImportInvoice}
      />
    </>
  )
}

export default memo(
  inject(
    'importStore',
    'loadingAnimationStore',
    'accountStore',
    'settingStore',
    'inventoryItemStore'
  )(observer(ImportPage))
)
