import React, { memo, useEffect, useState, useCallback } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import {
  Table,
  Pagination,
  Button,
  Modal,
  Form,
  Input,
  message,
  Row,
  Col,
} from 'antd'
import { TableWrapper } from '../../components/Common/Table'
import {
  EditOutlined,
  DeleteOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { SearchBar, CategoryTable } from './CategoryPageStyled'
import { EmptyText } from '../../components/Common/CellText'
import { Container } from '../../layouts/Container/Container'
import * as signalR from '@microsoft/signalr'
import { apiUrl } from '../../config'

const { Search } = Input
const { confirm } = Modal

const CategoryPage = props => {
  const { categoryStore, loadingAnimationStore } = props
  const [categoryData, setCategoryData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [form] = Form.useForm()
  const [selectedCategoryId, setSelectedCategoryId] = useState(null)

  const fetchCategoryData = useCallback(async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await categoryStore.getCategories(page, pageSize, search)
      const { pageData, totalCount } = response.data
      const data = pageData.map((item, index) => ({
        key: item.id,
        stt: (page - 1) * pageSize + index + 1,
        category_name: item.category_name,
        description: item.description,
        id: item.id,
      }))
      setCategoryData(data)
      setTotal(totalCount)
    } catch (error) {
      console.error('Có lỗi xảy ra khi lấy danh sách danh mục')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [page, pageSize, search])

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiUrl}/categoryHub`)
      .configureLogging(signalR.LogLevel.None)
      .withAutomaticReconnect()
      .build()

    connection.on('CategorySignal', () => {
      fetchCategoryData()
    })

    connection
      .start()
      .then(() => {
        fetchCategoryData()
      })
      .catch(() => {
        fetchCategoryData()
      })

    return () => {
      connection.stop()
    }
  }, [fetchCategoryData])

  const openModalWithRecord = (record = null) => {
    if (record) {
      setSelectedCategoryId(record.id)
      form.setFieldsValue({
        category_name: record.category_name,
        description: record.description,
      })
    } else {
      setSelectedCategoryId(null)
      form.resetFields()
    }
    setIsModalOpen(true)
  }

  const handleSearch = value => {
    setSearch(value)
    setPage(1)
  }

  const handleModalCancel = () => {
    setIsModalOpen(false)
    form.resetFields()
    setSelectedCategoryId(null)
  }

  const handleDeleteCategory = async record => {
    try {
      await categoryStore.deleteCategory(record.id)
      fetchCategoryData()
      message.success('Xóa nhóm vật tư thành công ')

      categoryStore.addLog({
        table_name: 'Nhóm vật tư',
        action: 'Xóa',
        newObj: {},
        oldObj: record,
      })
    } catch (error) {}
  }

  const handleModalOk = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      const values = await form.validateFields()

      if (selectedCategoryId) {
        values.id = selectedCategoryId
        await categoryStore.updateCategory(values)
        message.success('Cập nhật nhóm hàng hóa thành công')

        categoryStore.addLog({
          table_name: 'Nhóm vật tư',
          action: 'Chỉnh sửa',
          newObj: values,
          oldObj: categoryData?.find(item => item?.id === selectedCategoryId),
        })
      } else {
        await categoryStore.createCategory(values)
        message.success('Thêm nhóm hàng hóa mới thành công')

        categoryStore.addLog({
          table_name: 'Nhóm vật tư',
          action: 'Thêm',
          newObj: values,
          oldObj: {},
        })
      }
      setIsModalOpen(false)
      form.resetFields()
      setSelectedCategoryId(null)
      fetchCategoryData()
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const columns = [
    {
      title: 'STT',
      align: 'center',
      width: '50px',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'TÊN NHÓM HÀNG HÓA',
      key: 'category_name',
      dataIndex: 'category_name',
      sorter: (a, b) => a.category_name.localeCompare(b.category_name),
      width: 200,
      render: text => (
        <span>{text || <EmptyText>Chưa có tên nhóm hàng hóa</EmptyText>}</span>
      ),
    },
    {
      title: 'MÔ TẢ',
      key: 'description',
      dataIndex: 'description',
      render: text => (
        <span>{text || <EmptyText>Chưa có mô tả</EmptyText>}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      width: 1,
      render: (text, record) => (
        <div style={{ display: 'flex' }}>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            size="small"
            onClick={() => openModalWithRecord(record)}></Button>
          <Button
            type="danger"
            shape="circle"
            icon={<DeleteOutlined />}
            style={{ marginLeft: 8 }}
            size="small"
            onClick={() => {
              confirm({
                title: 'Bạn có chắc chắn muốn xóa nhóm hàng hóa này không?',
                icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
                okText: 'Đồng ý',
                cancelText: 'Không',
                okType: 'danger',
                onOk() {
                  handleDeleteCategory(record)
                },
              })
            }}></Button>
        </div>
      ),
    },
  ]

  return (
    <DashboardLayout>
      <Helmet>
        <title>Quản lý nhóm hàng hóa</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title="Quản lý nhóm hàng hóa"
        hiddenGoBack></PageTitle>
      <ContentBlockWrapper>
        <Container>
          <Row style={{ marginBottom: 0, height: '38px' }}>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  maxLength={500}
                  placeholder={'Tìm kiếm theo Tên nhóm hàng hóa'}
                  onSearch={handleSearch}
                />
              </SearchBar>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button type="primary" onClick={() => openModalWithRecord()}>
                Thêm
              </Button>
            </Col>
          </Row>
          <TableWrapper>
            <CategoryTable>
              <Table
                style={{
                  height: 'calc(100vh - 213px)',
                  overflowY: 'auto',
                }}
                columns={columns}
                dataSource={categoryData}
                bordered
                pagination={false}
              />
            </CategoryTable>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div>
                Tổng số: <b>{total}</b> bản ghi
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '8px',
                }}>
                <Pagination
                  onChange={(page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                  }}
                  current={page}
                  pageSize={pageSize}
                  total={total}
                  hideOnSinglePage={true}
                  showSizeChanger={true}
                  showLessItems
                />
              </div>
            </div>
          </TableWrapper>
        </Container>
      </ContentBlockWrapper>
      <Modal
        title={
          selectedCategoryId
            ? 'Chỉnh sửa nhóm hàng hóa'
            : 'Thêm nhóm hàng hóa mới'
        }
        visible={isModalOpen}
        okText={selectedCategoryId ? 'Cập nhật' : 'Thêm'}
        onCancel={handleModalCancel}
        onOk={handleModalOk}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="category_name"
            label="Tên nhóm hàng hóa"
            rules={[
              { required: true, message: 'Vui lòng nhập tên nhóm hàng hóa' },
            ]}>
            <Input placeholder="Nhập tên nhóm hàng hóa" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Mô tả"
            rules={[{ required: false }]}>
            <Input.TextArea rows={4} placeholder="Nhập mô tả" />
          </Form.Item>
        </Form>
      </Modal>
    </DashboardLayout>
  )
}

export default memo(
  inject('categoryStore', 'loadingAnimationStore')(observer(CategoryPage))
)
