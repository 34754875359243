import React, { memo, useEffect, useState } from 'react'
import {
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Steps,
  Upload,
  message,
  Typography,
  Button,
} from 'antd'
import { inject, observer } from 'mobx-react'
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons'
import XLSX from 'xlsx'

const { Step } = Steps
const { Dragger } = Upload
const { Paragraph, Text } = Typography

const ExportExcelCreateModal = props => {
  const {
    open,
    setOpen,
    exportStore,
    departmentStore,
    unitStore,
    setOpenExportCreate,
    setExcelData,
    inventoryItemStore,
  } = props
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])
  const [unitList, setUnitList] = useState([])
  const [currentStep, setCurrentStep] = useState(0)

  const tips = [
    'Tệp Excel phải có định dạng như mẫu để nhập thành công.',
    'Các trường bắt buộc phải có dữ liệu: Số HĐ, ngày ĐH, phòng ban.',
    'Số HĐ không được trùng với các số HĐ đã có trong hệ thống.',
    'Định dạng ngày ĐH phải là dd-mm-yyyy hoặc dd/mm/yyyy.',
    'Nhà cung cấp phải tồn tại trong hệ thống.',
    'Với những mã hàng đã có trong hệ thống chỉ cần nhập mã hàng với số lượng và số lượng sẽ được tổng hợp.',
  ]

  useEffect(() => {
    fetchUnits()
  }, [])

  const fetchUnits = async () => {
    const response = await unitStore.getUnit(1, 9999, '')
    setUnitList(response.data.pageData)
  }

  const downloadTemplate = () => {
    const ws = XLSX.utils.aoa_to_sheet([
      [
        'Số HĐ',
        'Ngày ĐH',
        'Phòng ban',
        'Mã hàng',
        'Nội dung',
        'ĐVT',
        'Số lượng',
        'Đơn giá',
        'Ghi chú',
      ],
    ])

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Template')

    const date = new Date()
    const formattedDate = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`
    const fileName = `Xuất kho ${formattedDate}.xlsx`

    XLSX.writeFile(wb, fileName)
  }

  const handleFileUpload = async ({ file, onSuccess, onError }) => {
    setCurrentStep(1)
    const reader = new FileReader()
    reader.onload = async e => {
      try {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: 'array' })

        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
        jsonData = jsonData.filter(row =>
          row.some(cell => cell !== undefined && cell !== null && cell !== '')
        )

        if (jsonData.length < 2) {
          message.error('File Excel không có dữ liệu hợp lệ!')
          setFileList([])
          onError('error')
          return
        }

        const isExistInvoice = await exportStore.checkExistInvoiceNo(
          jsonData[1][0]
        )
        if (isExistInvoice.data) {
          message.error('Số HĐ đã tồn tại!')
          setFileList([])
          onError('error')
          return
        }

        const rawDate = jsonData[1][1]
        let formattedDate = ''

        if (typeof rawDate === 'number') {
          const date = new Date((rawDate - 25569) * 86400 * 1000)
          const isoString = date.toISOString().split('T')[0]
          const [year, month, day] = isoString.split('-')
          formattedDate = `${day}/${month}/${year}`
        } else {
          const dateFormatRegex = /^(\d{1,2})[-/](\d{1,2})[-/](\d{4})$/
          const match = rawDate.match(dateFormatRegex)
          if (match) {
            const [_, day, month, year] = match
            const date = new Date(Date.UTC(year, month - 1, day))
            const isoString = date.toISOString().split('T')[0]
            const [isoYear, isoMonth, isoDay] = isoString.split('-')
            formattedDate = `${isoMonth}/${isoDay}/${isoYear}`
          } else {
            message.error('Ngày ĐH sai định dạng!')
            setFileList([])
            onError('error')
            return
          }
        }

        const dateFormatRegex = /^(0[1-9]|1[0-2])[-/](0[1-9]|[12][0-9]|3[01])[-/]\d{4}$/
        if (!dateFormatRegex.test(formattedDate)) {
          message.error('Ngày ĐH sai định dạng!')
          setFileList([])
          onError('error')
          return
        }

        const isExistDepartment = await departmentStore.checkExistDepartment(
          jsonData[1][2]
        )
        if (!isExistDepartment.data) {
          message.error('Phòng ban không tồn tại!')
          setFileList([])
          onError('error')
          return
        }

        const masterData = {
          invoice_no: jsonData[1][0],
          invoice_date: formattedDate,
          department_id: jsonData[1][2],
          note: jsonData[1][8],
        }

        if (
          !masterData.invoice_no ||
          !masterData.invoice_date ||
          !masterData.department_id
        ) {
          message.error(
            'Các cột Số HĐ, ngày ĐH và phòng ban là bắt buộc và không được để trống!'
          )
          setFileList([])
          onError('error')
          return
        }

        const detailsData = []

        for (let i = 1; i < jsonData.length; i++) {
          const row = jsonData[i]
          const itemId = row[3]
          const quantity = row[6]

          if (itemId) {
            const isExistItem = await inventoryItemStore.checkExistItem(itemId)
            if (isExistItem.data) {
              if (quantity <= 0) {
                message.error('Số lượng phải lớn hơn 0!')
                setFileList([])
                onError('error')
                return
              }

              const item = await inventoryItemStore.getInventoryItemByCode(
                itemId
              )
              detailsData.push({
                item_id: item.data.id,
                item_code: itemId,
                item_name: item.data.item_name,
                unit_id: item.data.unit_id,
                quantity: quantity,
                unit_price: row[7],
              })
            } else {
              message.error(`Mã hàng '${itemId}' không tồn tại trong kho!`)
              setFileList([])
              onError('error')
              return
            }
          }
        }

        setCurrentStep(2)

        const departmentId = await departmentStore.getDepartmentByName(
          masterData.department_id
        )
        setExcelData({
          master: {
            ...masterData,
            department_id: departmentId.data.id,
          },
          details: detailsData,
        })
        message.success('Nhập file thành công!')
      } catch (error) {
        message.error('Nhập file thất bại!')
        setFileList([])
        onError('error')
      }
    }
    reader.onerror = () => {
      message.error('Nhập file thất bại!')
      setFileList([])
      onError('error')
    }
    reader.readAsArrayBuffer(file)
  }

  const handleChange = info => {
    let newFileList = [...info.fileList]

    newFileList = newFileList.slice(-1)

    setFileList(newFileList)
  }

  const handleNextStep = () => {
    setOpen(false)
    setFileList([])
    setCurrentStep(0)
    setOpenExportCreate(true)
  }

  return (
    <Modal
      title={'Thêm đơn xuất kho từ file Excel'}
      width={'100vw'}
      visible={open}
      onCancel={() => {
        setOpen(false)
        setFileList([])
        setCurrentStep(0)
        setExcelData({ master: {}, details: [] })
      }}
      footer={false}>
      <Steps current={currentStep}>
        <Step title="Chọn tệp nguồn" />
        <Step title="Kiểm tra dữ liệu" />
        <Step title="Kết quả" />
      </Steps>
      <Divider />
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Loại chứng từ" name="invoice_type">
              <Input disabled placeholder="Xuất kho" />
            </Form.Item>
            <Form.Item
              label="Chọn tệp Excel"
              name="excel_file"
              rules={[{ required: true, message: 'Vui lòng chọn tệp Excel' }]}>
              <Dragger
                customRequest={handleFileUpload}
                accept=".xlsx"
                fileList={fileList}
                onChange={handleChange}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Nhấp hoặc kéo tệp vào khu vực này để tải lên
                </p>
                <p className="ant-upload-hint">
                  Hỗ trợ tải lên một lần hoặc hàng loạt
                </p>
              </Dragger>
            </Form.Item>
            <p>
              <a
                href="#"
                style={{ marginRight: '5px' }}
                onClick={downloadTemplate}>
                Tải tệp mẫu
              </a>
              <DownloadOutlined />
            </p>
          </Col>
          <Col span={12}>
            <img
              className="icon"
              src={`${process.env.PUBLIC_URL}/assets/icons/import/tips.png`}
            />
            <Paragraph>
              {tips.map((tip, index) => (
                <Text key={index}>
                  - {tip}
                  <br />
                </Text>
              ))}
            </Paragraph>
          </Col>
        </Row>
      </Form>
      {currentStep === 2 && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Button type="primary" onClick={handleNextStep}>
            Bước tiếp theo
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default memo(
  inject(
    'exportStore',
    'departmentStore',
    'unitStore',
    'inventoryItemStore'
  )(observer(ExportExcelCreateModal))
)
