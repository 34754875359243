import { inject, observer } from 'mobx-react'
import React, { memo, useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Container } from '../../layouts/Container/Container'
import { ItemsByDepartmentsTable } from './ItemsByDepartmentsReportPageStyled'
import {
  Button,
  Col,
  DatePicker,
  Form,
  message,
  Modal,
  Row,
  Select,
  Table,
  TreeSelect,
} from 'antd'
import './ItemsByDepartmentsReportPageStyled.css'
import { EmptyText } from '../../components/Common/CellText'
import { MinusOutlined, PlusOutlined, PrinterOutlined } from '@ant-design/icons'
import * as XLSX from 'xlsx'

const { RangePicker } = DatePicker
const { Option } = Select

const ItemsByDepartmentsReportPage = props => {
  const { loadingAnimationStore, departmentStore, inventoryItemStore } = props
  const [isOpen, setIsOpen] = useState(true)
  const [form] = Form.useForm()
  const [departmentList, setDepartmentList] = useState([])
  const [treeData, setTreeData] = useState([])
  const [tableData, setTableData] = useState([])
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')

  useEffect(() => {
    loadingAnimationStore.showSpinner(false)
    try {
      departmentStore.getDepartment(1, 9999, '').then(response => {
        if (response && response?.data && response?.data?.pageData) {
          setDepartmentList(response.data.pageData)
        }
      })
      inventoryItemStore.getItemByCategory().then(response => {
        if (response && response?.data) {
          const data = response.data.map(category => ({
            title: category.category_name,
            value: category.id,
            key: category.id,
            children: category.inventory_items?.map(item => ({
              title: `${item.item_code} - ${item.item_name}`,
              value: item.id,
              key: item.id,
            })),
          }))
          setTreeData(data)

          form.setFieldsValue({ department_id: 'all' })
          const allItemIds = data.flatMap(category =>
            category.children.map(item => item.value)
          )
          form.setFieldsValue({ item_id: allItemIds })
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [isOpen])

  function formatNumber(value) {
    if (value == null) return ''
    const roundedValue = Number(value).toFixed(2)
    const [integerPart, decimalPart] = roundedValue.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }

  const handleSubmit = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      await form.validateFields()
      const formData = form.getFieldsValue()
      const { date, department_id, item_id } = formData
      var departmentId
      if (department_id == 'all') {
        departmentId = departmentList.map(department => department.id)
      } else {
        departmentId = department_id
      }
      const [startDateSelected, endDateSelected] = date || []
      const startDate = startDateSelected ? startDateSelected.toISOString() : ''
      const endDate = endDateSelected ? endDateSelected.toISOString() : ''
      setFromDate(startDate)
      setToDate(endDate)

      await inventoryItemStore
        .getItemByDepartment({
          startDate,
          endDate,
          departmentIds: departmentId,
          itemIds: item_id,
        })
        .then(response => {
          if (response && response?.data && response?.data?.length > 0) {
            const dataWithKeys = response.data.map((department, index) => {
              const filteredItems = department.inventory_items.filter(
                item => item.outward_non_business_type !== 1
              )
              const total = formatNumber(
                filteredItems.reduce((sum, item) => sum + item.total_amount, 0)
              )
              return {
                ...department,
                key: index + 1,
                total,
              }
            })
            setTableData(dataWithKeys)
            message.success('Tải báo cáo thành công!')
            form.resetFields()
            setIsOpen(false)
          } else {
            setTableData([])
            message.info('Báo cáo không có dữ liệu!')
            form.resetFields()
            setIsOpen(false)
          }
        })
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      width: '20px',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'PHÒNG BAN',
      dataIndex: 'department_name',
      key: 'department_name',
      render: text => (
        <span>{text || <EmptyText>Chưa có phòng ban</EmptyText>}</span>
      ),
    },
    {
      title: 'TỔNG TIỀN (VNĐ)',
      dataIndex: 'total',
      key: 'total',
    },
  ]

  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const expandedRowRender = record => {
    if (record.inventory_items) {
      const itemData = record.inventory_items.map(item => ({
        ...item,
        key: `${item.key}`,
      }))

      return (
        <Table
          className="expandedTable"
          columns={[
            {
              title: 'MÃ SẢN PHẨM',
              dataIndex: 'item_code',
              key: 'item_code',
              width: '100px',
            },
            {
              title: 'TÊN SẢN PHẨM',
              dataIndex: 'item_name',
              key: 'item_name',
              width: '400px',
            },
            {
              title: 'LOẠI VẬT TƯ',
              dataIndex: 'export_type',
              key: 'export_type',
              width: '150px',
              render: text => (
                <span>{text === 0 ? 'Tiêu hao' : 'Phi kinh doanh'}</span>
              ),
            },
            {
              title: 'LOẠI PHI KINH DOANH',
              dataIndex: 'outward_non_business_type',
              key: 'outward_non_business_type',
              width: '150px',
              render: text => {
                let displayText
                switch (text) {
                  case 1:
                    displayText = 'Hoàn trả'
                    break
                  case 2:
                    displayText = 'Cấp phát'
                    break
                  case 3:
                    displayText = 'Bán'
                    break
                  case 4:
                    displayText = 'Thanh lý'
                    break
                  default:
                    displayText = 'Xuất tiêu hao'
                }
                return <span>{displayText}</span>
              },
            },
            {
              title: 'SỐ LƯỢNG',
              dataIndex: 'total_quantity',
              key: 'total_quantity',
              width: '50px',
              render: text => (
                <span>
                  {formatNumber(text) || (
                    <EmptyText>Chưa có số lượng</EmptyText>
                  )}
                </span>
              ),
            },
            {
              title: 'SỐ LƯỢNG TRẢ',
              dataIndex: 'total_returned',
              key: 'total_returned',
              width: '60px',
              render: (text, record) => (
                <span>
                  {record.outward_non_business_type === 1 ? (
                    <>
                      {formatNumber(text)} (trả)
                      {record.broken > 0 && (
                        <>
                          <br />
                          <span style={{ color: 'red' }}>
                            {formatNumber(record.broken)} (hỏng)
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </span>
              ),
            },
            {
              title: 'ĐƠN VỊ TÍNH',
              dataIndex: 'unit_name',
              key: 'unit_name',
              width: '50px',
            },
            {
              title: 'TỔNG TIỀN',
              dataIndex: 'total_amount',
              key: 'total_amount',
              width: '150px',
              render: (text, record) => (
                <span>
                  {record.outward_non_business_type === 1
                    ? '-'
                    : formatNumber(text) || '-'}
                </span>
              ),
            },
          ]}
          dataSource={itemData}
          pagination={false}
          showHeader={false}
          bordered
          rowKey="key"
        />
      )
    }
    return null
  }

  const handleExport = () => {
    const exportData = []

    tableData.forEach(department => {
      department.inventory_items.forEach(item => {
        exportData.push({
          'PHÒNG BAN': department.department_name,
          'MÃ SẢN PHẨM': item.item_code,
          'TÊN SẢN PHẨM': item.item_name,
          'LOẠI VẬT TƯ': item.export_type === 0 ? 'Tiêu hao' : 'Phi kinh doanh',
          'LOẠI PHI KINH DOANH':
            item.outward_non_business_type === 1
              ? 'Hoàn trả'
              : item.outward_non_business_type === 2
              ? 'Cấp phát'
              : item.outward_non_business_type === 3
              ? 'Bán'
              : '-',
          'SỐ LƯỢNG': item.total_quantity,
          'SỐ LƯỢNG TRẢ':
            item.outward_non_business_type === 1 ? item.total_returned : '-',
          'TỔNG TIỀN': item.total_amount,
          'TỔNG TIỀN PHÒNG BAN': department.total,
        })
      })
    })

    const worksheet = XLSX.utils.json_to_sheet(exportData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Báo cáo CTMHTPB')

    XLSX.writeFile(workbook, 'Báo cáo CTMHTPB.xlsx')
  }

  const expandAll = () => {
    if (expandedRowKeys.length === tableData?.length) {
      setExpandedRowKeys([])
    } else {
      const allKeys = tableData?.map(item => item.key)
      setExpandedRowKeys(allKeys)
    }
  }

  return (
    <>
      <DashboardLayout>
        <Helmet>
          <title>Báo cáo chi tiết mặt hàng theo phòng ban</title>
        </Helmet>
        <PageTitle
          location={props.location}
          title={'Báo cáo chi tiết mặt hàng theo phòng ban'}
          hiddenGoBack></PageTitle>
        <ContentBlockWrapper>
          <Container>
            <div
              style={{
                marginBottom: '8px',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <div>
                <Button onClick={expandAll}>
                  {expandedRowKeys?.length === tableData?.length ? (
                    <MinusOutlined />
                  ) : (
                    <PlusOutlined />
                  )}
                  {expandedRowKeys?.length === tableData?.length
                    ? 'Thu gọn tất cả'
                    : 'Mở rộng tất cả'}
                </Button>
              </div>
              <div>
                <Button onClick={handleExport} style={{ marginRight: '10px' }}>
                  <PrinterOutlined /> Xuất Excel
                </Button>
                <Button onClick={() => setIsOpen(true)} type={'primary'}>
                  Chọn tham số
                </Button>
                <div></div>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                marginBottom: '4px',
              }}>
              <strong style={{ display: !fromDate || !toDate ? 'none' : '' }}>
                Thời gian: {new Date(fromDate).toLocaleDateString('en-GB')} -{' '}
                {new Date(toDate).toLocaleDateString('en-GB')}
              </strong>
            </div>
            <ItemsByDepartmentsTable>
              <Table
                id="reportTable"
                className="reportTable"
                style={{
                  height: 'calc(100vh - 200px)',
                  overflowY: 'auto',
                }}
                columns={columns}
                dataSource={tableData}
                bordered
                pagination={false}
                expandable={{
                  expandedRowRender: record => (
                    <div style={{ margin: 0 }}>{expandedRowRender(record)}</div>
                  ),
                  expandedRowKeys,
                  onExpand: (expanded, record) => {
                    setExpandedRowKeys(
                      expanded
                        ? [...expandedRowKeys, record.key]
                        : expandedRowKeys.filter(key => key !== record.key)
                    )
                  },
                }}
              />
            </ItemsByDepartmentsTable>
          </Container>
        </ContentBlockWrapper>
      </DashboardLayout>
      <Modal
        title={'Chọn tham số'}
        width={1000}
        visible={isOpen}
        okText={'Xem báo cáo'}
        onOk={handleSubmit}
        onCancel={() => {
          form.resetFields()
          setIsOpen(false)
        }}
        maskClosable={false}>
        <Form form={form} layout="vertical" style={{ marginTop: '20px' }}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name={'date'}
                label={'Thời gian'}
                rules={[
                  { required: true, message: 'Vui lòng chọn thời gian' },
                ]}>
                <RangePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={'department_id'}
                label={'Phòng ban'}
                rules={[
                  { required: true, message: 'Vui lòng chọn phòng ban' },
                ]}>
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  placeholder="Chọn phòng ban"
                  mode="multiple"
                  maxTagCount={5}
                  maxTagPlaceholder={omittedValues =>
                    `+${omittedValues.length} phòng ban`
                  }
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .includes(input.trim().toLowerCase())
                  }>
                  <Option value="all">Tất cả phòng ban</Option>
                  {departmentList.map(department => (
                    <Option key={department.id} value={department.id}>
                      {department.department_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'item_id'}
                label={'Sản phẩm'}
                rules={[{ required: true, message: 'Vui lòng chọn sản phẩm' }]}>
                <TreeSelect
                  style={{ width: '100%' }}
                  allowClear
                  placeholder="Chọn sản phẩm"
                  treeCheckable={true}
                  showCheckedStrategy={TreeSelect.SHOW_CHILD}
                  treeData={treeData}
                  maxTagCount={5}
                  maxTagPlaceholder={omittedValues =>
                    `+${omittedValues.length} sản phẩm`
                  }
                  showSearch
                  filterTreeNode={(input, treeNode) =>
                    treeNode.title
                      .toLowerCase()
                      .includes(input.trim().toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default memo(
  inject(
    'loadingAnimationStore',
    'departmentStore',
    'inventoryItemStore'
  )(observer(ItemsByDepartmentsReportPage))
)
