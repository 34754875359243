import { apiUrl } from '../config'
import axios from 'axios'

export const ContractRequest = {
  addContract: data =>
    axios.post(`${apiUrl}/api/v1/contracts/create`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getContractBySupplierId: id =>
    axios.get(`${apiUrl}/api/v1/contracts/supplier/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
}
