import React, { memo, useEffect, useState, useCallback } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import {
  Table,
  Pagination,
  Button,
  Modal,
  Form,
  Input,
  message,
  Row,
  Col,
  DatePicker,
  Upload,
  Select,
  Tooltip,
} from 'antd'
import { TableWrapper } from '../../components/Common/Table'
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { SearchBar, SupplierTable } from '../SupplierPage/SupplierPageStyled'
import { EmptyText } from '../../components/Common/CellText'
import { Container } from '../../layouts/Container/Container'
import moment from 'moment'
import { Description } from '../DashboardPage/DashboardPageStyled'

const { Search } = Input
const { confirm } = Modal

const SupplierPage = props => {
  const {
    supplierStore,
    loadingAnimationStore,
    contractStore,
    invoiceStore,
  } = props
  const [supplierData, setSupplierData] = useState([])
  const [invoiceData, setInvoiceData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const [selectedSupplierId, setSelectedSupplierId] = useState(null)
  const [pdfVisible, setPdfVisible] = useState(false)
  const [pdfUrl, setPdfUrl] = useState()
  const [contractModalVisible, setContractModalVisible] = useState(false)
  const [contractData, setContractData] = useState([])
  const [extensionModalVisible, setExtensionModalVisible] = useState(false)

  const beforeUpload = file => {
    const isPDF = file.type === 'application/pdf'
    const isLt2M = file.size / 1024 / 1024 < 2

    if (!isPDF) {
      message.error('You can only upload PDF files!')
      return Upload.LIST_IGNORE // Prevent upload
    }

    if (!isLt2M) {
      message.error('File must be smaller than 2MB!')
      return Upload.LIST_IGNORE // Prevent upload
    }

    return false // Allow upload
  }

  const handleFileUpload = async ({ file, onSuccess, onError }) => {
    const maxSizeInMB = 2
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024

    if (!file) {
      onError(new Error('Chưa chọn tệp'))
      return
    }

    if (file.size > maxSizeInBytes) {
      onError(new Error(`Kích thước tệp không được vượt quá ${maxSizeInMB}MB`))
      return
    }

    const formData = new FormData()
    formData.append('file', file.originFileObj)

    try {
      const response = await invoiceStore.uploadInvoice(formData)
      onSuccess(response.data, file)
      return response.data.fileUrl
    } catch (error) {
      onError(error)
      console.error('Tải lên tệp tin không thành công:', error)
      return null
    }
  }

  function convertToDateFormat(isoDateString) {
    const date = new Date(isoDateString)

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
  }

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const res = await supplierStore.getSupplierDTO(1, 9999, '')
        if (res && res.data && res.data.pageData) {
          let dataSupplier = res.data.pageData
          dataSupplier = dataSupplier.filter(
            x => new Date(x.end_date) > new Date()
          )
          setSupplierData(dataSupplier)
        }
      } catch (error) {
        console.error('Error fetching suppliers:', error)
      }
    }

    fetchSuppliers()
  }, [])

  const fetchInvoiceData = useCallback(async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await invoiceStore.getPagingInvoice(
        page,
        pageSize,
        search,
        []
      )
      const { pageData, totalCount } = response.data
      const data = pageData.map((item, index) => ({
        key: item.id,
        supplier_name: item.supplier_name,
        description: item.description,
        supplier_id: item.supplier_id,
        import_date: convertToDateFormat(item.import_date),
        link_file: item.link_file,
      }))
      setInvoiceData(data)
      setTotal(totalCount)
    } catch (error) {
      console.error('Failed to fetch suppliers:', error)
      message.error('Failed to fetch suppliers')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }, [page, pageSize, search])

  useEffect(() => {
    fetchInvoiceData()
  }, [fetchInvoiceData])

  const handleSearch = value => {
    setSearch(value)
    setPage(1)
  }

  const handleCancel = () => {
    setVisible(false)
    form.resetFields()
    setSelectedSupplierId(null)
  }

  const handleOk = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      const values = await form.validateFields()
      const fileUrl = await handleFileUpload({
        file: form.getFieldValue('link_file')[0],
        onSuccess: data => {
          setPdfUrl(data.fileUrl)
        },
        onError: error => {
          console.error('Upload error:', error)
        },
      })
      if (!fileUrl) {
        return
      }

      const data = {
        supplier_id: values.supplier_id,
        supplier_name: supplierData.find(x => x.id == values.supplier_id)
          .supplier_name,
        description: values.description,
        import_date: values.import_date,
        link_file: fileUrl,
      }
      if (selectedSupplierId) {
        data.id = selectedSupplierId
        await invoiceStore.editInvoice(data)
        message.success('Chỉnh sửa hóa đơn thành công!')
      } else {
        await invoiceStore.addNewInvoice(data)
        message.success('Thêm hóa đơn thành công!')
      }
      setVisible(false)
      form.resetFields()
      setSelectedSupplierId(null)
      fetchInvoiceData()
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleEdit = record => {
    setSelectedSupplierId(record.key)
    form.setFieldsValue({
      supplier_id: record.supplier_id,
      supplier_name: record.supplier_name,
      description: record.description,
      import_date: record.import_date
        ? moment(record.import_date, 'DD-MM-YYYY') // Ensure the format matches the DatePicker format
        : null,
    })

    if (record.link_file) {
      // Assuming `record.link_file` is the URL or path to the previously uploaded file
      const file = {
        uid: '-1', // Unique identifier for the file
        name: 'UploadedInvoice.pdf', // File name
        status: 'done', // File status
        url: record.link_file, // URL to the previously uploaded file
      }
      form.setFieldsValue({
        link_file: [file], // Set the file list with the previously uploaded file
      })
    } else {
      form.setFieldsValue({
        link_file: [], // No file previously uploaded
      })
    }

    setVisible(true) // Show the modal or form for editing
  }

  const handleViewContract = contractResource => {
    const previewUrl = contractResource.replace('/view', '/preview')
    setPdfUrl(previewUrl)
    setPdfVisible(true)
  }

  const handleContractModalCancel = () => {
    setContractModalVisible(false)
    setContractData([])
  }

  const handlePdfCancel = () => {
    setPdfVisible(false)
    setPdfUrl(null)
  }

  const handleDelete = async record => {
    try {
      await invoiceStore.deleteInvoice(record.key)
      message.success('Xóa hóa đơn thành công!')
      fetchInvoiceData()
    } catch (error) {}
  }

  const contractColumns = [
    {
      title: 'STT',
      key: 'stt',
      width: '60px',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'Ngày bắt đầu',
      key: 'start_date',
      dataIndex: 'start_date',
      render: text => (
        <span>{text || <EmptyText>Chưa có ngày bắt đầu</EmptyText>}</span>
      ),
    },
    {
      title: 'Ngày kết thúc',
      key: 'end_date',
      dataIndex: 'end_date',
      render: text => (
        <span>{text || <EmptyText>Chưa có ngày kết thúc</EmptyText>}</span>
      ),
    },
    {
      title: '',
      key: 'view',
      render: (text, record) => (
        <Button
          onClick={() => handleViewContract(record.contract_resource)}
          icon={<EyeOutlined />}
        />
      ),
    },
  ]

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      width: '20px',
      render: (text, record, index) => (
        <span>{(page - 1) * pageSize + index + 1}</span>
      ),
    },
    {
      title: 'TÊN NHÀ CUNG CẤP',
      key: 'nhaCungCap',
      dataIndex: 'supplier_name',
      render: text => (
        <span>{text || <EmptyText>Chưa có tên nhà cung cấp</EmptyText>}</span>
      ),
    },
    {
      title: 'NGÀY NHẬP KHO',
      key: 'ngaybatdau',
      dataIndex: 'import_date',
      render: text => (
        <span>{text || <EmptyText>Chưa có ngày nhập kho</EmptyText>}</span>
      ),
    },
    {
      title: 'MÔ TẢ',
      key: 'moTa',
      dataIndex: 'description',
      render: text => (
        <span>{text || <EmptyText>Chưa có mô tả</EmptyText>}</span>
      ),
    },

    {
      title: '',
      key: '#',
      dataIndex: 'id',
      width: 1,
      render: (text, record) => (
        <div style={{ display: 'flex' }}>
          <Tooltip title="Xem hợp đồng">
            <Button
              style={{
                backgroundColor: 'green',
                color: 'white',
                marginLeft: '8px',
              }}
              onClick={() => handleViewContract(record.link_file)}
              shape="circle"
              icon={<EyeOutlined />}
              size="small"
            />
          </Tooltip>
          <Tooltip title="Chỉnh sửa">
            <Button
              style={{ marginLeft: '5px' }}
              onClick={() => handleEdit(record)}
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              size="small"
            />
          </Tooltip>
          <Tooltip title="Xóa">
            <Button
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
              style={{ marginLeft: 8 }}
              size="small"
              onClick={() => {
                confirm({
                  title: 'Bạn có chắc chắn muốn xóa hóa đơn này không?',
                  icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
                  okText: 'Đồng ý',
                  cancelText: 'Không',
                  okType: 'danger',
                  onOk() {
                    handleDelete(record)
                  },
                })
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ]

  return (
    <DashboardLayout>
      <Helmet>
        <title>Quản lý hóa đơn</title>
      </Helmet>
      <PageTitle location={props.location} title="Quản lý hóa đơn" hiddenGoBack>
        <Button type="primary" onClick={() => setVisible(true)}>
          Tải lên hóa đơn
        </Button>
      </PageTitle>
      <ContentBlockWrapper>
        <Container>
          <Row style={{ marginBottom: 0, height: '38px' }}>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  maxLength={500}
                  placeholder={'Tìm kiếm'}
                  onSearch={handleSearch}
                />
              </SearchBar>
            </Col>
          </Row>
          <TableWrapper>
            <SupplierTable>
              <Table
                className="exportTable"
                style={{
                  height: 'calc(100vh - 213px)',
                  overflowY: 'auto',
                }}
                columns={columns}
                dataSource={invoiceData}
                bordered
                pagination={false}
              />
            </SupplierTable>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div>
                Tổng số: <b>{total}</b> bản ghi
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '8px',
                }}>
                <Pagination
                  onChange={(page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                  }}
                  current={page}
                  pageSize={pageSize}
                  total={total}
                  hideOnSinglePage={true}
                  showSizeChanger={true}
                  showLessItems
                />
              </div>
            </div>
          </TableWrapper>
        </Container>
      </ContentBlockWrapper>
      <Modal
        title="Chi tiết hợp đồng"
        visible={contractModalVisible}
        onCancel={handleContractModalCancel}
        footer={null}
        width={800}>
        <Table
          columns={contractColumns}
          dataSource={contractData}
          pagination={false}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 16,
          }}>
          <Button key="back" onClick={handleContractModalCancel}>
            Hủy
          </Button>
        </div>
      </Modal>

      <Modal
        title={'Thêm mới hóa đơn'}
        visible={visible}
        width={'20vw'}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Hủy
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Thêm
          </Button>,
        ]}>
        <Form form={form} layout="vertical">
          <Row gutter={[16, 16]}>
            <Col span={24} style={{ marginTop: '10px' }}>
              <Form.Item
                label="Tên nhà cung cấp"
                name="supplier_id"
                rules={[
                  { required: true, message: 'Vui lòng nhập tên nhà cung cấp' },
                ]}>
                <Select
                  placeholder="Chọn nhà cung cấp"
                  style={{ width: '100%' }}>
                  {supplierData &&
                    supplierData.map(x => (
                      <Select.Option value={x.id} key={x.id}>
                        {x.supplier_name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Ngày nhập kho"
                name="import_date"
                rules={[
                  { required: true, message: 'Vui lòng chọn ngày nhập kho' },
                ]}>
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: '100%' }}
                  placeholder="Chọn ngày nhập kho"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Mô tả" name="description">
                <Input placeholder="Nhập mô tả" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="File hóa đơn"
                name="link_file"
                rules={[
                  { required: true, message: 'Vui lòng tải lên file hóa đơn' },
                ]}
                valuePropName="fileList" // Ensure this matches the property expected by the Upload component
                getValueFromEvent={e => e.fileList} // Extract the fileList from the event
              >
                <Upload
                  listType="text"
                  beforeUpload={beforeUpload} // Validate the file before upload
                  maxCount={1}
                  accept=".pdf"
                  showUploadList={{ showRemoveIcon: true }} // Show the remove icon for uploaded files
                >
                  <Button icon={<UploadOutlined />}>
                    Tải lên file hóa đơn
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Xem hóa đơn"
        visible={pdfVisible}
        footer={null}
        onCancel={handlePdfCancel}
        width="60%"
        bodyStyle={{ padding: 0 }}>
        <iframe
          title="Hóa đơn"
          src={pdfUrl}
          style={{ width: '100%', height: '80vh' }}
          frameBorder="0"></iframe>
      </Modal>
    </DashboardLayout>
  )
}

export default memo(
  inject(
    'supplierStore',
    'loadingAnimationStore',
    'contractStore',
    'invoiceStore'
  )(observer(SupplierPage))
)
