import { action } from 'mobx'
import { InventoryItemRequest } from '../requests/InventoryItemRequest'

class InventoryItemStore {
  @action getInventoryItem = (index, size, search) => {
    return new Promise((resolve, reject) => {
      InventoryItemRequest.getInventoryItem(index, size, search)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getInventoryItemById = id => {
    return new Promise((resolve, reject) => {
      InventoryItemRequest.getInventoryItemById(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action checkExistItem = code => {
    return new Promise((resolve, reject) => {
      InventoryItemRequest.checkExistItem(code)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createInventoryItem = data => {
    return new Promise((resolve, reject) => {
      InventoryItemRequest.createInventoryItem(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getInventoryItemDTO = (index, size, search, filters) => {
    return new Promise((resolve, reject) => {
      InventoryItemRequest.getInventoryItemDTO(index, size, search, filters)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getReportItem = (
    index,
    size,
    search,
    startDate,
    endDate,
    category_id,
    item_type
  ) => {
    return new Promise((resolve, reject) => {
      InventoryItemRequest.getReportItem(
        index,
        size,
        search,
        startDate,
        endDate,
        category_id,
        item_type
      )
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getInventoryItemByCode = code => {
    return new Promise((resolve, reject) => {
      InventoryItemRequest.getInventoryItemByCode(code)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getTotal = () => {
    return new Promise((resolve, reject) => {
      InventoryItemRequest.getTotal()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateItem = data => {
    return new Promise((resolve, reject) => {
      InventoryItemRequest.updateItem(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getRemind = (page, pageSize, quantity) => {
    return new Promise((resolve, reject) => {
      InventoryItemRequest.getRemind(page, pageSize, quantity)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getItemByCategory = () => {
    return new Promise((resolve, reject) => {
      InventoryItemRequest.getItemByCategory()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getItemByDepartment = request => {
    return new Promise((resolve, reject) => {
      InventoryItemRequest.getItemByDepartment(request)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteItem = id => {
    return new Promise((resolve, reject) => {
      InventoryItemRequest.deleteItem(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getBrokenReport = keyword => {
    return new Promise((resolve, reject) => {
      InventoryItemRequest.getBrokenReport(keyword)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action addLog = data => {
    return new Promise((resolve, reject) => {
      InventoryItemRequest.addLog(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new InventoryItemStore()
