import {
  message,
  Card,
  notification,
  Modal,
  Button,
  List,
  Checkbox,
  Select,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { memo, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import DashboardLayout from '../../layouts/DashboardLayout'
import {
  RowWrapper,
  LoginWrapper,
  FormLogin,
  CardWrapper,
} from './DashboardPageStyled'
import { GoogleLogin } from '@react-oauth/google'
import CounterCard from '../../components/CounterCard/CounterCard'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import { Line } from 'react-chartjs-2'
import './RemindModal.css'
import { ListWrapper } from '../../layouts/DashboardLayout/DashboardLayoutStyled'
import { NewWrapper } from '../../components/CounterCard/CounterCardStyled'
import { PrinterOutlined } from '@ant-design/icons'
import utils from '../../utils'
import { getCookieValue } from '../../cookie_utils'

const DashboardPage = props => {
  const {
    history,
    authenticationStore,
    importStore,
    supplierStore,
    inventoryItemStore,
    departmentStore,
    exportStore,
    settingStore,
    campusStore,
  } = props

  const [isLoadingCounter, setIsLoadingCounter] = useState(false)

  const accessToken = getCookieValue('accessToken')
  const refreshToken = getCookieValue('refreshToken')

  const [totalImportWait, setTotalImportWait] = useState(0)
  const [totalSupplier, setTotalSupplier] = useState(0)
  const [totalInventoryItem, setTotalInventoryItem] = useState(0)
  const [totalDepartment, setTotalDepartment] = useState(0)
  const [costPerYear, setCostPerYear] = useState([])
  const [isRemindDetailVisible, setIsRemindDetailVisible] = useState(false)
  const [itemRemindData, setItemRemindData] = useState([])
  const [quantity, setQuantity] = useState(0)
  const [campuses, setCampuses] = useState([])
  const [campusId, setCampusId] = useState(null)

  useEffect(() => {
    if (accessToken || refreshToken) {
      importStore.getPending().then(response => {
        setTotalImportWait(response.data)
      })
      supplierStore.getTotal().then(response => {
        setTotalSupplier(response.data)
      })
      inventoryItemStore.getTotal().then(response => {
        setTotalInventoryItem(response.data)
      })
      departmentStore.getTotal().then(response => {
        setTotalDepartment(response.data)
      })
      exportStore
        .getTotalCostPerYear(new Date().getFullYear())
        .then(response => {
          setCostPerYear(response.data)
        })
      settingStore.getSetting().then(async response => {
        if (response && response.data && response.data.length > 0) {
          var data = response.data.find(x => x.name == 'AllowRemindUnderstock')

          if (!data) {
            return
          }
          setQuantity(data?.value)
          if (data?.is_active) {
            const remindResponse = await inventoryItemStore.getRemind(
              1,
              5,
              data?.value
            )
            const remindData = remindResponse.data
              .slice(0, 5)
              .map(item => {
                return `Mặt hàng <strong style="color: blue">${item.item_name} (${item.item_code})</strong> còn <strong style="color: red">${item.quantity}</strong> sản phẩm`
              })
              .join('<br />')

            const additionalItems =
              remindResponse.data.length > 5 ? '<br />...' : ''

            notification.info({
              icon: (
                <img
                  style={{ width: '35px' }}
                  src={`${process.env.PUBLIC_URL}/assets/icons/dashboard/remind.png`}
                />
              ),
              message: (
                <strong style={{ fontSize: '13px' }}>
                  Mặt hàng tồn kho dưới số lượng tối thiểu
                </strong>
              ),
              description: (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: remindData + additionalItems,
                    }}
                  />
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      type="link"
                      onClick={() => {
                        setIsRemindDetailVisible(true)
                      }}>
                      Xem chi tiết
                    </Button>
                  </div>
                </>
              ),
              duration: 0,
            })
          }
        }
      })
    }
  }, [accessToken, refreshToken])

  useEffect(() => {
    if (isRemindDetailVisible) {
      inventoryItemStore.getRemind(1, 9999, quantity).then(response => {
        setItemRemindData(response.data)
      })
    }
  }, [isRemindDetailVisible])

  useEffect(() => {
    campusStore.getCampusPublic().then(res => {
      setCampuses(res.data)
    })
  }, [campusStore])

  const onSuccess = async credentialResponse => {
    const token = credentialResponse.credential

    try {
      const response = await authenticationStore.userLogin(token, campusId)

      if (response.status === 200) {
        message.success(
          `Xin chào! ${
            response.data.existingUser.username ?? ''
          } đã đăng nhập thành công!`
        )

        history.push('/dashboard')
      } else {
        message.error('Unexpected response status')
      }
    } catch (error) {
      if (error?.response?.data) {
        // message.error(error?.response?.data.DevMsg || 'Có lỗi xảy ra trong quá trình đăng nhập')
      }
    }
  }

  const onFailure = response => {
    console.log(response)
    document.cookie =
      'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    message.error('Login failed response status!')
  }

  const [isAdmin, setIsAdmin] = useState(false)

  const handleCheckboxChange = e => {
    setIsAdmin(e.target.checked)
    if (!e.target.checked) {
      setCampusId(null)
    }
  }

  const handleChange = value => {
    if (isAdmin) {
      setCampusId(value)
    } else {
      setCampusId(null)
    }
  }

  const loginPage = (
    <LoginWrapper
      bgImage={`${process.env.PUBLIC_URL}/assets/photos/FPT_background.jpg`}>
      <FormLogin>
        <div className="formLogin">
          <div className="formLoginGG">
            <div className="intro">
              <h1>Đăng nhập</h1>
              <p style={{ color: 'black' }}>
                Hệ thống quản lý kho FPT University <br />
              </p>
            </div>
            <div id="textError"></div>
            <div
              className="logIn"
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <div>
                <GoogleLogin
                  onSuccess={onSuccess}
                  onError={onFailure}
                  useOneTap
                  cancel_on_tap_outside
                  auto_select
                />

                <Checkbox
                  style={{ marginTop: '8px' }}
                  onChange={handleCheckboxChange}>
                  Đăng nhập với vai trò Admin
                </Checkbox>
                <br />
                {isAdmin && (
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Chọn cơ sở"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={handleChange}
                    options={campuses.map(campus => ({
                      value: campus.id,
                      label: campus.campus_name,
                    }))}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </FormLogin>
    </LoginWrapper>
  )

  const monthLabels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const allMonths = Array.from({ length: 12 }, (_, index) => index + 1)

  const filledData = allMonths.map(month => {
    const existingData = costPerYear
      ? costPerYear.find(item => item.month === month)
      : null
    return existingData ? existingData.total_cost : 0
  })

  const lineData = {
    labels: monthLabels.map(month => `${month}`),
    datasets: [
      {
        label: 'Tổng chi phí',
        data: filledData,
        fill: true,
        backgroundColor: '#cae0f5',
        borderColor: '#3590df',
        borderWidth: 2,
      },
    ],
  }

  const lineOptions = {
    maintainAspectRatio: true,
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: false,
            labelString: 'Tháng',
          },
          ticks: {
            callback: function (value, index, values) {
              return monthLabels[index]
            },
          },
        },
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: false,
            labelString: 'Tổng chi phí',
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            callback: function (value, index, values) {
              return formatNumber(value)
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          const label = monthLabels[tooltipItem.index]
          const value = lineData.datasets[0].data[tooltipItem.index]
          return ` ${formatNumber(value)} VNĐ`
        },
      },
    },
  }

  function formatNumber(value) {
    if (value == null) return ''
    const roundedValue = Number(value).toFixed(2)
    const [integerPart, decimalPart] = roundedValue.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }

  const handlePrint = () => {
    const data = itemRemindData.map((item, index) => ({
      STT: index + 1,
      'MÃ SẢN PHẨM': item.item_code || '',
      'TÊN SẢN PHẨM': item.item_name || '',
      'SỐ LƯỢNG': item.quantity || '',
    }))

    utils.exportToExcel({ data, fileName: 'import-approval-data' })
  }

  return (
    <div>
      {accessToken || refreshToken ? (
        <>
          <DashboardLayout
            title={'Link People - Link the World'}
            backgroundColor={'#efefef'}>
            <Helmet>
              <title>Dashboard | IMS Portal</title>
            </Helmet>
            <div style={{ position: 'relative' }}>
              <RowWrapper>
                <CardWrapper>
                  <CounterCard
                    title={'Số phiếu nhập kho chờ duyệt'}
                    counter={totalImportWait}
                    icon={`${process.env.PUBLIC_URL}/assets/icons/dashboard/import.png`}
                    onClick={() => history.push('/warehouse/approval')}>
                    <SpinnerInlineComponent
                      sizeSpin={'small'}
                      isLoading={isLoadingCounter}
                      backGround={'#ffffffc7'}
                    />
                  </CounterCard>
                  <CounterCard
                    title={'Số nhà cung cấp'}
                    counter={totalSupplier}
                    icon={`${process.env.PUBLIC_URL}/assets/icons/dashboard/supplier.png`}
                    onClick={() => history.push('/category/supplier')}>
                    <SpinnerInlineComponent
                      sizeSpin={'small'}
                      isLoading={isLoadingCounter}
                      backGround={'#ffffffc7'}
                    />
                  </CounterCard>
                  <CounterCard
                    title={'Tổng số hàng hóa'}
                    counter={totalInventoryItem}
                    icon={`${process.env.PUBLIC_URL}/assets/icons/dashboard/item.png`}
                    onClick={() => history.push('/category/item')}>
                    <SpinnerInlineComponent
                      sizeSpin={'small'}
                      isLoading={isLoadingCounter}
                      backGround={'#ffffffc7'}
                    />
                  </CounterCard>
                  <CounterCard
                    title={'Tổng số phòng ban'}
                    counter={totalDepartment}
                    icon={`${process.env.PUBLIC_URL}/assets/icons/dashboard/department.png`}
                    onClick={() => history.push('/category/department')}>
                    <SpinnerInlineComponent
                      sizeSpin={'small'}
                      isLoading={isLoadingCounter}
                      backGround={'#ffffffc7'}
                    />
                  </CounterCard>
                </CardWrapper>
              </RowWrapper>
              <RowWrapper style={{ marginTop: '16px' }}>
                <Card
                  title="Tổng chi phí của các phòng ban theo tháng"
                  style={{ width: '49%', borderRadius: '6px' }}>
                  <Line data={lineData} options={lineOptions} />
                </Card>
                <div style={{ width: '49%' }}>
                  <NewWrapper onClick={() => history.push('/report/inventory')}>
                    <div className="card-info">
                      <img
                        className="icon"
                        src={`${process.env.PUBLIC_URL}/assets/icons/dashboard/báo-cáo-tổng-hợp-tồn-kho.png`}
                        alt=""
                      />
                      <span className="card-title">
                        Báo cáo tổng hợp tồn kho
                      </span>
                    </div>
                  </NewWrapper>
                  <NewWrapper
                    onClick={() => history.push('/report/departmental-cost')}>
                    <div className="card-info">
                      <img
                        className="icon"
                        src={`${process.env.PUBLIC_URL}/assets/icons/dashboard/báo-cáo-chi-phí-các-phòng-ban.png`}
                        alt=""
                      />
                      <span className="card-title">
                        Báo cáo chi phí các phòng ban
                      </span>
                    </div>
                  </NewWrapper>
                  <NewWrapper
                    onClick={() =>
                      history.push('/report/items-by-departments')
                    }>
                    <div className="card-info">
                      <img
                        className="icon"
                        src={`${process.env.PUBLIC_URL}/assets/icons/dashboard/báo-cáo-chi-tiết-mặt-hàng-theo-phòng-ban.png`}
                        alt=""
                      />
                      <span className="card-title">
                        Báo cáo chi tiết mặt hàng theo phòng ban
                      </span>
                    </div>
                  </NewWrapper>
                  <NewWrapper
                    onClick={() =>
                      history.push('/report/departments-by-items')
                    }>
                    <div className="card-info">
                      <img
                        className="icon"
                        src={`${process.env.PUBLIC_URL}/assets/icons/dashboard/báo-cáo-chi-tiết-mặt-hàng-theo-phòng-ban.png`}
                        alt=""
                      />
                      <span className="card-title">
                        Báo cáo chi tiết phòng ban theo mặt hàng
                      </span>
                    </div>
                  </NewWrapper>
                </div>
              </RowWrapper>
            </div>
          </DashboardLayout>
          <Modal
            title={
              <>
                Mặt hàng tồn kho dưới số lượng tối thiểu
                <Button style={{ float: 'right' }} onClick={handlePrint}>
                  <PrinterOutlined /> Xuất excel
                </Button>
              </>
            }
            visible={isRemindDetailVisible}
            closable={false}
            footer={null}
            onCancel={() => setIsRemindDetailVisible(false)}>
            <ListWrapper id="scrollableDiv" style={{ height: '275px' }}>
              <List
                itemLayout="horizontal"
                dataSource={itemRemindData}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.item_name}
                      description={`Mặt hàng ${item.item_name} (${item.item_code}) còn ${item.quantity} sản phẩm`}
                    />
                  </List.Item>
                )}
              />
            </ListWrapper>
          </Modal>
        </>
      ) : (
        loginPage
      )}
    </div>
  )
}

export default memo(
  inject(
    'authenticationStore',
    'importStore',
    'supplierStore',
    'inventoryItemStore',
    'departmentStore',
    'exportStore',
    'settingStore',
    'campusStore'
  )(observer(DashboardPage))
)
