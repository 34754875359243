const ResourceName = {
  INWARD_INVENTORY: 'INWARD_INVENTORY',
  OUTWARD_INVENTORY: 'OUTWARD_INVENTORY',
  CAMPUS: 'CAMPUS',
  ITEM: 'ITEM',
  UNIT: 'UNIT',
  ACCOUNT: 'ACCOUNT',
  DEPARTMENT: 'DEPARTMENT',
  SUPPLIER: 'SUPPLIER',
  ROLE: 'ROLE',
  CATEGORY: 'CATEGORY',
}

const Scope = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
  VIEW: 'VIEW',
  APPROVE: 'APPROVE',
}

const PermissionType = {
  // Permissions for UNIT
  UNIT_CREATE: { resource: ResourceName.UNIT, scope: Scope.CREATE },
  UNIT_DELETE: { resource: ResourceName.UNIT, scope: Scope.DELETE },
  UNIT_UPDATE: { resource: ResourceName.UNIT, scope: Scope.UPDATE },
  UNIT_VIEW: { resource: ResourceName.UNIT, scope: Scope.VIEW },
  UNIT_APPROVE: { resource: ResourceName.UNIT, scope: Scope.APPROVE },

  //permissions for Category
  CATEGORY_CREATE: { resource: ResourceName.CATEGORY, scope: Scope.CREATE },
  CATEGORY_DELETE: { resource: ResourceName.CATEGORY, scope: Scope.DELETE },
  CATEGORY_UPDATE: { resource: ResourceName.CATEGORY, scope: Scope.UPDATE },
  CATEGORY_VIEW: { resource: ResourceName.CATEGORY, scope: Scope.VIEW },
  CATEGORY_APPROVE: { resource: ResourceName.CATEGORY, scope: Scope.APPROVE },

  // Permissions for ROLE
  ROLE_CREATE: { resource: ResourceName.ROLE, scope: Scope.CREATE },
  ROLE_DELETE: { resource: ResourceName.ROLE, scope: Scope.DELETE },
  ROLE_UPDATE: { resource: ResourceName.ROLE, scope: Scope.UPDATE },
  ROLE_VIEW: { resource: ResourceName.ROLE, scope: Scope.VIEW },
  ROLE_APPROVE: { resource: ResourceName.ROLE, scope: Scope.APPROVE },

  // Permissions for SUPPLIER
  SUPPLIER_CREATE: { resource: ResourceName.SUPPLIER, scope: Scope.CREATE },
  SUPPLIER_DELETE: { resource: ResourceName.SUPPLIER, scope: Scope.DELETE },
  SUPPLIER_UPDATE: { resource: ResourceName.SUPPLIER, scope: Scope.UPDATE },
  SUPPLIER_VIEW: { resource: ResourceName.SUPPLIER, scope: Scope.VIEW },
  SUPPLIER_APPROVE: { resource: ResourceName.SUPPLIER, scope: Scope.APPROVE },

  // Permissions for INWARD_INVENTORY
  INWARD_INVENTORY_CREATE: {
    resource: ResourceName.INWARD_INVENTORY,
    scope: Scope.CREATE,
  },
  INWARD_INVENTORY_DELETE: {
    resource: ResourceName.INWARD_INVENTORY,
    scope: Scope.DELETE,
  },
  INWARD_INVENTORY_UPDATE: {
    resource: ResourceName.INWARD_INVENTORY,
    scope: Scope.UPDATE,
  },
  INWARD_INVENTORY_VIEW: {
    resource: ResourceName.INWARD_INVENTORY,
    scope: Scope.VIEW,
  },
  INWARD_INVENTORY_APPROVE: {
    resource: ResourceName.INWARD_INVENTORY,
    scope: Scope.APPROVE,
  },

  // Permissions for OUTWARD_INVENTORY
  OUTWARD_INVENTORY_CREATE: {
    resource: ResourceName.OUTWARD_INVENTORY,
    scope: Scope.CREATE,
  },
  OUTWARD_INVENTORY_DELETE: {
    resource: ResourceName.OUTWARD_INVENTORY,
    scope: Scope.DELETE,
  },
  OUTWARD_INVENTORY_UPDATE: {
    resource: ResourceName.OUTWARD_INVENTORY,
    scope: Scope.UPDATE,
  },
  OUTWARD_INVENTORY_VIEW: {
    resource: ResourceName.OUTWARD_INVENTORY,
    scope: Scope.VIEW,
  },
  OUTWARD_INVENTORY_APPROVE: {
    resource: ResourceName.OUTWARD_INVENTORY,
    scope: Scope.APPROVE,
  },

  // Permissions for CAMPUS
  CAMPUS_CREATE: { resource: ResourceName.CAMPUS, scope: Scope.CREATE },
  CAMPUS_DELETE: { resource: ResourceName.CAMPUS, scope: Scope.DELETE },
  CAMPUS_UPDATE: { resource: ResourceName.CAMPUS, scope: Scope.UPDATE },
  CAMPUS_VIEW: { resource: ResourceName.CAMPUS, scope: Scope.VIEW },
  CAMPUS_APPROVE: { resource: ResourceName.CAMPUS, scope: Scope.APPROVE },

  // Permissions for ITEM
  ITEM_CREATE: { resource: ResourceName.ITEM, scope: Scope.CREATE },
  ITEM_DELETE: { resource: ResourceName.ITEM, scope: Scope.DELETE },
  ITEM_UPDATE: { resource: ResourceName.ITEM, scope: Scope.UPDATE },
  ITEM_VIEW: { resource: ResourceName.ITEM, scope: Scope.VIEW },
  ITEM_APPROVE: { resource: ResourceName.ITEM, scope: Scope.APPROVE },

  // Permissions for ACCOUNT
  ACCOUNT_CREATE: { resource: ResourceName.ACCOUNT, scope: Scope.CREATE },
  ACCOUNT_DELETE: { resource: ResourceName.ACCOUNT, scope: Scope.DELETE },
  ACCOUNT_UPDATE: { resource: ResourceName.ACCOUNT, scope: Scope.UPDATE },
  ACCOUNT_VIEW: { resource: ResourceName.ACCOUNT, scope: Scope.VIEW },
  ACCOUNT_APPROVE: { resource: ResourceName.ACCOUNT, scope: Scope.APPROVE },

  // Permissions for DEPARTMENT
  DEPARTMENT_CREATE: { resource: ResourceName.DEPARTMENT, scope: Scope.CREATE },
  DEPARTMENT_DELETE: { resource: ResourceName.DEPARTMENT, scope: Scope.DELETE },
  DEPARTMENT_UPDATE: { resource: ResourceName.DEPARTMENT, scope: Scope.UPDATE },
  DEPARTMENT_VIEW: { resource: ResourceName.DEPARTMENT, scope: Scope.VIEW },
  DEPARTMENT_APPROVE: {
    resource: ResourceName.DEPARTMENT,
    scope: Scope.APPROVE,
  },
}
export { PermissionType }
