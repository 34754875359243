import { action } from 'mobx'
import { InvoiceImportRequest } from '../requests/InvoiceImportRequest'
import { resolve } from 'path'
class InvoiceStore {
  @action addNewInvoice = data => {
    return new Promise((resolve, reject) => {
      InvoiceImportRequest.addNewInvoice(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getPagingInvoice = (index, size, search, filters) => {
    return new Promise((resolve, reject) => {
      InvoiceImportRequest.getPagingInvoice(index, size, search, filters)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action uploadInvoice = data => {
    return new Promise((resolve, reject) => {
      InvoiceImportRequest.uploadInvoice(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action editInvoice = data => {
    return new Promise((resolve, reject) => {
      InvoiceImportRequest.editInvoice(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteInvoice = id => {
    return new Promise((resolve, reject) => {
      InvoiceImportRequest.deleteInvoice(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
export default new InvoiceStore()
