import { action } from 'mobx'
import { SupplierRequest } from '../requests/SupplierRequest'

class SupplierStore {
  @action getSupplier = (index, size, search) => {
    return new Promise((resolve, reject) => {
      SupplierRequest.getSupplier(index, size, search)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getSupplierDTO = (index, size, search) => {
    return new Promise((resolve, reject) => {
      SupplierRequest.getSupplierDTO(index, size, search)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getSupplierById = id => {
    return new Promise((resolve, reject) => {
      SupplierRequest.getSupplierById(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createSupplier = data => {
    return new Promise((resolve, reject) => {
      SupplierRequest.createSupplier(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateSupplier = data => {
    return new Promise((resolve, reject) => {
      SupplierRequest.updateSupplier(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action checkExistSupplier = name => {
    return new Promise((resolve, reject) => {
      SupplierRequest.checkExistSupplier(name)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getSupplierByName = name => {
    return new Promise((resolve, reject) => {
      SupplierRequest.getSupplierByName(name)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getTotal = campusId => {
    return new Promise((resolve, reject) => {
      SupplierRequest.getTotal(campusId)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action uploadContract = data => {
    return new Promise((resolve, reject) => {
      SupplierRequest.uploadContract(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteSupplier = id => {
    return new Promise((resolve, reject) => {
      SupplierRequest.deleteSupplier(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action addLog = data => {
    return new Promise((resolve, reject) => {
      SupplierRequest.addLog(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default new SupplierStore()
