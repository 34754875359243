import React, { memo, useCallback, useEffect, useState } from 'react'
import { SearchBar } from '../ImportPage/ImportPageStyled'
import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Input,
  Modal,
  Pagination,
  Row,
  Switch,
  Table,
  message,
} from 'antd'
import { TableWrapper } from '../../components/Common/Table'
import { inject, observer } from 'mobx-react'
import AccountModal from './AccountModal'
import SelectRoleModal from './SelectRoleModal'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Container } from '../../layouts/Container/Container'
import * as signalR from '@microsoft/signalr'
import { apiUrl } from '../../config'
const { Search } = Input
const { confirm } = Modal

const AccountTab = props => {
  const { tabKey } = props
  const [selectRoleModalVisible, setSelectRoleModalVisible] = useState(false)
  const { accountStore, loadingAnimationStore } = props
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(0)
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false)
  const [selectedAccountId, setSelectedAccountId] = useState()
  const [accounts, setAccounts] = useState([])

  const fetchAccountsData = useCallback(async () => {
    try {
      const response = await accountStore.getAccounts(page, pageSize, search)
      const { pageData, totalCount } = response.data
      const data = pageData.map((item, index) => ({
        key: item.id,
        stt: (page - 1) * pageSize + index + 1,
        username: item.username,
        email: item.email,
        campus: item.campus,
        active: item.active,
        id: item.id,
        roles: item.roles,
      }))
      setAccounts(data)
      setTotal(totalCount)
    } catch (error) {
      message.error('Có lỗi xảy ra')
    } finally {
    }
  }, [accountStore, page, pageSize, search])

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiUrl}/accountHub`)
      .configureLogging(signalR.LogLevel.None)
      .withAutomaticReconnect()
      .build()

    connection.on('AccountSignal', () => {
      fetchAccountsData()
    })

    connection
      .start()
      .then(() => {
        fetchAccountsData()
      })
      .catch(() => {
        fetchAccountsData()
      })

    return () => {
      connection.stop()
    }
  }, [fetchAccountsData, tabKey])

  const statusFilters = [
    { text: 'Họat động', value: true },
    { text: 'Vô hiệu hóa', value: false },
  ]

  const columns = [
    {
      title: 'STT',
      align: 'center',
      width: '50px',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'USERNAME',
      key: 'username',
      dataIndex: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: 'E-MAIL',
      key: 'email',
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'CƠ SỞ',
      key: 'campus.campus_name',
      dataIndex: 'campus',
      render: (text, record) => (
        <span>
          {record.campus ? record.campus.campus_name : 'Chưa có dữ liệu'}
        </span>
      ),
    },
    {
      title: 'TRẠNG THÁI',
      key: 'active',
      dataIndex: 'active',
      align: 'center',
      filters: statusFilters,
      onFilter: (value, record) => record.active === value,
      render: (text, record) => (
        <Switch
          checked={record.active}
          onChange={checked => handleActivateSwitchChange(checked, record)}
        />
      ),
    },
    {
      title: 'CHỨC NĂNG',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <a
          onClick={() => {
            onUpdateAccount(record.id)
          }}>
          {' '}
          <EditOutlined style={{ marginRight: 8 }} />
          Chỉnh sửa vai trò
        </a>
      ),
    },
  ]

  const handleActivateSwitchChange = (checked, record) => {
    if (checked) {
      handleActivateAccount(record)
    } else {
      confirm({
        title: `Bạn có chắc chắn muốn vô hiệu hóa tài khoản của người dùng ${record.username}?`,
        icon: <ExclamationCircleOutlined />,
        okText: 'Vô hiệu hóa',
        cancelText: 'Hủy',
        onOk() {
          handleDeactivateAccount(record)
        },
        onCancel() {
          setAccounts(
            accounts.map(item =>
              item.key === record.key ? { ...item, active: !checked } : item
            )
          )
        },
      })
    }
  }

  const handleDeactivateAccount = async record => {
    try {
      await accountStore.deactivateAccount(record.id)
      fetchAccountsData()
      message.success('Vô hiệu hóa tài khoản thành công')
    } catch (error) {
      console.error('Failed to deactivate account:', error)
      message.error('Có lỗi xảy ra')
    }
  }

  const handleActivateAccount = async record => {
    try {
      await accountStore.activateAccount(record.id)
      fetchAccountsData()
      message.success('Kích hoạt tài khoản thành công')
    } catch (error) {
      message.error('Có lỗi xảy ra!')
    }
  }

  const handleCreateAccount = value => {
    accountStore.createAccount(value).then(res => {
      fetchAccountsData()
      message.success('Tạo tài khoản mới thành công')
      setIsAccountModalOpen(false)
      accountStore.addLog({
        table_name: 'Tài khoản',
        action: 'Tạo mới',
        newObj: value,
        oldObj: {
          email: '',
        },
      })
    })
  }

  const handleAccountModalOpen = () => {
    setIsAccountModalOpen(true)
  }

  const handleAccountModalCancel = () => {
    setIsAccountModalOpen(false)
  }

  const handleAccountModalOk = values => {
    handleCreateAccount(values)
  }

  const onUpdateAccount = accountId => {
    setSelectedAccountId(accountId)
    setSelectRoleModalVisible(true)
  }

  const handleCloseSelectRoleModal = () => {
    setSelectRoleModalVisible(false)
  }

  const handleOkUpdateAccount = async (id, request) => {
    try {
      await accountStore.updateAccount(id, request)
      fetchAccountsData()
      message.success('Cập nhật tài khoản thành công')
      setSelectRoleModalVisible(false)
    } catch (error) {
      message.error('Có lỗi xảy ra!')
    }
  }

  return (
    <>
      <ContentBlockWrapper>
        <Container>
          <Row style={{ marginBottom: 0, height: '38px' }}>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  onSearch={value => {
                    setPage(1), setPageSize(10), setSearch(value)
                  }}
                  maxLength={500}
                  placeholder={'Tìm kiếm...'}
                />
              </SearchBar>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button type={'primary'} onClick={handleAccountModalOpen}>
                Thêm tài khoản
              </Button>
            </Col>
          </Row>
          <TableWrapper>
            <Table
              style={{
                height: 'calc(100vh - 235px)',
                overflowY: 'auto',
              }}
              columns={columns}
              dataSource={accounts}
              bordered
              pagination={false}
              className="custom-table"
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div>
                Tổng số: <b>{total}</b> bản ghi
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '8px',
                }}>
                <Pagination
                  onChange={(page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                  }}
                  current={page}
                  pageSize={pageSize}
                  total={total}
                  showSizeChanger={true}
                  showLessItems
                />
              </div>
            </div>
          </TableWrapper>
        </Container>
      </ContentBlockWrapper>
      <AccountModal
        isModalOpen={isAccountModalOpen}
        handleModalCancel={handleAccountModalCancel}
        handleModalOk={handleAccountModalOk}
      />
      <SelectRoleModal
        setAccountId={setSelectedAccountId}
        accountId={selectedAccountId}
        open={selectRoleModalVisible}
        handleSubmit={handleOkUpdateAccount}
        handleClose={handleCloseSelectRoleModal}
      />
    </>
  )
}

export default memo(
  inject('accountStore', 'loadingAnimationStore')(observer(AccountTab))
)
