// src/pages/ItemPage/ItemPage.js

import React, { useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import DashboardLayout from '../../layouts/DashboardLayout'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import { TabWrapper } from '../../components/Common/TabComponent'
import { Tabs } from 'antd'
import ItemVatTu from './ItemVatTu'
import ItemPKD from './ItemPKD'

const ItemPage = props => {
  const { loadingAnimationStore, inventoryItemStore } = props

  return (
    <>
      <DashboardLayout>
        <Helmet>
          <title>Quản lý hàng hóa, dịch vụ </title>
        </Helmet>
        <PageTitle
          location={props.location}
          title={'Quản lý hàng hóa, dịch vụ'}
          hiddenGoBack></PageTitle>
        <TabWrapper>
          <Tabs>
            <Tabs.TabPane tab="Tiêu hao" key="0">
              <ItemVatTu />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Phi kinh doanh" key="1">
              <ItemPKD />
            </Tabs.TabPane>
          </Tabs>
        </TabWrapper>
      </DashboardLayout>
    </>
  )
}

export default inject()(observer(ItemPage))
