import React, { memo } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import PageTitle from '../../components/PageTitle'
import { Helmet } from 'react-helmet/es/Helmet'
import { inject, observer } from 'mobx-react'
import { Tabs } from 'antd'
import ExportItemTab from './ExportItemTab'
import ExportPrinterTab from './Printer/ExportPrinterTab'
import { TabWrapper } from '../../components/Common/TabComponent'

const ExportPage = props => {
  return (
    <>
      <DashboardLayout>
        <Helmet>
          <title>Quản lý xuất kho</title>
        </Helmet>
        <PageTitle
          location={props.location}
          title={'Quản lý xuất kho'}
          hiddenGoBack></PageTitle>
        <TabWrapper>
          <Tabs defaultActiveKey="export">
            <Tabs.TabPane tab="Xuất kho" key="export">
              <ExportItemTab />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Máy in" key="printer">
              <ExportPrinterTab />
            </Tabs.TabPane>
          </Tabs>
        </TabWrapper>
      </DashboardLayout>
    </>
  )
}

export default memo(inject('loadingAnimationStore')(observer(ExportPage)))
