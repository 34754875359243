import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import React from 'react'
import HomeOutlined from '@ant-design/icons/lib/icons/HomeOutlined'
import { routes } from '../../routes'

const SimpleBreadCrumb = props => {
  const { location } = props

  const pathSnippets =
    location !== undefined ? location.pathname.split('/').filter(i => i) : []
  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      return `/${pathSnippets.slice(0, index + 1).join('/')}`
    })
    .map(url => routes.find(route => route.path === url))
    .filter(route => route)
    .map(route => (
      <Breadcrumb.Item key={route.path}>
        <Link to={route.path}>
          <strong>{route.name.toUpperCase()}</strong>
        </Link>
      </Breadcrumb.Item>
    ))

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/dashboard">
        <HomeOutlined />
      </Link>
      <strong style={{ marginLeft: '-1px', marginRight: '4px' }}>.</strong>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems)

  return (
    <Breadcrumb separator="" style={{ display: 'flex' }}>
      {breadcrumbItems}
    </Breadcrumb>
  )
}

export default SimpleBreadCrumb
