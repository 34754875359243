import React, { memo, useEffect, useState } from 'react'
import { Spin, Result } from 'antd'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CompassFilled,
} from '@ant-design/icons'
import './RejectPage.css'
import { inject, observer } from 'mobx-react'

const RejectPage = props => {
  const [status, setStatus] = useState('loading')
  const [orderId, setOrderId] = useState(null)
  const { exportStore } = props
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    const type = params.get('type')
    setOrderId(id)

    if (id) {
      exportStore
        .approveOrder({ id: id, type: type })
        .then(() => {
          setStatus('success')
        })
        .catch(error => {
          setStatus('failed')
        })
    }
  }, [])

  return (
    <div className="confirm-receive-container">
      {status === 'loading' && (
        <div>
          <Spin size="large" />
          <h1>Đang xử lý quá trình từ chối...</h1>
        </div>
      )}
      {status === 'success' && (
        <Result
          icon={<CheckCircleOutlined style={{ color: 'green' }} />}
          title="Hoàn tất từ chối đơn hàng"
          subTitle="Đơn hàng của bạn đã được từ chối thành công."
        />
      )}
      {status === 'failed' && (
        <Result
          icon={<CloseCircleOutlined style={{ color: 'red' }} />}
          title="Bạn không thể từ chối nhận hàng"
          subTitle="Đã xảy ra lỗi trong quá trình từ chối đơn hàng của bạn."
        />
      )}
    </div>
  )
}
export default memo(
  inject('exportStore', 'loadingAnimationStore')(observer(RejectPage))
)
