import React, { memo, useEffect, useState } from 'react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Modal,
  Pagination,
  Row,
  Space,
  Table,
  message,
  Tooltip,
  Tag,
} from 'antd'
import { TableWrapper } from '../../components/Common/Table'
import { FilterButtonWrapper, ExportTable, SearchBar } from './ExportPageStyled'
import {
  CaretDownOutlined,
  CloseCircleOutlined,
  CheckOutlined,
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
  FilterOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
  CloseOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import AdvancedFilterBlock from './AdvancedFilterBlock'
import ExportCreateModal from './ExportCreateModal'
import { inject, observer } from 'mobx-react'
import utils from '../../utils'
import ExportDetailUpdate from './ExportDetailUpdate'
import ExportExcelCreateModal from './ExportExcelCreateModal'
import ExportPagePrinter from './Printer/ExportPrinterCreateModal'
import { EmptyText } from '../../components/Common/CellText'
import { Container } from '../../layouts/Container/Container'
import * as signalR from '@microsoft/signalr'
import { apiUrl } from '../../config'
import { PermissionType } from '../../enum'

const { Search } = Input
const { confirm } = Modal

const ExportItemTab = props => {
  const {
    exportStore,
    loadingAnimationStore,
    inventoryItemStore,
    accountStore,
  } = props
  const [exportData, setExportData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchValue, setSearchValue] = useState('')
  const [filters, setFilters] = useState([])
  const [total, setTotal] = useState(0)
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false)
  const [countFilter, setCountFilter] = useState(0)
  const [openExportCreate, setOpenExportCreate] = useState(false)
  const [openExportExcelCreate, setOpenExportExcelCreate] = useState(false)
  const [openExportPagePrinter, setOpenExportPagePrinter] = useState(false)
  const [excelData, setExcelData] = useState({})
  const [openExportDetailUpdate, setOpenExportDetailUpdate] = useState(false)
  const [exportId, setExportId] = useState('')
  const [type, setType] = useState('')

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${apiUrl}/outwardStockHub`)
      .configureLogging(signalR.LogLevel.None)
      .withAutomaticReconnect()
      .build()

    connection.on('OutwardStockSignal', () => {
      fetchExportInvoice()
    })

    connection
      .start()
      .then(() => {
        fetchExportInvoice()
      })
      .catch(() => {
        fetchExportInvoice()
      })

    return () => {
      connection.stop()
    }
  }, [page, pageSize, searchValue, filters])

  const fetchExportInvoice = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      const response = await exportStore.getExportInvoice(
        page,
        pageSize,
        searchValue,
        [
          {
            data_field: 'export_type',
            value: '0',
            operator_type: 'equal',
          },
          ...filters,
        ]
      )
      const data = response.data.pageData.map((item, index) => ({
        key: item.id,
        stt: index + 1,
        invoice_no: item.invoice_no,
        invoice_date: item.invoice_date,
        department_name: item.department_name,
        total_amount: item.total_amount,
        note: item.note,
        status: item.status,
      }))
      setExportData(data)
      setTotal(response.data.totalCount)
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  function formatNumber(value) {
    if (value == null) return ''
    const roundedValue = Number(value).toFixed(2)
    const [integerPart, decimalPart] = roundedValue.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }

  const fullWidthStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  }

  const statusFilters = [
    { text: 'Đang kiểm hàng để xuất', value: 0 },
    { text: 'Đợi người nhận xác nhận', value: 1 },
    { text: 'Đơn hàng đã bị hủy', value: 2 },
    { text: 'Chờ xác nhận đủ hàng', value: 3 },
    { text: 'Hoàn thành', value: 4 },
  ]

  const columns = [
    {
      title: 'STT',
      key: 'stt',
      width: '20px',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'SỐ PHIẾU XUẤT',
      key: 'invoice_no',
      dataIndex: 'invoice_no',
      sorter: (a, b) => a.invoice_no.localeCompare(b.invoice_no),
      render: text => (
        <span>{text || <EmptyText>Chưa có số phiếu xuất</EmptyText>}</span>
      ),
    },
    {
      title: 'NGÀY XUẤT HÀNG',
      key: 'invoice_date',
      dataIndex: 'invoice_date',
      sorter: (a, b) => new Date(a.invoice_date) - new Date(b.invoice_date),
      render: text => {
        if (text) {
          const date = new Date(text)
          return (
            <span>
              {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
            </span>
          )
        } else {
          return <EmptyText>Chưa có ngày xuất hàng</EmptyText>
        }
      },
    },
    {
      title: 'PHÒNG BAN',
      key: 'department_name',
      dataIndex: 'department_name',
      sorter: (a, b) => a.department_name.localeCompare(b.department_name),
      render: text => (
        <span>{text || <EmptyText>Chưa có phòng ban</EmptyText>}</span>
      ),
    },
    {
      title: 'THÀNH TIỀN (VNĐ)',
      key: 'total_amount',
      dataIndex: 'total_amount',
      sorter: (a, b) => a.total_amount - b.total_amount,
      render: text => (
        <span>
          {formatNumber(text) || <EmptyText>Chưa có tổng tiền</EmptyText>}
        </span>
      ),
    },
    {
      title: 'GHI CHÚ',
      key: 'note',
      dataIndex: 'note',
      render: text => (
        <span>{text || <EmptyText>Chưa có ghi chú</EmptyText>}</span>
      ),
    },
    {
      title: 'TRẠNG THÁI',
      key: 'status',
      sorter: (a, b) => a.status - b.status,
      dataIndex: 'status',
      filters: statusFilters,
      onFilter: (value, record) => record.status === value,
      render: status => {
        let color
        let content
        switch (status) {
          case 0:
            color = 'darkgrey'
            content = 'Đang kiểm hàng để xuất'
            break
          case 1:
            color = 'orange'
            content = 'Đợi người nhận xác nhận'
            break
          case 2:
            color = 'red'
            content = 'Đơn hàng đã bị hủy'
            break
          case 3:
            color = 'yellow'
            content = 'Chờ xác nhận đủ hàng'
            break
          case 4:
            color = 'green'
            content = 'Hoàn thành'
            break
          default:
            color = ''
            content = ''
        }
        if (status != null)
          return (
            <div style={fullWidthStyle}>
              <Tag
                color={color}
                style={{ width: '150px', textAlign: 'center' }}>
                {content}
              </Tag>
            </div>
          )
        else return <EmptyText>Chưa có trạng thái</EmptyText>
      },
    },
    {
      title: '',
      key: '#',
      width: 1,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {record.status === 0 && (
            <>
              <Tooltip title="Duyệt">
                <Button
                  style={{ marginRight: '5px' }}
                  onClick={e => {
                    e.stopPropagation()
                    confirm({
                      title:
                        'Bạn có muốn chuyển trạng thái đơn xuất kho này thành chờ xác nhận không?',
                      icon: (
                        <QuestionCircleOutlined style={{ color: 'blue' }} />
                      ),
                      okText: 'Đồng ý',
                      cancelText: 'Không',
                      okType: 'primary',
                      onOk() {
                        handleAction(record.key, 1)
                      },
                    })
                  }}
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  size="small"
                />
              </Tooltip>
              {isAccessPermission(PermissionType.OUTWARD_INVENTORY_CREATE) && (
                <Tooltip title="Sửa">
                  <Button
                    onClick={e => {
                      e.stopPropagation()
                      setOpenExportDetailUpdate(true)
                      setExportId(record.key)
                      setType('update')
                    }}
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    size="small"
                  />
                </Tooltip>
              )}
              {isAccessPermission(PermissionType.OUTWARD_INVENTORY_DELETE) && (
                <Tooltip title="Xóa đơn hàng">
                  <Button
                    style={{ marginLeft: '5px' }}
                    onClick={e => {
                      e.stopPropagation()
                      showConfirm(record.key)
                    }}
                    type="danger"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    size="small"
                  />
                </Tooltip>
              )}
            </>
          )}
        </div>
      ),
    },
  ]

  const handleAction = async (id, status) => {
    loadingAnimationStore.showSpinner(true)
    try {
      await exportStore.updateStatus(id, status)
      message.success('Cập nhật trạng thái xuất kho thành công!')
      await fetchExportInvoice()
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const showConfirm = id => {
    confirm({
      title: 'Bạn có muốn xóa đơn xuất kho này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDelete(id)
      },
    })
  }
  const mapToOutwardStockDetailDTO = (detail, itemNameMap) => ({
    id: detail.id,
    outward_id: detail.outward_id,
    item_id: detail.item_id,
    item_name: itemNameMap?.find(x => x.id == detail.item_id)?.item_name, // Set to null or handle as needed
    campus_id: detail.campus_id,
    unit_id: detail.unit_id,
    unit_name: itemNameMap?.find(x => x.id == detail.item_id)?.unit_name, // Set to null or handle as needed
    quantity: detail.quantity,
    unit_price: detail.unit_price,
    outward_non_business_type: detail.outward_non_business_type,
    deleted: detail.deleted,
    return_date: detail.return_date,
  })

  const handleDelete = async id => {
    loadingAnimationStore.showSpinner(true)
    try {
      exportStore.getExportInvoiceDetail(id).then(async res => {
        await exportStore.deleteExportInvoice(id)

        var setOldMaster = res.data.masterDTO
        var setOldDetails = res.data.details
        await inventoryItemStore
          .getInventoryItem(1, 9999, '')
          .then(async res => {
            var itemList = res.data.pageData
            if (setOldDetails) {
              const convertedDetails = setOldDetails.map(detail =>
                mapToOutwardStockDetailDTO(detail, itemList)
              )
              if (convertedDetails) {
                var auditLogRequest = {
                  table_name: 'Xuất kho',
                  action: 'Xóa',
                  oldObj: setOldMaster,
                  newObj: null,
                  oldDetails: convertedDetails,
                  newDetails: null,
                }
                await exportStore.addAuditLog(auditLogRequest)
              }
            }
          })

        message.success('Xóa đơn xuất kho thành công!')
        await fetchExportInvoice()
      })
    } catch (error) {
      console.error(error)
    } finally {
      loadingAnimationStore.showSpinner(false)
      await fetchExportInvoice()
    }
  }

  const menu = (
    <Menu>
      <Menu.Item key="nhap-file-xlsx">
        <a onClick={() => setOpenExportExcelCreate(true)}>Nhập file XLSX</a>
      </Menu.Item>
    </Menu>
  )

  const handlePrint = () => {
    const data = exportData.map((item, index) => ({
      STT: index + 1,
      'Số Phiếu xuất': item.invoice_no || '',
      'Ngày xuất': item.invoice_date
        ? new Date(item.invoice_date).toLocaleDateString()
        : '',
      'Phòng ban': item.department_name || '',
      'Tổng tiền': formatNumber(item.total_amount) || '',
      'Ghi chú': item.note || '',
    }))

    utils.exportToExcel({ data, fileName: 'xuat_kho_tieu_hao' })
  }

  const isAccessPermission = permissionType => {
    if (accountStore.hasPermission(permissionType)) return true
    else return false
  }

  return (
    <>
      <ContentBlockWrapper>
        <Container>
          <Row style={{ marginBottom: 0, height: '38px' }}>
            <Col span={12}>
              {!showAdvancedFilter ? (
                <SearchBar>
                  <Search
                    allowClear
                    onSearch={value => {
                      setPage(1), setPageSize(10), setSearchValue(value)
                    }}
                    maxLength={500}
                    placeholder={
                      'Tìm kiếm theo Số phiếu xuất / Phòng ban / Ghi chú'
                    }
                  />
                </SearchBar>
              ) : null}
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <FilterButtonWrapper>
                <span
                  className={'count-filter'}
                  style={{ display: !countFilter && 'none' }}>
                  {countFilter}
                </span>
                <Button
                  type={showAdvancedFilter ? 'primary' : 'default'}
                  ghost={showAdvancedFilter}
                  style={{ marginBottom: showAdvancedFilter ? 10 : 0 }}
                  onClick={() => setShowAdvancedFilter(!showAdvancedFilter)}>
                  {showAdvancedFilter ? (
                    <Space>
                      <CloseCircleOutlined />
                      Ẩn tìm kiếm nâng cao
                    </Space>
                  ) : (
                    <Space>
                      <FilterOutlined />
                      Tìm kiếm nâng cao
                    </Space>
                  )}
                </Button>
              </FilterButtonWrapper>
              <Button style={{ marginLeft: '10px' }} onClick={handlePrint}>
                <PrinterOutlined />
                Xuất excel
              </Button>
              <Button
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  fetchExportInvoice()
                }}>
                <ReloadOutlined />
              </Button>
              {isAccessPermission(PermissionType.OUTWARD_INVENTORY_CREATE) && (
                <Dropdown overlay={menu} placement="bottomLeft">
                  <Button
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      setOpenExportCreate(true)
                    }}
                    type={'primary'}>
                    Thêm | <CaretDownOutlined />
                  </Button>
                </Dropdown>
              )}
            </Col>
          </Row>
          {showAdvancedFilter ? (
            <AdvancedFilterBlock
              setPage={setPage}
              setPageSize={setPageSize}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              setFilters={setFilters}
              filters={filters}
              setCountFilter={setCountFilter}
            />
          ) : null}
          <TableWrapper>
            <ExportTable>
              <Table
                className="exportTable"
                style={{
                  height: !showAdvancedFilter
                    ? 'calc(100vh - 235px)'
                    : 'calc(100vh - 304.5px)',
                  overflowY: 'auto',
                }}
                columns={columns}
                dataSource={exportData}
                bordered
                pagination={false}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: event => {
                      setOpenExportDetailUpdate(true)
                      setExportId(record.key)
                      setType('detail')
                    },
                  }
                }}
              />
            </ExportTable>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div>
                Tổng số: <b>{total}</b> bản ghi
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '8px',
                }}>
                <Pagination
                  onChange={(page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                  }}
                  current={page}
                  pageSize={pageSize}
                  total={total}
                  hideOnSinglePage={true}
                  showSizeChanger={true}
                  showLessItems
                />
              </div>
            </div>
          </TableWrapper>
        </Container>
      </ContentBlockWrapper>
      <ExportCreateModal
        open={openExportCreate}
        setOpen={setOpenExportCreate}
        fetchExportInvoice={fetchExportInvoice}
        excelData={excelData}
        setExcelData={setExcelData}
      />
      <ExportExcelCreateModal
        open={openExportExcelCreate}
        setOpen={setOpenExportExcelCreate}
        setOpenExportCreate={setOpenExportCreate}
        setExcelData={setExcelData}
      />
      <ExportPagePrinter
        open={openExportPagePrinter}
        setOpen={setOpenExportPagePrinter}
      />
      <ExportDetailUpdate
        open={openExportDetailUpdate}
        setOpen={setOpenExportDetailUpdate}
        exportId={exportId}
        setExportId={setExportId}
        type={type}
        fetchExportInvoice={fetchExportInvoice}
      />
    </>
  )
}

export default memo(
  inject(
    'exportStore',
    'loadingAnimationStore',
    'supplierStore',
    'inventoryItemStore',
    'accountStore'
  )(observer(ExportItemTab))
)
