import axios from 'axios'
import { apiUrl } from '../config'

export const RoleRequest = {
  getRole: id =>
    axios.get(`${apiUrl}/api/v1/roles/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  getRoles: (index, size, search) =>
    axios.post(
      `${apiUrl}/api/v1/roles/paging_filter`,
      {
        pageIndex: index,
        pageSize: size,
        search: search,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    ),

  createRole: request =>
    axios.post(`${apiUrl}/api/v1/roles/create`, request, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  updateRole: (id, request) =>
    axios.post(`${apiUrl}/api/v1/roles/${id}/update`, request, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  deleteRole: id =>
    axios.post(`${apiUrl}/api/v1/roles/${id}/delete`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  assignAccounts: (id, request) =>
    axios.post(`${apiUrl}/api/v1/roles/${id}/assign-accounts`, {
      request,
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  addLog: request =>
    axios.post(`${apiUrl}/api/v1/roles/add-audit-log`, request),
}
