export function setCookie(name, value, days, path, domain, secure, sameSite) {
  const expires = new Date()
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000)
  const secureFlag = secure ? 'Secure' : ''
  const sameSiteFlag = sameSite ? `SameSite=${sameSite}` : ''
  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value
  )}; expires=${expires.toUTCString()}; path=${path}; domain=${domain}; ${secureFlag}; ${sameSiteFlag}`
}

export function deleteCookie(name, path, domain) {
  document.cookie = `${encodeURIComponent(
    name
  )}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}; domain=${domain}`
}

export function getCookieValue(name) {
  const regex = new RegExp('(^|; )' + encodeURIComponent(name) + '=([^;]*)')
  const match = document.cookie.match(regex)
  return match ? decodeURIComponent(match[2]) : null
}
