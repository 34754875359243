import React, { useState, useEffect, memo } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import DashboardLayout from '../../layouts/DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { Input, Row, Col, Table, Typography, Select } from 'antd'
import { TableWrapper } from '../../components/Common/Table'
import { inject, observer } from 'mobx-react'
import { SearchBar } from '../ImportPage/ImportPageStyled'
import './ReportDepartmentStyled.css'
import departmentStore from '../../stores/departmentStore'
import { Container } from '../../layouts/Container/Container'
import { render } from 'less'

const { Search } = Input
const { Text } = Typography
const { Option } = Select

const ReportDepartmentPage = props => {
  const { history, DepartmentStore } = props
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [originalData, setOriginalData] = useState([])
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

  function formatNumber(value) {
    if (value == null) return ''
    const roundedValue = Number(value).toFixed(2)
    const [integerPart, decimalPart] = roundedValue.split('.')
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart
  }

  const departmentFilters = data
    .map(item => item.department_name)
    .filter((value, index, self) => self.indexOf(value) === index)
    .map(department => ({
      text: department,
      value: department,
    }))

  const columns = [
    {
      title: 'TÊN',
      dataIndex: 'department_name',
      fixed: 'left',
      filters: departmentFilters,
      onFilter: (value, record) => record.department_name === value,
    },
    {
      title: 'THÁNG 1',
      dataIndex: 'jan',
      render: text => <span>{text ? formatNumber(text) : ''}</span>,
      sorter: (a, b) => (a.jan || 0) - (b.jan || 0),
    },
    {
      title: 'THÁNG 2',
      dataIndex: 'feb',
      render: text => <span>{text ? formatNumber(text) : ''}</span>,
      sorter: (a, b) => (a.feb || 0) - (b.feb || 0),
    },
    {
      title: 'THÁNG 3',
      dataIndex: 'mar',
      render: text => <span>{text ? formatNumber(text) : ''}</span>,
      sorter: (a, b) => (a.mar || 0) - (b.mar || 0),
    },
    {
      title: 'THÁNG 4',
      dataIndex: 'apr',
      render: text => <span>{text ? formatNumber(text) : ''}</span>,
      sorter: (a, b) => (a.apr || 0) - (b.apr || 0),
    },
    {
      title: 'THÁNG 5',
      dataIndex: 'may',
      render: text => <span>{text ? formatNumber(text) : ''}</span>,
      sorter: (a, b) => (a.may || 0) - (b.may || 0),
    },
    {
      title: 'THÁNG 6',
      dataIndex: 'jun',
      render: text => <span>{text ? formatNumber(text) : ''}</span>,
      sorter: (a, b) => (a.jun || 0) - (b.jun || 0),
    },
    {
      title: 'THÁNG 7',
      dataIndex: 'jul',
      render: text => <span>{text ? formatNumber(text) : ''}</span>,
      sorter: (a, b) => (a.jul || 0) - (b.jul || 0),
    },
    {
      title: 'THÁNG 8',
      dataIndex: 'aug',
      render: text => <span>{text ? formatNumber(text) : ''}</span>,
      sorter: (a, b) => (a.aug || 0) - (b.aug || 0),
    },
    {
      title: 'THÁNG 9',
      dataIndex: 'sep',
      render: text => <span>{text ? formatNumber(text) : ''}</span>,
      sorter: (a, b) => (a.sep || 0) - (b.sep || 0),
    },
    {
      title: 'THÁNG 10',
      dataIndex: 'oct',
      render: text => <span>{text ? formatNumber(text) : ''}</span>,
      sorter: (a, b) => (a.oct || 0) - (b.oct || 0),
    },
    {
      title: 'THÁNG 11',
      dataIndex: 'nov',
      render: text => <span>{text ? formatNumber(text) : ''}</span>,
      sorter: (a, b) => (a.nov || 0) - (b.nov || 0),
    },
    {
      title: 'THÁNG 12',
      dataIndex: 'dec',
      render: text => <span>{text ? formatNumber(text) : ''}</span>,
      sorter: (a, b) => (a.dec || 0) - (b.dec || 0),
    },

    {
      title: 'TỔNG',
      dataIndex: 'Total',
      fixed: 'right',
      render: (_, record) => {
        const total =
          (record.jan || 0) +
          (record.feb || 0) +
          (record.mar || 0) +
          (record.apr || 0) +
          (record.may || 0) +
          (record.jun || 0) +
          (record.jul || 0) +
          (record.aug || 0) +
          (record.sep || 0) +
          (record.oct || 0) +
          (record.nov || 0) +
          (record.dec || 0)
        return <Text>{total === 0 ? '' : formatNumber(total)}</Text>
      },
      sorter: (a, b) => {
        const totalA =
          (a.jan || 0) +
          (a.feb || 0) +
          (a.mar || 0) +
          (a.apr || 0) +
          (a.may || 0) +
          (a.jun || 0) +
          (a.jul || 0) +
          (a.aug || 0) +
          (a.sep || 0) +
          (a.oct || 0) +
          (a.nov || 0) +
          (a.dec || 0)
        const totalB =
          (b.jan || 0) +
          (b.feb || 0) +
          (b.mar || 0) +
          (b.apr || 0) +
          (b.may || 0) +
          (b.jun || 0) +
          (b.jul || 0) +
          (b.aug || 0) +
          (b.sep || 0) +
          (b.oct || 0) +
          (b.nov || 0) +
          (b.dec || 0)
        return totalA - totalB
      },
    },
  ]

  const fetchData = async year => {
    setLoading(true)
    try {
      const response = await departmentStore.getDepartmentMonthlyCost(year)
      const transformedData = response.data.map(item => ({
        key: item.id,
        department_name: item.department_name,
        jan: item.jan,
        feb: item.feb,
        mar: item.mar,
        apr: item.apr,
        may: item.may,
        jun: item.jun,
        jul: item.jul,
        aug: item.aug,
        sep: item.sep,
        oct: item.oct,
        nov: item.nov,
        dec: item.dec,
      }))

      setOriginalData(transformedData)
      setData(transformedData)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = value => {
    setSearch(value)
    const filteredData = originalData.filter(item =>
      item.department_name.toLowerCase().includes(value.toLowerCase())
    )
    setData(filteredData)
  }

  const handleYearChange = value => {
    setSelectedYear(value)
    fetchData(value)
  }

  useEffect(() => {
    fetchData(selectedYear)
  }, [selectedYear])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Chi phí phòng ban</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={'Chi phí phòng ban'}
        hiddenGoBack></PageTitle>
      <ContentBlockWrapper>
        <Container>
          <Row style={{ marginBottom: 0, height: '38px' }}>
            <Col span={12}>
              <SearchBar>
                <Search
                  allowClear
                  onSearch={handleSearch}
                  maxLength={500}
                  placeholder={'Tìm kiếm theo tên phòng ban'}
                />
              </SearchBar>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Select
                defaultValue={selectedYear}
                style={{ width: 65 }}
                onChange={handleYearChange}>
                {Array.from(
                  { length: 10 },
                  (_, i) => new Date().getFullYear() - i
                ).map(year => (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <TableWrapper>
            <Table
              columns={columns}
              dataSource={data}
              bordered
              scroll={{ x: 'calc(100vw - 200px)', y: 'calc(100vh - 335px)' }}
              pagination={false}
              loading={loading}
              summary={pageData => {
                let totals = {
                  jan: 0,
                  feb: 0,
                  mar: 0,
                  apr: 0,
                  may: 0,
                  jun: 0,
                  jul: 0,
                  aug: 0,
                  sep: 0,
                  oct: 0,
                  nov: 0,
                  dec: 0,
                }

                pageData.forEach(item => {
                  totals.jan += item.jan || 0
                  totals.feb += item.feb || 0
                  totals.mar += item.mar || 0
                  totals.apr += item.apr || 0
                  totals.may += item.may || 0
                  totals.jun += item.jun || 0
                  totals.jul += item.jul || 0
                  totals.aug += item.aug || 0
                  totals.sep += item.sep || 0
                  totals.oct += item.oct || 0
                  totals.nov += item.nov || 0
                  totals.dec += item.dec || 0
                })

                const totalSum = Object.values(totals).reduce(
                  (acc, curr) => acc + curr,
                  0
                )

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-row">
                      <Table.Summary.Cell index={0} fixed="left">
                        Tổng cộng
                      </Table.Summary.Cell>
                      {Object.values(totals).map((total, index) => (
                        <Table.Summary.Cell key={index} index={index + 1}>
                          <Text>{total === 0 ? '' : formatNumber(total)}</Text>
                        </Table.Summary.Cell>
                      ))}
                      <Table.Summary.Cell index={13}>
                        <Text>
                          {totalSum === 0 ? '' : formatNumber(totalSum)}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )
              }}
            />
          </TableWrapper>
        </Container>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default memo(inject('departmentStore')(observer(ReportDepartmentPage)))
