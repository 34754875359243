import { action } from 'mobx'
import { ContractRequest } from '../requests/ContractRequest'
import { resolve } from 'path'
class ContractStore {
  @action addContract = data => {
    return new Promise((resolve, reject) => {
      ContractRequest.addContract(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getContractsBySupplierId = id => {
    return new Promise((resolve, reject) => {
      ContractRequest.getContractBySupplierId(id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
export default new ContractStore()
